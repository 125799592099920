import { post } from "../../helpers/api";
import { i18n } from "../../main"; // Adjust the import path accordingly

const state = {
  items: [],
  isLoadingData: true,
  isBtnLoading: false,
  isBtnEditLoading: false,
  search: "",
  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
  box: {
    bo_name: "",
  },
  total_iqd: 0,
  total_usd: 0,
};

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items.transactions;
    const t = i18n.global.t;
    //loop inside items and localize every type replcae by $t(item.type)
    state.items.forEach((item) => {
      item.type = t(item.type);
      //and currency too check if ==1 replace with t('dinar') else t('dollar')
   
      item.currency = item.currency == 1 ? t("dinar") : t("dollar");
      //item.money_direction =in then in_money else if  /item.money_direction =out then out_money else item.money_direction=exchange then exchange_money
      item.money_direction =
        item.money_direction == "in"
          ? t("in_money")
          : item.money_direction == "out"
          ? t("out_money")
          : t("exchange_money");
    });
    state.total_iqd = items.total_iqd;
    state.total_usd = items.total_usd;
    state.box = items.box;
    state.isLoadingData = false;
  },
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },

  REMOVE_ITEM(state, id) {
    state.items = state.items.filter((item) => {
      return item.cu_id !== id;
    });
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  SET_DELETE_SUCCESS(state, value) {
    state.isShowResultDialog = value.state;
    state.resultMsg = value.msg;
    state.isSuccess = value.isSuccess;
  },
  // eslint-disable-next-line no-unused-vars
  SET_IS_BTN_EDIT_LOADING(state, isBtnEditLoading) {},
};

const actions = {
  async getItems({ commit }, payload) {
    try {
      return post("box/account-report", payload, {
        id: 14,
        action: "detail",
      })
        .then((response) => {
          commit("SET_ITEMS", response.data);
          commit("SET_IS_BTN_LOADING", false);
          commit("SET_IS_BTN_EDIT_LOADING", false);
          return response;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 408) {
            window.location.href = "/404";
          }
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};

const getters = {
  filterItems(state) {
    function deepSearch(obj, searchValue) {
      for (const key in obj) {
        const value = obj[key];

        if (value !== null && typeof value === "object") {
          // If the value is an object, recursively search inside it
          if (deepSearch(value, searchValue)) {
            return true; // Found a match in a nested object
          }
        } else {
          // If the value is not an object, check if it includes the search value
          if (
            value !== null &&
            value.toString().toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return true; // Found a match
          }
        }
      }
      return false; // No match found in this object
    }

    return state.items.filter((item) => {
      // Search in all fields recursively
      return deepSearch(item, state.search.toLowerCase());
    });
  },
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
