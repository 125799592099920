//postFile
import { postFile } from "../../helpers/api";
import { showToast as toast } from "../../components/Modals/toast";
import { i18n } from "../../main"; // Adjust the import path accordingly

const state = {
  items: [],
  isLoadingData: true,
  isBtnLoading: false,
  isBtnEditLoading: false,
  search: "",
  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
};
const mutations = {
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },
  // eslint-disable-next-line no-unused-vars
  SET_IS_BTN_EDIT_LOADING(state, isBtnEditLoading) {},
};
const actions = {
  async addNewItem({ commit }, payload) {
    return postFile("item/store", payload, {
      id: 8,
      action: "add",
    })
      .then((response) => {
        commit("SET_IS_BTN_LOADING", false);
        const t = i18n.global.t;
        toast("success", t("added_successfully"));
        return response;
      })
      .catch((error) => {
        commit("SET_IS_BTN_LOADING", false);
        const t = i18n.global.t;
        //check reponse message
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast

          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }

        if (error.response.data.message.includes("Duplicate entry")) {
          toast("error", t("item_already_exist"));
          return error;
        }
        toast("error", t("something_wrong"));
        console.log(error);
        return error;
      });
  },
  //addCsvFile
  async addCsvFile({ commit }, payload) {
    return postFile("item/add-csv", payload, {
      id: 9,
      action: "add",
    })
      .then((response) => {
        commit("SET_IS_BTN_LOADING", false);
        const t = i18n.global.t;
        toast("success", t("added_successfully"));
        return response;
      })
      .catch((error) => {
        commit("SET_IS_BTN_LOADING", false);
        const t = i18n.global.t;
        //check reponse message
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast

          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }

        if (error.response.data.message.includes("Duplicate entry")) {
          toast("error", t("item_already_exist"));
          return error;
        }
        toast("error", t("something_wrong"));
        console.log(error);
        return error;
      });
  },
};

const getters = {
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
