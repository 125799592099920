import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";
import store from "./store/index";
import "./assets/css/style.css";
import "./assets/css/output.css";
import "aos/dist/aos.css";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import Vue3Toasity, { toast } from "vue3-toastify";
import router from "./router/index";
// Vuetify
import VueApexCharts from "vue3-apexcharts";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import Loading from "./components/loading-progress.vue";
//import VueBarcode from 'vue-barcode';
import Vue3Barcode from "vue3-barcode";
import { $t } from "./helpers/i18n"; // Import the helper function
import "vue-search-select/dist/VueSearchSelect.css";
import Multiselect from 'vue-multiselect';
const vuetify = createVuetify({
  components,
  directives,
});
var language = localStorage.getItem("lang_wise_manager");
if (language == null) {
  language = "ku";
}
const i18n = createI18n({
  locale: language, // default language
  //change direction of the app

  messages: {
    en: require("../locales/en.json"), // import language file
    // add other languages here
    ar: require("../locales/ar.json"),
    ku: require("../locales/ku.json"),
  },
});
const app = createApp(App);
app.use(store);
app.use(router);
//check theme if dark or light
const theme = localStorage.getItem("theme");
if (theme == "dark") {
  document.querySelector("html").classList.add("dark");
} else {
  document.querySelector("html").classList.remove("dark");
}
app.component("EasyDataTable", Vue3EasyDataTable);
app.use(VueApexCharts);
app.component("apex-chart", VueApexCharts);
app.use(Vue3Toasity);
app.use(i18n);
app.use(vuetify);
app.component("Vue3Barcode", Vue3Barcode);
app.component("loading-progress", Loading);
app.component('multi-select', Multiselect)
app.config.globalProperties.$ = $t; // Add the helper function directly to the root instance

app.config.globalProperties.$toast = toast;
app.mount("#app");
export { i18n };
