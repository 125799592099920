<template>
  <popup-modal ref="popup" @click="_cancel">
    <!-- Main modal -->
    <div class="modal-content">
      <span class="close" @click="_cancel">
        <i class="fas fa-times text-2xl text-white"></i>
      </span>
      <img :src="selectedImage" alt="Full Size" class="h-screen" />
    </div>
  </popup-modal>
</template>
<script>
import PopupModal from "./PopupModal.vue";

export default {
  name: "ConfirmDialogue",

  components: { PopupModal },

  data: () => ({

    selectedImage: "",
  }),

  methods: {
    show(opts = {}) {
      this.selectedImage = opts;

      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User canceled the dialogue'))
    },
  },
};
</script>
