<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("add_items") }}
      </p>
    </div>

    <div class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="">
        <form @submit="addSaleItem">
          <div class="grid gap-x-6 mb-6 md:grid-cols-2">
            <BaseInput
              v-model="i_id"
              type="number"
              :label="$t('item_barcode')"
              :placeholder="$t('item_barcode')"
              :rules="rules"
              :isError="checkRulesIsError(i_id, 'add', this.rules).isError"
              :errorMsg="checkRulesIsError(i_id, 'add', this.rules).errorMsg"
            />
            <BaseInput
              v-model="i_name"
              :label="$t('item_name')"
              :placeholder="$t('item_name')"
              :rules="rules"
              :isError="checkRulesIsError(i_name, 'add', this.rules).isError"
              :errorMsg="checkRulesIsError(i_name, 'add', this.rules).errorMsg"
            /><DropdownInput
              v-model="ca_id"
              :placeholder="$t('choose_item_category')"
              :label="$t('item_category')"
              :items="convertToListIdValueCategory"
              :rules="rules_dropdown"
              :isError="
                checkRulesIsError(ca_id, 'add', this.rules_dropdown).isError
              "
              :errorMsg="
                checkRulesIsError(ca_id, 'add', this.rules_dropdown).errorMsg
              "
              linkDrop="/item-category"
            /><DropdownInput
              v-model="un_id"
              :label="$t('item_unit')"
              :items="convertToListIdValueUnits"
              :placeholder="$t('choose_item_unit')"
              :rules="rules_dropdown"
              :isError="
                checkRulesIsError(un_id, 'add', this.rules_dropdown).isError
              "
              :errorMsg="
                checkRulesIsError(un_id, 'add', this.rules_dropdown).errorMsg
              "
              linkDrop="/item-unit"
            />

            <BaseInput
              v-model="i_sale_price"
              type="number"
              :label="$t('sale_price')"
              :placeholder="$t('sale_price')"
              :rules="rules"
              :isError="
                checkRulesIsError(i_sale_price, 'add', this.rules).isError
              "
              :errorMsg="
                checkRulesIsError(i_sale_price, 'add', this.rules).errorMsg
              "
            /><DropdownInput
              v-model="cur_id"
              :label="$t('currency_type')"
              :items="convertToListIdValue"
              :placeholder="$t('choose_currency_type')"
              :rules="rules_dropdown"
              :isError="
                checkRulesIsError(cur_id, 'add', this.rules_dropdown).isError
              "
              :errorMsg="
                checkRulesIsError(cur_id, 'add', this.rules_dropdown).errorMsg
              "
            />
            <div>
              <label
                for="image"
                class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
              >
                {{ $t("item_photo") }}</label
              >
              <label
                for="image"
                class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              >
                <i class="fa fa-cloud-upload mx-1"></i>

                {{ i_image == "" ? $t("choose_item_photo") : i_image.name }}
              </label>
              <input
                type="file"
                v-on:change="onChange"
                :placeholder="$t('choose_item_photo')"
                id="image"
                class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              />
            </div>
            <TextAreaInput
              v-model="i_desc"
              :label="$t('item_description')"
              :placeholder="$t('item_description')"
              :rules="no_rule"
              :isError="checkRulesIsError(i_desc, 'add', this.no_rule).isError"
              :errorMsg="
                checkRulesIsError(i_desc, 'add', this.no_rule).errorMsg
              "
            />
          </div>

          <ButtonInput
            type="button"
            @click="addSaleItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>

  <ResultDialog ref="ResultDialog"></ResultDialog>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import { CURRENCYS } from "../../constants/config";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
//import Compressor from "compressorjs";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ResultDialog,
    TextAreaInput,
    DropdownInput,
  },
  data() {
    return {
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_rule: [],
      isSubmited: false,
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      currencys: CURRENCYS,
      ca_id: "",
      un_id: "",
      i_desc: "",
      i_name: "",

      i_expire_date: "",
      i_image: "",
      i_quantity: "",
      i_id: "",
      i_sale_price: "",
      cur_id: "",
      compressedImage: null,
    };
  },
  created() {
    this.$store.dispatch("item_category/getCategory");
    this.$store.dispatch("item_unit/getUnits");
  },

  computed: {
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isBtnLoading() {
      return this.$store.state.add_item.isBtnLoading;
    },
    convertToListIdValue() {
      var list = [];
      for (const item of this.currencys) {
        list.push({
          id: item.cur_id,
          value: this.$t(item.cur_name),
        });
      }

      return list;
    },
    convertToListIdValueUnits() {
      var boxes = this.$store.state.item_unit.units;
      var list = [];
      for (const item of boxes) {
        list.push({
          id: item.un_id,
          value: item.un_name,
        });
      }

      return list;
    },

    convertToListIdValueCategory() {
      var categorys = this.$store.state.item_category.categorys;
      var list = [];
      for (const item of categorys) {
        list.push({
          id: item.ca_id,
          value: item.ca_name,
        });
      }

      return list;
    },
  },

  methods: {
    formatDate(date) {
      return formatDate(date);
    },

    async addSaleItem(e) {
      e.preventDefault();
      //check form validation
      if (this.$store.state.add_item.isBtnLoading) return;

      this.isSubmited = true;
      if (
        this.checkRulesIsError(this.ca_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.un_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.i_name, "add", this.rules).isError ||
        this.checkRulesIsError(this.i_sale_price, "add", this.rules).isError ||
        this.checkRulesIsError(this.cur_id, "add", this.rules).isError
      ) {
        return;
      }
  
      this.$store.state.add_item.isBtnLoading = true;
      var res = await this.$store.dispatch("add_item/addNewItem", {
        ca_id: this.ca_id,
        un_id: this.un_id,
        i_desc: this.i_desc,
        i_name: this.i_name,
        i_sale_price: this.i_sale_price,
        cur_id: this.cur_id,

        i_expire_date: this.i_expire_date,
        file: this.i_image,
        i_quantity: this.i_quantity,
        i_id: this.i_id,
      });
      if (res.status == 200) {
        this.i_desc = "";
        this.i_name = "";
        this.i_expire_date = "";
        this.i_image = "";
        this.i_image = "";
        this.i_quantity = "";
        this.ca_id = "";
        this.$store.state.add_item.isBtnLoading = false;
        this.i_id = "";
        this.i_sale_price = "";

        this.isSubmited = false;
      }
      this.isSubmited = false;
    },
    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    onChange(e) {
      this.i_image = e.target.files[0];
  
      /* new Compressor(this.i_image, {
        quality: 0.5, // Adjust the compression quality (0 to 1)
        success: (compressedFile) => {
          this.i_image = compressedFile;
          console.log("Original file size:", this.i_image.size);
        },
        error: (err) => {
          console.error("Image compression error:", err);
        },
      });*/
      e.target.value = "";

      // this.compressImage();
    },
    compressImage() {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set the canvas dimensions to the image dimensions
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0);

          // Get the compressed data URL
          const compressedDataURL = canvas.toDataURL("image/jpeg", 0.7);

          // Set the compressed image
          this.compressedImage = compressedDataURL;
        };
      };

      reader.readAsDataURL(this.i_image);
    },
  },
};
</script>
<style>
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(40%) sepia(96%) saturate(694%) hue-rotate(195deg)
    brightness(85%) contrast(88%);
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>
