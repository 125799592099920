import axios from "axios";
import { SERVER_URL } from "../../constants/config";
const isAuthenticated = async () => {
  //check if user is authenticated
  const token = localStorage.getItem("token_wise_manager");
  //if token not exist

  if (!token) {
    return false;
  }
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  var isAuth = false;
  await axios
    .get(SERVER_URL + "user")
    .then((response) => {

      if (response.status == 200) {
        localStorage.setItem(
          "user_wise_manager",
          JSON.stringify(response.data)
        );
     
        isAuth = true;
      } else {
        isAuth = false;
        localStorage.removeItem("token_wise_manager");
        localStorage.removeItem("user_wise_manager");
      }
    })
    // eslint-disable-next-line no-unused-vars
    .catch((error) => {

  
      isAuth = false;
      localStorage.removeItem("token_wise_manager");
      localStorage.removeItem("user_wise_manager");
      //go to login page
   
    });
  return isAuth;
};

export default isAuthenticated;
