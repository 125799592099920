<template>
  <HeaderApp />
  <div class="mt-14"></div>

  <div
    id="printDev"
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      (isHide == true
        ? '  '
        : $store.getters['isRtl']
        ? ' md:mr-60 '
        : ' md:ml-60  ')
    "
  >
    <div class="flex items-center space-x-4 mb-4" v-if="isHide != true">
      <label
        for="selectedHeaders"
        class="text-sm font-semibold"
        @click="columnsControl = !columnsControl"
      >
        <span class="flex">
          {{ $t("columns") }} <i class="fa fa-close my-1 mx-2"></i
        ></span>
      </label>
      <multi-select
        v-model="selectedHeaders"
        :options="headers"
        track-by="value"
        label="text"
        multiple
        placeholder="Select Columns"
        class="custom-multiselect"
        @input="printCall"
        v-if="columnsControl"
      >
      </multi-select>
    </div>

    <InvoiceHeader invoice_type="expenses_list" v-if="isHide == true" />
    <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
      {{ $t("expenses_list") }}
    </p>
    <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="relative overflow-x-auto mt-4 mb-6">
        <div class="flex items-center justify-between pb-4 mx-5 no-print">
          <label for="table-search" class="sr-only">{{ $t("search") }}</label>
          <div class="relative">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <input
              type="text"
              id="table-search"
              class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :placeholder="$t('search')"
              @input="
                $store.commit('expense_list/SET_SEARCH', $event.target.value),
                  loadFromServer(),
                  (serverOptions.page = 1)
              "
            />
          </div>
          <button
            class="mx-2 my_font bg-primaryColor hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
            @click="printReportFunc"
            id="button_print"
            v-if="$store.getters['checkPrintAccess']"
          >
            {{ $t("print") }}
          </button>
        </div>
        <!--in ltr change (text-right to text-left)
        <EasyDataTable
          :headers="headers"
          :items="filterItems"
          :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
          alternating
          :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
          :loading="isLoadingData"
          :class="isDark ? 'customize-table ' : 'light-mode-table'"
        >
          <template #default="{ column, sortDirection }">
            <span class="custom-sort-icon" v-if="column.sortable">
              <i
                v-if="sortDirection === 'asc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i
                v-else-if="sortDirection === 'desc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
            </span>
            {{ column.label }} </template
          ><template #item-ex_image="item">
            <img
              :src="file_url + item.ex_image"
              class="inline-flex items-center justify-center text-white transition-all duration-200 ease-in-out text-size-sm h-20 w-20 rounded-xl"
              v-if="item.ex_image"
              @click="openModal(file_url + item.ex_image)"
            />
            <span v-if="item.ex_image == '' || item.ex_image == null">
              <i class="fa-solid fa-image text-primaryColor"></i>
            </span>
          </template>
          <template #item-actions="item">
            <button class="mx-1" @click="showModal(item)">
              <i
                class="fa-solid fa-pen text-center p-1 w-7 h-7 text-sm bg-blue-200 dark:bg-blue-300 rounded-md text-blue-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </button>
            <button class="mx-1" @click="deleteItem(item.ex_id)">
              <i
                class="fa-solid fa-trash text-center p-1 w-7 h-7 text-sm bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto"
              ></i></button
            ><a class="mx-1" :href="'expense-detail/' + item.ex_id">
              <i
                class="m-1 fa-solid fa-eye text-center p-1 w-7 h-7 text-sm bg-green-200 dark:bg-blue-300 rounded-md text-green-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </a> </template
          ><template #item-currency_cur_name="item">
            {{ $t(item.currency.cur_name) }}
          </template>
          <template #loading>
            <img
              src="../../assets/loading.webp"
              style="width: 70px; height: 70px"
            />
          </template>
          <template #item-ex_note="item">
            <span
              v-if="item.ex_note == null || item.ex_note == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.ex_note }}
            </span>
          </template>
          <template #item-customer_cu_name="item">
            <span
              v-if="item.customer == null || item.customer == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.customer.cu_name }}
            </span>
          </template>
          <template #item-ex_wasl="item">
            <span
              v-if="item.ex_wasl == null || item.ex_wasl == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.ex_wasl }}
            </span>
          </template>
          <template #item-created_at="item">
            {{ formatDate(item.created_at) }} </template
          ><template #empty-message>
            <span class="text-textBlack1 dark:text-white">
              {{ $t("no_data_found") }}
            </span>
          </template>
          <template
            #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
          >
            <button :disabled="isFirstPage" @click="prevPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-chevron-right'
                    : ' fa-chevron-left')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-chevron-left'
                    : ' fa-chevron-right')
                "
              ></i>
            </button>
          </template>
        </EasyDataTable>-->
        <EasyDataTable
          v-model:server-options="serverOptions"
          :headers="showHeaders"
          :items="items"
          :server-items-length="serverItemsLength"
          :loading="isLoadingData"
          alternating
          :class="isDark ? 'customize-table ' : 'light-mode-table'"
          :buttons-pagination="true"
          :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
          :hide-footer="isHide"
          :rows-items="[25, 50, 100, serverItemsLength]"
          rows-per-page="10"
        >
          <template #default="{ column, sortDirection }">
            <span class="custom-sort-icon" v-if="column.sortable">
              <i
                v-if="sortDirection === 'asc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i
                v-else-if="sortDirection === 'desc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
            </span>
            {{ column.label }}
          </template>
          <template #item-ex_image="item">
            <img
              :src="file_url + item.ex_image"
              class="inline-flex items-center justify-center text-white transition-all duration-200 ease-in-out text-size-sm h-20 w-20 rounded-xl"
              v-if="item.ex_image"
              @click="openModal(file_url + item.ex_image)"
            />
            <span v-if="item.ex_image == '' || item.ex_image == null">
              <i class="fa-solid fa-image text-primaryColor"></i>
            </span>
          </template>
          <template #item-actions="item">
            <button
              class="mx-1 my-1"
              @click="showModal(item)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '23',
                  action: 'edit',
                })
              "
            >
              <i
                class="fa-solid fa-pen text-center p-1 w-7 h-7 text-sm bg-blue-200 dark:bg-blue-300 rounded-md text-blue-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </button>
            <button
              class="mx-1 my-1"
              @click="deleteItem(item.ex_id)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '23',
                  action: 'delete',
                })
              "
            >
              <i
                class="fa-solid fa-trash text-center p-1 w-7 h-7 text-sm bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto"
              ></i></button
            ><a
              class="mx-1 my-1"
              :href="'expense-detail/' + item.ex_id"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '23',
                  action: 'detail',
                })
              "  target="_blank"
            >
              <i
                class="m-1 fa-solid fa-eye text-center p-1 w-7 h-7 text-sm bg-green-200 dark:bg-blue-300 rounded-md text-green-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </a> </template
          ><template #item-currency_cur_name="item">
            {{ $t(item.currency.cur_name) }}
          </template>
          <template #loading>
            <img
              src="../../assets/loading.webp"
              style="width: 70px; height: 70px"
            />
          </template>
          <template #item-ex_amount="item">
            {{ formatNumber(item.ex_amount) }}
          </template>
          <template #item-ex_note="item">
            <span
              v-if="item.ex_note == null || item.ex_note == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.ex_note }}
            </span>
          </template>
          <template #item-customer_cu_name="item">
            <span
              v-if="item.customer == null || item.customer == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.customer.cu_name }}
            </span>
          </template>
          <template #item-ex_wasl="item">
            <span
              v-if="item.ex_wasl == null || item.ex_wasl == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.ex_wasl }}
            </span>
          </template>
          <template #item-created_at="item">
            {{ formatDate(item.created_at) }} </template
          ><template #empty-message>
            <span class="text-textBlack1 dark:text-white">
              {{ $t("no_data_found") }}
            </span>
          </template>
          <template
            #pagination="{
              prevPage,
              nextPage,
              isFirstPage,
              isLastPage,
              currentPaginationNumber,
              maxPaginationNumber,
            }"
          >
            <button :disabled="isFirstPage" @click="goToPage(1)">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-double-right'
                    : ' fa-angle-double-left')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isFirstPage" @click="prevPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-right'
                    : ' fa-angle-left ')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button
              v-for="page in getVisiblePages(
                currentPaginationNumber,
                maxPaginationNumber
              )"
              :key="page"
              @click="goToPage(page)"
              :class="getPageButtonClasses(page, currentPaginationNumber)"
            >
              {{ page }}
            </button>

            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-left'
                    : ' fa-angle-right')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button
              :disabled="isLastPage"
              @click="goToPage(maxPaginationNumber)"
            >
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-double-left'
                    : ' fa-angle-double-right')
                "
              ></i>
            </button> </template
        ></EasyDataTable>
      </div>
    </div>
  </div>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <edit-modal ref="editModal">
    <div class="items-center">
      <form @submit="editItem">
        <BaseInput
          v-model="current.ex_amount"
          :label="$t('money_amount')"
          :placeholder="$t('expense_amount')"
          :rules="rules"
          type="number"
          :isError="checkRulesIsError(current.ex_amount, 'edit', rules).isError"
          :errorMsg="
            checkRulesIsError(current.ex_amount, 'edit', rules).errorMsg
          "
        />
        <DropdownInput
          v-model="current.exc_id"
          :label="$t('expense_category')"
          :placeholder="$t('choose_expense_category')"
          :rules="rules_dropdown"
          :items="convertToListIdValueExpenseCategory"
          :isError="
            checkRulesIsError(current.exc_id, 'edit', rules_dropdown).isError
          "
          :errorMsg="
            checkRulesIsError(current.exc_id, 'edit', rules_dropdown).errorMsg
          "
        /><DropdownInput
          v-model="current.cu_id"
          :label="$t('customer')"
          :placeholder="$t('choose_if_you_spent_the_money_to_customer')"
          :rules="no_rule"
          :items="convertToListIdValueCustomers"
          :isError="checkRulesIsError(current.cu_id, 'edit', no_rule).isError"
          :errorMsg="checkRulesIsError(current.cu_id, 'edit', no_rule).errorMsg"
        />
        <BaseInput
          v-model="current.ex_wasl"
          :label="$t('expense_invoice')"
          :placeholder="$t('expense_invoice')"
          :rules="no_rule"
          :isError="checkRulesIsError(current.ex_wasl, 'edit', no_rule).isError"
          :errorMsg="
            checkRulesIsError(current.ex_wasl, 'edit', no_rule).errorMsg
          "
        />
        <BaseInput
          v-model="current.created_at"
          type="date"
          :label="$t('expense_date')"
          :placeholder="$t('expense_date')"
          :rules="rules"
          :isError="
            checkRulesIsError(current.created_at, 'add', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.created_at, 'add', this.rules).errorMsg
          "
        />
        <div>
          <label
            for="image"
            class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
          >
            {{ $t("expense_photo") }}</label
          >
          <label
            for="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          >
            <i class="fa fa-cloud-upload mx-1"></i>
            {{ ex_image == "" ? $t("choose_expense_photo") : ex_image.name }}
          </label>
          <input
            type="file"
            v-on:change="onChange"
            :placeholder="$t('choose_expense_photo')"
            id="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          />
        </div>
        <TextAreaInput
          v-model="current.ex_note"
          :label="$t('note')"
          :placeholder="$t('note')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.ex_note, 'edit', this.no_rule).isError
          "
          :errorMsg="
            checkRulesIsError(current.ex_note, 'edit', this.no_rule).errorMsg
          "
        />
        <center>
          <ButtonInput
            type="button"
            @click="editItem"
            :label="$t('edit')"
            :isLoading="isBtnEditLoading"
          />
        </center>
      </form>
    </div> </edit-modal
  ><image-modal ref="imageModal" />
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import EditModal from "../../components/Modals/EditModal.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
import { FILE_URL } from "../../constants/config";
import ImageModal from "../../components/Modals/ImageModal2.vue";
import InvoiceHeader from "../../components/Navbar/InvoiceHeader.vue";
import { formatNumber } from "../../utils/functions";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ConfirmDialogue,
    ResultDialog,
    EditModal,
    DropdownInput,
    TextAreaInput,
    ImageModal,
    InvoiceHeader,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      exc_name: "",
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      no_rule: [],
      current: {},
      ex_image: "",
      file_url: FILE_URL,
      serverItemsLength: 0,
      serverOptions: {
        page: 1,
        rowsPerPage: 5,
      },
      loading: false,
      items: [],
      isHide: false,
      selectedHeaders: [],
      columnsControl: false,
    };
  },
  created() {
    this.$store.dispatch("expense_category/getExpenseCategory");
    this.$store.dispatch("customer/getCustomers");
    this.$store.dispatch("navbar/getItems");
    this.selectedHeaders = [...this.headers];
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.expense_list.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.expense_list.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("expense_list/SET_DELETE_SUCCESS", false);
      }
    },
    serverOptions: {
      handler() {
        this.loadFromServer();
      },
      deep: true,
    },
  },
  mounted() {
    // First load when the component is mounted
    if (
      this.$store.getters["checkUserAccess"]({
        id: "23",
        action: "view",
      })
    ) {
      this.loadFromServer();
    }
  },
  computed: {
    getResultDialog() {
      return this.$store.state.expense_list.isShowResultDialog;
    },

    filterItems() {
      return this.$store.getters["expense_list/filterItems"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.expense_list.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.expense_list.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.expense_list.isBtnEditLoading;
    },
    headers() {
      var listHeader = [
        { text: "#", value: "ex_id", sortable: true },
        {
          text: this.$t("expense_invoice_number"),
          value: "ex_wasl",
          sortable: true,
        },
        { text: this.$t("photo"), value: "ex_image", sortable: false },
        { text: this.$t("expense_amount"), value: "ex_amount", sortable: true },
        {
          text: this.$t("currency_type"),
          value: "currency_cur_name",
          sortable: true,
        },
        {
          text: this.$t("dolar_price"),
          value: "ex_main_currency_price",
          sortable: true,
        },
        {
          text: this.$t("expense_category"),
          value: "expense_category.exc_name",
          sortable: true,
        },
        { text: this.$t("expense_box"), value: "box.bo_name", sortable: true },
        {
          text: this.$t("customer"),
          value: "customer_cu_name",
          sortable: true,
        },
        { text: this.$t("note"), value: "ex_note", sortable: true },
        { text: this.$t("admin"), value: "users.name", sortable: true },
        { text: this.$t("created_at"), value: "created_at", sortable: true },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ];
      if (this.isHide) {
        listHeader.pop();
      }
      return listHeader;
    },
    showHeaders() {

      var temp = [];
      //cretae a for check all selectedHeaders add to temp
      for (let i = 0; i < this.selectedHeaders.length; i++) {
        for (let j = 0; j < this.headers.length; j++) {
          if (this.selectedHeaders[i].text == this.headers[j].text) {
            temp.push(this.headers[j]);
          }
        }
      }

      if (temp.length == 0) {
        temp = this.headers;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedHeaders = temp;
      }

      return temp;
    },
    convertToListIdValueCustomers() {
      var customers = this.$store.state.customer.customers;
      var list = [];
      for (const item of customers) {
        list.push({
          id: item.cu_id.toString(),
          value: item.cu_name,
        });
      }

      return list;
    },
    convertToListIdValueExpenseCategory() {
      var expenseCategory = this.$store.state.expense_category.expenseCategory;
      var list = [];
      for (const item of expenseCategory) {
        list.push({
          id: item.exc_id.toString(),
          value: item.exc_name,
        });
      }

      return list;
    },
  },

  methods: {
    formatNumber(number) {
      return formatNumber(number);
    },
    printReportFunc() {
      this.isHide = true;
      //wait 1 second
      setTimeout(() => {
        var printContents = document.getElementById("printDev").innerHTML;

        // Replace the body's innerHTML with the print contents
        document.body.innerHTML = printContents;
        var isRtl = this.$store.getters["isRtl"];
        // Set direction based on the parameter
        if (isRtl) {
          document.body.style.direction = "rtl";
        } else {
          document.body.style.direction = "ltr";
        }

        // Print the content
        window.print();
        //reload page
      }, 100);
      //print element id invoice_print
    },
    getVisiblePages(currentPage, totalPages) {
      const numVisibleButtons = 3; // Adjust this number based on your design preference
      const pages = [];

      // Display pages 1 to numVisibleButtons (e.g., 1, 2, 3)
      if (currentPage <= Math.floor(numVisibleButtons / 2) + 1) {
        for (let i = 1; i <= Math.min(totalPages, numVisibleButtons); i++) {
          pages.push(i);
        }
      }
      // Display pages around the current page (e.g., 1, ..., 10, ..., 20)
      else {
        const startPage = Math.max(
          1,
          currentPage - Math.floor(numVisibleButtons / 2)
        );
        const endPage = Math.min(totalPages, startPage + numVisibleButtons - 1);

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
      }

      return pages;
    },

    // Function to get Tailwind CSS classes for each page button
    getPageButtonClasses(page, currentPage) {
      return {
        "bg-blue-500 text-white": page === currentPage,
        "bg-gray-300 text-gray-700 hover:bg-gray-400": page !== currentPage,
        "p-2 rounded-md mx-1": true, // Adjust padding and rounding based on your design
      };
    },

    goToPage(page) {
      // Implement your logic to update the current page
      //update page
      this.serverOptions.page = page;
      this.loadFromServer();
    },
    async loadFromServer() {
      this.loading = true;

      var tempObj = { ...this.serverOptions };
      //add new field to object search
      tempObj.search = this.$store.state.expense_list.search;

      const res = await this.$store.dispatch("expense_list/getItems", tempObj);

      this.items = res.data.data;
      this.serverItemsLength = res.data.total;
      this.loading = false;
    },
    openModal(image) {
      this.$refs.imageModal.show(image);
    },
    onChange(e) {
      this.ex_image = e.target.files[0];
      e.target.value = "";
    },

    formatDate(date) {
      return formatDate(date);
    },

    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";

      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    async deleteItem(id) {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$t("are_you_sure_remove_this_item") + id,
        okButton: this.$t("delete"),
      });
      if (ok) {
        this.$store.commit("expense_list/REMOVE_ITEM", id);
        await this.$store.dispatch("expense_list/deleteItem", id);
        this.loadFromServer();
      }
    },
    //edit
    async showModal(item) {
      this.current = item;
      //if not null
      if (this.current.exc_id != null)
        this.current.exc_id = item.expense_category.exc_id.toString();
      if (this.current.cu_id != null)
        this.current.cu_id = item.customer.cu_id.toString();
      this.current.created_at = this.current.created_at
        .toString()
        .split("T")[0];
      await this.$refs.editModal.show();
    },
    async editItem(e) {
      e.preventDefault();
      this.isSubmitedEdit = true;
      if (
        this.checkRulesIsError(this.current.exc_id, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(this.current.ex_amount, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(this.current.created_at, "edit", this.rules)
          .isError
      ) {
        return;
      }
      this.$store.state.expense_list.isBtnEditLoading = true;
      var res = await this.$store.dispatch("expense_list/editItem", {
        ex_id: this.current.ex_id,
        exc_id: this.current.exc_id,
        cu_id: this.current.cu_id,
        ex_note: this.current.ex_note,
        ex_wasl: this.current.ex_wasl,
        ex_amount: this.current.ex_amount,
        created_at: this.current.created_at,
        ex_image: this.ex_image,
      });

      this.isSubmitedEdit = false;
      this.$store.state.expense_list.isBtnEditLoading = false;
      this.ex_image = "";
      if (res.status == 200) {
        this.$refs.editModal.hide();
        this.loadFromServer();
      }
    },
  },
};
</script>
