import { post } from "../../helpers/api";
import { showToast as toast } from "../../components/Modals/toast";
import { i18n } from "../../main"; // Adjust the import path accordingly

const state = {
  items: [],
  isLoadingData: true,
  isBtnLoading: false,
  isBtnEditLoading: false,
  search: "",
  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
  itemsPOS: [],
  searchPos: "",
  ca_id: "",
  currentInvoice: {},
};

const mutations = {
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },
  SET_IS_LOADING_DATA(state, isLoadingData) {
    state.isLoadingData = isLoadingData;
  },
  SET_EDIT_SALE_CLEAR(state) {
    state.items = [];
    state.isLoadingData = true;
    state.isBtnLoading = false;
    state.isBtnEditLoading = false;
    state.search = "";
    state.isShowResultDialog = false;
    state.resultMsg = "";
    state.isSuccess = false;
    state.itemsPOS = [];
    state.searchPos = "";
    state.ca_id = "";
    state.currentInvoice = {};
  },

  REMOVE_ITEM(state, id) {
    state.items = state.items.filter((item) => {
      return item.i_id !== id;
    });
  },

  SET_SEARCH_POS(state, search) {
    state.searchPos = search;
  },
  SET_DELETE_SUCCESS(state, value) {
    state.isShowResultDialog = value.state;
    state.resultMsg = value.msg;
    state.isSuccess = value.isSuccess;
  },

  SET_ITEMS_POS(state, itemsPOS) {
    state.itemsPOS = itemsPOS;
  },
  SET_CURRENT_INVOICE(state, currentInvoice) {
    state.currentInvoice = currentInvoice;
  },
};

const actions = {
  async getCurrentInvoice({ commit }, payload) {
    try {
      return post(
        "invoice/get-invoice/" + payload.in_id,
        {},
        {
          id: 19,
          action: "detail",
        }
      )
        .then((response) => {
          commit("SET_CURRENT_INVOICE", response.data);
          //SET_IS_LOADING_DATA
          commit("SET_IS_LOADING_DATA", false);

          return response;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 408) {
            window.location.href = "/404";
          }
          return error;
        });
    } catch (error) {
      console.log(error);
    }
  },
  async editItem({ commit }, payload) {
    return post("invoice/edit/" + payload.in_id, payload, {
      id: 19,
      action: "edit",
    })
      .then((response) => {
        const t = i18n.global.t;
        toast("success", t("edited_successfully"));
        return response;
      })
      .catch((error) => {
        commit("SET_IS_BTN_LOADING", false);
        const t = i18n.global.t;
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }
        if (error.response.data.message.includes("Invoice not found")) {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t("invoice_not_found"),
            isSuccess: false,
          }); //
        } else if (
          error.response.data.message.includes("Invoice is returned")
        ) {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t("invoice_is_returned"),
            isSuccess: false,
          });
        } else if (
          error.response.data.message.includes(
            "Item quantity is less than sold item quantity"
          )
        ) {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t("item_quantity_is_less_than_sold_item_quantity"),
            isSuccess: false,
          });
        } else {
          toast("error", t("something_wrong"));
        }

        console.log(error);
        return error;
      });
  },
  async deleteItem({ commit }, id) {
    post(
      "invoice/delete/" + id,
      {},
      {
        id: 19,
        action: "delete",
      }
    )
      .then((response) => {
        this.dispatch("edit_sale/getItems");

        const t = i18n.global.t;
        toast("success", t("deleted_successfully"));
        return response;
      })
      .catch((error) => {
        const t = i18n.global.t;
        this.dispatch("edit_sale/getItems");
        commit("SET_IS_BTN_LOADING", false);
        console.log(error);
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }
        if (
          error.response.data.message.includes(
            "Cannot delete or update a parent row"
          )
        ) {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t(
              "you_can_not_delete_this_item_because_there_are_related_items"
            ),
            isSuccess: false,
          });
        } else {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t("something_wrong"),
            isSuccess: false,
          });
        }
      });
  },
};

const getters = {
  filterItemsPOS(state) {
    function deepSearch(obj, searchValue) {
      if (state.ca_id !== "") {
        if (obj.ca_id !== state.ca_id) {
          return false;
        }
      }
      for (const key in obj) {
        const value = obj[key];

        if (value !== null && typeof value === "object") {
          // If the value is an object, recursively search inside it
          if (deepSearch(value, searchValue)) {
            return true; // Found a match in a nested object
          }
        } else {
          // If the value is not an object, check if it includes the search value
          if (
            value != null &&
            value.toString().toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return true; // Found a match
          }
        }
      }
      return false; // No match found in this object
    }

    return state.itemsPOS.filter((item) => {
      // Search in all fields recursively
      return deepSearch(item, state.searchPos.toLowerCase());
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
