<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unused-vars -->
<template>
  <div class="gap-6 mb-6">
    <div>
      <label
        for="saleType"
        class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
        v-if="label != ''"
        >{{ label }}</label
      >

      <textarea
        id="discription"
        rows="3"
        :value="modelValue"
        @input="checkInput"
        v-bind="$attrs"
        :placeholder="placeholder"
        :class="inputClasses"
      ></textarea>

      <!-- Error message -->
      <div v-if="isError" class="text-red-500 text-sm mt-2">
        {{ errorMsg }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  modelValue: {
    type: String,
    default: "",
  },
  isError: {
    type: Boolean,
    default: false,
  },
  errorMsg: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["update:isError", "update:modelValue"]);

// Compute input classes based on the isError prop
const inputClasses = `bg-slate-100 border ${
  props.isError ? "border-red-500 text-red-500" : "border-gray-300"
} text-textBlack1 placeholder-gray-400 text-base rounded-lg  ${
  props.isError ? "" : ""
} block w-full p-2 dark:bg-bgFullDark ${
  props.isError ? "dark:border-red-600" : "dark:border-gray-600"
} dark:placeholder-gray-200 ${
  props.isError ? "dark:text-red-500" : "dark:text-white"
} `;
//check input
const checkInput = (e) => {
  emit("update:modelValue", e.target.value);
};
</script>
