<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("transfer_between_boxes") }}
      </p>
    </div>

    <div
      class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl"
      v-if="
        $store.getters['checkUserAccess']({
          id: '16',
          action: 'add',
        })
      "
    >
      <div class="">
        <form @submit="addSaleItem">
          <div class="grid gap-6 md:grid-cols-4">
            <div>
              <DropdownInput
                v-model="bo_id_from"
                :label="$t('transfer_from_box')"
                :placeholder="$t('choose_box')"
                :rules="rules_dropdown"
                :items="convertToListIdValueBoxes"
                :isError="
                  checkRulesIsError(bo_id_from, 'add', this.rules_dropdown)
                    .isError
                "
                :errorMsg="
                  checkRulesIsError(bo_id_from, 'add', this.rules_dropdown)
                    .errorMsg
                "
              />
            </div>
            <div>
              <DropdownInput
                v-model="cur_id"
                :label="$t('choose_currency')"
                :items="convertToListIdValue"
                :placeholder="$t('choose_currency')"
                :rules="rules_dropdown"
                :isError="
                  checkRulesIsError(cur_id, 'add', this.rules_dropdown)
                    .isError
                "
                :errorMsg="
                  checkRulesIsError(cur_id, 'add', this.rules_dropdown)
                    .errorMsg
                "
              />
            </div>
            <div>
              <BaseInput
                v-model="boh_amount"
                type="number"
                :label="$t('money_amount')"
                :placeholder="$t('money_amount')"
                :rules="rules"
                :isError="
                  checkRulesIsError(boh_amount, 'add', this.rules).isError
                "
                :errorMsg="
                  checkRulesIsError(boh_amount, 'add', this.rules).errorMsg
                "
              />
            </div>
            <div>
              <DropdownInput
                v-model="bo_id_to"
                :label="$t('transfer_to_box')"
                :placeholder="$t('choose_box')"
                :rules="rules_dropdown"
                :items="convertToListIdValueBoxes"
                :isError="
                  checkRulesIsError(bo_id_to, 'add', this.rules_dropdown)
                    .isError
                "
                :errorMsg="
                  checkRulesIsError(bo_id_to, 'add', this.rules_dropdown)
                    .errorMsg
                "
              />
            </div>
          </div>
          <TextAreaInput
            v-model="boh_note"
            :label="$t('note')"
            :placeholder="$t('note')"
            :rules="rules"
            :isError="checkRulesIsError(boh_note, 'add', this.rules).isError"
            :errorMsg="checkRulesIsError(boh_note, 'add', this.rules).errorMsg"
          />
          <ButtonInput
            type="button"
            @click="addSaleItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>

  <div
    :class="
      ' mx-2 mt-4 mb-5 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
    v-if="
      $store.getters['checkUserAccess']({
        id: '16',
        action: 'view',
      })
    "
  >
    <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="relative overflow-x-auto mt-4 mb-6">
        <div class="flex items-center justify-between pb-4 mx-5">
          <label for="table-search" class="sr-only">گەڕان</label>
          <div class="relative">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <input
              type="text"
              id="table-search"
              class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :placeholder="$t('search')"
              @input="
                $store.commit('transfer_box/SET_SEARCH', $event.target.value)
              "
            />
          </div>
        </div>
        <!--in ltr change (text-right to text-left)-->
        <EasyDataTable
          :headers="headers"
          :items="filterItems"
          :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
          alternating
          :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
          :loading="isLoadingData"
          :class="isDark ? 'customize-table ' : 'light-mode-table'"
        >
          <template #default="{ column, sortDirection }">
            <span class="custom-sort-icon" v-if="column.sortable">
              <i
                v-if="sortDirection === 'asc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i
                v-else-if="sortDirection === 'desc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
            </span>
            {{ column.label }}
          </template>
          <template #item-actions="item">
            <button
              class="mx-1 my-1"
              @click="showModal(item)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '16',
                  action: 'edit',
                })
              "
            >
              <i
                class="fa-solid fa-pen text-center p-1 w-7 h-7 text-sm bg-blue-200 dark:bg-blue-300 rounded-md text-blue-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </button>
            <button
              class="mx-1 my-1"
              @click="deleteItem(item.boh_id)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '16',
                  action: 'delete',
                })
              "
            >
              <i
                class="fa-solid fa-trash text-center p-1 w-7 h-7 text-sm bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto"
              ></i>
            </button>
          </template>
          <template #loading>
            <img
              src="../../assets/loading.webp"
              style="width: 70px; height: 70px"
            />
          </template>
          <template #item-created_at="item">
            {{ formatDate(item.created_at) }}
          </template>

          <template #item-boh_amount="item">
            {{ formatNumber(item.boh_amount) }} </template
          ><template #item-currency_cur_name="item">
            {{ $t(item.currency.cur_name) }}
          </template>

          <template #empty-message>
            <span class="text-textBlack1 dark:text-white">
              {{ $t("no_data_found") }}
            </span>
          </template>
          <template
            #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
          >
            <button :disabled="isFirstPage" @click="prevPage">
               <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-right'
                    : ' fa-angle-left ')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-left'
                    : ' fa-angle-right')
                "
              ></i>
            </button>
          </template>
        </EasyDataTable>
      </div>
    </div>
  </div>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <edit-modal ref="editModal">
    <div class="items-center">
      <form @submit="editItem">
        <BaseInput
          v-model="current.boh_amount"
          type="number"
          :label="$t('money_amount')"
          :placeholder="$t('money_amount')"
          :rules="rules"
          :isError="
            checkRulesIsError(current.boh_amount, 'edit', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.boh_amount, 'edit', this.rules).errorMsg
          "
        />
        <TextAreaInput
          v-model="current.boh_note"
          :label="$t('note')"
          :placeholder="$t('note')"
          :rules="rules"
          :isError="
            checkRulesIsError(current.boh_note, 'edit', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.boh_note, 'edit', this.rules).errorMsg
          "
        />
        <center>
          <ButtonInput
            type="button"
            @click="editItem"
            :label="$t('edit')"
            :isLoading="isBtnEditLoading"
          />
        </center>
      </form>
    </div>
  </edit-modal>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import { formatNumber } from "../../utils/functions";

import { checkDolarDinar } from "../../utils/functions";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import { CURRENCYS } from "../../constants/config";
import EditModal from "../../components/Modals/EditModal.vue";

export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ConfirmDialogue,
    ResultDialog,
    EditModal,
    DropdownInput,
    TextAreaInput,
  },
  data() {
    return {
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      boh_amount: "",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      currencys: CURRENCYS,
      bo_id_to: "",
      bo_id_from: "",
      cur_id: "",
      bo_id: "",

      boh_note: "",
      resultChanged: "",
    };
  },
  created() {
    if (
      this.$store.getters["checkUserAccess"]({
        id: "16",
        action: "view",
      })
    ) {
      this.getData();
    }
    this.getBoxes();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.transfer_box.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.transfer_box.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("transfer_box/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.transfer_box.isShowResultDialog;
    },
    items() {
      return this.$store.state.transfer_box.items;
    },
    filterItems() {
      return this.$store.getters["transfer_box/filterItems"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.transfer_box.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.transfer_box.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.transfer_box.isBtnEditLoading;
    },
    headers() {
      return [
        { text: "#", value: "boh_id", sortable: true },
        {
          text: this.$t("currency_type"),
          value: "currency_cur_name",
          sortable: true,
        },
        {
          text: this.$t("money_amount"),
          value: "boh_amount",
          sortable: true,
        },
        {
          text: this.$t("transfer_from_box"),
          value: "box_from.bo_name",
          sortable: true,
        },
        {
          text: this.$t("transfer_to_box"),
          value: "box_to.bo_name",
          sortable: true,
        },

        {
          text: this.$t("note"),
          value: "boh_note",
          sortable: true,
        },

        { text: this.$t("admin"), value: "users.name", sortable: true },
        { text: this.$t("created_at"), value: "created_at", sortable: true },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ];
    },
    convertToListIdValue() {
      var list = [];
      for (const item of this.currencys) {
        list.push({
          id: item.cur_id,
          value: this.$t(item.cur_name),
        });
      }

      return list;
    },
    convertToListIdValueBoxes() {
      var boxes = this.$store.state.all_box.boxes;
      var list = [];
      for (const item of boxes) {
        list.push({
          id: item.bo_id,
          value: item.bo_name,
        });
      }

      return list;
    },
    checkDolarDinarResult() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return checkDolarDinar(
        this.cur_id_from,
        this.boh_amount,
        this.$store.state.navbar.dp_price
      );
    },
  },

  methods: {
    getDolarExchangeResult(from, amount, dolar_price) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return checkDolarDinar(from, amount, dolar_price);
    },
    formatDate(date) {
      return formatDate(date);
    },

    formatNumber(number) {
      return formatNumber(number);
    },
    getData() {
      this.$store.dispatch("transfer_box/getItems");
    },
    getBoxes() {
      this.$store.dispatch("all_box/getBoxes");
    },
    async addSaleItem() {
      //check form validation
      if (this.$store.state.transfer_box.isBtnLoading) return;
      //check form validation
      this.isSubmited = true;

      if (
        this.checkRulesIsError(this.boh_amount, "add", this.rules).isError ||
        this.checkRulesIsError(this.bo_id_from, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.cur_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.bo_id_to, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.boh_note, "add", this.rules).isError
      ) {
        return;
      }
      if (this.bo_id_from == this.bo_id_to) {
        this.$refs.ResultDialog.show({
          message: this.$t("you_can_not_transfer_to_same_box"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }
      this.$store.state.transfer_box.isBtnLoading = true;
      var res = await this.$store.dispatch("transfer_box/addNewItem", {
        bo_id_from: this.bo_id_from,
        bo_id_to: this.bo_id_to,
        boh_amount: this.boh_amount,
        cur_id: this.cur_id,
        boh_note: this.boh_note,
      });
      if (res.status == 200) {
        this.boh_amount = "";
        this.boh_note = "";
        this.isSubmited = false;
        this.$store.state.transfer_box.isBtnLoading = false;
      }
      this.$store.state.transfer_box.isBtnLoading = false;
      this.isSubmited = false;
    },
    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    async deleteItem(id) {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$t("are_you_sure_remove_this_item") + id,
        okButton: this.$t("delete"),
      });
      if (ok) {
        this.$store.commit("transfer_box/REMOVE_ITEM", id);
        this.$store.dispatch("transfer_box/deleteItem", id);
      }
    },
    //edit
    async showModal(item) {
      this.current = item;
      await this.$refs.editModal.show();
    },
    async editItem(e) {
      e.preventDefault();
      this.isSubmitedEdit = true;
      if (
        this.checkRulesIsError(this.current.boh_amount, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(this.current.boh_note, "edit", this.rules)
          .isError
      ) {
        return;
      }
      this.$store.state.transfer_box.isBtnEditLoading = true;
      var res = await this.$store.dispatch("transfer_box/editItem", {
        boh_id: this.current.boh_id,
        boh_amount: this.current.boh_amount,
        boh_note: this.current.boh_note,
      });

      this.isSubmitedEdit = false;
      this.$store.state.transfer_box.isBtnEditLoading = false;
      if (res.status == 200) {
        this.$refs.editModal.hide();
      }
    },
  },
};
</script>
