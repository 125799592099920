<template>
  <HeaderApp />
  <div
    id="printDev"
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      (isHide == true
        ? '  '
        : $store.getters['isRtl']
        ? ' md:mr-60 '
        : ' md:ml-60  ')
    "
  >
    <InvoiceHeader invoice_type="citys_report" v-if="isHide == true" />
    <div class="flex lg:flex-row flex-col mb-4 justify-between" v-if="!isHide">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite">
        {{ this.$t("citys_report") }}
      </p>
      <div class="lg:flex grid grid-cols-2">
        <div class="mx-0 xl:mx-8 lg:mx-4 mt-4 lg:mt-0">
          <label
            for="boxType"
            class="block mb-2 text-sm text-textBlack1 dark:text-textWhite1"
          >
            {{ $t("report_type") }}
          </label>
          <select
            id="boxType"
            class="bg-white border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="selectedReport"
            @change="changeReportType"
          >
            <option value="item_quantity_sold">
              {{ $t("sold_quantity") }}
            </option>
            <option value="invoice_count">{{ $t("customer_count") }}</option>
            <option value="total_sale_price_dinar">
              {{ $t("total_sale_in_dinar") }}
            </option>
            <option value="total_cost_price_dinar">
              {{ $t("total_cost_in_dinar") }}
            </option>
            <option value="total_profit_dinar">
              {{ $t("total_profit_in_dinar") }}
            </option>
            <option value="total_discount_dinar">
              {{ $t("total_discount_in_dinar") }}
            </option>
            <option value="total_sale_price_dollar">
              {{ $t("total_sale_in_dollar") }}
            </option>
            <option value="total_cost_price_dollar">
              {{ $t("total_cost_in_dollar") }}
            </option>
            <option value="total_profit_dollar">
              {{ $t("total_profit_in_dollar") }}
            </option>
            <option value="total_discount_dollar">
              {{ $t("total_discount_in_dollar") }}
            </option>
          </select>
        </div>
        <div class="my-1 lg:my-0 mt-4 lg:mt-0">
          <label
            for="boxType"
            class="block mb-2 text-sm text-textBlack1 dark:text-textWhite1"
            >{{ $t("sale_type") }}
          </label>
          <select
            id="boxType"
            class="bg-white border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="invoiceType"
            @change="getData"
          >
            <option value="1" selected>{{ $t("sale_tak") }}</option>
            <option value="2">{{ $t("sale_jwmla") }}</option>
            <option value="0">{{ $t("all") }}</option>
          </select>
        </div>
      </div>

      <div class="lg:flex lg:flex-row grid grid-cols-2">
        <div class="md:mx-2 my-1 lg:my-0 mt-4 lg:mt-0">
          <label
            for="debtDate"
            class="block mb-2 text-sm font-medium text-textBlack1 dark:text-textWhite1"
            >{{ $t("start_date") }}
          </label>
          <input
            type="date"
            id="debtDate"
            class="bg-white border text-sm border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
            v-model="startDate"
            @change="getData"
          />
        </div>

        <div class="md:mx-2 my-1 lg:my-0 mt-4 lg:mt-0">
          <label
            for="debtDate"
            class="block mb-2 text-sm font-medium text-textBlack1 dark:text-textWhite1"
            >{{ $t("end_date") }}</label
          >
          <input
            type="date"
            id="debtDate"
            class="bg-white border text-sm border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
            v-model="endDate"
            @change="getData"
          />
        </div>

        <div class="md:mx-2 my-1 lg:my-0 mt-4 lg:mt-0">
          <label
            for="boxType"
            class="block mb-2 text-sm text-textBlack1 dark:text-textWhite1"
          >
            {{ $t("choose_period") }}
          </label>
          <select
            id="countries"
            class="bg-white border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="period"
            @change="periodChanged(period)"
          >
            <option value="today" class="my_font">{{ $t("today") }}</option>
            <option value="yesterday" class="my_font">
              {{ $t("yesterday") }}
            </option>
            <option value="last7days" class="my_font">
              {{ $t("last7days") }}
            </option>
            <option value="last30days" class="my_font">
              {{ $t("last30days") }}
            </option>
            <option value="thismonth" class="my_font">
              {{ $t("thismonth") }}
            </option>
            <option value="lastmonth" class="my_font">
              {{ $t("lastmonth") }}
            </option>
            <option value="lastyear" class="my_font">
              {{ $t("lastyear") }}
            </option>
            <option value="thisyear" class="my_font">
              {{ $t("thisyear") }}
            </option>
            <option value="all" class="my_font">{{ $t("all_time") }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="flex justify-between" v-if="isHide">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite">
        {{ $t("citys_report") }}
      </p>
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite">
        {{ $t("sale_type") }}&nbsp;{{ "  :  " }}&nbsp;{{
          $t(
            invoiceType == 0
              ? "all"
              : invoiceType == 1
              ? "sale_tak"
              : "sale_jwmla"
          )
        }}
      </p>
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite">
        {{ $t("report_type") }}&nbsp;{{ "  :  " }}&nbsp;{{
          $t(getTextReportByselectedReport)
        }}
      </p>
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite">
        {{ $t("date") }}&nbsp;{{ "  :  " }} &nbsp; {{ startDate }} &nbsp;{{
          "  -  "
        }}&nbsp;
        {{ endDate }}
      </p>
    </div>
    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 my-6 gap-x-4 gap-y-2"
    >
      <ReportComponent
        :labelDolar="formatNumber(total['total_sale_price_dollar'])"
        :labelDinar="formatNumber(total['total_sale_price_dinar'])"
        :labelTitle="$t('total_sale')"
        classColor="from-blue-500 "
      />
      <ReportComponent
        :labelDolar="formatNumber(total['total_cost_price_dollar'])"
        :labelDinar="formatNumber(total['total_cost_price_dinar'])"
        :labelTitle="$t('total_cost')"
        classColor="from-red-500 "
      />
      <ReportComponent
        :labelDolar="formatNumber(total['total_profit_dollar'])"
        :labelDinar="formatNumber(total['total_profit_dinar'])"
        :labelTitle="$t('total_profit')"
        classColor="from-green-500 "
      />
      <ReportComponenet1Number
        :labelTxt="formatNumber(total['quantity']) + $t('quantity')"
        :labelTitle="$t('total_sold_quantity')"
        classColor="from-blue-500 "
      />
      <ReportComponenet1Number
        :labelTxt="formatNumber(total['invoice_count']) + $t('customer')"
        :labelTitle="$t('customer_count')"
        classColor="from-purple-500 "
      />
      <ReportComponent
        :labelDolar="formatNumber(total['total_discount_dollar'])"
        :labelDinar="formatNumber(total['total_discount_dinar'])"
        :labelTitle="$t('total_discount')"
        classColor="from-yellow-500 "
      />
    </div>

    <div
      class="w-full flex flex-col lg:justify-between lg:flex-row flex-wrap space-y-5 lg:space-y-0 lg:space-x-5 my-10"
    >
      <!-- <div
        class="w-11/12 lg:w-7/12 mx-auto lg:mx-0 bg-white rounded-lg shadow dark:bg-bgMeduimDark p-4 md:p-6"
      >
        <div></div>
      </div>-->

      <div
        class="w-11/12 lg:w-6/12 mx-auto lg:mx-1 bg-white rounded-lg shadow dark:bg-bgMeduimDark p-4 md:p-6"
      >
        <div>
          <div>
            <center>
              <p
                class="text-lg font-semibold text-textBlack dark:text-textWhite"
                v-if="series.length > 0"
              >
                {{ getTextReportByselectedReport }}
              </p>
            </center>
          </div>
          <center>
            <apex-chart
              width="400"
              type="donut"
              :options="chartOptions"
              :series="series"
              v-if="series.length > 0"
            ></apex-chart>
          </center>

          <div>
            <center>
              <p
                class="text-lg font-semibold text-textBlack dark:text-textWhite"
                v-if="series.length == 0 && !isLoadingData"
              >
                <span class="text-textBlack1 dark:text-white">
                  {{ $t("no_data_found") }}
                </span>
              </p>
            </center>
          </div>
          <div>
            <center>
              <p
                class="text-lg font-semibold text-textBlack dark:text-textWhite"
                v-if="series.length == 0 && isLoadingData"
              >
                <img
                  src="../../assets/loading.webp"
                  style="width: 70px; height: 70px"
                />
              </p>
            </center>
          </div>
        </div>
      </div>
    </div>

    <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="relative overflow-x-auto mt-4 mb-6">
        <div class="flex items-center justify-between pb-4 mx-5 no-print">
          <label for="table-search" class="sr-only"> {{ $t("search") }}</label>
          <div class="relative">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <input
              type="text"
              id="table-search"
              class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :placeholder="$t('search')"
              @input="
                $store.commit('city_report/SET_SEARCH', $event.target.value)
              "
            />
          </div>
          <button
            class="mx-2 my_font bg-primaryColor hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
            @click="printReportFunc"
            id="button_print"
            v-if="$store.getters['checkPrintAccess']"
          >
            {{ $t("print") }}
          </button>
        </div>
        <!--in ltr change (text-right to text-left)-->
        <EasyDataTable
          :headers="headers"
          :items="filterItems"
          :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
          alternating
          :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
          :loading="isLoadingData"
          :class="isDark ? 'customize-table ' : 'light-mode-table'"
          :hide-footer="isHide"
          :rows-items="[25, 50, 100, filterItems.length]"
          rows-per-page="10"
        >
          <template #default="{ column, sortDirection }">
            <span class="custom-sort-icon" v-if="column.sortable">
              <i
                v-if="sortDirection === 'asc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i
                v-else-if="sortDirection === 'desc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
            </span>
            {{ column.label }}
          </template>

          <template #loading>
            <img
              src="../../assets/loading.webp"
              style="width: 70px; height: 70px"
            />
          </template>
          <template #item-item_quantity_sold="item">
            <span
              :class="
                selectedReport == 'item_quantity_sold'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.item_quantity_sold) }}
            </span>
          </template>
          <template #item-total_sale_price_dinar="item">
            <span
              :class="
                selectedReport == 'total_sale_price_dinar'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.total_sale_price_dinar) }}
            </span>
          </template>
          <template #item-total_profit_dinar="item">
            <span
              :class="
                selectedReport == 'total_profit_dinar'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.total_profit_dinar) }}
            </span>
          </template>
          <template #item-total_discount_dinar="item">
            <span
              :class="
                selectedReport == 'total_discount_dinar'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.total_discount_dinar) }}
            </span>
          </template>
          <template #item-total_sale_price_dollar="item">
            <span
              :class="
                selectedReport == 'total_sale_price_dollar'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.total_sale_price_dollar) }}
            </span>
          </template>
          <template #item-total_profit_dollar="item">
            <span
              :class="
                selectedReport == 'total_profit_dollar'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.total_profit_dollar) }}
            </span>
          </template>
          <template #item-total_discount_dollar="item">
            <span
              :class="
                selectedReport == 'total_discount_dollar'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.total_discount_dollar) }}
            </span>
          </template>
          <template #item-total_cost_price_dinar="item">
            <span
              :class="
                selectedReport == 'total_cost_price_dinar'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.total_cost_price_dinar) }}
            </span>
          </template>
          <template #item-total_cost_price_dollar="item">
            <span
              :class="
                selectedReport == 'total_cost_price_dollar'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.total_cost_price_dollar) }}
            </span>
          </template>
          <template #empty-message>
            <span class="text-textBlack1 dark:text-white">
              {{ $t("no_data_found") }}
            </span>
          </template>
          <template
            #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
          >
            <button :disabled="isFirstPage" @click="prevPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-right'
                    : ' fa-angle-left ')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-left'
                    : ' fa-angle-right')
                "
              ></i>
            </button>
          </template>
        </EasyDataTable>
      </div>
    </div>
  </div>
  <LoadingProgress v-show="isLoadingData" />
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import { formatDate } from "../../utils/functions";
import { formatNumber } from "../../utils/functions";
import ReportComponent from "../../components/Report/ReportComponent.vue";
//ReportComponenet1Number
import ReportComponenet1Number from "../../components/Report/ReportComponenet1Number.vue";
import moment from "moment";
import LoadingProgress from "../../components/loading-progress.vue";
import InvoiceHeader from "../../components/Navbar/InvoiceHeader.vue";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    ReportComponent,
    ReportComponenet1Number,
    LoadingProgress,
    InvoiceHeader,
  },
  data() {
    return {
      series: [],
      selectedReport: "item_quantity_sold",
      chartOptions: {
        chart: {
          width: 380,
          type: "donut",
          fontFamily: "kurdish",
        },
        stroke: {
          width: 0,
        },

        labels: [],
        //change labels position to bottom
        legend: {
          position: "bottom",
          //labels colo dark and light
          labels: {
            colors: "#fff",
          },
        },
        dataLabels: {
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },

        states: {
          hover: {
            filter: "none",
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_title: "",
      no_description: "",
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      startDate: "",
      endDate: "",
      period: "last30days",
      invoiceType: "0",
      isHide: false,
    };
  },
  created() {
    //start date and end date set to last 30 days

    this.startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");

    this.getData();
    //change labelscolor dark and light
    if (this.isDark) {
      this.chartOptions.legend.labels.colors = "#fff";
    } else {
      this.chartOptions.legend.labels.colors = "#000";
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.city_report.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.city_report.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("city_report/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    filterItems() {
      return this.$store.getters["city_report/filterItems"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.city_report.isLoadingData;
    },
    getTextReportByselectedReport() {
      if (this.selectedReport == "item_quantity_sold")
        return this.$t("sold_quantity");
      else if (this.selectedReport == "total_sale_price_dinar")
        return this.$t("total_sale_in_dinar");
      else if (this.selectedReport == "total_cost_price_dinar")
        return this.$t("total_cost_in_dinar");
      else if (this.selectedReport == "total_profit_dinar")
        return this.$t("total_profit_in_dinar");
      else if (this.selectedReport == "total_discount_dinar")
        return this.$t("total_discount_in_dinar");
      else if (this.selectedReport == "total_sale_price_dollar")
        return this.$t("total_sale_in_dollar");
      else if (this.selectedReport == "total_cost_price_dollar")
        return this.$t("total_cost_in_dollar");
      else if (this.selectedReport == "total_profit_dollar")
        return this.$t("total_profit_in_dollar");
      else if (this.selectedReport == "invoice_count")
        return this.$t("customer_count");
      else return this.$t("total_discount_in_dollar");
    },
    //total
    total() {
      return this.$store.state.city_report.total;
    },
    headers() {
      return [
        { text: "#", value: "ci_id", sortable: true },
        { text: this.$t("city_name"), value: "ci_name", sortable: true },
        {
          text: this.$t("sold_quantity"),
          value: "item_quantity_sold",
          sortable: true,
        },
        {
          text: this.$t("customer_count"),
          value: "invoice_count",
          sortable: true,
        },
        {
          text: this.$t("total_sale_dinar"),
          value: "total_sale_price_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_cost_dinar"),
          value: "total_cost_price_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_profit_dinar"),
          value: "total_profit_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_discount_dinar"),
          value: "total_discount_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_sale_dollar"),
          value: "total_sale_price_dollar",
          sortable: true,
        },
        {
          text: this.$t("total_cost_dollar"),
          value: "total_cost_price_dollar",
          sortable: true,
        },
        {
          text: this.$t("total_profit_dollar"),
          value: "total_profit_dollar",
          sortable: true,
        },
        {
          text: this.$t("total_discount_dollar"),
          value: "total_discount_dollar",
          sortable: true,
        },
      ];
    },
  },

  methods: {
    printReportFunc() {
      this.isHide = true;
      //wait 1 second
      setTimeout(() => {
        var printContents = document.getElementById("printDev").innerHTML;

        // Replace the body's innerHTML with the print contents
        document.body.innerHTML = printContents;
        var isRtl = this.$store.getters["isRtl"];
        // Set direction based on the parameter
        if (isRtl) {
          document.body.style.direction = "rtl";
        } else {
          document.body.style.direction = "ltr";
        }

        // Print the content
        window.print();
        //reload page
      }, 100);
      //print element id invoice_print
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    async getData() {
      this.$store.state.city_report.isLoadingData = true;
      await this.$store.dispatch("city_report/getItems", {
        startDate: this.startDate,
        endDate: this.endDate,
        invoiceType: this.invoiceType,
      });
      this.changeReportType();
    },
    changeReportType() {
      var listServer = this.$store.state.city_report.items;
      //get top 5 items and for all other items make them one item sum
      if (listServer.length > 7) {
        var top7 = listServer.slice(0, 7);
        var other = listServer.slice(7);
        var sum = other.reduce((a, b) => {
          return {
            item_quantity_sold:
              parseInt(a.item_quantity_sold) + parseInt(b.item_quantity_sold),
            total_sale_price_dinar:
              parseInt(a.total_sale_price_dinar) +
              parseInt(b.total_sale_price_dinar),
            total_cost_price_dinar:
              parseInt(a.total_cost_price_dinar) +
              parseInt(b.total_cost_price_dinar),
            total_profit_dinar:
              parseInt(a.total_profit_dinar) + parseInt(b.total_profit_dinar),
            total_discount_dinar:
              parseInt(a.total_discount_dinar) +
              parseInt(b.total_discount_dinar),
            total_sale_price_dollar:
              parseInt(a.total_sale_price_dollar) +
              parseInt(b.total_sale_price_dollar),
            total_cost_price_dollar:
              parseInt(a.total_cost_price_dollar) +
              parseInt(b.total_cost_price_dollar),
            total_profit_dollar:
              parseInt(a.total_profit_dollar) + parseInt(b.total_profit_dollar),
            total_discount_dollar:
              parseInt(a.total_discount_dollar) +
              parseInt(b.total_discount_dollar),
            invoice_count:
              parseInt(a.invoice_count) + parseInt(b.invoice_count),
            ci_name: this.$t("others"),
          };
        });
        top7.push(sum);
        listServer = top7;
      }

      var ci_name = listServer.map((item) => item.ci_name);
      var values = listServer.map((item) => {
        if (this.selectedReport == "item_quantity_sold")
          return parseFloat(item.item_quantity_sold);
        else if (this.selectedReport == "total_sale_price_dinar")
          return parseFloat(item.total_sale_price_dinar);
        else if (this.selectedReport == "total_cost_price_dinar")
          return parseFloat(item.total_cost_price_dinar);
        else if (this.selectedReport == "total_profit_dinar")
          return parseFloat(item.total_profit_dinar);
        else if (this.selectedReport == "total_discount_dinar")
          return parseFloat(item.total_discount_dinar);
        else if (this.selectedReport == "total_sale_price_dollar")
          return parseFloat(item.total_sale_price_dollar);
        else if (this.selectedReport == "total_cost_price_dollar")
          return parseFloat(item.total_cost_price_dollar);
        else if (this.selectedReport == "total_profit_dollar")
          return parseFloat(item.total_profit_dollar);
        else if (this.selectedReport == "total_discount_dollar")
          return parseFloat(item.total_discount_dollar);
        else if (this.selectedReport == "invoice_count")
          return parseFloat(item.invoice_count);
      });

      this.chartOptions = {
        ...this.chartOptions,
        labels: ci_name,
      };
      this.series = values;
    },
    periodChanged(action) {
      this.period = action;

      if (action == "today") {
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "yesterday") {
        this.startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (action == "last7days") {
        this.startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "last30days") {
        this.startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "thismonth") {
        this.startDate = moment().startOf("month").format("YYYY-MM-DD");
        this.endDate = moment().endOf("month").format("YYYY-MM-DD");
      } else if (action == "lastmonth") {
        this.startDate = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.endDate = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (action == "lastyear") {
        this.startDate = moment()
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.endDate = moment()
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
      } else if (action == "thisyear") {
        this.startDate = moment().startOf("year").format("YYYY-MM-DD");
        this.endDate = moment().endOf("year").format("YYYY-MM-DD");
      } else if (action == "all") {
        this.startDate = "2000-01-01";
        this.endDate = moment().endOf("year").format("YYYY-MM-DD");
      }

      this.getData();
    },
  },
};
</script>
