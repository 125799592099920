import { post } from "../../helpers/api";
//get
import { get } from "../../helpers/api";
const state = {
  dp_price: 0,
  currentUser: {},
  expiredItems: 0,
};

const mutations = {
  SET_DP_PRICE(state, dp_price) {
    state.dp_price = dp_price;
  },
  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser;
  },
  SET_EXPIRED_ITEMS(state, expiredItems) {
    state.expiredItems = expiredItems;
  },
};

const actions = {
  async getItems({ commit }) {
    try {
      post("dolar-price/last", {})
        .then((response) => {
          commit("SET_DP_PRICE", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  },
  async getExpiredCount({ commit }) {
    try {
      return post("item/get-number-expired", {})
        .then((response) => {
          console.log(response.data);
          commit("SET_EXPIRED_ITEMS", response.data);
          return response;
        })
        .catch((error) => {
          console.log(error);
          return 0;
        });
    } catch (error) {
      console.log(error);
      return 0;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async backup({ commit }) {
    try {
      get("backup", {})
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  },
  async getCurrentUser({ commit }) {
    try {
      post("user/get", {})
        .then((response) => {
          commit("SET_CURRENT_USER", response.data);
        })
        .catch((error) => {
          console.log(error);
          //check if 401 go to login
          if (error.response.status == 401) {
            localStorage.removeItem("token_wise_manager");
            localStorage.removeItem("user_wise_manager");
            //go to login page
            window.location.href = "/login";
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
