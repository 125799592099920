<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <nav
    class="fixed top-0 h-14 z-50 w-full bg-white border-gray-200 dark:bg-bgLightDark"
  >
    <!--in ltr change lg:pr-3 to lg:pl-3-->
    <div class="px-3 py-1 lg:px-5 lg:pr-3">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-start">
          <div class="flex flex-col my-2">
            <a
              href="/sold-list"
              class="flex text-xl dark:text-textWhite"
              id="darkModeToggle"
            >
              <!-- <img src="/assets/image/kcStore.png" alt="" class="w-16 lg:w-20 mx-3"> -->
              <i class="fa-solid fa-chevron-right my-auto mx-2"></i>
              {{ $t("app_name") }}
            </a>
          </div>
          <div class="w-10"></div>
          <div class="dark:text-white">
            {{ $t("dolar_price") }} : {{ formatNumber(getDolarPrice) }}
          </div>
        </div>
      </div>
    </div>
  </nav>
  <LoadingProgress v-show="isLoadingData" />

  <div
    class="mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl"
  >
    <div class="flex flex-wrap mb-4 justify-between">
      <p
        class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3 my-2 lg:my-0"
      >
        {{ $t("edit_invoice_number") }} #{{ in_id }}
      </p>
      <p
        class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3 my-2 lg:my-0"
      >
        {{ $t("admin") }} {{ name }}
      </p>
      <p
        class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3 my-2 lg:my-0"
      >
        {{ $t("date") }} : {{ formatDate(created_at) }}
      </p>
      <div
        class="font-semibold text-lg text-textBlack dark:text-textWhite mx-3 my-2 lg:my-0"
      >
        <span v-show="in_status == 1" class="text-success">
          {{ $t("invoice_status") + " : " + $t("sold") }}</span
        >
        <span v-show="in_status != 1" class="text-red-500">
          {{ $t("invoice_status") + " : " + $t("returned") }}</span
        >
      </div>
      <div class="my-2 lg:my-0">
        <label
          for="boxType"
          class="block mb-1 text-sm text-textBlack1 dark:text-textWhite1"
          >{{ $t("currency_invoice_type") }}</label
        >
        <button
          id="boxType"
          class="bg-white border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-32 p-1.5 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <i
            class="fa-solid fa-arrows-rotate text-primaryColor mx-1 text-sm"
          ></i>
          <span class="mx-1" v-show="cur_id == 1">{{ $t("dinar") }}</span>
          <span class="mx-1" v-show="cur_id == 2">{{ $t("dollar") }}</span>
        </button>
      </div>
    </div>
    <div class="md:flex">
      <div class="w-4"></div>

      <div
        class="w-full md:w-full my-3 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl mb-20 md:mb-14 mx-auto md:mx-0"
      >
        <form action="">
          <!--sale extra information-->

          <div id="accordion-color">
            <h2
              id="accordion-color-heading-1"
              :class="isShowDetail ? 'border-primaryColor text-blue-600' : ''"
            >
              <button
                type="button"
                class="flex bg-slate-100 dark:bg-bgFullDark items-center justify-between w-full px-4 py-2 font-medium text-right text-textBlack1 dark:text-textWhite1 border-l-0 border-r-0 border border-gray-300 dark:border-gray-600 hover:border-primaryColor"
                @click="isShowDetail = !isShowDetail"
              >
                <span>{{ $t("detail") }}</span>
                <svg
                  data-accordion-icon
                  class="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>

            <!-- extra sale information-->
            <div
              id="accordion-color-body-1"
              aria-labelledby="accordion-color-heading-1"
              v-show="isShowDetail"
            >
              <div class="p-5 grid grid-cols-2 gap-x-3">
                <DropdownInput
                  v-model="bo_id"
                  :label="$t('box')"
                  :isDisabled="true"
                  :placeholder="$t('choose_box_that_money_get_in')"
                  :rules="no_rule"
                  :items="convertToListIdValueBoxes"
                  :isError="
                    checkRulesIsError(bo_id, 'add', this.no_rule).isError
                  "
                  :errorMsg="
                    checkRulesIsError(bo_id, 'add', this.no_rule).errorMsg
                  "
                />
                <DropdownInput
                  v-model="st_id"
                  :label="$t('sale_type')"
                  :placeholder="$t('choose_sale_type')"
                  :rules="rules_dropdown"
                  :items="convertToListIdValueSaleTypes"
                  :isError="
                    checkRulesIsError(st_id, 'add', this.rules_dropdown).isError
                  "
                  :errorMsg="
                    checkRulesIsError(st_id, 'add', this.rules_dropdown)
                      .errorMsg
                  "
                />

                <div class="mb-6">
                  <label
                    for="saleType"
                    class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
                    >{{ $t("customer") }}</label
                  >
                  <VueMultiselect
                    v-model="cu_id"
                    :options="convertToListIdValueCustomers"
                    :multiple="false"
                    :placeholder="$t('choose_customer')"
                    label="value"
                    track-by="id"
                    :taggable="true"
                    class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-bgFullDark dark:border-gray-600"
                  >
                  </VueMultiselect>
                </div>
                <DropdownInput
                  v-model="pl_id"
                  :label="$t('sale_platform')"
                  :placeholder="$t('choose_sale_platform')"
                  :rules="rules_dropdown"
                  :items="convertToListIdValuePlatforms"
                  :isError="
                    checkRulesIsError(pl_id, 'add', this.rules_dropdown).isError
                  "
                  :errorMsg="
                    checkRulesIsError(pl_id, 'add', this.rules_dropdown)
                      .errorMsg
                  "
                />
                <BaseInput
                  v-model="created_at"
                  type="date"
                  :label="$t('sale_date')"
                  :placeholder="$t('sale_date')"
                  :rules="no_rule"
                  :isError="
                    checkRulesIsError(created_at, 'add', this.no_rule).isError
                  "
                  :errorMsg="
                    checkRulesIsError(created_at, 'add', this.no_rule).errorMsg
                  "
                />
                <TextAreaInput
                  v-model="in_note"
                  :label="$t('note')"
                  :placeholder="$t('note')"
                  :rules="no_rule"
                  :isError="
                    checkRulesIsError(in_note, 'add', this.no_rule).isError
                  "
                  :errorMsg="
                    checkRulesIsError(in_note, 'add', this.no_rule).errorMsg
                  "
                />
                <div>
                  <label
                    for="currency"
                    class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
                    >{{ $t("do_you_want_to_show_item_cost_price") }}</label
                  >
                  <div class="flex mx-4 mt-4">
                    <div class="flex items-center ml-4">
                      <input
                        id="inline-radio111"
                        type="radio"
                        value="1"
                        checked="true"
                        v-model="isShowCost"
                        name="inline-radio-group11"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        @change="costStatus"
                      />
                      <label
                        for="inline-radio"
                        class="mr-2 text-base font-medium text-gray-900 dark:text-gray-300"
                        >{{ $t("yes") }}</label
                      >
                    </div>
                    <div class="flex items-center ml-4">
                      <input
                        id="inline-2-radio111"
                        type="radio"
                        value="2"
                        v-model="isShowCost"
                        @change="costStatus"
                        name="inline-radio-group11"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="inline-2-radio"
                        class="mr-2 text-base font-medium text-gray-900 dark:text-gray-300"
                        >{{ $t("no") }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--table-->
          <div class="relative overflow-x-auto mt-10 mb-6 overflow-y-auto">
            <!--in ltr change (text-right to text-left)-->
            <table class="w-full text-right text-gray-500 dark:text-gray-400">
              <thead
                class="text-xs text-gray-600 border-l-0 border-r-0 border border-gray-300 dark:border-gray-600 uppercase bg-slate-100 dark:bg-bgFullDark dark:text-gray-400"
              >
                <tr>
                  <th scope="col" class="px-2 py-1">{{ $t("item_name") }}</th>
                  <th scope="col" class="px-2 py-1">{{ $t("barcode") }}</th>
                  <th scope="col" class="px-2 py-1" v-if="isShowCost == '1'">
                    {{ $t("cost") }}
                  </th>
                  <th scope="col" class="px-2 py-1">{{ $t("price") }}</th>
                  <th scope="col" class="px-2 py-1">{{ $t("quantity") }}</th>
                  <th scope="col" class="px-2 py-1">{{ $t("unit") }}</th>
                  <th scope="col" class="px-2 py-1">{{ $t("discount") }}</th>
                  <th scope="col" class="px-2 py-1" v-if="isShowCost == '1'">
                    {{ $t("total_cost") }}
                  </th>
                  <th scope="col" class="px-2 py-1">{{ $t("final_price") }}</th>
                  <th scope="col" class="px-2 py-1">{{ $t("actions") }}</th>
                </tr>
              </thead>
              <tbody class="text-sm">
                <tr
                  class="bg-white text-textBlack border-b dark:bg-bgLightDark dark:text-textWhite2 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-bgFullDark"
                  v-for="(item, index) in getSoldListByCurrentCurrency"
                  :key="index"
                >
                  <td class="px-2 py-2">
                    {{ item.item.i_name }}
                  </td>
                  <td class="px-2 py-2">
                    {{ item.i_id }}
                  </td>
                  <td class="px-2 py-2" v-if="isShowCost == '1'">
                    {{ item.si_cost_price }}
                  </td>
                  <td class="px-2 py-2">
                    {{ formatNumber(getPriceByUnit(item)) }}
                  </td>
                  <td class="px-2 py-2">
                    <input
                      type="number"
                      class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-14 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                      v-model="item.si_quantity"
                      min="0"
                      @input="calculateDue(item, item.si_quantity)"
                    />
                  </td>
                  <td class="px-2 py-2">
                    {{ getUnit(item) }}
                  </td>
                  <td class="px-2 py-2">
                    <input
                      type="number"
                      class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                      v-model="item.si_discount"
                      min="0"
                    />
                  </td>
                  <td class="px-2 py-2" v-if="isShowCost == '1'">
                    {{ item.si_quantity * item.si_cost_price }}
                  </td>
                  <td class="px-2 py-2">
                    {{
                      formatNumber(
                        item.si_quantity * getPriceByUnit(item) -
                          item.si_discount
                      )
                    }}<span class="mx-1" v-show="cur_id == 1">{{
                      $t("dinar")
                    }}</span>
                    <span class="mx-1" v-show="cur_id == 2">{{
                      $t("dollar")
                    }}</span>
                  </td>
                  <td class="px-2 py-2 flex mt-2">
                    <div @click="removeItem(item.i_id)" class="curser-pointer">
                      <i
                        class="fa-solid fa-trash text-center p-1 w-6 h-6 text-xs bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto my-auto"
                      ></i>
                    </div>
                  </td>
                </tr>
                <tr
                  class="bg-white text-textBlack border-b dark:bg-bgLightDark dark:text-textWhite2 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-bgFullDark"
                  v-show="sold_list.length != 0"
                >
                  <td class="px-2 py-2"></td>
                  <td class="px-2 py-2"></td>
                  <td class="px-2 py-2"></td>
                  <td class="px-2 py-2"></td>
                  <td class="px-2 py-2"></td>
                  <td class="px-2 py-2" v-if="isShowCost == '1'"></td>
                  <td class="px-2 py-2">
                    {{ $t("discount_amount") }} : {{ formatNumber(sumDiscount)
                    }}<span class="mx-1" v-show="cur_id == 1">{{
                      $t("dinar")
                    }}</span>
                    <span class="mx-1" v-show="cur_id == 2">{{
                      $t("dollar")
                    }}</span>
                  </td>
                  <td class="px-2 py-2" v-if="isShowCost == '1'">
                    {{ $t("total_cost") }} : {{ formatNumber(sumCost) }}
                    <span class="mx-1" v-show="cur_id == 1">{{
                      $t("dinar")
                    }}</span>
                    <span class="mx-1" v-show="cur_id == 2">{{
                      $t("dollar")
                    }}</span>
                  </td>
                  <td class="px-2 py-2">
                    {{ $t("total_price") }} : {{ formatNumber(sumPrice) }}
                    <span class="mx-1" v-show="cur_id == 1">{{
                      $t("dinar")
                    }}</span>
                    <span class="mx-1" v-show="cur_id == 2">{{
                      $t("dollar")
                    }}</span>
                  </td>
                  <td class="px-2 py-2 flex mt-2"></td>
                </tr>
              </tbody>
            </table>
            <center>
              <span
                class="text-center text-textBlack1 dark:text-white"
                v-show="sold_list.length == 0"
                >{{ $t("no_item_in_invoice") }}</span
              >
            </center>
          </div>
        </form>
      </div>
    </div>

    <div
      class="flex justify-between items-center px-4 md:px-16 py-3 left-0 right-0 fixed bottom-0 bg-white dark:bg-bgMeduimDark rounded-tl-xl z-50"
    >
      <div class="md:flex items-baseline">
        <p class="text-sm md:text-base text-textBlack1 dark:text-white">
          {{ $t("total_items_price") }} :
          <span class="text-xl md:text-2xl font-bold"
            >{{ formatNumber(sumPrice)
            }}<span class="mx-1" v-show="cur_id == 1">{{ $t("dinar") }}</span>
            <span class="mx-1" v-show="cur_id == 2">{{
              $t("dollar")
            }}</span></span
          >
        </p>

        <div class="flex mx-0 md:mx-6 items-center my-3 lg:my-0">
          <span
            class="text-sm md:text-base font-medium text-textBlack1 dark:text-textWhite1 mx-1"
            >{{ $t("money_amount_received") }}:</span
          >
          <input
            type="number"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 w-20 md:w-40 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            v-model="in_paid"
            min="0"
          />
        </div>

        <p class="text-sm md:text-base mx-2 text-textBlack1 dark:text-white">
          {{ $t("debt") }}:
          <span class="text-xl md:text-2xl font-bold">{{
            formatNumber(sumPrice - in_paid)
          }}</span>
        </p>
      </div>

      <div class="lg:flex">
        <div class="flex justify-between mb-1 lg:mb-0">
          <button
            data-modal-target="defaultModal"
            data-modal-toggle="defaultModal"
            class="text-white px-2 text-lg md:text-xl bg-green-500 px-4 md:px-8 py-2 rounded items-center hover:shadow-md"
            @click="modalPrintID"
          >
            <i class="fa-solid fa-print"></i>
          </button>
          <div class="w-2 md:w-4"></div>
          <button
            data-modal-target="defaultModal"
            data-modal-toggle="defaultModal"
            class="text-white px-2 text-lg md:text-xl bg-blue-300 px-4 md:px-8 py-2 rounded items-center hover:shadow-md"
            @click="showModal(item)"
          >
            <i class="fa-solid fa-calculator"></i>
          </button>
          <div class="w-2 md:w-4"></div>
          <button
            data-modal-target="defaultModal"
            data-modal-toggle="defaultModal"
            class="text-white px-2 text-lg md:text-xl bg-red-500 px-4 md:px-8 py-2 rounded items-center hover:shadow-md"
            @click="removeInvoice"
            v-show="in_status == 1"
            v-if="
              $store.getters['checkUserAccess']({
                id: '19',
                action: 'delete',
              })
            "
          >
            <i class="fa-solid fa-trash"></i>
          </button>
        </div>

        <div class="w-2 md:w-4"></div>
        <button
          class="text-white text-lg md:text-xl bg-success px-2 w-40 md:w-72 lg:w-40 py-2 rounded items-center hover:shadow-md"
          @click="addSaleItem"
          v-show="in_status == 1"
          v-if="
            $store.getters['checkUserAccess']({
              id: '19',
              action: 'edit',
            })
          "
        >
          <div v-if="!isBtnLoading">{{ $t("edit") }}</div>

          <div
            class="flex items-center hover:shadow-mdtext-white flex justify-center items-center hover:shadow-md"
            role="status"
            v-else
          >
            <svg
              width="20"
              height="20"
              fill="currentColor"
              class="mr-2 animate-spin"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
              ></path>
            </svg>
            <span class="mx-1"> {{ $t("wait") }}</span>
          </div>
        </button>
      </div>
    </div>
  </div>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <DynamicModalAction ref="actionModal"
    ><div class="relative w-full max-w-2xl max-h-full">
      <!-- Modal content -->
      <div class="relative">
        <!-- Modal header -->
        <div class="flex items-start justify-between p-4">
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            {{ $t("change_money_counter") }}
          </h3>
          <i
            class="fa-solid fa-trash text-red-500"
            @click="recivedMoney = 0"
          ></i>
        </div>
        <!-- Modal body -->
        <div class="p-2 mb-14 md:mb-80 text-textBlack1 dark:text-white">
          <p>{{ $t("1_dollar_price") }} = {{ formatNumber(getDolarPrice) }}</p>
          <div class="md:flex w-full mt-2 rounded-lg">
            <div
              class="w-11/12 md:w-7/12 h-20 grid grid-cols-2 gap-x-2 gap-y-4 mx-auto"
            >
              <div
                class="flex flex-col bg-slate-100 dark:bg-bgMeduimDark rounded-lg w-36 h-24 md:w-40 md:h-28 shadow-md"
                @click="addRecievedMoney(2, 1)"
              >
                <img
                  src="../../assets/image/1_dolar.jpg"
                  alt=""
                  class="w-full h-3/4 rounded-lg curser-pointer"
                />
                <p class="text-center my-auto curser-pointer">
                  {{ $t("1_dollar") }}
                </p>
              </div>
              <div
                class="flex flex-col bg-slate-100 dark:bg-bgMeduimDark rounded-lg w-36 h-24 md:w-40 md:h-28 shadow-md"
                @click="addRecievedMoney(2, 5)"
              >
                <img
                  src="../../assets/image/5_dolar.png"
                  alt=""
                  class="w-full h-3/4 rounded-lg"
                />
                <p class="text-center my-auto">{{ $t("5_dollar") }}</p>
              </div>
              <div
                class="flex flex-col m-1 bg-slate-100 dark:bg-bgMeduimDark rounded-lg w-36 h-24 md:w-40 md:h-28 shadow-md"
                @click="addRecievedMoney(2, 50)"
              >
                <img
                  src="../../assets/image/50dollar.jpeg"
                  alt=""
                  class="w-full h-3/4 rounded-lg"
                />
                <p class="text-center my-auto">{{ $t("50_dollar") }}</p>
              </div>
              <div
                class="flex m-1 flex-col bg-slate-100 dark:bg-bgMeduimDark rounded-lg w-36 h-24 md:w-40 md:h-28 shadow-md"
                @click="addRecievedMoney(2, 100)"
              >
                <img
                  src="../../assets/image/100dollar.jpg"
                  alt=""
                  class="w-full h-3/4 rounded-lg"
                />
                <p class="text-center my-auto">{{ $t("100_dollar") }}</p>
              </div>
              <div
                class="flex flex-col m-1 bg-slate-100 dark:bg-bgMeduimDark rounded-lg w-36 h-24 md:w-40 md:h-28 shadow-md"
                @click="addRecievedMoney(1, 1000)"
              >
                <img
                  src="../../assets/image/1_iqd.jpg"
                  alt=""
                  class="w-full h-3/4 rounded-lg"
                />
                <p class="text-center my-auto">{{ $t("1_thousand_dinar") }}</p>
              </div>
              <div
                class="flex flex-col m-1 bg-slate-100 dark:bg-bgMeduimDark rounded-lg w-36 h-24 md:w-40 md:h-28 shadow-md"
                @click="addRecievedMoney(1, 5000)"
              >
                <img
                  src="../../assets/image/5dinnar.png"
                  alt=""
                  class="w-full h-3/4 rounded-lg"
                />
                <p class="text-center my-auto">{{ $t("5_thousand_dinar") }}</p>
              </div>
              <div
                class="flex flex-col m-1 bg-slate-100 dark:bg-bgMeduimDark rounded-lg w-36 h-24 md:w-40 md:h-28 shadow-md"
                @click="addRecievedMoney(1, 10000)"
              >
                <img
                  src="../../assets/image/10dinnar.jpg"
                  alt=""
                  class="w-full h-3/4 rounded-lg"
                />
                <p class="text-center my-auto">{{ $t("10_thousand_dinar") }}</p>
              </div>
              <div
                class="flex flex-col m-1 bg-slate-100 dark:bg-bgMeduimDark rounded-lg w-36 h-24 md:w-40 md:h-28 shadow-md"
                @click="addRecievedMoney(1, 25000)"
              >
                <img
                  src="../../assets/image/25dinnar.jpg"
                  alt=""
                  class="w-full h-3/4 rounded-lg"
                />
                <p class="text-center my-auto">{{ $t("25_thousand_dinar") }}</p>
              </div>
              <div
                class="flex flex-col m-1 bg-slate-100 dark:bg-bgMeduimDark rounded-lg w-36 h-24 md:w-40 md:h-28 shadow-md"
                @click="addRecievedMoney(1, 50000)"
              >
                <img
                  src="../../assets/image/50dinnar.png"
                  alt=""
                  class="w-full h-3/4 rounded-lg"
                />
                <p class="text-center my-auto">{{ $t("50_thousand_dinar") }}</p>
              </div>
            </div>
            <div class="h-40 md:mt-0"></div>
            <div class="w-full md:w-5/12 h-20 mt-96 md:mt-0">
              <div class="flex mb-2 justify-between">
                <span class="my-auto">{{ $t("items_price") }}</span>
                <input
                  type="number"
                  class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-1.5 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  v-model="in_paid"
                />
              </div>

              <div class="flex mb-2 justify-between">
                <span class="my-auto">{{ $t("money_amount_received") }}</span>
                <input
                  type="number"
                  class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-1.5 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  v-model="recivedMoney"
                />
              </div>

              <div class="flex mb-2 justify-between">
                <span class="my-auto">{{ $t("change_money") }} </span>
                <div class="w-2"></div>
                <input
                  type="number"
                  class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-1.5 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  v-model="getChangeMoney"
                />
              </div>
            </div>
          </div>
        </div>
      </div></div
  ></DynamicModalAction>
  <div :class="classesPrintModal()">
    <!-- modal -->
    <div class="my_font bg-white rounded shadow-lg w-10/12 md:w-1/3">
      <!-- modal body -->
      <div
        class="p-3 bg-white my_font font-bold text-size-base text-black"
        id="invoice_print"
      >
        <div>
          <h6 class="text-center text-black font-bold text-size-lg">
            {{ settings.app_name }}
          </h6>
          <p class="my_font text-center font-bold text-size-base text-black">
            {{ settings.set_header_invoice }}
          </p>
          <p class="my_font text-center font-bold text-size-base text-black">
            <i
              class="fas fa-phone text-size-base relative mx-2 text-pink text-center"
            ></i>
            {{ settings.set_phone_number }}
          </p>
        </div>
        <div class="w-full max-w-full shrink-0 md:w-full md:flex-0 bg-white">
          <div class="flex-auto pb-2 my_font">
            <div class="overflow-x-auto">
              <table
                class="items-center w-full mb-0 align-top border-gray-200 text-black-500"
              >
                <tbody>
                  <tr v-if="in_id != ''">
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("invoice_id") }}
                    </td>
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ in_id }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("date") }}
                    </td>
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      <!--
                                            show index of item in loop 1,2,3,4,5,6,7,8,9,10
                                          -->
                      <div class="whitespace-normal">
                        {{ formatDate(created_at) }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex-auto pb-2">
            <div class="overflow-x-auto">
              <table
                class="items-center w-full mb-0 align-top border-gray-200 text-black-500"
              >
                <thead class="align-bottom text-center">
                  <tr>
                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      #
                    </th>

                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                      colspan="3"
                    >
                      {{ $t("item_name") }}
                    </th>
                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("price") }}
                    </th>
                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("quantity") }}
                    </th>

                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("discount") }}
                    </th>
                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("total_price") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="w-full"
                    v-for="(item, index) in getSoldListByCurrentCurrency"
                    :key="index"
                  >
                    <td
                      class="text-black -bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ index + 1 }}
                    </td>

                    <td
                      class="text-black font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-normal dark:border-white/40"
                      colspan="3"
                    >
                      <div class="whitespace-normal">
                        {{ item.i_name }}
                      </div>
                    </td>
                    <td
                      class="text-black font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ formatNumber(getPriceByUnit(item)) }}
                    </td>
                    <td
                      class="text-black font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ formatNumber(item.si_quantity) }}
                    </td>
                    <td
                      class="text-black font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ formatNumber(item.si_discount) }}
                    </td>
                    <td
                      class="text-black font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                      colspan="1"
                    >
                      {{
                        formatNumber(
                          item.si_quantity * getPriceByUnit(item) -
                            item.si_discount
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <table
            class="items-center w-full mb-0 align-top border-gray-200 text-black-500"
          >
            <tbody>
              <tr>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <b>{{ $t("items_price") }}</b>
                </td>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <p>
                    {{ formatNumber(sumPriceWithoutDiscount) }}
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <b>{{ $t("discount") }} </b>
                </td>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <p>{{ formatNumber(sumDiscount) }}</p>
                </td>
              </tr>

              <!--end tr-->
              <tr>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <b>{{ $t("final_price") }}</b>
                </td>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-lg my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <b> {{ formatNumber(sumPrice) }}</b>
                </td>
              </tr>
              <tr>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <b>{{ $t("debt") }}</b>
                </td>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-lg my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <b> {{ formatNumber(getInDue) }}</b>
                </td>
              </tr>
              <tr>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <b>{{ $t("paid_money") }}</b>
                </td>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-lg my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <b> {{ formatNumber(in_paid) }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="border-b w-full"></div>
      <div class="flex justify-start items-center w-full p-3 bg-white">
        <button
          class="mx-auto text-center bg-pink-600 hover:bg-pink-700 px-3 py-1 rounded text-white"
          @click="modalPrintID"
        >
          <svg
            aria-hidden="true"
            class="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="loading"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span v-if="!loading"> {{ $t("print") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "../../components/Inputs/BaseInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import { formatDate } from "../../utils/functions";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import { FILE_URL } from "../../constants/config";
import { CURRENCYS } from "../../constants/config";
import { formatNumber } from "../../utils/functions";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
import DynamicModalAction from "../../components/Modals/DynamicModalAction.vue";
import LoadingProgress from "../../components/loading-progress.vue";
import VueMultiselect from "vue-multiselect";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    BaseInput,

    ConfirmDialogue,
    ResultDialog,
    TextAreaInput,
    DropdownInput,
    DynamicModalAction,
    LoadingProgress,
    VueMultiselect,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value || value == 0) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      no_rule: [],
      inputType: "text",

      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      isShowDetail: false,
      file_url: FILE_URL,
      currencys: CURRENCYS,
      sold_list: [],
      barcode: "",
      in_paid: 0,
      in_price: 0,
      in_discount: 0,
      in_due: 0,
      cu_id: "",
      bo_id: "",
      cur_id: 2,
      st_id: "",
      in_main_currency_price: 0,
      pl_id: "",
      in_note: "",
      created_at: "",
      tempItem: 0,
      recivedMoney: 0,
      changeMoney: 0,
      in_id: 0,
      in_status: 1,
      name: "",
      isModalPrint: false,
      settings: {},
      isShowCost: "2",
    };
  },
  created() {
    this.settings = JSON.parse(
      localStorage.getItem("currentSettingWiseManager")
    );
    this.getSaleTypes();
    this.getBoxs();
    this.getCurrentInvoice();
    this.getCustomers();

    this.getPlatforms();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.edit_sale.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.edit_sale.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("edit_sale/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    getChangeMoney() {
      return this.recivedMoney - this.in_paid;
    },
    getInDue() {
      return this.sumPrice - this.in_paid;
    },
    getResultDialog() {
      return this.$store.state.edit_sale.isShowResultDialog;
    },
    getDolarPrice() {
      return this.in_main_currency_price;
    },

    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.edit_sale.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.edit_sale.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.edit_sale.isBtnEditLoading;
    },

    convertToListIdValueCategory() {
      var categorys = this.$store.state.item_category.categorys;
      var list = [];
      for (const item of categorys) {
        list.push({
          id: item.ca_id,
          value: item.ca_name,
        });
      }

      return list;
    },
    convertToListIdValueBoxes() {
      var boxes = this.$store.state.all_box.boxes;
      var list = [];
      for (const item of boxes) {
        list.push({
          id: item.bo_id,
          value: item.bo_name,
        });
      }

      return list;
    },
    convertToListIdValueSaleTypes() {
      var saleTypes = this.$store.state.sale_type.saleTypes;
      var list = [];
      for (const item of saleTypes) {
        list.push({
          id: item.st_id,
          value: item.st_name,
        });
      }

      return list;
    },
    convertToListIdValuePlatforms() {
      var platforms = this.$store.state.sale_platform.platforms;
      var list = [];
      for (const item of platforms) {
        list.push({
          id: item.pl_id,
          value: item.pl_name,
        });
      }

      return list;
    },
    convertToListIdValue() {
      var list = [];
      for (const item of this.currencys) {
        list.push({
          id: item.cur_id,
          value: this.$t(item.cur_name),
        });
      }

      return list;
    },
    sumPriceWithoutDiscount() {
      var sum = 0;
      for (const item of this.getSoldListByCurrentCurrency) {
        sum += this.getPriceByUnit(item) * item.si_quantity;
      }
      return sum.toFixed(2);
    },
    convertToListIdValueCustomers() {
      var customers = this.$store.state.customer.customers;
      var list = [];
      for (const item of customers) {
        list.push({
          id: item.cu_id,
          value: item.cu_name,
        });
      }

      return list;
    },
    sumDiscount() {
      var sum = 0;
      for (const item of this.getSoldListByCurrentCurrency) {
        sum += item.si_discount;
      }
      return sum;
    },
    //sum price of all items
    sumPrice() {
      var sum = 0;
      for (const item of this.getSoldListByCurrentCurrency) {
        sum += this.getPriceByUnit(item) * item.si_quantity - item.si_discount;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //  this.in_paid = sum.toFixed(2);
      return sum.toFixed(2);
    },
    sumCost() {
      var sum = 0;
      for (const item of this.getSoldListByCurrentCurrency) {
        sum += this.getCostPriceByUnit(item) * item.si_quantity;
      }
      return sum.toFixed(2);
    },
    getSoldListByCurrentCurrency() {
      var list = [];
      for (const item of this.sold_list) {
        //cehck this.cur_id == item.cur_id nothing to do otherwise convert price if this.cur_id==1 convert to dinar if this.cur_id==2 convert to dollar

        list.push(item);
      }

      return list;
    },
    checkIfEdit() {
      if (this.$route.params.id) {
        return true;
      }
      return false;
    },
  },

  methods: {
    //costStatus
    costStatus() {},
    modalPrintID() {
      this.isModalPrint = !this.isModalPrint;

      // Get the content to print
      const printContents = document.getElementById("invoice_print").innerHTML;

      // Open a new window for printing
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
        <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.16/dist/tailwind.min.css" rel="stylesheet">
        <style>
          /* Set the RTL direction */
          body {
            direction: rtl;
            font-family: 'Rabar_022', sans-serif; /* Use the font name you define here */
          }
        </style>
        <link rel="stylesheet" href="https://kcstore.online/fonts/Rabar_022.6835fa5e.ttf">
      </head>
      <body>
        <div >
          ${printContents}
        </div>
      </body>
    </html>
  `);
      printWindow.document.close();

      // Print the contents
      printWindow.print();

      //
      printWindow.document.close();

      // Print the contents
      printWindow.print();

      // Close the print window after printing

      this.isModalPrint = !this.isModalPrint;
    },
    classesPrintModal() {
      return {
        "modal  bg-opacity-5 h-full   w-full  fixed top-0 left-0  justify-center   hidden overflow-y-auto":
          !this.isModalPrint,
        "modal  bg-opacity-5 h-full   w-full  fixed top-0 left-0  justify-center  flex overflow-y-auto ":
          this.isModalPrint,
      };
    },
    async getCustomers() {
      await this.$store.dispatch("customer/getCustomers");
    },
    async getPlatforms() {
      await this.$store.dispatch("sale_platform/getPlatforms");

      this.pl_id = this.$store.state.sale_platform.platforms[0].pl_id;
    },
    async getCurrentInvoice() {
      this.in_id = this.$route.params.id;
      var res = await this.$store.dispatch("edit_sale/getCurrentInvoice", {
        in_id: this.$route.params.id,
      });
      //check status

      if (res.status == 200) {
        this.sold_list = res.data.invoice_items;
        //loop through sold_list and add some properties

        this.in_paid = res.data.in_paid;

        this.in_price = res.data.in_price;
        this.in_discount = res.data.in_discount;
        this.in_due = res.data.in_due;
        this.cu_id = res.data.cu_id;
        this.cu_id = {
          id: res.data.cu_id,
          value: res.data.customer.cu_name,
        };
        this.bo_id = res.data.bo_id;
        this.cur_id = res.data.cur_id;
        this.st_id = res.data.st_id;
        this.in_main_currency_price = res.data.in_main_currency_price;
        this.pl_id = res.data.pl_id;
        this.in_note = res.data.in_note;
        this.created_at = res.data.created_at;
        this.in_status = res.data.in_status;
        this.name = res.data.users.name;
      } else {
        //route to 404
        this.$router.push({ name: "NotFound" });
      }
    },
    changeCurrencyType() {
      if (this.cur_id == 1) {
        this.cur_id = 2;
      } else {
        this.cur_id = 1;
      }
    },
    getUnit(item) {
      return item.item.unit.un_name;
    },
    getPriceByUnit(item) {
      return item.si_sale_price;

      /*if (this.cur_id == item.item.cur_id) {
        return item.item.i_package_price;
      } else {
        if (this.cur_id == 1) {
          return item.item.i_package_price * this.in_main_currency_price;
        } else {
          return item.item.i_package_price / this.in_main_currency_price;
        }
      }*/
    },
    getCostPriceByUnit(item) {
      return item.si_cost_price;
    },
    async getBoxs() {
      await this.$store.dispatch("all_box/getBoxes");

      this.bo_id = this.$store.state.all_box.boxes[0].bo_id;
    },
    async getSaleTypes() {
      await this.$store.dispatch("sale_type/getSaleTypes");

      this.st_id = this.$store.state.sale_type.saleTypes[0].st_id;
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    calculateDue(item, num) {
      this.updateInvoice(item, num);
    },
    async addSaleItem() {
      //check form validation
      if (this.$store.state.edit_sale.isBtnLoading) return;

      this.isSubmited = true;
      if (
        this.checkRulesIsError(this.st_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.cu_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.pl_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.in_paid, "add", this.rules).isError ||
        this.checkRulesIsError(this.sumPrice, "add", this.rules).isError ||
        this.checkRulesIsError(this.sumDiscount, "add", this.rules).isError ||
        this.checkRulesIsError(this.getInDue, "add", this.rules).isError
      ) {
        this.$refs.ResultDialog.show({
          message: this.$t("please_fill_all_fields"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }
      //check if in paid > in price
      if (this.in_paid > this.sumPrice) {
        this.$refs.ResultDialog.show({
          message: this.$t("paid_money_more_than_total_price"),

          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }
      //check if in_paid or in_price or in_discount or in_due is not number or < 0
      if (
        isNaN(this.in_paid) ||
        isNaN(this.in_price) ||
        isNaN(this.in_discount) ||
        isNaN(this.in_due) ||
        this.in_paid < 0 ||
        this.in_price < 0 ||
        this.in_discount < 0 ||
        this.in_due < 0
      ) {
        this.$refs.ResultDialog.show({
          message: this.$t("please_enter_valid_number_for_all_fields"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }

      if (this.sold_list.length == 0) {
        this.$refs.ResultDialog.show({
          message: this.$t("no_item_in_invoice"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }
      if (this.getInDue > 0) {
        const ok = await this.$refs.confirmDialogue.show({
          message:
            this.$t("are_you_sure_this_invoice_has_due") +
            " " +
            this.formatNumber(this.getInDue) +
            (this.cur_id == 1 ? this.$t("dinar") : this.$t("dollar")) +
            " ?",
          okButton: this.$t("yes"),
          isIcon: false,
          color:
            "bg-blue-600 hover:bg-blue-700 focus:ring-blue-300 dark:bg-blue-500  dark:hover:bg-blue-600 dark:focus:ring-blue-900", // Color of the confirm button
        });
        if (!ok) {
          this.$store.state.edit_sale.isBtnLoading = false;
          return;
        }
      }
      this.$store.state.edit_sale.isBtnLoading = true;
      this.$store.state.sale_pos.isBtnLoading = true;
      var tempSoldList = [];
      for (const item of this.getSoldListByCurrentCurrency) {
        tempSoldList.push({
          i_id: item.i_id,
          si_quantity: item.si_quantity,
          cur_id: item.cur_id,
          si_sale_price: this.getPriceByUnit(item),
          si_discount: item.si_discount,
          si_cost_price: item.si_cost_price,
        });
      }

      var res = await this.$store.dispatch("edit_sale/editItem", {
        cu_id: this.cu_id.id,
        in_id: this.in_id,
        st_id: this.st_id,
        pl_id: this.pl_id,
        in_paid: this.in_paid,
        in_price: this.sumPrice,
        in_cost: this.sumCost,
        in_discount: this.sumDiscount,
        in_due: this.getInDue,
        in_note: this.in_note,
        sold_items: tempSoldList,
        created_at: this.created_at,
      });
      if (res.status == 200) {
        this.isSubmited = false;
      }
      this.$store.state.edit_sale.isBtnLoading = false;
    },
    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    addRecievedMoney(curency, amount) {
      //check this.cur_id == curency.cur_id
      if (this.cur_id == curency) {
        this.recivedMoney += amount;
      } else {
        if (this.cur_id == 1) {
          //convert to dinar
          this.recivedMoney += amount * this.in_main_currency_price;
        } else {
          //convert to dollar
          this.recivedMoney += amount / this.in_main_currency_price;
        }
      }
    },

    async showModal(item) {
      this.current = item;
      await this.$refs.actionModal.show();
    },
    addItemToInvoice(item) {
      //check if item is already in the list
      if (item.item.i_quantity <= 0) {
        this.$refs.ResultDialog.show({
          message: this.$t("this_item_is_not_available"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }

      for (const soldItem of this.sold_list) {
        if (soldItem.i_id == item.i_id) {
          if (soldItem.si_quantity >= item.item.i_quantity) {
            /* this.$refs.ResultDialog.show({
              message: this.$t("not_enough_item_in_stock"),
              okButton: this.$t("done"),
              isSuccess: false,
            });
            return;*/
          }
          soldItem.si_quantity++;
          break;
        }
      }

      this.sold_list = [...this.sold_list];
    },
    updateInvoice(item, num) {
      if (num > item.item.i_quantity) {
        /*this.$refs.ResultDialog.show({
          message: this.$t("not_enough_item_in_stock"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;*/
      }
      for (const soldItem of this.sold_list) {
        if (soldItem.i_id == item.i_id) {
          soldItem.si_quantity = num;
          break;
        }
      }

      this.sold_list = [...this.sold_list];
    },
    checkHowMuchItemInCard(i_id) {
      for (const item of this.sold_list) {
        if (item.i_id == i_id) {
          return item.si_quantity;
        }
      }
      return 0;
    },
    removeItem(i_id) {
      for (const item of this.sold_list) {
        if (item.i_id == i_id) {
          this.sold_list.splice(this.sold_list.indexOf(item), 1);
          break;
        }
      }
    },

    async removeInvoice() {
      const ok = await this.$refs.confirmDialogue.show({
        message:
          this.$t("are_you_sure_delete_invoice_number") + " " + this.in_id,
        okButton: this.$t("delete"),
      });
      if (ok) {
        this.$store.dispatch("edit_sale/deleteItem", this.in_id);
      }
    },
  },
};
</script>
