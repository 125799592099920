<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl no-print ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("add_waste_items") }}
      </p>
    </div>

    <div
      class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl"
      v-if="
        $store.getters['checkUserAccess']({
          id: '13',
          action: 'add',
        })
      "
    >
      <div class="">
        <form @submit="addSaleItem">
          <div class="grid gap-x-6 mb-6 md:grid-cols-2">
            <div class="mb-5">
              <label
                for="saleType"
                class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
                >{{ $t("wasted_item") }}</label
              >
              <VueMultiselect
                v-model="i_id"
                :options="convertToListIdValueItem"
                :multiple="false"
                :placeholder="$t('choose_item')"
                label="value"
                track-by="id"
                :taggable="true"
                @update:model-value="updateSelected"
                class="bg-slate-100 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-bgFullDark dark:border-gray-600"
              >
              </VueMultiselect>
            </div>

            <DropdownInput
              v-model="cawt_id"
              :label="$t('waste_category')"
              :items="convertToListIdValueWasteCategory"
              :placeholder="$t('choose_waste_category')"
              :rules="rules_dropdown"
              :isError="
                checkRulesIsError(cawt_id, 'add', this.rules_dropdown).isError
              "
              :errorMsg="
                checkRulesIsError(cawt_id, 'add', this.rules_dropdown).errorMsg
              "
              linkDrop="/item-waste-category"
            />
            <div class="mb-5">
              <label
                for="saleType"
                class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
                >{{ $t("purchases") }}</label
              >
              <VueMultiselect
                v-model="pui_id"
                :options="convertToListIdValuePurchases"
                :multiple="false"
                :placeholder="$t('choose_purchase')"
                label="value"
                track-by="id"
                :taggable="true"
                @update:model-value="updateSelected"
                class="bg-slate-100 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-bgFullDark dark:border-gray-600"
              >
              </VueMultiselect>
            </div>

            <BaseInput
              v-model="wt_quantity"
              :label="$t('waste_quantity')"
              :placeholder="$t('waste_quantity')"
              type="number"
              :rules="rules"
              :isError="checkRulesIsError(wt_quantity, 'add').isError"
              :errorMsg="checkRulesIsError(wt_quantity, 'add').errorMsg"
            />
            <TextAreaInput
              v-model="wt_note"
              :label="$t('note')"
              :placeholder="$t('note')"
              :rules="rules"
              :isError="checkRulesIsError(wt_note, 'add').isError"
              :errorMsg="checkRulesIsError(wt_note, 'add').errorMsg"
            />
          </div>

          <ButtonInput
            type="button"
            @click="addSaleItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>
  <div
    class="w-full"
    id="printDev"
    v-if="
      $store.getters['checkUserAccess']({
        id: '13',
        action: 'view',
      })
    "
  >
    <InvoiceHeader invoice_type="wasted_items_list" v-if="isHide == true" />

    <div
      :class="
        ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
        (isHide == true
          ? '  '
          : $store.getters['isRtl']
          ? ' md:mr-60 '
          : ' md:ml-60  ')
      "
    >
      <div class="flex items-center space-x-4 mb-4" v-if="isHide != true">
        <label
          for="selectedHeaders"
          class="text-sm font-semibold"
          @click="columnsControl = !columnsControl"
        >
          <span class="flex">
            {{ $t("columns") }} <i class="fa fa-close my-1 mx-2"></i
          ></span>
        </label>
        <multi-select
          v-model="selectedHeaders"
          :options="headers"
          track-by="value"
          label="text"
          multiple
          placeholder="Select Columns"
          class="custom-multiselect"
          @input="printCall"
          v-if="columnsControl"
        >
        </multi-select>
      </div>
      <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
        <div class="relative overflow-x-auto mt-4 mb-6">
          <div class="flex items-center justify-between pb-4 mx-5 no-print">
            <label for="table-search" class="sr-only">{{ $t("search") }}</label>
            <div class="relative">
              <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
              >
                <svg
                  class="w-5 h-5 text-gray-400 dark:text-gray-500"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <!--in ltr change (pr-10 to pl-10)-->
              <input
                type="text"
                id="table-search"
                class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                :placeholder="$t('search')"
                @input="
                  $store.commit('waste_item/SET_SEARCH', $event.target.value),
                    loadFromServer(),
                    (serverOptions.page = 1)
                "
              />
            </div>
            <button
              class="mx-2 my_font bg-primaryColor hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
              @click="printReportFunc"
              id="button_print"
              v-if="$store.getters['checkPrintAccess']"
            >
              {{ $t("print") }}
            </button>
          </div>
          <!--in ltr change (text-right to text-left)-->
          <EasyDataTable
            v-model:server-options="serverOptions"
            :headers="showHeaders"
            :items="items"
            :server-items-length="serverItemsLength"
            :loading="isLoadingData"
            alternating
            :class="isDark ? 'customize-table ' : 'light-mode-table'"
            :buttons-pagination="true"
            :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
            :hide-footer="isHide"
            :rows-items="[25, 50, 100, serverItemsLength]"
            rows-per-page="10"
          >
            <template #default="{ column, sortDirection }">
              <span class="custom-sort-icon" v-if="column.sortable">
                <i
                  v-if="sortDirection === 'asc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i
                  v-else-if="sortDirection === 'desc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
              </span>
              {{ column.label }}
            </template>
            <template #item-actions="item">
              <button
                class="mx-1 my-1"
                @click="showModal(item)"
                v-if="
                  $store.getters['checkUserAccess']({
                    id: '13',
                    action: 'edit',
                  })
                "
              >
                <i
                  class="fa-solid fa-pen text-center p-1 w-7 h-7 text-sm bg-blue-200 dark:bg-blue-300 rounded-md text-blue-500 mx-auto"
                >
                  {{ item.text }}
                </i>
              </button>
              <button
                class="mx-1 my-1"
                @click="deleteItem(item.wt_id)"
                v-if="
                  $store.getters['checkUserAccess']({
                    id: '13',
                    action: 'delete',
                  })
                "
              >
                <i
                  class="fa-solid fa-trash text-center p-1 w-7 h-7 text-sm bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto"
                ></i>
              </button>
            </template>
            <template #item-wt_sale_price_jwmla="item">
              {{ formatNumber(item.wt_sale_price_jwmla) }}
            </template>
            <template #item-wt_cost_price="item">
              {{ formatNumber(item.wt_cost_price) }}
            </template>
            <template #item-wt_sale_price="item">
              {{ formatNumber(item.wt_sale_price) }}
            </template>
            <template #item-wt_main_currency_price="item">
              {{ formatNumber(item.wt_main_currency_price) }}
            </template>
            <template #item-currency_cur_name="item">
              <span>
                {{ $t(item.currency.cur_name) }}
              </span>
            </template>
            <template #loading>
              <img
                src="../../assets/loading.webp"
                style="width: 70px; height: 70px"
              />
            </template>
            <template #item-created_at="item">
              {{ formatDate(item.created_at) }} </template
            ><template #empty-message>
              <span class="text-textBlack1 dark:text-white">
                {{ $t("no_data_found") }}
              </span>
            </template>

            <template
            #pagination="{
              prevPage,
              nextPage,
              isFirstPage,
              isLastPage,
              currentPaginationNumber,
              maxPaginationNumber,
            }"
          >
            <button :disabled="isFirstPage" @click="goToPage(1)">
             
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-double-right'
                    : ' fa-angle-double-left')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isFirstPage" @click="prevPage">
               <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-right'
                    : ' fa-angle-left ')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button
              v-for="page in getVisiblePages(
                currentPaginationNumber,
                maxPaginationNumber
              )"
              :key="page"
              @click="goToPage(page)"
              :class="getPageButtonClasses(page, currentPaginationNumber)"
            >
              {{ page }}
            </button>

            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-left'
                    : ' fa-angle-right')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button
              :disabled="isLastPage"
              @click="goToPage(maxPaginationNumber)"
            >
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-double-left'
                    : ' fa-angle-double-right')
                "
              ></i
              >
            </button> </template
        ></EasyDataTable>
        </div>
      </div>
    </div>
  </div>

  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <edit-modal ref="editModal">
    <div class="items-center">
      <form @submit="editItem">
        <DropdownInput
          v-model="current.cawt_id"
          :label="$t('waste_category')"
          :items="convertToListIdValueWasteCategory"
          :placeholder="$t('choose_waste_category')"
          :rules="rules_dropdown"
          :isError="
            checkRulesIsError(current.cawt_id, 'edit', this.rules_dropdown)
              .isError
          "
          :errorMsg="
            checkRulesIsError(current.cawt_id, 'edit', this.rules_dropdown)
              .errorMsg
          "
        />

        <TextAreaInput
          v-model="current.wt_note"
          :label="$t('note')"
          :placeholder="$t('note')"
          :rules="rules"
          :isError="checkRulesIsError(current.wt_note, 'edit').isError"
          :errorMsg="checkRulesIsError(current.wt_note, 'edit').errorMsg"
        />
        <center>
          <ButtonInput
            type="button"
            @click="editItem"
            :label="$t('edit')"
            :isLoading="isBtnEditLoading"
          />
        </center>
      </form>
    </div>
  </edit-modal>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import { formatNumber } from "../../utils/functions";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import EditModal from "../../components/Modals/EditModal.vue";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
import VueMultiselect from "vue-multiselect";
import InvoiceHeader from "../../components/Navbar/InvoiceHeader.vue";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ConfirmDialogue,
    ResultDialog,
    EditModal,
    TextAreaInput,
    DropdownInput,
    // eslint-disable-next-line vue/no-unused-components
    VueMultiselect,
    InvoiceHeader,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      wt_quantity: "",
      wt_note: "",
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      cawt_id: "",
      i_id: "",
      pui_id: "",
      serverItemsLength: 0,
      serverOptions: {
        page: 1,
        rowsPerPage: 5,
      },
      loading: false,
      items: [],
      isHide: false,
      selectedHeaders: [],
      columnsControl: false,
      purchases: [],
    };
  },
  mounted() {
    // First load when the component is mounted
    this.loadFromServer();
    this.selectedHeaders = [...this.headers];
  },
  created() {
    this.$store.dispatch("item_waste_category/getWasteCategorys");
    //item_list/getPurchasedItems

    if (
      this.$store.getters["checkUserAccess"]({
        id: "13",
        action: "view",
      })
    ) {
      this.$store.dispatch("item_list/getItemsData");
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.waste_item.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.waste_item.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("waste_item/SET_DELETE_SUCCESS", false);
      }
    },
    serverOptions: {
      handler() {
        this.loadFromServer();
      },
      deep: true,
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.waste_item.isShowResultDialog;
    },

    filterItems() {
      return this.$store.getters["waste_item/filterItems"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.waste_item.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.waste_item.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.waste_item.isBtnEditLoading;
    },
    headers() {
      var listHeader = [
        { text: "#", value: "wt_id", sortable: true },
        { text: this.$t("item_barcode"), value: "item.i_id", sortable: true },
        { text: this.$t("item_name"), value: "item.i_name", sortable: true },
        {
          text: this.$t("purchase_id"),
          value: "pu_id",
          sortable: true,
        },
        {
          text: this.$t("waste_category"),
          value: "category_waste_item.cawt_name",
          sortable: true,
        },
        {
          text: this.$t("cost_item_price"),
          value: "wt_cost_price",
          sortable: true,
        },
        {
          text: this.$t("sale_item_price"),
          value: "wt_sale_price",
          sortable: true,
        },
        {
          text: this.$t("wholesale_item_price"),
          value: "wt_sale_price_jwmla",
          sortable: true,
        },
        {
          text: this.$t("currency"),
          value: "currency_cur_name",
          sortable: true,
        },
        {
          text: this.$t("item_quantity"),
          value: "wt_quantity",
          sortable: true,
        },
        {
          text: this.$t("dolar_price_when_item_purchased"),
          value: "wt_main_currency_price",
          sortable: true,
        },
        { text: this.$t("note"), value: "wt_note", sortable: true },
        { text: this.$t("admin"), value: "users.name", sortable: true },
        { text: this.$t("created_at"), value: "created_at", sortable: true },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ];

      return listHeader;
    },
    showHeaders() {
  
      var temp = [];
      //cretae a for check all selectedHeaders add to temp
      for (let i = 0; i < this.selectedHeaders.length; i++) {
        for (let j = 0; j < this.headers.length; j++) {
          if (this.selectedHeaders[i].text == this.headers[j].text) {
            temp.push(this.headers[j]);
          }
        }
      }

      if (temp.length == 0) {
        temp = this.headers;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedHeaders = temp;
      }

      return temp;
    },
    convertToListIdValueWasteCategory() {
      var wasteCategorys = this.$store.state.item_waste_category.wasteCategorys;
      var list = [];
      for (const item of wasteCategorys) {
        list.push({
          id: item.cawt_id,
          value: item.cawt_name,
        });
      }

      return list;
    },
    convertToListIdValuePurchases() {
      var purchases = this.purchases;
      var list = [];
      for (const item of purchases) {
        list.push({
          id: item.pui_id,
          value:
            this.$t("purchase_id") +
            "  " +
            item.pu_id +
            " - " +
            this.$t("remain") +
            "  " +
            (item.pui_quantity - item.pui_out_quantity) +
            " - " +
            this.$t("cost") +
            "  " +
            this.formatNumber(item.pui_cost_price) +
            " " +
            (item.cur_id == 2 ? "$" : "IQD"),
        });
      }

      return list;
    },
    convertToListIdValueItem() {
      var itemsData = this.$store.state.item_list.itemsData;
      var list = [];
      for (const item of itemsData) {
        list.push({
          id: item.i_id,
          value: item.i_name,
        });
      }

      return list;
    },
  },

  methods: {
    async getPurchases() {
      var res = await this.$store.dispatch("item_list/getPurchasedItems", {
        i_id: this.i_id.id,
      });

      this.purchases = res.data;
    },
    printReportFunc() {
      this.isHide = true;
      //wait 1 second
      setTimeout(() => {
        var printContents = document.getElementById("printDev").innerHTML;

        // Replace the body's innerHTML with the print contents
        document.body.innerHTML = printContents;
        var isRtl = this.$store.getters["isRtl"];
        // Set direction based on the parameter
        if (isRtl) {
          document.body.style.direction = "rtl";
        } else {
          document.body.style.direction = "ltr";
        }

        // Print the content
        window.print();
        //reload page
      }, 100);
      //print element id invoice_print
    },
    getVisiblePages(currentPage, totalPages) {
      const numVisibleButtons = 3; // Adjust this number based on your design preference
      const pages = [];

      // Display pages 1 to numVisibleButtons (e.g., 1, 2, 3)
      if (currentPage <= Math.floor(numVisibleButtons / 2) + 1) {
        for (let i = 1; i <= Math.min(totalPages, numVisibleButtons); i++) {
          pages.push(i);
        }
      }
      // Display pages around the current page (e.g., 1, ..., 10, ..., 20)
      else {
        const startPage = Math.max(
          1,
          currentPage - Math.floor(numVisibleButtons / 2)
        );
        const endPage = Math.min(totalPages, startPage + numVisibleButtons - 1);

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
      }

      return pages;
    },

    // Function to get Tailwind CSS classes for each page button
    getPageButtonClasses(page, currentPage) {
      return {
        "bg-blue-500 text-white": page === currentPage,
        "bg-gray-300 text-gray-700 hover:bg-gray-400": page !== currentPage,
        "p-2 rounded-md mx-1": true, // Adjust padding and rounding based on your design
      };
    },

    goToPage(page) {
      // Implement your logic to update the current page
      //update page
      this.serverOptions.page = page;
      this.loadFromServer();
    },
    async loadFromServer() {
      this.loading = true;

      var tempObj = { ...this.serverOptions };
      //add new field to object search
      tempObj.search = this.$store.state.waste_item.search;

      const res = await this.$store.dispatch("waste_item/getItems", tempObj);

      this.items = res.data.data;
      this.serverItemsLength = res.data.total;
      this.loading = false;
    },
    updateSelected(value) {
      this.purchases = [];
      if (value == null) return;
      if (value.id) {
        this.getPurchases();
      }
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    getData() {
      this.$store.dispatch("waste_item/getItems");
    },
    async addSaleItem() {
      //check form validation
      if (this.$store.state.waste_item.isBtnLoading) return;

      this.isSubmited = true;
      if (
        this.checkRulesIsError(this.wt_quantity, "add", this.rules).isError ||
        this.checkRulesIsError(this.wt_note, "add", this.rules).isError ||
        this.checkRulesIsError(this.i_id.id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.cawt_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.pui_id.id, "add", this.rules_dropdown)
          .isError
      ) {
        return;
      }


      this.$store.state.waste_item.isBtnLoading = true;
      var res = await this.$store.dispatch("waste_item/addNewItem", {
        wt_quantity: this.wt_quantity,
        wt_note: this.wt_note,
        i_id: this.i_id.id,
        cawt_id: this.cawt_id,
        pui_id: this.pui_id.id,
      });
      if (res.status == 200) {
        this.wt_quantity = "";
        this.wt_note = "";
        this.i_id = "";
        this.cawt_id = "";
        this.isSubmited = false;
        this.purchases = [];
        this.pui_id = "";
        this.$store.state.waste_item.isBtnLoading = false;
        this.loadFromServer();
      }
      this.isSubmited = false;
    },
    checkRulesIsError(value, type) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of this.rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    async deleteItem(id) {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$t("are_you_sure_remove_this_item") + id,
        okButton: this.$t("delete"),
      });
      if (ok) {
        this.$store.commit("waste_item/REMOVE_ITEM", id);
        await this.$store.dispatch("waste_item/deleteItem", id);
        this.loadFromServer();
      }
    },
    //edit
    async showModal(item) {
      this.current = item;
      await this.$refs.editModal.show();
    },
    async editItem(e) {
      e.preventDefault();
      this.isSubmitedEdit = true;
      if (
        this.checkRulesIsError(this.current.wt_quantity, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(this.current.wt_note, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(
          this.current.cawt_id,
          "edit",
          this.rules_dropdown
        ).isError
      ) {
        return;
      }
      this.$store.state.waste_item.isBtnEditLoading = true;
      var res = await this.$store.dispatch("waste_item/editItem", {
        wt_id: this.current.wt_id,
        wt_note: this.current.wt_note,
        cawt_id: this.current.cawt_id,
      });

      this.isSubmitedEdit = false;
      this.$store.state.waste_item.isBtnEditLoading = false;
      if (res.status == 200) {
        this.$refs.editModal.hide();
        this.loadFromServer();
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
