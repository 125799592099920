import { post } from "../../helpers/api";
import { showToast as toast } from "../../components/Modals/toast";
import { i18n } from "../../main"; // Adjust the import path accordingly

const state = {
  items: [],
  isLoadingData: true,
  isBtnLoading: false,
  isBtnEditLoading: false,
  search: "",
  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
};

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items;
    state.isLoadingData = false;
  },
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },

  REMOVE_ITEM(state, id) {
    state.items = state.items.filter((item) => {
      return item.no_id !== id;
    });
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  SET_DELETE_SUCCESS(state, value) {
    state.isShowResultDialog = value.state;
    state.resultMsg = value.msg;
    state.isSuccess = value.isSuccess;
  },
  SET_IS_LOADING_DATA(state, isLoadingData) {
    state.isLoadingData = isLoadingData;
  },
};

const actions = {
  async getItems({ commit }, payload) {
    commit("SET_IS_LOADING_DATA", true);
    try {
      return post("note/index?page=" + payload.page, payload, {
        id: 32,
        action: "view",
      })
        .then((response) => {
          commit("SET_IS_BTN_LOADING", false);
          commit("SET_IS_BTN_EDIT_LOADING", false);
          commit("SET_IS_LOADING_DATA", false);

          return response;
        })
        .catch((error) => {
          console.log(error);
          //check if status 408 redirect to 404

          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async addNewItem({ commit }, payload) {
    return post(
      "note/store",
      {
        no_title: payload.no_title,
        no_description: payload.no_description,
      },
      {
        id: 32,
        action: "add",
      }
    )
      .then((response) => {
        this.dispatch("note/getItems");

        const t = i18n.global.t;
        toast("success", t("added_successfully"));
        return response;
      })
      .catch((error) => {
        commit("SET_IS_BTN_LOADING", false);

        const t = i18n.global.t;
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }

        toast("error", t("something_wrong"));
        console.log(error);
        return error;
      });
  },
  async deleteItem({ commit }, id) {
    post("note/delete/" + id, {}, { id: 32, action: "delete" })
      .then((response) => {
        this.dispatch("note/getItems");

        const t = i18n.global.t;
        toast("success", t("deleted_successfully"));
        return response;
      })
      .catch((error) => {
        const t = i18n.global.t;
        this.dispatch("note/getItems");
        commit("SET_IS_BTN_LOADING", false);
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }

        if (
          error.response.data.message.includes(
            "Cannot delete or update a parent row"
          )
        ) {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t(
              "you_can_not_delete_this_item_because_there_are_related_items"
            ),
            isSuccess: false,
          });
        } else {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t("something_wrong"),
            isSuccess: false,
          });
        }
      });
  },
  async editItem({ commit }, payload) {
    return post(
      "note/edit/" + payload.no_id,
      {
        no_title: payload.no_title,
        no_description: payload.no_description,
      },
      {
        id: 32,
        action: "add",
      }
    )
      .then((response) => {
        this.dispatch("note/getItems");

        const t = i18n.global.t;
        toast("success", t("edited_successfully"));
        return response;
      })
      .catch((error) => {
        commit("SET_IS_BTN_EDIT_LOADING", false);

        const t = i18n.global.t;
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }

        toast("error", t("something_wrong"));
        console.log(error);
        return error;
      });
  },
};

const getters = {
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
