import { post } from "../../helpers/api";

const state = {
  items: [],
  isLoadingData: true,
  isBtnLoading: false,
  isBtnEditLoading: false,
  search: "",
  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
};

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items;
    state.isLoadingData = false;
  },
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },

  REMOVE_ITEM(state, id) {
    state.items = state.items.filter((item) => {
      return item.no_id !== id;
    });
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  SET_DELETE_SUCCESS(state, value) {
    state.isShowResultDialog = value.state;
    state.resultMsg = value.msg;
    state.isSuccess = value.isSuccess;
  },
  SET_IS_LOADING_DATA(state, isLoadingData) {
    state.isLoadingData = isLoadingData;
  },
};

const actions = {
  async getItems({ commit }, payload) {
    commit("SET_IS_LOADING_DATA", true);
    try {
      return post("history-log/index?page=" + payload.page, payload, {
        id: 37,
        action: "view",
      })
        .then((response) => {
          commit("SET_IS_BTN_LOADING", false);
          commit("SET_IS_BTN_EDIT_LOADING", false);
          commit("SET_IS_LOADING_DATA", false);

          return response;
        })
        .catch((error) => {
          console.log(error);
          //check if status 408 redirect to 404

          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};

const getters = {
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
