<template>
  <HeaderApp />
  <div
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div
      class="md:flex w-full h-full p-2 bg-white dark:bg-bgMeduimDark rounded-2xl"
    >
      <div class="flex flex-col w-11/12 md:w-7/12 px-4 py-2 justify-between">
        <div class="w-11/12 md:w-4/12">
          <div>
            <label
              for="saleType"
              class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
              >{{ $t("choose_item") }}</label
            >
            <VueMultiselect
              v-model="barcode"
              :options="convertToListIdValueItem"
              :multiple="false"
              @update:model-value="updateSelected"
              :placeholder="$t('choose_item')"
              label="value"
              track-by="id"
              :taggable="true"   class="bg-slate-100 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-bgFullDark dark:border-gray-600"
            
            >
            </VueMultiselect>
            <br />
            <BaseInput
              v-model="barcode.id"
              :label="$t('write_barcode')"
              :placeholder="$t('write_barcode')"
              :rules="rules"
              :isError="false"
              :errorMsg="''"
            />
          </div>

          <div class="mt-8">
            <label
              for="currency"
              class="block text-base font-medium text-textBlack1 dark:text-textWhite1"
              >{{ $t("show_text_on_barcode") }}</label
            >
            <div class="flex mt-2">
              <div class="flex items-center ml-4">
                <input
                  id="inline-radio"
                  type="radio"
                  value="1"
                  checked="true"
                  v-model="isShowName"
                  @change="logValue"
                  name="inline-radio-group"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="inline-radio"
                  class="mr-2 text-base font-medium text-gray-900 dark:text-gray-300"
                  >{{ $t("yes") }}</label
                >
              </div>
              <div class="flex items-center ml-4">
                <input
                  id="inline-2-radio"
                  type="radio"
                  value="0"
                  v-model="isShowName"
                  @change="logValue"
                  name="inline-radio-group"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="inline-2-radio"
                  class="mr-2 text-base font-medium text-gray-900 dark:text-gray-300"
                  >{{ $t("no") }}</label
                >
              </div>
            </div>
          </div>
          <div class="mt-8">
            <label
              for="currency"
              class="block text-base font-medium text-textBlack1 dark:text-textWhite1"
              >{{ $t("show_price_on_barcode") }}</label
            >
            <div class="flex mt-2">
              <div class="flex items-center ml-4">
                <input
                  id="inline-radio22"
                  type="radio"
                  value="1"
                  checked="true"
                  v-model="isShowPrice"
                  @change="logValue"
                  name="inline-radio-group1"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="inline-radio"
                  class="mr-2 text-base font-medium text-gray-900 dark:text-gray-300"
                  >{{ $t("yes") }}</label
                >
              </div>
              <div class="flex items-center ml-4">
                <input
                  id="inline-2-radio22"
                  type="radio"
                  value="0"
                  v-model="isShowPrice"
                  @change="logValue"
                  name="inline-radio-group1"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="inline-2-radio"
                  class="mr-2 text-base font-medium text-gray-900 dark:text-gray-300"
                  >{{ $t("no") }}</label
                >
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="w-11/12 md:w-6/12">
          <ButtonInput
            type="button"
            @click="printBarcode"
            :label="$t('print')"
            :isLoading="isBtnLoading"
          />
        </div>
      </div>
      <div
        class="flex flex-col w-11/12 md:w-1/3 rounded-2xl items-center py-8 justify-end"
      >
        <div class="h-16 md:h-24">
          <div id="barcodeDiv">
            <center>
              <Vue3Barcode
                :value="barcode.id"
                :height="50"
                :display-value="isShowName == 1"
                v-if="barcode.id != ''"
              >
                {{ $t("something_wrong") }}
              </Vue3Barcode>
              <div
                v-if="
                  isShowPrice == 1 && barcode.price != '' && barcode.price != 0
                "
                class="text-lg"
              >
                {{ formatNumber(barcode.price)+(barcode.cur_id==1?$t("dinar"):$t("dollar")) }}
              </div>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import { formatNumber } from "../../utils/functions";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import VueMultiselect from "vue-multiselect";
export default {
  name: "BARCODE_PRINT",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,

    VueMultiselect,
  },
  data() {
    return {
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      i_id: "",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      selected: null,
      barcode: {
        id: "",
        price: "",
      },
      isBtnLoading: false,
      isShowName: 1,
      isShowPrice: 1,
    };
  },
  created() {
    this.$store.dispatch("item_list/getItemsData");
  },

  computed: {
    isDark() {
      return this.$store.state.theme == "dark";
    },
    convertToListIdValueItem() {
      var itemsData = this.$store.state.item_list.itemsData;
      var list = [];

      for (const item of itemsData) {
        list.push({
          id: item.i_id,
          value: item.i_name,
          price: item.i_sale_price,
          cur_id: item.cur_id,
        });
      }

      return list;
    },
  },

  methods: {
    formatNumber(number) {
      return formatNumber(number);
    },
    updateSelected(value) {

      this.barcode = value;
      //check if null
      if (this.barcode == null) {
        this.barcode = {
          id: "",
          price: "",
        };
      }
    },
    onChange(e) {
      this.cu_image = e.target.files[0];
      e.target.value = "";
    },
    logValue() {},

    async printBarcode() {
      this.isBtnLoading = true;
      //print div with id barcodeDiv
      var printContents = document.getElementById("barcodeDiv").innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      //refresh page
      location.reload();

      this.isBtnLoading = false;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
