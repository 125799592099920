<template>
  <div :class="!isDark ? 'loading-overlay' : 'loading-overlay-dark'">
    <!-- Your loading indicator here (e.g., spinner) -->
    <div class="spinner"></div>
  </div>
</template>
<script>
export default {
  name: "App",
  computed: {
    isDark() {
      return localStorage.getItem("theme") == "dark";
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the loading overlay is above other elements */
}
.loading-overlay-dark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 26, 26, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the loading overlay is above other elements */
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
