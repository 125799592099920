<template>
  <HeaderApp /> <LoadingProgress v-if="customer == null" />
  <div
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
    v-if="customer != null"
    id="printDev"
  >
    <InvoiceHeader
      invoice_type="customer_account_detail"
      v-if="isHide == true"
    />
    <div class="flex lg:flex-row flex-col mb-4 justify-between">
      <div class="flex justify-between">
        <p class="font-semibold text-xl text-textBlack dark:text-textWhite">
          {{ $t("customer_account_detail") }}
        </p>
        <p
          class="font-semibold text-xl text-textBlack dark:text-textWhite"
          v-if="isHide == true"
        >
          {{ startDate }} - {{ endDate }}
        </p>
      </div>

      <div class="flex lg:flex-row flex-col no-print">
        <div class="md:mx-2 my-1 md:my-0">
          <label
            for="debtDate"
            class="block mb-2 text-sm font-medium text-textBlack1 dark:text-textWhite1"
            >{{ $t("start_date") }}
          </label>
          <input
            type="date"
            id="debtDate"
            class="bg-white border text-sm border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
            v-model="startDate"
            @change="getData"
          />
        </div>

        <div class="md:mx-2 my-1 md:my-0">
          <label
            for="debtDate"
            class="block mb-2 text-sm font-medium text-textBlack1 dark:text-textWhite1"
            >{{ $t("end_date") }}</label
          >
          <input
            type="date"
            id="debtDate"
            class="bg-white border text-sm border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
            v-model="endDate"
            @change="getData"
          />
        </div>

        <div class="md:mx-2 my-1 md:my-0">
          <label
            for="boxType"
            class="block mb-2 text-sm text-textBlack1 dark:text-textWhite1"
          >
            {{ $t("choose_period") }}
          </label>
          <select
            id="countries"
            class="bg-white border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block xl:w-40 lg:w-36 w-4/6 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="period"
            @change="periodChanged(period)"
          >
            <option value="today" class="my_font">{{ $t("today") }}</option>
            <option value="yesterday" class="my_font">
              {{ $t("yesterday") }}
            </option>
            <option value="last7days" class="my_font">
              {{ $t("last7days") }}
            </option>
            <option value="last30days" class="my_font">
              {{ $t("last30days") }}
            </option>
            <option value="thismonth" class="my_font">
              {{ $t("thismonth") }}
            </option>
            <option value="lastmonth" class="my_font">
              {{ $t("lastmonth") }}
            </option>
            <option value="lastyear" class="my_font">
              {{ $t("lastyear") }}
            </option>
            <option value="thisyear" class="my_font">
              {{ $t("thisyear") }}
            </option>
            <option value="all" class="my_font">{{ $t("all_time") }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="md:flex mx-1 mb-6">
      <div class="w-44 h-44 rounded-xl">
        <img
          src="../../assets/image/no_person_image.png"
          alt=""
          class="w-full h-full rounded-xl"
          v-if="customer.customer.cu_image == null"
        />
        <img
          :src="file_url + customer.customer.cu_image"
          alt=""
          class="w-full h-full rounded-xl"
          v-else
        />
      </div>

      <div class="flex flex-col mt-2 md:mt-0 justify-evenly mx-5">
        <div class="flex">
          <p class="text-lg text-textBlack1 dark:text-textWhite1">
            {{ $t("customer_id") }} :
          </p>
          <p class="text-lg text-textBlack1 dark:text-textWhite1 font-semibold">
            {{ customer.customer.cu_id }}
          </p>
        </div>
        <div class="flex">
          <p class="text-lg text-textBlack1 dark:text-textWhite1">
            {{ $t("customer_name") }} :
          </p>
          <p class="text-lg text-textBlack1 dark:text-textWhite1 font-semibold">
            {{ customer.customer.cu_name }}
          </p>
        </div>
        <div class="flex">
          <p class="text-lg text-textBlack1 dark:text-textWhite1">
            {{ $t("phone_number") }} :
          </p>
          <p class="text-lg text-textBlack1 dark:text-textWhite1 font-semibold">
            {{ customer.customer.cu_phone }}
          </p>
        </div>
        <div class="flex">
          <p class="text-lg text-textBlack1 dark:text-textWhite1">
            {{ $t("gmail") }} :
          </p>
          <p class="text-lg text-textBlack1 dark:text-textWhite1 font-semibold">
            {{ customer.customer.cu_email }}
          </p>
        </div>
        <div class="flex">
          <p class="text-lg text-textBlack1 dark:text-textWhite1">
            {{ $t("city") }} :
          </p>
          <p class="text-lg text-textBlack1 dark:text-textWhite1 font-semibold">
            {{ customer.customer.city.ci_name }}
          </p>
        </div>
        <div class="flex">
          <p class="text-lg text-textBlack1 dark:text-textWhite1">
            {{ $t("address") }} :
          </p>
          <p class="text-lg text-textBlack1 dark:text-textWhite1 font-semibold">
            {{ customer.customer.cu_address }}
          </p>
        </div>
        <div class="flex">
          <p class="text-lg text-textBlack1 dark:text-textWhite1">
            {{ $t("note") }} :
          </p>
          <p class="text-lg text-textBlack1 dark:text-textWhite1 font-semibold">
            {{ customer.customer.cu_note }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-4 mb-6 gap-x-4 gap-y-8"
    >
      <ReportComponentButton
        :labelDolar="formatNumber(customer.result_debt.dollar)"
        :labelDinar="formatNumber(customer.result_debt.dinar)"
        :labelTitle="$t('remain_debt')"
        startColor=" bg-yellow-600 "
        classColor=" from-yellow-400 "
        buttonColor=" bg-yellow-950 "
        toColor=" to-yellow-400 "
      />
      <ReportComponentButton
        :labelDolar="formatNumber(customer.debt_grouped.borrow.dollar)"
        :labelDinar="formatNumber(customer.debt_grouped.borrow.dinar)"
        :labelTitle="$t('borrow')"
        startColor=" bg-green-900 "
        classColor=" from-green-400 "
        buttonColor=" bg-green-950 "
        toColor=" to-green-400 "
      />
      <ReportComponentButton
        :labelDolar="formatNumber(customer.debt_grouped.purchase.dollar)"
        :labelDinar="formatNumber(customer.debt_grouped.purchase.dinar)"
        :labelTitle="$t('purchase_due')"
        startColor=" bg-green-900 "
        classColor=" from-green-400 "
        buttonColor=" bg-green-950 "
        toColor=" to-green-400 "
      />
      <ReportComponentButton
        :labelDolar="formatNumber(customer.debt_grouped.lend.dollar)"
        :labelDinar="formatNumber(customer.debt_grouped.lend.dinar)"
        :labelTitle="$t('lend')"
        startColor=" bg-red-900 "
        classColor=" from-red-400 "
        buttonColor=" bg-red-950 "
        toColor=" to-red-400 "
      />
      <ReportComponentButton
        :labelDolar="formatNumber(customer.debt_grouped.invoice.dollar)"
        :labelDinar="formatNumber(customer.debt_grouped.invoice.dinar)"
        :labelTitle="$t('sale_due')"
        startColor=" bg-red-900 "
        classColor=" from-red-400 "
        buttonColor=" bg-red-950 "
        toColor=" to-red-400 "
      />

      <ReportComponentButton
        :labelDolar="formatNumber(customer.expense_grouped.dollar)"
        :labelDinar="formatNumber(customer.expense_grouped.dinar)"
        :labelTitle="$t('expense_amount')"
        startColor="bg-red-900"
        classColor=" from-red-400 "
        buttonColor=" bg-red-950 "
        toColor=" to-red-400 "
      />
      <ReportComponentButton
        :labelDolar="formatNumber(income_grouped_dollar)"
        :labelDinar="formatNumber(income_grouped_dinar)"
        :labelTitle="$t('income_amount')"
        startColor=" bg-blue-900 "
        classColor=" from-blue-400 "
        buttonColor=" bg-blue-950 "
        toColor=" to-blue-400 "
      />
      <ReportComponenet1NumberButton
        :labelDolar="formatNumber(customer.invoice_count)"
        :labelTitle="$t('invoice_count')"
        startColor=" bg-pink-900 "
        classColor=" from-pink-400 "
        buttonColor=" bg-pink-950 "
        toColor=" to-pink-400 "
      />

      <ReportComponentButton
        :labelDolar="formatNumber(customer.invoice_grouped_price.dollar)"
        :labelDinar="formatNumber(customer.invoice_grouped_price.dinar)"
        :labelTitle="$t('total_buys_price')"
        startColor=" bg-purple-900 "
        classColor=" from-purple-400 "
        buttonColor=" bg-purple-950 "
        toColor=" to-purple-400 "
      />
      <ReportComponentButton
        :labelDolar="formatNumber(customer.invoice_grouped_profit.dollar)"
        :labelDinar="formatNumber(customer.invoice_grouped_profit.dinar)"
        :labelTitle="$t('total_buys_profit')"
        startColor=" bg-green-900 "
        classColor=" from-green-400 "
        buttonColor=" bg-green-950 "
        toColor=" to-green-400 "
      />

      <ReportComponenet1NumberButton
        :labelDolar="formatNumber(customer.returned_invoice)"
        :labelTitle="$t('returned_buy_invoice_count')"
        startColor=" bg-gray-600 "
        classColor=" from-gray-400 "
        buttonColor=" bg-gray-950 "
        toColor=" to-gray-400 "
      />
      <ReportComponentButton
        :labelDolar="formatNumber(profitFromThisCustomerDollar())"
        :labelDinar="formatNumber(profitFromThisCustomerDinar())"
        :labelTitle="$t('total_profit_from_this_customer')"
        startColor=" bg-green-900 "
        classColor=" from-green-400 "
        buttonColor=" bg-green-950 "
        toColor=" to-green-400 "
      />
    </div>
    <br />
    <div class="no-print">
      <ButtonInput
        type="button"
        @click="showModalAdd"
        :label="$t('new_debt')"
        :isLoading="isBtnLoading"
      />
    </div>
    <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="relative overflow-x-auto mt-4 mb-6">
        <div class="flex items-center justify-between pb-4 mx-5 no-print">
          <label for="table-search" class="sr-only">{{ $t("search") }}</label>
          <div class="relative">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <input
              type="text"
              id="table-search"
              class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :placeholder="$t('search')"
              @input="
                $store.commit(
                  'customer_account/SET_SEARCH',
                  $event.target.value
                )
              "
            />
          </div>
          <button
            class="mx-2 my_font bg-primaryColor hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
            @click="printReportFunc"
            id="button_print"
            v-if="$store.getters['checkPrintAccess']"
          >
            {{ $t("print") }}
          </button>
        </div>
        <!--in ltr change (text-right to text-left)-->
        <EasyDataTable
          :headers="headers"
          :items="filterItems"
          :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
          alternating
          :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
          :loading="isLoadingData"
          :class="isDark ? 'customize-table ' : 'light-mode-table'"
          :hide-footer="isHide"
          :rows-items="[25, 50, 100, filterItems.length]"
          rows-per-page="10"
        >
          <template #default="{ column, sortDirection }">
            <span class="custom-sort-icon" v-if="column.sortable">
              <i
                v-if="sortDirection === 'asc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i
                v-else-if="sortDirection === 'desc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
            </span>
            {{ column.label }}
          </template>

          <template #item-currency_cur_name="item">
            {{ $t(item.cur_name) }}
          </template>
          <template #item-deb_amount="item">
            <span
              :class="
                item.deb_type == $t('sale_due')
                  ? 'text-yellow-500 font-bold'
                  : ''
              "
              v-show="item.deb_type == $t('sale_due')"
            >
              {{ formatNumber(item.deb_amount) }}
            </span>
            <span
              :class="
                item.deb_type == $t('purchase_due')
                  ? 'text-yellow-500 font-bold'
                  : ''
              "
              v-show="item.deb_type == $t('purchase_due')"
            >
              {{ formatNumber(item.deb_amount) }}
            </span>
            <span
              :class="
                item.deb_type == $t('borrow') ? 'text-green-500 font-bold' : ''
              "
              v-show="item.deb_type == $t('borrow')"
            >
              {{ formatNumber(item.deb_amount) }}
            </span>
            <span
              :class="
                item.deb_type == $t('lend') ? 'text-red-500 font-bold' : ''
              "
              v-show="item.deb_type == $t('lend')"
            >
              {{ formatNumber(item.deb_amount) }}
            </span>
          </template>
          <template #item-deb_type="item">
            <span
              :class="
                item.deb_type == $t('sale_due')
                  ? 'text-yellow-500 font-bold'
                  : ''
              "
              v-show="item.deb_type == $t('sale_due')"
            >
              {{ $t("sale_due") }}
            </span>
            <span
              :class="
                item.deb_type == $t('purchase_due')
                  ? 'text-yellow-500 font-bold'
                  : ''
              "
              v-show="item.deb_type == $t('purchase_due')"
            >
              {{ $t("purchase_due") }}
            </span>
            <span
              :class="
                item.deb_type == $t('borrow') ? 'text-green-500 font-bold' : ''
              "
              v-show="item.deb_type == $t('borrow')"
            >
              {{ $t("borrow") }}
            </span>
            <span
              :class="
                item.deb_type == $t('lend') ? 'text-red-500 font-bold' : ''
              "
              v-show="item.deb_type == $t('lend')"
            >
              {{ $t("lend") }}
            </span>
          </template>
          <template #item-total_usd="item">
            <span
              :class="
                item.total_usd > 0
                  ? 'bg-red-500 font-bold text-white px-2 rounded-sm'
                  : 'bg-green-500 font-bold text-white px-2 rounded-sm'
              "
            >
              {{ formatNumber(item.total_usd) }}
            </span>
          </template>
          <template #item-total_iqd="item">
            <span
              :class="
                item.total_iqd > 0
                  ? 'bg-red-500 font-bold text-white px-2 rounded-sm'
                  : 'bg-green-500 font-bold text-white px-2 rounded-sm'
              "
            >
              {{ formatNumber(item.total_iqd) }}
            </span>
          </template>
          <template #item-deb_image="item">
            <img
              :src="file_url + item.deb_image"
              class="inline-flex items-center justify-center text-white transition-all duration-200 ease-in-out text-size-sm h-16 w-16 rounded-xl"
              v-if="item.deb_image"
              @click="openModal(file_url + item.deb_image)"
            />
            <span v-if="item.deb_image == '' || item.deb_image == null">
              <i class="fa-solid fa-image text-primaryColor"></i>
            </span>
          </template>
          <template #item-actions="item">
            <button
              class="mx-1"
              @click="showModal(item)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '21',
                  action: 'edit',
                })
              "
            >
              <i
                class="fa-solid fa-pen text-center p-1 w-7 h-7 text-sm bg-blue-200 dark:bg-blue-300 rounded-md text-blue-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </button>
            <button
              class="mx-1"
              @click="deleteItem(item.deb_id)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '21',
                  action: 'delete',
                })
              "
            >
              <i
                class="fa-solid fa-trash text-center p-1 w-7 h-7 text-sm bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto"
              ></i>
            </button>
            <a
              class="mx-1"
              target="_blank"
              :href="'../debt-detail/' + item.deb_id"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '21',
                  action: 'detail',
                })
              "
            >
              <i
                class="m-1 fa-solid fa-eye text-center p-1 w-7 h-7 text-sm bg-green-200 dark:bg-blue-300 rounded-md text-green-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </a></template
          >
          <template #loading>
            <img
              src="../../assets/loading.webp"
              style="width: 70px; height: 70px"
            />
          </template>
          <template #item-created_at="item">
            {{ formatDate(item.created_at) }} </template
          ><template #empty-message>
            <span class="text-textBlack1 dark:text-white">
              {{ $t("no_data_found") }}
            </span>
          </template>
          <template
            #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
          >
            <button :disabled="isFirstPage" @click="prevPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-right'
                    : ' fa-angle-left ')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-left'
                    : ' fa-angle-right')
                "
              ></i>
            </button>
          </template>
        </EasyDataTable>
      </div>
    </div>
  </div>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <edit-modal ref="addModal">
    <div class="items-center">
      <form @submit="addItem">
        <BaseInput
          v-model="deb_amount"
          :label="$t('debt_amount')"
          :placeholder="$t('debt_amount')"
          :rules="rules"
          type="number"
          :isError="checkRulesIsError(deb_amount, 'add', this.rules).isError"
          :errorMsg="checkRulesIsError(deb_amount, 'add', this.rules).errorMsg"
        /><DropdownInput
          v-model="cur_id"
          :label="$t('currency_type')"
          :items="convertToListIdValue"
          :placeholder="$t('currency_type')"
          :rules="rules_dropdown"
          :isError="
            checkRulesIsError(cur_id, 'add', this.rules_dropdown).isError
          "
          :errorMsg="
            checkRulesIsError(cur_id, 'add', this.rules_dropdown).errorMsg
          "
        /><DropdownInput
          v-model="deb_type"
          :label="$t('debt_type')"
          :placeholder="$t('debt_type')"
          :rules="rules_dropdown"
          :items="debtTypes"
          :isError="
            checkRulesIsError(deb_type, 'add', this.rules_dropdown).isError
          "
          :errorMsg="
            checkRulesIsError(deb_type, 'add', this.rules_dropdown).errorMsg
          "
        /><DropdownInput
          v-model="bo_id"
          :label="$t('box_debt')"
          :placeholder="$t('choose_box_if_debt_is_in_or_out_from_box')"
          :rules="no_rule"
          :items="convertToListIdValueBoxes"
          :isError="checkRulesIsError(bo_id, 'add', this.no_rule).isError"
          :errorMsg="checkRulesIsError(bo_id, 'add', this.no_rule).errorMsg"
          linkDrop="/all-box"
        />

        <BaseInput
          v-model="deb_wasl"
          :label="$t('debt_invoice_number')"
          :placeholder="$t('debt_invoice_number')"
          :rules="no_rule"
          :isError="checkRulesIsError(deb_wasl, 'edit', this.no_rule).isError"
          :errorMsg="checkRulesIsError(deb_wasl, 'edit', this.no_rule).errorMsg"
        />
        <BaseInput
          v-model="created_at"
          type="date"
          :label="$t('debt_date')"
          :placeholder="$t('debt_date')"
          :rules="no_rule"
          :isError="checkRulesIsError(created_at, 'edit', this.no_rule).isError"
          :errorMsg="
            checkRulesIsError(created_at, 'edit', this.no_rule).errorMsg
          "
        />
        <BaseInput
          v-model="deb_return_date"
          type="date"
          :label="$t('debt_return_date')"
          :placeholder="$t('debt_return_date')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(deb_return_date, 'edit', this.no_rule).isError
          "
          :errorMsg="
            checkRulesIsError(deb_return_date, 'edit', this.no_rule).errorMsg
          "
        />
        <TextAreaInput
          v-model="deb_note"
          :label="$t('note')"
          :placeholder="$t('note')"
          :rules="no_rule"
          :isError="checkRulesIsError(deb_note, 'edit', this.no_rule).isError"
          :errorMsg="checkRulesIsError(deb_note, 'edit', this.no_rule).errorMsg"
        />
        <div>
          <label
            for="image"
            class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
          >
            {{ $t("debt_photo") }}</label
          >
          <label
            for="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          >
            <i class="fa fa-cloud-upload mx-1"></i>
            {{ deb_image == "" ? $t("choose_debt_photo") : deb_image.name }}
          </label>
          <input
            type="file"
            v-on:change="onChange"
            :placeholder="$t('choose_debt_photo')"
            id="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          />
        </div>
        <br />
        <center>
          <ButtonInput
            type="button"
            @click="addItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </center>
      </form>
    </div>
  </edit-modal>
  <edit-modal ref="editModal">
    <div class="items-center">
      <form @submit="editItem">
        <BaseInput
          v-model="current.deb_amount"
          :label="$t('debt_amount')"
          :placeholder="$t('debt_amount')"
          :rules="rules"
          type="number"
          :isError="
            checkRulesIsError(current.deb_amount, 'edit', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.deb_amount, 'edit', this.rules).errorMsg
          "
        />

        <BaseInput
          v-model="current.deb_wasl"
          :label="$t('debt_invoice_number')"
          :placeholder="$t('debt_invoice_number')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.deb_wasl, 'edit', this.no_rule).isError
          "
          :errorMsg="
            checkRulesIsError(current.deb_wasl, 'edit', this.no_rule).errorMsg
          "
        />
        <BaseInput
          v-model="current.created_at"
          type="date"
          :label="$t('debt_date')"
          :placeholder="$t('debt_date')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.created_at, 'edit', this.no_rule).isError
          "
          :errorMsg="
            checkRulesIsError(current.created_at, 'edit', this.no_rule).errorMsg
          "
        />
        <BaseInput
          v-model="current.deb_return_date"
          type="date"
          :label="$t('debt_return_date')"
          :placeholder="$t('debt_return_date')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.deb_return_date, 'edit', this.no_rule)
              .isError
          "
          :errorMsg="
            checkRulesIsError(current.deb_return_date, 'edit', this.no_rule)
              .errorMsg
          "
        />
        <TextAreaInput
          v-model="current.deb_note"
          :label="$t('note')"
          :placeholder="$t('note')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.deb_note, 'edit', this.no_rule).isError
          "
          :errorMsg="
            checkRulesIsError(current.deb_note, 'edit', this.no_rule).errorMsg
          "
        />
        <div>
          <label
            for="image"
            class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
          >
            {{ $t("debt_photo") }}</label
          >
          <label
            for="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          >
            <i class="fa fa-cloud-upload mx-1"></i>
            {{ deb_image == "" ? $t("choose_debt_photo") : deb_image.name }}
          </label>
          <input
            type="file"
            v-on:change="onChange"
            :placeholder="$t('choose_debt_photo')"
            id="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          />
        </div>
        <br />
        <center>
          <ButtonInput
            type="button"
            @click="editItem"
            :label="$t('edit')"
            :isLoading="isBtnEditLoading"
          />
        </center>
      </form>
    </div>
  </edit-modal>
  <image-modal ref="imageModal" />
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import InvoiceHeader from "../../components/Navbar/InvoiceHeader.vue";
import LoadingProgress from "../../components/loading-progress.vue";
import { formatDate } from "../../utils/functions";
import { formatNumber } from "../../utils/functions";
import moment from "moment";
import { FILE_URL } from "../../constants/config";
import ReportComponentButton from "../../components/Report/ReportComponentButton.vue";
import ReportComponenet1NumberButton from "../../components/Report/ReportComponenet1NumberButton.vue";
import ImageModal from "../../components/Modals/ImageModal2.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import EditModal from "../../components/Modals/EditModal.vue";
import { CURRENCYS } from "../../constants/config";

import DropdownInput from "../../components/Inputs/DropdownInput.vue";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    LoadingProgress,
    ReportComponentButton,
    ReportComponenet1NumberButton,
    InvoiceHeader,
    ImageModal,
    BaseInput,
    TextAreaInput,
    ButtonInput,
    ResultDialog,

    DropdownInput,
    ConfirmDialogue,
    EditModal,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_rule: [],
      cu_name: "",
      cu_note: "",
      cu_phone: "",
      cu_address: "",
      cu_email: "",
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      ci_id: "",

      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      file_url: FILE_URL,
      cu_image: "",
      startDate: "",
      endDate: "",
      period: "all",
      customer: null,
      isHide: false,
      cur_id: "",
      su_id: "",
      bo_id: "",
      cu_id: "",
      deb_note: "",
      deb_wasl: "",
      deb_amount: "",
      created_at: "",
      deb_return_date: "",
      deb_type: "",
      deb_image: "",
      income_grouped_dinar: 0,
      income_grouped_dollar: 0,

      debtTypes: [
        {
          id: "1",
          value: this.$t("lend"),
        },
        {
          id: "0",
          value: this.$t("borrow"),
        },
      ],
      currencys: CURRENCYS,
    };
  },
  created() {
    this.$store.dispatch("all_box/getBoxes");

    this.$store.dispatch("debt_category/getExpenseCategory");
    this.$store.dispatch("customer/getCustomers");
    this.$store.dispatch("suplier/getSupliers");
    this.$store.dispatch("navbar/getItems");
    this.periodChanged("all");
    this.getData();
    this.$store.dispatch("sale_city/getCitys");
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.customer_account.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.customer_account.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("customer_account/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.customer_account.isShowResultDialog;
    },
    items() {
      return this.$store.state.customer_account.items;
    },
    filterItems() {
      return this.$store.getters["customer_account/filterItems"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.customer_account.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.customer_account.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.customer_account.isBtnEditLoading;
    },
    headers() {
      var headerList = [
        { text: "#", value: "deb_id", sortable: true },
        {
          text: this.$t("debt_invoice_number"),
          value: "deb_wasl",
          sortable: true,
        },
        { text: this.$t("photo"), value: "deb_image", sortable: false },

        { text: this.$t("debt_amount"), value: "deb_amount", sortable: true },
        {
          text: this.$t("currency_type"),
          value: "currency_cur_name",
          sortable: true,
        },
        {
          text: this.$t("dolar_price"),
          value: "deb_main_currency_price",
          sortable: true,
        },
        {
          text: this.$t("debt_type"),
          value: "deb_type",
          sortable: true,
        },
        { text: this.$t("box"), value: "bo_name", sortable: true },
        {
          text: this.$t("debt_result_dollar"),
          value: "total_usd",
          sortable: true,
        },
        {
          text: this.$t("debt_result_dinar"),
          value: "total_iqd",
          sortable: true,
        },
        { text: this.$t("invoice_id"), value: "in_id", sortable: true },
        { text: this.$t("note"), value: "deb_note", sortable: true },
        {
          text: this.$t("debt_return_date"),
          value: "deb_return_date",
          sortable: true,
        },

        { text: this.$t("created_at"), value: "created_at", sortable: true },
        { text: this.$t("admin"), value: "name", sortable: true },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ];
      if (this.isHide) {
        headerList.pop();
        headerList.splice(2, 1);
      }
      return headerList;
    },
    convertToListIdValue() {
      var list = [];
      for (const item of this.currencys) {
        list.push({
          id: item.cur_id,
          value: this.$t(item.cur_name),
        });
      }

      return list;
    },
    convertToListIdValueBoxes() {
      var boxes = this.$store.state.all_box.boxes;
      var list = [];
      for (const item of boxes) {
        list.push({
          id: item.bo_id,
          value: item.bo_name,
        });
      }

      return list;
    },
    convertToListIdValueCustomers() {
      var customers = this.$store.state.customer.customers;
      var list = [];
      for (const item of customers) {
        list.push({
          id: item.cu_id,
          value: item.cu_name,
        });
      }

      return list;
    },
    convertToListIdValueSupliers() {
      var supliers = this.$store.state.suplier.supliers;
      var list = [];
      for (const item of supliers) {
        list.push({
          id: item.su_id,
          value: item.su_name,
        });
      }

      return list;
    },
  },

  methods: {
    async deleteItem(id) {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$t("are_you_sure_remove_this_item") + id,
        okButton: this.$t("delete"),
      });
      if (ok) {
        this.$store.commit("debt_list/REMOVE_ITEM", id);
        await this.$store.dispatch("debt_list/deleteItem", id);
        this.getData();
      }
    },
    async showModal(item) {
      this.current = item;
      //if not null
      this.current.created_at = this.current.created_at
        .toString()
        .split("T")[0];
      if (this.current.su_id != null) {
        //check if su_id number
        if (typeof this.current.su_id == "number") {
          this.current.su_id = {
            id: item.su_id,
            value: item.suplier != null ? item.suplier.su_name : "",
          };
        } else {
          this.current.su_id = {
            id: item.su_id.id,
            value: item.suplier != null ? item.suplier.su_name : "",
          };
        }
      } else {
        this.current.su_id = {
          id: "",
          value: "",
        };
      }
      if (this.current.cu_id != null) {
        //check if cu_id number
        if (typeof this.current.cu_id == "number") {
          this.current.cu_id = {
            id: item.cu_id,
            value: item.customer != null ? item.customer.cu_name : "",
          };
        } else {
          this.current.cu_id = {
            id: item.cu_id.id,
            value: item.customer != null ? item.customer.cu_name : "",
          };
        }
      } else {
        this.current.cu_id = {
          id: "",
          value: "",
        };
      }
      await this.$refs.editModal.show();
    },
    printReportFunc() {
      this.isHide = true;
      //wait 1 second
      setTimeout(() => {
        var printContents = document.getElementById("printDev").innerHTML;

        // Replace the body's innerHTML with the print contents
        document.body.innerHTML = printContents;
        var isRtl = this.$store.getters["isRtl"];
        // Set direction based on the parameter
        if (isRtl) {
          document.body.style.direction = "rtl";
        } else {
          document.body.style.direction = "ltr";
        }

        // Print the content
        window.print();
        //reload page
      }, 100);
      //print element id invoice_print
    },
    onChange(e) {
      this.deb_image = e.target.files[0];
      e.target.value = "";
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    groupIncomesByCurId() {
      //get all income where cu_id = $customer_id use income Model this.customer.income
      var incomes = this.customer.income;
      var result = {};
      for (var i = 0; i < incomes.length; i++) {
        var item = incomes[i];
        if (!result[item.cur_id]) {
          result[item.cur_id] = [];
        }
        result[item.cur_id].push(item);
      }
      //sum each array group inc_amount
      for (var key in result) {
        var total = 0;
        for (var ii = 0; ii < result[key].length; ii++) {
          total += result[key][ii].inc_amount;
        }
        result[key] = total;
      }
      //check if has key 1 and 2 if not set it to 0
      if (!result[1]) {
        result[1] = 0;
      }
      if (!result[2]) {
        result[2] = 0;
      }
      this.income_grouped_dinar = result[1];
      this.income_grouped_dollar = result[2];
      console.log("grouped income");
      console.log(result);
      console.log(this.customer.income_grouped);
      return result;
    },
    profitFromThisCustomerDinar() {
      var total = 0;
      //check if  this.customer.invoice_grouped_profit.dinar is null set it to 0
      if (this.customer.invoice_grouped_profit.dinar == null) {
        this.customer.invoice_grouped_profit.dinar = 0;
      }
      if (this.customer.expense_grouped.dinar == null) {
        this.customer.expense_grouped.dinar = 0;
      }
      if (this.customer.income_grouped.dinar != null) {
        this.customer.income_grouped.dinar = 0;
      }
      if (this.customer.invoice_grouped_profit.length == 0) {
        //make it object and add dinar and dollar
        this.customer.invoice_grouped_profit = {
          dinar: 0,
          dollar: 0,
        };
      }
      if (this.customer.expense_grouped.length == 0) {
        //make it object and add dinar and dollar
        this.customer.expense_grouped = {
          dinar: 0,
          dollar: 0,
        };
      }
      if (this.customer.income_grouped.length == 0) {
        //make it object and add dinar and dollar
        this.customer.income_grouped = {
          dinar: 0,
          dollar: 0,
        };
      }

      total =
        this.customer.invoice_grouped_profit.dinar +
        this.income_grouped_dinar -
        this.customer.expense_grouped.dinar;
      return total;
    },
    //profitFromThisCustomerDollar
    profitFromThisCustomerDollar() {
      var total = 0;
      //check if  this.customer.invoice_grouped_profit.dollar is null set it to 0
      if (this.customer.invoice_grouped_profit.dollar == null) {
        this.customer.invoice_grouped_profit.dollar = 0;
      }
      if (this.customer.expense_grouped.dollar == null) {
        this.customer.expense_grouped.dollar = 0;
      }
      if (this.customer.income_grouped.dollar != null) {
        this.customer.income_grouped.dollar = 0;
      }
      if (this.customer.invoice_grouped_profit.length == 0) {
        //make it object and add dinar and dollar
        this.customer.invoice_grouped_profit = {
          dinar: 0,
          dollar: 0,
        };
      }
      if (this.customer.expense_grouped.length == 0) {
        //make it object and add dinar and dollar
        this.customer.expense_grouped = {
          dinar: 0,
          dollar: 0,
        };
      }
      if (this.customer.income_grouped.length == 0) {
        //make it object and add dinar and dollar
        this.customer.income_grouped = {
          dinar: 0,
          dollar: 0,
        };
      }

      total =
        this.customer.invoice_grouped_profit.dollar +
        this.income_grouped_dollar -
        this.customer.expense_grouped.dollar;
      return total;
    },
    formatDate(date) {
      return formatDate(date);
    },
    openModal(image) {
      this.$refs.imageModal.show(image);
    },
    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    periodChanged(action) {
      this.period = action;

      if (action == "today") {
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "yesterday") {
        this.startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (action == "last7days") {
        this.startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "last30days") {
        this.startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "thismonth") {
        this.startDate = moment().startOf("month").format("YYYY-MM-DD");
        this.endDate = moment().endOf("month").format("YYYY-MM-DD");
      } else if (action == "lastmonth") {
        this.startDate = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.endDate = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (action == "lastyear") {
        this.startDate = moment()
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.endDate = moment()
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
      } else if (action == "thisyear") {
        this.startDate = moment().startOf("year").format("YYYY-MM-DD");
        this.endDate = moment().endOf("year").format("YYYY-MM-DD");
      }
      //all
      else if (action == "all") {
        this.startDate = "2000-01-01";
        this.endDate = moment().endOf("year").format("YYYY-MM-DD");
      }

      this.getData();
    },
    checkIsEmpty(value) {
      if (value == "" || value == null) return true;
      return false;
    },
    async editItem(e) {
      e.preventDefault();
      this.isSubmitedEdit = true;

      if (
        this.checkRulesIsError(this.current.deb_amount, "edit", this.rules)
          .isError
      ) {
        return;
      }

      this.$store.state.customer_account.isBtnEditLoading = true;
      var res = await this.$store.dispatch("debt_list/editItem", {
        deb_id: this.current.deb_id,
        cu_id: this.current.cu_id.id,
        deb_note: this.current.deb_note,
        deb_wasl: this.current.deb_wasl,
        deb_amount: this.current.deb_amount,
        created_at: this.current.created_at,
        deb_return_date: this.current.deb_return_date,

        deb_image: this.deb_image,
      });

      this.isSubmitedEdit = false;
      this.$store.state.customer_account.isBtnEditLoading = false;
      this.deb_image = "";
      if (res.status == 200) {
        this.$refs.editModal.hide();
        this.getData();
      }
    },
    async showModalAdd(item) {
      this.current = item;

      await this.$refs.addModal.show();
    },
    async addItem() {
      //check form validation
      if (this.$store.state.customer_account.isBtnLoading) return;

      this.isSubmited = true;
      if (
        this.checkRulesIsError(this.cur_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.deb_type, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.deb_amount, "add", this.rules).isError
      ) {
        return;
      }
      this.cu_id = {
        id: this.$route.params.id,
      };

      this.$store.state.customer_account.isBtnLoading = true;
      var res = await this.$store.dispatch("add_debt/addNewItem", {
        cur_id: this.cur_id,
        deb_type: this.deb_type,
        bo_id: this.bo_id,
        cu_id: this.cu_id.id,
        deb_note: this.deb_note,
        deb_wasl: this.deb_wasl,
        deb_amount: this.deb_amount,
        deb_main_currency_price: this.$store.state.navbar.dp_price,
        created_at: this.created_at,
        deb_return_date: this.deb_return_date,
        su_id: this.su_id.id,
        deb_image: this.deb_image,
      });
      if (res.status == 200) {
        this.deb_amount = "";
        this.deb_note = "";
        this.deb_wasl = "";
        this.cur_id = "";
        this.deb_type = "";
        this.bo_id = "";
        this.cu_id = "";
        this.su_id = "";
        this.created_at = "";
        this.deb_return_date = "";
        this.$store.state.customer_account.isBtnLoading = false;
        this.isSubmited = false;
        //close modal

        this.getData();
        this.$refs.addModal.hide();
      }
      this.isSubmited = false;
    },
    async getData() {
      var res = await this.$store.dispatch("customer_account/getItems", {
        cu_id: this.$route.params.id,
        startDate: this.startDate,
        endDate: this.endDate,
      });
      //check res status

      if (res.status == 200) {
        this.customer = res.data;
      } else if (res.response.status == 404) {
        //back to customer page
        window.open("/customer", "_self");
      }
      this.groupIncomesByCurId();
    },
  },
};
</script>
<style>
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px none;

  font-size: 14px;
}
</style>
