<template>
  <HeaderApp />
  <div
  :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="h-full w-full bg-gray-50 flex items-center rounded-2xl">
      <div
        class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700"
      >
        <div class="w-full lg:w-1/2 mx-8">
          <div
            class="text-6xl bg-gradient-to-bl from-blue-900 to-blue-400 bg-clip-text text-transparent font-dark font-bold mb-8"
          >
            {{ $t("404_page_not_found") }}
          </div>
          <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
            {{ $t("404_page_not_found_description") }}
          </p>

          <a
            href="/"
            class="px-5 inline py-3 text-base font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-primaryColor active:bg-red-600 hover:bg-blue-800"
          >
            {{ $t("go_to_home_page") }}
          </a>
        </div>
        <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
          <img
            src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg"
            class=""
            alt="Page not found"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";

export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
  },
};
</script>
