<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("edit_role") }}
      </p>
    </div>

    <div class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <form action="">
        <div class="grid gap-6 mb-6 md:grid-cols-2">
          <BaseInput
            v-model="ro_name"
            :label="$t('role_name')"
            :placeholder="$t('role_name')"
            :rules="rules"
            :isError="checkRulesIsError(ro_name, 'add').isError"
            :errorMsg="checkRulesIsError(ro_name, 'add').errorMsg"
          />
        </div>
        <div>
          <label for="print">{{ $t("print") }}</label>

          <input class="mx-20" type="checkbox" name="" id="" v-model="print" />
        </div>
        <br />
        <div>
          <label>{{ $t("tutorial") }}</label>

          <input
            class="mx-20"
            type="checkbox"
            name=""
            id=""
            v-model="tutorial"
          />
        </div>
        <br />

        <div class="flex justify-end">
          <label class="-translate-y-1">{{ $t("all") }}</label>

          <input
            class="mx-5"
            type="checkbox"
            name=""
            id=""
            @change="selectDeselectAll"
          />
        </div>
        <br />
        <!--in ltr change (text-right to text-left)-->
        <div class="overflow-x-auto">
          <table class="w-full text-right text-gray-500 dark:text-gray-400">
            <tr>
              <th scope="col" class="w-1/6 sm:w-1/4 px-1 sm:px-6 py-3">
                {{ $t("pages") }}
              </th>
              <th
                scope="col"
                class="w-1/6 sm:w-1/4 px-1 sm:px-4 py-3 text-center"
              >
                {{ $t("view")
                }}<input
                  type="checkbox"
                  class="mx-2"
                  name=""
                  id=""
                  @change="selectAllView"
                />
              </th>
              <th
                scope="col"
                class="w-1/6 sm:w-1/4 px-1 sm:px-4 py-3 text-center"
              >
                {{ $t("add") }}
                <input
                  type="checkbox"
                  class="mx-2"
                  name=""
                  id=""
                  @change="selectAllAdd"
                />
              </th>
              <th
                scope="col"
                class="w-1/6 sm:w-1/4 px-1 sm:px-4 py-3 text-center"
              >
                {{ $t("edite") }}
                <input
                  type="checkbox"
                  class="mx-2"
                  name=""
                  id=""
                  @change="selectAllEdit"
                />
              </th>
              <th
                scope="col"
                class="w-1/6 sm:w-1/4 px-1 sm:px-4 py-3 text-center"
              >
                {{ $t("delete") }}
                <input
                  type="checkbox"
                  class="mx-2"
                  name=""
                  id=""
                  @change="selectAllDelete"
                />
              </th>
              <th
                scope="col"
                class="w-1/6 sm:w-1/4 px-1 sm:px-4 py-3 text-center"
              >
                {{ $t("detail") }}
                <input
                  type="checkbox"
                  class="mx-2"
                  name=""
                  id=""
                  @change="selectAllDetail"
                />
              </th>
            </tr>
            <tbody class="text-base">
              <tr
                class="bg-white text-textBlack border-b dark:bg-bgLightDark dark:text-textWhite2 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-bgFullDark"
                v-for="role in roles"
                :key="role.id"
              >
                <td class="w-1/6 sm:w-1/4 px-1 sm:px-6 py-3">
                  {{ $t(role.name) }}
                </td>
                <td class="w-1/6 sm:w-1/4 px-1 sm:px-4 py-3 text-center">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="role.view"
                    v-if="role.actions.view"
                  />
                </td>
                <td class="w-1/6 sm:w-1/4 px-1 sm:px-4 py-3 text-center">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="role.add"
                    v-if="role.actions.add"
                  />
                </td>
                <td class="w-1/6 sm:w-1/4 px-1 sm:px-4 py-3 text-center">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="role.edit"
                    v-if="role.actions.edit"
                  />
                </td>
                <td class="w-1/6 sm:w-1/4 px-1 sm:px-4 py-3 text-center">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="role.delete"
                    v-if="role.actions.delete"
                  />
                </td>
                <td class="w-1/6 sm:w-1/4 px-1 sm:px-4 py-3 text-center">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="role.detail"
                    v-if="role.actions.detail"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <ButtonInput
          type="button"
          @click="editItem"
          :label="$t('edit')"
          :isLoading="isBtnLoading"
          v-if="
            $store.getters['checkUserAccess']({
              id: '27',
              action: 'edit',
            })
          "
        />
      </form>
    </div>
  </div>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";

import ButtonInput from "../../components/Inputs/ButtonInput.vue";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
  },
  //data
  data() {
    return {
      isSubmited: false,
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      ro_name: "",
      roles: [],
      roles_new: [
        {
          id: 1,
          name: "dashboard",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: false,
            edit: false,
            delete: false,
            detail: false,
          },
        },
        {
          id: 2,
          name: "item_units",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //item_categorys
        {
          id: 3,
          name: "item_categorys",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //waste_items_categorys
        {
          id: 4,
          name: "waste_items_categorys",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //sale_types
        {
          id: 5,
          name: "sale_types",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //sale_platforms
        {
          id: 6,
          name: "sale_platforms",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //sale_citys
        {
          id: 7,
          name: "sale_citys",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //add_new_item
        {
          id: 8,
          name: "items",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: true,
          },
        },
        //add_item_from_csv
        {
          id: 9,
          name: "add_item_from_csv",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: false,
            edit: false,
            delete: false,
            detail: false,
          },
        },

        {
          id: 11,
          name: "shortage_items",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: false,
            edit: false,
            delete: false,
            detail: false,
          },
        },
        //expire_items
        {
          id: 12,
          name: "expire_items",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: false,
            edit: false,
            delete: false,
            detail: false,
          },
        },
        //waste_items
        {
          id: 13,
          name: "waste_items",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //boxes
        {
          id: 14,
          name: "boxes",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: true,
          },
        },
        //exchange_inside_boxes
        {
          id: 15,
          name: "exchange_inside_boxes",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //transfer_between_boxes
        {
          id: 16,
          name: "transfer_between_boxes",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //purchases
        {
          id: 17,
          name: "purchases",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: true,
          },
        },
        //return_item_to_suplier
        {
          id: 18,
          name: "return_item_to_suplier",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //sales
        {
          id: 19,
          name: "sales",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: true,
          },
        },
        //return_invoices
        {
          id: 20,
          name: "return_invoices",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //debts
        {
          id: 21,
          name: "debts",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: true,
          },
        },
        //expense_categorys
        {
          id: 22,
          name: "expense_categorys",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //expenses
        {
          id: 23,
          name: "expenses",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: true,
          },
        },
        //income_categorys
        {
          id: 24,
          name: "income_categorys",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //incomes
        {
          id: 25,
          name: "incomes",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: true,
          },
        },
        //reports
        {
          id: 26,
          name: "reports",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: false,
            edit: false,
            delete: false,
            detail: false,
          },
        },
        //roles
        {
          id: 27,
          name: "roles",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: true,
          },
        },
        //employees
        {
          id: 28,
          name: "employees",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //customers
        {
          id: 29,
          name: "customers",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: true,
          },
        },
        //supliers
        {
          id: 30,
          name: "supliers",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: true,
          },
        },
        //dolar_price
        {
          id: 31,
          name: "dolar_price",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: false,
            delete: true,
            detail: false,
          },
        },
        //notes
        {
          id: 32,
          name: "notes",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            detail: false,
          },
        },
        //barcode
        {
          id: 33,
          name: "barcode",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: false,
            add: true,
            edit: false,
            delete: false,
            detail: false,
          },
        },
        //change_password
        {
          id: 34,
          name: "change_password",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: false,
            add: false,
            edit: true,
            delete: false,
            detail: false,
          },
        },
        //settings
        {
          id: 35,
          name: "settings",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: false,
            add: false,
            edit: true,
            delete: false,
            detail: false,
          },
        },
        //faq
        {
          id: 36,
          name: "faq",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: false,
            edit: false,
            delete: false,
            detail: false,
          },
        },
        {
          id: 37,
          name: "activity_history",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: true,
            add: false,
            edit: false,
            delete: false,
            detail: false,
          },
        },
        {
          id: 38,
          name: "backup",
          view: false,
          add: false,
          edit: false,
          delete: false,
          detail: false,
          actions: {
            view: false,
            add: true,
            edit: false,
            delete: false,
            detail: false,
          },
        },
      ],
      print: false,
      tutorial: false,
    };
  },
  //created
  created() {
    this.showItem();
  },
  //computed
  computed: {
    isBtnLoading() {
      return this.$store.state.role_list.isBtnLoading;
    },
    //isLoadingDataEdit
    isLoadingDataEdit() {
      return this.$store.state.role_list.isLoadingDataEdit;
    },
  },
  //methods
  methods: {
    checkRulesIsError(value, type) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of this.rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    //selectDeselectAll
    selectDeselectAll(e) {
      try {
        for (let index = 0; index < this.roles.length; index++) {
          this.roles[index].view = e.target.checked;
          this.roles[index].add = e.target.checked;
          this.roles[index].edit = e.target.checked;
          this.roles[index].delete = e.target.checked;
          this.roles[index].detail = e.target.checked;
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectAllAdd(e) {
      try {
        for (let index = 0; index < this.roles.length; index++) {
          if (this.roles[index].actions.add) {
            this.roles[index].add = e.target.checked;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectAllView(e) {
      try {
        for (let index = 0; index < this.roles.length; index++) {
          if (this.roles[index].actions.view) {
            this.roles[index].view = e.target.checked;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectAllEdit(e) {
      try {
        for (let index = 0; index < this.roles.length; index++) {
          if (this.roles[index].actions.edit) {
            this.roles[index].edit = e.target.checked;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectAllDelete(e) {
      try {
        for (let index = 0; index < this.roles.length; index++) {
          if (this.roles[index].actions.delete) {
            this.roles[index].delete = e.target.checked;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectAllDetail(e) {
      try {
        for (let index = 0; index < this.roles.length; index++) {
          if (this.roles[index].actions.detail) {
            this.roles[index].detail = e.target.checked;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async editItem() {
      //check form validation
      if (this.$store.state.role_list.isBtnLoading) return;

      this.isSubmited = true;
      if (this.checkRulesIsError(this.ro_name, "add").isError) {
        return;
      }
      this.$store.state.role_list.isBtnLoading = true;
      var res = await this.$store.dispatch("role_list/editItem", {
        ro_id: this.$route.params.id,
        ro_name: this.ro_name,
        ro_access: {
          print: this.print,
          tutorial: this.tutorial,
          roles: this.roles,
        },
      });
      if (res.status == 200) {
        this.isSubmited = false;
      }
    },
    //showItem
    async showItem() {
      var res = await this.$store.dispatch("role_list/showItem", {
        id: this.$route.params.id,
      });

      if (res.status == 200) {
        this.ro_name = res.data.ro_name;
        this.print = res.data.ro_access.print;
        this.tutorial = res.data.ro_access.tutorial;
        this.roles = res.data.ro_access.roles;
        //loop inside roles_new check if role exist in roles if not add it
        for (let index = 0; index < this.roles_new.length; index++) {
          var role = this.roles_new[index];
          var role_exist = false;
          for (let index2 = 0; index2 < this.roles.length; index2++) {
            var role2 = this.roles[index2];
            if (role.id == role2.id) {
              role_exist = true;
              break;
            }
          }
          if (role_exist == false) {
            this.roles.push(role);
          }
        }
      }
    },
  },
};
</script>
