<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <nav
    class="fixed top-0 h-14 z-50 w-full bg-white border-gray-200 dark:bg-bgLightDark"
    v-show="!isLoadingData"
  >
    <!--in ltr change lg:pr-3 to lg:pl-3-->
    <div class="px-3 py-1 lg:px-5 lg:pr-3">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-start">
          <div class="flex flex-col my-2">
            <a
              href="/return-purchases"
              class="flex text-xl dark:text-textWhite"
              id="darkModeToggle"
            >
              <!-- <img src="/assets/image/kcStore.png" alt="" class="w-16 lg:w-20 mx-3"> -->
              <i class="fa-solid fa-chevron-right my-auto mx-2"></i>
              {{ $t("app_name") }}
            </a>
          </div>
          <div class="w-10"></div>
          <div class="dark:text-white">
            {{ $t("dolar_price") }} : {{ formatNumber(getDolarPrice) }}
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div
    class="mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl"
    v-show="!isLoadingData"
  >
    <div class="flex flex-wrap mb-4 justify-between">
      <p
        class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3 my-2 lg:my-0"
      >
        {{ $t("edit_return_purchase_to_suplier") }}
      </p>
      <p
        class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3 my-2 lg:my-0"
      >
        {{ $t("invoice_id") }} #{{ rep_id }}
      </p>
      <p
        class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3 my-2 lg:my-0"
      >
        {{ $t("admin") }} {{ name }}
      </p>
      <p
        class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3 my-2 lg:my-0"
      >
        {{ $t("date") }} : {{ formatDate(created_at) }}
      </p>
      <div class="mx-3 my-2 lg:my-0">
        <label
          for="boxType"
          class="block mb-1 text-sm text-textBlack1 dark:text-textWhite1"
          >{{ $t("currency_invoice_type") }}</label
        >
        <button
          id="boxType"
          class="bg-white border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-32 p-1.5 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <i
            class="fa-solid fa-arrows-rotate text-primaryColor mx-1 text-sm"
          ></i>
          <span class="mx-1" v-show="cur_id == 1">{{ $t("dinar") }}</span>
          <span class="mx-1" v-show="cur_id == 2">{{ $t("dollar") }}</span>
        </button>
      </div>
    </div>
    <div class="md:flex">
      <div class="w-full md:w-5/12 flex flex-col py-3 mx-auto md:mx-0">
        <div
          class="flex bg-white dark:bg-bgMeduimDark rounded-2xl p-3 w-full overflow-x-auto space-x-2 whitespace-nowrap mb-4"
        >
          <div class="relative w-full">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <input
              type="text"
              id="table-search"
              class="w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :placeholder="$t('search_item')"
              @input="
                $store.commit(
                  'edit_return_purchase/SET_SEARCH_POS',
                  $event.target.value
                )
              "
            />
          </div>

          <div class="w-6"></div>

          <div class="relative w-full">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <i
                class="fa-solid fa-barcode w-5 h-5 text-gray-400 dark:text-gray-500"
              ></i>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <form @submit="enterBarcode">
              <input
                type="text"
                id="table-search"
                class="w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                :placeholder="$t('barcode')"
                v-model="barcode"
              />
            </form>
          </div>
        </div>
        <h2
          id="accordion-color-heading-2"
          :class="
            isShowItemsCategorys ? 'border-primaryColor text-blue-600' : ''
          "
        >
          <button
            type="button"
            class="flex bg-slate-100 dark:bg-bgFullDark items-center justify-between w-full px-4 py-2 font-medium text-right text-textBlack1 dark:text-textWhite1 border-l-0 border-r-0 border border-gray-300 dark:border-gray-600 hover:border-primaryColor"
            @click="isShowItemsCategorys = !isShowItemsCategorys"
          >
            <span>{{ $t("items") + " & " + $t("items_categorys") }} </span>
            <svg
              data-accordion-icon
              class="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5 5 1 1 5"
              />
            </svg>
          </button>
        </h2>
        <div
          class="flex flex-wrap gap-2 bg-white dark:bg-bgMeduimDark rounded-2xl p-3 w-full overflow-x-auto space-x-2 whitespace-nowrap mb-4"
          v-if="isShowItemsCategorys"
        >
          <button
            :class="
              'px-3 py-1 text-sm bg-white dark:bg-bgMeduimDark text-textBlack2 dark:text-textWhite3 rounded-xl border ' +
              (this.$store.state.edit_return_purchase.ca_id == ''
                ? 'border-primaryColor dark:border-primaryColor'
                : 'border-gray-300 dark:border-gray-600')
            "
            @click="this.$store.state.edit_return_purchase.ca_id = ''"
          >
            {{ $t("all_items") }}
          </button>
          <button
            :class="
              'px-3 py-1 text-sm bg-white dark:bg-bgMeduimDark text-textBlack2 dark:text-textWhite3 rounded-xl border ' +
              (this.$store.state.edit_return_purchase.ca_id == category.ca_id
                ? 'border-primaryColor dark:border-primaryColor'
                : 'border-gray-300 dark:border-gray-600')
            "
            v-for="(category, index) in this.$store.state.item_category
              .categorys"
            :key="index"
            @click="
              this.$store.state.edit_return_purchase.ca_id = category.ca_id
            "
          >
            {{ category.ca_name }}
          </button>
        </div>
        <div
          class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 pb-10"
          v-if="isShowItemsCategorys"
        >
          <!-- item 1-->
          <div
            :class="
              'flex flex-col p-2 bg-white dark:bg-bgMeduimDark rounded-2xl items-center shadow-sm p-2 cursor-pointer ' +
              (checkHowMuchItemInCard(item.i_id) != 0
                ? 'border border-primaryColor dark:border-primaryColor'
                : 'border-gray-300 dark:border-gray-600')
            "
            v-for="(item, index) in filterItemsPos"
            :key="index"
          >
            <button
              type="button"
              @click="removeItem(item.i_id)"
              class="text-left mb-1"
              v-if="checkHowMuchItemInCard(item.i_id) != 0"
            >
              <i
                class="fa-solid fa-trash text-center p-1 w-6 h-6 text-xs bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto my-auto"
              ></i>
            </button>
            <img
              :src="file_url + item.i_image"
              alt=""
              class="mb-1 rounded-xl h-24 cursor-pointer"
              @click="addItemToReturnPurchase(item)"
              v-if="item.i_image != '' && item.i_image != null"
            />

            <img
              src="../../assets/image/image_not_found.png"
              alt=""
              class="mb-1 rounded-xl h-24 cursor-pointer"
              @click="addItemToReturnPurchase(item)"
              v-if="item.i_image == '' || item.i_image == null"
            />
            <p
              class="text-textBlack1 dark:text-textWhite1 text-sm text-center cursor-pointer"
              @click="addItemToReturnPurchase(item)"
            >
              {{ item.i_name }}
            </p>

            <p
              class="text-textBlack2 dark:text-textWhite2 cursor-pointer"
              @click="addItemToReturnPurchase(item)"
            >
              {{ $t("last_suplier") }} :
              {{ item.suplier != null ? item.suplier.su_name : "" }}
            </p>
            <span
              v-show="checkHowMuchItemInCard(item.i_id) != 0"
              class="bg-green-500 px-3 py-2 text-white rounded-full text-xs mt-2"
              @click="addItemToReturnPurchase(item)"
            >
              {{ checkHowMuchItemInCard(item.i_id) }}
            </span>
          </div>
        </div>
        <center>
          <span
            v-show="filterItemsPos.length == 0 && isLoadingData == false"
            class="text-textBlack1 dark:text-white"
          >
            {{ $t("you_didnt_buy_any_item_from_this_suplier_before") }}
          </span>
        </center>
        <center>
          <img
            src="../../assets/loading.webp"
            style="width: 70px; height: 70px"
            v-show="isLoadingData"
          />
        </center>
      </div>

      <div class="w-4"></div>

      <div
        class="w-full md:w-7/12 my-3 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl mb-20 md:mb-14 mx-auto md:mx-0"
      >
        <form action="">
          <!--sale extra information-->

          <div id="accordion-color">
            <h2
              id="accordion-color-heading-1"
              :class="isShowDetail ? 'border-primaryColor text-blue-600' : ''"
            >
              <button
                type="button"
                class="flex bg-slate-100 dark:bg-bgFullDark items-center justify-between w-full px-4 py-2 font-medium text-right text-textBlack1 dark:text-textWhite1 border-l-0 border-r-0 border border-gray-300 dark:border-gray-600 hover:border-primaryColor"
                @click="isShowDetail = !isShowDetail"
              >
                {{ $t("detail") }}
                <svg
                  data-accordion-icon
                  class="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>

            <!-- extra sale information-->
            <div
              id="accordion-color-body-1"
              aria-labelledby="accordion-color-heading-1"
              v-show="isShowDetail"
            >
              <div class="p-5 grid grid-cols-2 gap-x-3">
                <DropdownInput
                  v-model="bo_id"
                  :label="$t('box')"
                  :placeholder="$t('choose_box_that_money_get_out')"
                  :rules="rules_dropdown"
                  :items="convertToListIdValueBoxes"
                  :isDisabled="true"
                  :isError="
                    checkRulesIsError(bo_id, 'add', this.rules_dropdown).isError
                  "
                  :errorMsg="
                    checkRulesIsError(bo_id, 'add', this.rules_dropdown)
                      .errorMsg
                  "
                />

                <div class="mb-6">
                  <div class="flex">
                    <div class="w-full">
                      <label
                        for="saleType"
                        class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
                        >{{ $t("suplier") }}</label
                      >
                      <VueMultiselect
                        v-model="su_id"
                        :options="convertToListIdValueSupliers"
                        :multiple="false"
                        :placeholder="$t('choose_suplier_that_sell_to_you')"
                        label="value"
                        track-by="id"
                        :taggable="true"
                        class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-bgFullDark dark:border-gray-600"
                        @Select="handleSelectChange"
                      >
                      </VueMultiselect>
                    </div>
                    <div class="w-2"></div>
                    <a
                      class="w-2/12 lg:w-1/12 text-white text-2xl my-8 bg-primaryColor p-1 rounded-lg hover:shadow-md text-center"
                      target="_blank"
                      href="/suplier"
                    >
                      +
                    </a>
                  </div>
                </div>
                <BaseInput
                  v-model="created_at"
                  type="date"
                  :label="$t('return_item_to_suplier_date')"
                  :placeholder="$t('return_item_to_suplier_date')"
                  :rules="no_rule"
                  :isError="
                    checkRulesIsError(created_at, 'add', this.no_rule).isError
                  "
                  :errorMsg="
                    checkRulesIsError(created_at, 'add', this.no_rule).errorMsg
                  "
                />
                <BaseInput
                  v-model="rep_wasl_company"
                  :label="$t('return_item_to_suplier_invoice_number')"
                  :placeholder="$t('return_item_to_suplier_invoice_number')"
                  :rules="no_rule"
                  :isError="
                    checkRulesIsError(rep_wasl_company, 'add', this.no_rule)
                      .isError
                  "
                  :errorMsg="
                    checkRulesIsError(rep_wasl_company, 'add', this.no_rule)
                      .errorMsg
                  "
                />
                <TextAreaInput
                  v-model="rep_note"
                  :label="$t('note')"
                  :placeholder="$t('note')"
                  :rules="no_rule"
                  :isError="
                    checkRulesIsError(rep_note, 'add', this.no_rule).isError
                  "
                  :errorMsg="
                    checkRulesIsError(rep_note, 'add', this.no_rule).errorMsg
                  "
                />
              </div>
            </div>
          </div>

          <!--table-->
          <div class="relative overflow-x-auto mt-10 mb-6 overflow-y-auto">
            <!--in ltr change (text-right to text-left)-->
            <table class="w-full text-right text-gray-500 dark:text-gray-400">
              <thead
                class="text-xs text-gray-600 border-l-0 border-r-0 border border-gray-300 dark:border-gray-600 uppercase bg-slate-100 dark:bg-bgFullDark dark:text-gray-400"
              >
                <tr>
                  <th scope="col" class="px-2 py-1">{{ $t("item_name") }}</th>
                  <th scope="col" class="px-2 py-1">{{ $t("barcode") }}</th>
                  <th scope="col" class="px-2 py-1">{{ $t("purchase") }}</th>
                  <th scope="col" class="px-2 py-1">{{ $t("quantity") }}</th>
                  <th scope="col" class="px-2 py-1">{{ $t("cost") }}</th>

                  <th scope="col" class="px-2 py-1">{{ $t("final_price") }}</th>
                  <th scope="col" class="px-2 py-1">{{ $t("actions") }}</th>
                </tr>
              </thead>
              <tbody class="text-sm">
                <tr
                  class="bg-white text-textBlack border-b dark:bg-bgLightDark dark:text-textWhite2 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-bgFullDark"
                  v-for="(item, index) in returned_purchase_items"
                  :key="index"
                >
                  <td class="px-2 py-2">
                    {{ getItemNameById(item) }}
                  </td>
                  <td class="px-2 py-2">
                    {{ item.i_id }}
                  </td>
                  <td class="px-2 py-2">
                    <select
                      :value="item.pu_id"
                      :placeholder="$t('choose_purchase_that_item_bought_from')"
                      class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      @change="setPUID(item, $event.target.value)"
                    >
                      <option
                        v-for="(item, index) in item.purchase_items"
                        :key="index"
                        :value="item.pui_id"
                      >
                        {{
                          $t("purchase_id") +
                          " : " +
                          item.pu_id +
                          " - " +
                          item.pui_cost_price +
                          " " +
                          (item.cur_id == 1 ? $t("dinar") : $t("dollar")) +
                          " - " +
                          $t("remain") +
                          " " +
                          (item.pui_quantity - item.pui_out_quantity)
                        }}
                      </option>
                    </select>
                  </td>
                  <td class="px-2 py-2">
                    <input
                      type="number"
                      class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-14 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                      v-model="item.repi_quantity"
                      min="0"
                      @input="calculateDue(item, item.repi_quantity)"
                    />
                  </td>
                  <td class="px-2 py-2">
                    {{ item.repi_cost_price }}
                  </td>

                  <td class="px-2 py-2">
                    {{ formatNumber(item.repi_quantity * item.repi_cost_price)
                    }}<span class="mx-1" v-show="cur_id == 1">{{
                      $t("dinar")
                    }}</span>
                    <span class="mx-1" v-show="cur_id == 2">{{
                      $t("dollar")
                    }}</span>
                  </td>
                  <td class="px-2 py-2 flex mt-2">
                    <button type="button" @click="removeItem(item.i_id)">
                      <i
                        class="fa-solid fa-trash text-center p-1 w-6 h-6 text-xs bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto my-auto"
                      ></i>
                    </button>
                  </td>
                </tr>
                <tr
                  class="bg-white text-textBlack border-b dark:bg-bgLightDark dark:text-textWhite2 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-bgFullDark"
                  v-show="returned_purchase_items.length != 0"
                >
                  <td class="px-2 py-2"></td>
                  <td class="px-2 py-2"></td>
                  <td class="px-2 py-2"></td>

                  <td class="px-2 py-2"></td>
                  <td class="px-2 py-2"></td>
                  <td class="px-2 py-2">
                    {{ $t("total_price") }} : {{ formatNumber(sumCost)
                    }}<span class="mx-1" v-show="cur_id == 1">{{
                      $t("dinar")
                    }}</span>
                    <span class="mx-1" v-show="cur_id == 2">{{
                      $t("dollar")
                    }}</span>
                  </td>
                  <td class="px-2 py-2 flex mt-2"></td>
                </tr>
              </tbody>
            </table>
            <center>
              <span
                class="text-center text-textBlack1 dark:text-white"
                v-show="returned_purchase_items.length == 0"
                >{{ $t("no_item_in_invoice") }}</span
              >
            </center>
          </div>
        </form>
      </div>
    </div>

    <div
      class="text-textBlack1 dark:text-white flex justify-between items-center px-4 md:px-16 py-3 left-0 right-0 fixed bottom-0 bg-white dark:bg-bgMeduimDark rounded-tl-xl z-50"
    >
      <div class="md:flex items-baseline">
        <p class="text-sm md:text-base">
          {{ $t("total_items_price") }} :
          <span class="text-xl md:text-2xl font-bold"
            >{{ formatNumber(sumCost)
            }}<span class="mx-1" v-show="cur_id == 1">{{ $t("dinar") }}</span>
            <span class="mx-1" v-show="cur_id == 2">{{
              $t("dollar")
            }}</span></span
          >
        </p>
        <div class="flex mx-0 md:mx-6 items-center mx-3 my-2 lg:my-0">
          <span
            class="text-sm md:text-base font-medium text-textBlack1 dark:text-textWhite1 mx-1"
            >{{ $t("money_amount_received") }}:</span
          >
          <input
            type="number"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 w-20 md:w-40 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            v-model="rep_paid"
            min="0"
          />
        </div>

        <p class="text-sm md:text-base mx-2 text-textBlack1 dark:text-white">
          {{ $t("debt") }}:
          <span class="text-xl md:text-2xl font-bold">{{
            formatNumber(sumCost - rep_paid)
          }}</span>
        </p>
      </div>

      <div class="lg:flex">
        <button
          data-modal-target="defaultModal"
          data-modal-toggle="defaultModal"
          class="text-white  text-lg md:text-xl bg-green-500 w-32 lg:w-40  my-2 py-2 md:px-8 rounded items-center hover:shadow-md"
          @click="modalPrintID"
        >
          <i class="fa-solid fa-print"></i>
        </button>
        <div class="w-2 md:w-4"></div>

        <div class="w-2 md:w-4"></div>
        <button
          class="text-white text-lg md:text-xl bg-green-500 w-32 lg:w-40 my-2 py-2 rounded items-center hover:shadow-md"
          @click="editItem"
          v-if="
            $store.getters['checkUserAccess']({
              id: '18',
              action: 'edit',
            })
          "
        >
          <div v-if="!isBtnLoading">{{ $t("edit") }}</div>

          <div
            class="flex items-center hover:shadow-mdtext-white flex justify-center items-center hover:shadow-md"
            role="status"
            v-else
          >
            <svg
              width="20"
              height="20"
              fill="currentColor"
              class="mr-2 animate-spin"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
              ></path>
            </svg>
            <span class="mx-1">{{ $t("wait") }} </span>
          </div>
        </button>
      </div>
    </div>
  </div>
  <LoadingProgress v-show="isLoadingData" />
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <div :class="classesPrintModal()">
    <!-- modal -->
    <div class="my_font bg-white rounded shadow-lg w-10/12 md:w-1/3">
      <!-- modal body -->
      <div
        class="p-3 bg-white my_font font-bold text-size-base text-black"
        id="invoice_print"
      >
        <div>
          <h6 class="text-center text-black font-bold text-size-lg">
            {{ settings.app_name }}
          </h6>
          <p class="my_font text-center font-bold text-size-base text-black">
            {{ settings.set_header_return_purchase }}
          </p>

          <p class="my_font text-center font-bold text-size-base text-black">
            <i
              class="fas fa-phone text-size-base relative mx-2 text-pink text-center"
            ></i>
            {{ settings.set_phone_number }}
          </p>
        </div>
        <div class="w-full max-w-full shrink-0 md:w-full md:flex-0 bg-white">
          <div class="flex-auto pb-2 my_font">
            <div class="overflow-x-auto">
              <table
                class="items-center w-full mb-0 align-top border-gray-200 text-black-500"
              >
                <tbody>
                  <tr v-if="rep_id != ''">
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("return_purchase_id") }}
                    </td>
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ rep_id }}
                    </td>
                  </tr>
                  <tr v-if="su_id != ''">
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("suplier_name") }}
                    </td>
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ getSuplierName(su_id.id) }}
                    </td>
                  </tr>
                  <tr v-if="rep_wasl_company != ''">
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("return_item_to_suplier_invoice_number") }}
                    </td>
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ rep_wasl_company }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("date") }}
                    </td>
                    <td
                      class="text-black border border-black row-table-border font-bold px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      <!--
                                              show index of item in loop 1,2,3,4,5,6,7,8,9,10
                                            -->
                      <div class="whitespace-normal">
                        {{ formatDate(date_return) }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex-auto pb-2">
            <div class="overflow-x-auto">
              <table
                class="items-center w-full mb-0 align-top border-gray-200 text-black-500"
              >
                <thead class="align-bottom text-center">
                  <tr>
                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      #
                    </th>

                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                      colspan="3"
                    >
                      {{ $t("item_name") }}
                    </th>
                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("cost") }}
                    </th>
                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("quantity") }}
                    </th>

                    <th
                      class="text-black text-right font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ $t("total") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="w-full"
                    v-for="(item, index) in returned_purchase_items"
                    :key="index"
                  >
                    <td
                      class="text-black -bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ index + 1 }}
                    </td>

                    <td
                      class="text-black font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-normal dark:border-white/40"
                      colspan="3"
                    >
                      <div class="whitespace-normal">
                        {{ getItemNameById(item) }}
                      </div>
                    </td>
                    <td
                      class="text-black font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ formatNumber(item.repi_cost_price) }}
                    </td>
                    <td
                      class="text-black font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    >
                      {{ formatNumber(item.repi_quantity) }}
                    </td>

                    <td
                      class="text-black font-bold border border-black row-table-border my_font p-2 font-bold align-middle text-size-base bg-transparent border-b whitespace-nowrap dark:border-white/40"
                      colspan="1"
                    >
                      {{
                        formatNumber(item.repi_quantity * item.repi_cost_price)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <table
            class="items-center w-full mb-0 align-top border-gray-200 text-black-500"
          >
            <tbody>
              <tr>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-base my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <b> {{ $t("total_price") }}</b>
                </td>
                <td
                  class="text-black border border-black row-table-border px-4 py-2 w-1/5 text-size-lg my_font p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                >
                  <b> {{ formatNumber(sumCost) }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="border-b w-full"></div>
      <div class="flex justify-start items-center w-full p-3 bg-white">
        <button
          class="mx-auto text-center bg-pink-600 hover:bg-pink-700 px-3 py-1 rounded text-white"
          @click="modalPrintID"
        >
          <svg
            aria-hidden="true"
            class="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="loading"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span v-if="!loading"> {{ $t("print") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "../../components/Inputs/BaseInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import { formatDate } from "../../utils/functions";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import { FILE_URL } from "../../constants/config";
import { CURRENCYS } from "../../constants/config";
import { formatNumber } from "../../utils/functions";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
import LoadingProgress from "../../components/loading-progress.vue";
import VueMultiselect from "vue-multiselect";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    BaseInput,

    ConfirmDialogue,
    ResultDialog,
    TextAreaInput,
    DropdownInput,
    LoadingProgress,
    VueMultiselect,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value || value == 0) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      no_rule: [],
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      isShowDetail: false,
      file_url: FILE_URL,
      currencys: CURRENCYS,
      returned_purchase_items: [],
      barcode: "",

      su_id: "",
      bo_id: "",
      cur_id: 1,
      st_id: "",
      rep_main_currency_price: 0,
      pl_id: "",
      rep_note: "",
      created_at: "",
      rep_wasl_company: "",
      tempItem: 0,
      recivedMoney: 0,
      changeMoney: 0,
      in_type: 1,
      isPrint: "2",
      isModalPrint: false,
      rep_id: "",
      date_return: "",
      settings: {},
      isShowItemsCategorys: true,
      rep_paid: 0,
      rep_due: 0,
    };
  },
  created() {
    this.settings = JSON.parse(
      localStorage.getItem("currentSettingWiseManager")
    );
    this.getSupliers();
    this.getBoxs();
    this.$store.dispatch("item_category/getCategory");
    this.getPosData();
    this.getCurrentReturnPurchase();

    this.$store.dispatch("navbar/getItems");
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.edit_return_purchase.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.edit_return_purchase.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("edit_return_purchase/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.edit_return_purchase.isShowResultDialog;
    },
    getDolarPrice() {
      return this.$store.state.navbar.dp_price;
    },
    filterItems() {
      return this.$store.getters["edit_return_purchase/filterItems"];
    },
    filterItemsPos() {
      return this.$store.getters["edit_return_purchase/filterItemsPOS"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.edit_return_purchase.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.edit_return_purchase.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.edit_return_purchase.isBtnEditLoading;
    },

    convertToListIdValueCategory() {
      var categorys = this.$store.state.item_category.categorys;
      var list = [];
      for (const item of categorys) {
        list.push({
          id: item.ca_id,
          value: item.ca_name,
        });
      }

      return list;
    },
    convertToListIdValueBoxes() {
      var boxes = this.$store.state.all_box.boxes;
      var list = [];
      for (const item of boxes) {
        list.push({
          id: item.bo_id,
          value: item.bo_name,
        });
      }

      return list;
    },

    convertToListIdValue() {
      var list = [];
      for (const item of this.currencys) {
        list.push({
          id: item.cur_id,
          value: this.$t(item.cur_name),
        });
      }

      return list;
    },
    convertToListIdValueSupliers() {
      var supliers = this.$store.state.suplier.supliers;
      var list = [];
      for (const item of supliers) {
        list.push({
          id: item.su_id,
          value: item.su_name,
        });
      }

      return list;
    },

    sumCost() {
      var sum = 0;
      for (const item of this.returned_purchase_items) {
        sum += item.repi_cost_price * item.repi_quantity;
      }
      return sum.toFixed(2);
    },
  },

  methods: {
    async getCurrentReturnPurchase() {
      this.rep_id = this.$route.params.id;
      var res = await this.$store.dispatch(
        "edit_return_purchase/getcurrentReturnPurchase",
        {
          rep_id: this.$route.params.id,
        }
      );
      //check status

      if (res.status == 200) {
        //loop through purchase_items and add some properties

        this.su_id = res.data.su_id;

        this.su_id = {
          id: res.data.su_id,
          value: this.getSuplierName(res.data.su_id),
        };

        this.bo_id = res.data.bo_id;
        this.cur_id = res.data.cur_id;
        this.rep_main_currency_price = res.data.rep_main_currency_price;
        this.rep_wasl_company = res.data.rep_wasl_company;
        this.rep_note = res.data.rep_note;

        this.rep_paid = res.data.rep_paid;
        this.rep_due = res.data.rep_due;

        this.date_return = res.data.created_at;
        this.created_at = res.data.created_at;
        this.name = res.data.users.name;
        await this.getPosData();
        this.returned_purchase_items = res.data.returned_purchase_items;
        //loop inside filterItemsPos and add purchase_items to each returned_purchase_items when i_id equal
        for (const item of this.returned_purchase_items) {
          for (const item1 of this.filterItemsPos) {
            if (item1.i_id == item.i_id) {
              item.purchase_items = item1.purchase_items;
              for (const item2 of item1.purchase_items) {
                if (item2.pui_id == item.pui_id) {
                  this.setPUID(item, item2.pui_id);
                }
              }
            }
          }
        }
      } else {
        //route to 404
        this.$router.push({ name: "NotFound" });
      }
    },
    getPriceInCurrentCurrency(item) {
      if (this.cur_id == item.cur_id) {
        return item.repi_cost_price;
      } else {
        if (this.cur_id == 1) {
          return item.repi_cost_price * this.getDolarPrice;
        } else {
          return item.repi_cost_price / this.getDolarPrice;
        }
      }
    },
    getSuplierName(su_id) {
      var supliers = this.$store.state.suplier.supliers;
      for (const item of supliers) {
        if (item.su_id == su_id) {
          return item.su_name;
        }
      }
    },
    modalPrintID() {
      this.isModalPrint = !this.isModalPrint;

      // Get the content to print
      const printContents = document.getElementById("invoice_print").innerHTML;

      // Open a new window for printing
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.16/dist/tailwind.min.css" rel="stylesheet">
          <style>
            /* Set the RTL direction */
            body {
              direction: rtl;
              font-family: 'Rabar_022', sans-serif; /* Use the font name you define here */
            }
          </style>
          <link rel="stylesheet" href="https://kcstore.online/fonts/Rabar_022.6835fa5e.ttf">
        </head>
        <body>
          <div >
            ${printContents}
          </div>
        </body>
      </html>
    `);
      printWindow.document.close();

      // Print the contents
      printWindow.print();

      //
      printWindow.document.close();

      // Print the contents
      printWindow.print();

      // Close the print window after printing

      this.isModalPrint = !this.isModalPrint;
    },
    classesPrintModal() {
      return {
        "modal  bg-opacity-5 h-full   w-full  fixed top-0 left-0  justify-center   hidden overflow-y-auto":
          !this.isModalPrint,
        "modal  bg-opacity-5 h-full   w-full  fixed top-0 left-0  justify-center  flex overflow-y-auto ":
          this.isModalPrint,
      };
    },
    printStatus() {},
    getItemNameById(item1) {
      if (item1.item != null) {
        return item1.item.i_name;
      }
      //lop in filterItemsPos and get name
      for (const item of this.filterItemsPos) {
        if (item.i_id == item1.i_id) {
          return item.i_name;
        }
      }
      return "";
    },
    handleSelectChange() {
      this.returned_purchase_items = [];
      this.getPosData();
    },
    async getPosData() {
      this.$store.commit("new_return_purchase/SET_IS_LOADING_DATA", true);
      //clear itemsPOS in store
      this.$store.commit("new_return_purchase/SET_ITEMS_POS", []);
      await this.$store.dispatch("edit_return_purchase/getItemsPOS", {
        in_type: this.in_type,
        cur_id: this.cur_id,
        su_id: this.su_id.id,
      });
    },
    changeCurrencyType() {
      if (this.cur_id == 1) {
        this.cur_id = 2;
      } else {
        this.cur_id = 1;
      }
    },

    async getSupliers() {
      await this.$store.dispatch("suplier/getSupliers");
    },

    async getBoxs() {
      await this.$store.dispatch("all_box/getBoxes");
    },

    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    calculateDue(item, num) {
      this.updatePurchase(item, num);
    },
    async editItem() {
      if (this.$store.state.edit_return_purchase.isBtnLoading) return;

      this.isSubmited = true;
      if (
        this.checkRulesIsError(this.su_id.id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.sumCost, "add", this.rules).isError ||
        this.checkRulesIsError(this.rep_paid, "add", this.rules).isError
      ) {
        this.$refs.ResultDialog.show({
          message: this.$t("please_fill_all_fields"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }
      if (this.rep_paid > this.sumCost) {
        this.$refs.ResultDialog.show({
          message: this.$t("paid_money_more_than_total_price"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }

      if (isNaN(this.sumCost) || this.sumCost < 0 || this.rep_paid < 0) {
        this.$refs.ResultDialog.show({
          message: this.$t("please_enter_valid_number_for_all_fields"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }

      if (this.returned_purchase_items.length == 0) {
        this.$refs.ResultDialog.show({
          message: this.$t("no_item_in_invoice"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }
      if (this.sumCost - this.rep_paid > 0) {
        const ok = await this.$refs.confirmDialogue.show({
          message:
            this.$t("are_you_sure_this_invoice_has_due") +
            " " +
            this.formatNumber(this.sumCost - this.rep_paid) +
            (this.cur_id == 1 ? this.$t("dinar") : this.$t("dollar")) +
            " ?",
          okButton: this.$t("yes"),
          isIcon: false,
          color:
            "bg-blue-600 hover:bg-blue-700 focus:ring-blue-300 dark:bg-blue-500  dark:hover:bg-blue-600 dark:focus:ring-blue-900", // Color of the confirm button
        });
        if (!ok) {
          this.$store.state.edit_return_purchase.isBtnLoading = false;
          return;
        }
      }
      this.$store.state.edit_return_purchase.isBtnLoading = true;
      var tempPurchaseList = [];
      for (const item of this.returned_purchase_items) {
        tempPurchaseList.push({
          i_id: item.i_id,
          repi_quantity: item.repi_quantity,
          repi_cost_price: item.repi_cost_price,
          pui_id: item.pui_id,
        });
        if (item.pui_id == 0) {
          this.$refs.ResultDialog.show({
            message: this.$t("you_must_choose_purchase_item"),
            okButton: this.$t("done"),
            isSuccess: false,
          });
          return;
        }
      }

      var res = await this.$store.dispatch("edit_return_purchase/editItem", {
        su_id: this.su_id.id,
        rep_amount: this.sumCost,
        rep_note: this.rep_note,
        returned_purchase_items: tempPurchaseList,
        created_at: this.created_at,
        rep_wasl_company: this.rep_wasl_company,
        rep_id: this.rep_id,
        rep_paid: this.rep_paid,
        rep_due: this.sumCost - this.rep_paid,
      });

      if (res.status == 200) {
        this.isSubmited = false;

        this.getPosData();
      }
      //cehck if status code ==502
      else if (res.response.status == 502) {
        this.$refs.ResultDialog.show({
          message: this.$t("no_enough_quantity_in_stock"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
      } else if (res.response.status == 505) {
        this.$refs.ResultDialog.show({
          message: this.$t("you_didnt_buy_this_item_from_this_suplier_before"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
      } else {
        this.$refs.ResultDialog.show({
          message: this.$t("something_wrong"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
      }

      this.$store.state.edit_return_purchase.isBtnLoading = false;
    },
    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },

    clearPurchase() {
      this.returned_purchase_items = [];

      this.rep_main_currency_price = 0;
      this.rep_note = "";
      this.created_at = "";
      this.rep_wasl_company = "";
    },
    setPUID(obj, value) {
      for (const item of this.returned_purchase_items) {
        if (obj.i_id == item.i_id) {
          item.pu_id = value;

          //loop in purchase items
          for (const purchaseItem of item.purchase_items) {
            if (purchaseItem.pui_id == value) {
              //check cur_id if it is equal to item cur_id
              //if it is equal set repi_cost_price to purchaseItem.pui_cost_price

              item.pui_id = purchaseItem.pui_id;

              if (this.cur_id == purchaseItem.cur_id) {
                item.repi_cost_price = purchaseItem.pui_cost_price;
              } else if (this.cur_id == 1) {
                item.repi_cost_price =
                  purchaseItem.pui_cost_price * this.getDolarPrice;
              } else if (this.cur_id == 2) {
                item.repi_cost_price =
                  purchaseItem.pui_cost_price / this.getDolarPrice;
              }

              return;
            }
          }

          break;
        }
      }
    },
    addItemToReturnPurchase(item) {
      //check if item is already in the list
      if (
        item.i_quantity <= 0 ||
        item.i_quantity < this.checkHowMuchItemInCard(item.i_id) + 1
      ) {
        this.$refs.ResultDialog.show({
          message: this.$t("no_enough_quantity_in_stock"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }
      var isExist = false;
      for (const purchaseItem of this.returned_purchase_items) {
        if (purchaseItem.i_id == item.i_id) {
          isExist = true;

          purchaseItem.repi_quantity++;
          break;
        }
      }
      if (isExist == false) {
        var obj = {
          i_id: item.i_id,
          repi_quantity: 1,
          repi_cost_price: 0,
          i_name: item.i_name,
          cur_id: item.cur_id,
          purchase_items: item.purchase_items,
          pu_id: 0,
          pui_id: 0,
        };
        this.returned_purchase_items.push(obj);
      } else {
        this.returned_purchase_items = [...this.returned_purchase_items];
      }
    },
    updatePurchase(item, num) {
      for (const purchaseItem of this.returned_purchase_items) {
        if (purchaseItem.i_id == item.i_id) {
          purchaseItem.repi_quantity = num;
          break;
        }
      }

      this.returned_purchase_items = [...this.returned_purchase_items];
    },
    checkHowMuchItemInCard(i_id) {
      for (const item of this.returned_purchase_items) {
        if (item.i_id == i_id) {
          return item.repi_quantity;
        }
      }
      return 0;
    },
    removeItem(i_id) {
      for (const item of this.returned_purchase_items) {
        if (item.i_id == i_id) {
          this.returned_purchase_items.splice(
            this.returned_purchase_items.indexOf(item),
            1
          );
          break;
        }
      }
    },
    enterBarcode(e) {
      e.preventDefault();
      if (this.barcode == "") return;
      var item = this.filterItemsPos.find((item) => item.i_id == this.barcode);
      if (item) {
        this.addItemToReturnPurchase(item);
        this.barcode = "";
      } else {
        this.$refs.ResultDialog.show({
          message: this.$t("item_not_found_for_this_barcode"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
      }
    },
  },
};
</script>
