<template>
  <HeaderApp />
  <LoadingProgress v-show="!isShowExpenseDetail" />
  <div
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
    v-if="isShowExpenseDetail"
    id="printableArea"
  >
    <div
      class="w-full p-4 bg-white dark:bg-bgLightDark rounded-2xl center-table"
    >
      <div
        class="flex justify-between mb-6 items-center border-b-2 border-gray-700 dark:border-gray-400 pb-4"
      >
        <div class="flex-col">
          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("expense_id") }} :
            </p>
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ expenseDetail.ex_id }}#
            </p>
          </div>
          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("expense_person_name") }}:
            </p>
            <p class="text-textBlack dark:text-textWhite text-base">
              {{
                expenseDetail.customer != null
                  ? expenseDetail.customer.cu_name
                  : ""
              }}
            </p>
          </div>

          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("admin") }} :
            </p>
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ expenseDetail.users.name }}
            </p>
          </div>
        </div>
        <div class="flex flex-col items-center">
          <img
            src="../../assets/logo.png"
            alt=""
            width="180"
            height="180"
            v-if="settings.set_logo == '' || settings.set_logo == null"
          /><img
            :src="file_url + settings.set_logo"
            alt=""
            width="180"
            height="180"
          />
          <p
            class="text-textBlack3 dark:text-textWhite3 text-2xl font-semibold mt-2"
          >
          {{ settings.app_name }}
          </p>
        </div>

        <div class="flex-col">
          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("invoice_type") }} :
            </p>
            <p
              class="text-textBlack dark:text-textWhite text-base font-semibold"
            >
              {{ $t("expense") }}
            </p>
          </div>
          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("date") }} :
            </p>
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ formatDate(expenseDetail.created_at).toString().slice(0, 10) }}
            </p>
          </div>

          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("time") }} :
            </p>
            <p class="text-textBlack dark:text-textWhite text-base">
              {{
               formatDate( expenseDetail.created_at).toString().slice(10, 16)
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table
          class="table-auto text-center w-full border-collapse border border-gray-400 dark:border-gray-800"
        >
          <tbody>
            <tr>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("money_amount") }}
              </td>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2 font-bold text-xl"
              >
                {{ formatNumber(expenseDetail.ex_amount) }}
                {{ $t(expenseDetail.currency.cur_name) }}
              </td>
            </tr>
            <tr>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("box") }}
              </td>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ expenseDetail.box.bo_name }}
              </td>
            </tr>
            <tr>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("expense_category") }}
              </td>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ expenseDetail.expense_category.exc_name }}
              </td>
            </tr>
            <tr
              v-if="
                expenseDetail.ex_wasl != '' && expenseDetail.ex_wasl != null
              "
            >
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("expense_invoice_number") }}
              </td>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ expenseDetail.ex_wasl }}
              </td>
            </tr>
            <tr
              v-if="
                expenseDetail.ex_note != '' && expenseDetail.ex_note != null
              "
            >
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("note") }}
              </td>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ expenseDetail.ex_note }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button
      class="noPrint text-white text-lg md:text-xl bg-primaryColor px-6 md:px-16 py-2 my-6 rounded-2xl items-center hover:shadow-md"
      @click="printDiv()"
    >
      {{ $t("print") }}
    </button>
  </div>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import LoadingProgress from "../../components/loading-progress.vue";
import { formatDate } from "../../utils/functions";
import { CURRENCYS } from "../../constants/config";
import { formatNumber } from "../../utils/functions";
import { FILE_URL } from "../../constants/config";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    LoadingProgress,
  },
  data() {
    return {
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_rule: [],

      isSubmited: false,
      currencys: CURRENCYS,
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      cur_id: "",
      exc_id: "",
      bo_id: "",
      cu_id: "",
      ex_note: "",
      ex_wasl: "",
      file_url: FILE_URL,
      ex_amount: "",
      created_at: "",
      ex_image: "",
      settings: {},
    };
  },
  created() {
    this.settings = JSON.parse(
      localStorage.getItem("currentSettingWiseManager")
    );
    this.getExpenseDetail();
  },

  computed: {
    isDark() {
      return this.$store.state.theme == "dark";
    },
    //isShowExpenseDetail
    isShowExpenseDetail() {
      return this.$store.state.expense_list.isShowExpenseDetail;
    },
    expenseDetail() {
      return this.$store.state.expense_list.expenseDetail;
    },
  },

  methods: {
    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    //function to get time from dattime
    getTime(date) {
      return date.toString().slice(10, 16);
    },
    async getExpenseDetail() {
      var res = await this.$store.dispatch(
        "expense_list/showExpense",
        this.$route.params.id
      ); //check status
      if (res.status != 200) {
        window.location.href = "/404";
      }
    },
    printDiv() {
      var printContents = document.getElementById("printableArea").innerHTML;
      var originalContents = document.body.innerHTML;
      //rtl
      document.body.dir = "rtl";
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
  },
};
</script>
<style scoped>
@media print {
  .noPrint {
    display: none;
  }
}
</style>
