<template>
  <HeaderApp />
  <div
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("add_expense") }}
      </p>
    </div>

    <div class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="">
        <form @submit="addSaleItem">
          <div class="grid gap-x-6 mb-6 md:grid-cols-2">
            <BaseInput
              v-model="ex_amount"
              :label="$t('money_amount')"
              :placeholder="$t('expense_amount')"
              :rules="rules"
              type="number"
              :isError="checkRulesIsError(ex_amount, 'add', this.rules).isError"
              :errorMsg="
                checkRulesIsError(ex_amount, 'add', this.rules).errorMsg
              "
            />
            <DropdownInput
              v-model="cur_id"
              :label="$t('currency_type')"
              :placeholder="$t('choose_currency')"
              :items="convertToListIdValue"
              :rules="rules_dropdown"
              :isError="
                checkRulesIsError(cur_id, 'add', this.rules_dropdown).isError
              "
              :errorMsg="
                checkRulesIsError(cur_id, 'add', this.rules_dropdown).errorMsg
              "
            /><DropdownInput
              v-model="exc_id"
              :label="$t('expense_category')"
              :placeholder="$t('choose_expense_category')"
              :rules="rules_dropdown"
              :items="convertToListIdValueExpenseCategory"
              :isError="
                checkRulesIsError(exc_id, 'add', this.rules_dropdown).isError
              "
              :errorMsg="
                checkRulesIsError(exc_id, 'add', this.rules_dropdown).errorMsg
              "
              linkDrop="/expense-category"
            /><DropdownInput
              v-model="bo_id"
              :label="$t('expense_box')"
              :placeholder="$t('choose_expense_box')"
              :rules="rules_dropdown"
              :items="convertToListIdValueBoxes"
              :isError="
                checkRulesIsError(bo_id, 'add', this.rules_dropdown).isError
              "
              :errorMsg="
                checkRulesIsError(bo_id, 'add', this.rules_dropdown).errorMsg
              "
              linkDrop="/all-box"
            />
            <div class="mb-6">
              <div class="flex">
                <div class="w-full">
                  <label
                    for="saleType"
                    class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
                    >{{ $t("customer") }}</label
                  >
                  <VueMultiselect
                    v-model="cu_id"
                    :options="convertToListIdValueCustomers"
                    :multiple="false"
                    :placeholder="
                      $t('choose_if_you_spent_the_money_to_customer')
                    "
                    label="value"
                    track-by="id"
                    :taggable="true"
                    class="h-10 mt-2 bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-bgFullDark dark:border-gray-600"
                  >
                  </VueMultiselect>
                </div>
                <div class="w-2"></div>
                <a
                  class="w-2/12 lg:w-1/12 text-white text-2xl my-9 bg-primaryColor p-1 rounded-lg hover:shadow-md text-center"
                  target="_blank"
                  href="/customer"
                >
                  +
                </a>
              </div>
            </div>

            <BaseInput
              v-model="ex_wasl"
              :label="$t('expense_invoice')"
              :placeholder="$t('expense_invoice')"
              :rules="no_rule"
              :isError="checkRulesIsError(ex_wasl, 'add', this.no_rule).isError"
              :errorMsg="
                checkRulesIsError(ex_wasl, 'add', this.no_rule).errorMsg
              "
            />
            <BaseInput
              v-model="created_at"
              type="date"
              :label="$t('expense_date')"
              :placeholder="$t('expense_date')"
              :rules="no_rule"
              :isError="
                checkRulesIsError(created_at, 'add', this.no_rule).isError
              "
              :errorMsg="
                checkRulesIsError(created_at, 'add', this.no_rule).errorMsg
              "
            />
            <div>
              <label
                for="image"
                class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
              >
                {{ $t("expense_photo") }}</label
              >
              <label
                for="image"
                class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              >
                <i class="fa fa-cloud-upload mx-1"></i>
                {{
                  ex_image == "" ? $t("choose_expense_photo") : ex_image.name
                }}
              </label>
              <input
                type="file"
                v-on:change="onChange"
                :placeholder="$t('choose_expense_photo')"
                id="image"
                class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              />
            </div>
            <TextAreaInput
              v-model="ex_note"
              :label="$t('note')"
              :placeholder="$t('note')"
              :rules="no_rule"
              :isError="checkRulesIsError(ex_note, 'add', this.no_rule).isError"
              :errorMsg="
                checkRulesIsError(ex_note, 'add', this.no_rule).errorMsg
              "
            />
          </div>

          <ButtonInput
            type="button"
            @click="addSaleItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>

  <ResultDialog ref="ResultDialog"></ResultDialog>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import { CURRENCYS } from "../../constants/config";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
import VueMultiselect from "vue-multiselect";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ResultDialog,
    TextAreaInput,
    DropdownInput,
    VueMultiselect,
  },
  data() {
    return {
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_rule: [],
      isSubmited: false,
      currencys: CURRENCYS,
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      cur_id: "",
      exc_id: "",
      bo_id: "",
      cu_id: "",
      ex_note: "",
      ex_wasl: "",
      ex_amount: "",
      created_at: "",
      ex_image: "",
    };
  },
  created() {
    this.$store.dispatch("all_box/getBoxes");

    this.$store.dispatch("expense_category/getExpenseCategory");
    this.$store.dispatch("customer/getCustomers");
    this.$store.dispatch("navbar/getItems");
  },

  computed: {
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isBtnLoading() {
      return this.$store.state.add_expense.isBtnLoading;
    },
    convertToListIdValue() {
      var list = [];
      for (const item of this.currencys) {
        list.push({
          id: item.cur_id,
          value: this.$t(item.cur_name),
        });
      }

      return list;
    },
    convertToListIdValueBoxes() {
      var boxes = this.$store.state.all_box.boxes;
      var list = [];
      for (const item of boxes) {
        list.push({
          id: item.bo_id,
          value: item.bo_name,
        });
      }

      return list;
    },
    convertToListIdValueCustomers() {
      var customers = this.$store.state.customer.customers;
      var list = [];
      for (const item of customers) {
        list.push({
          id: item.cu_id,
          value: item.cu_name,
        });
      }

      return list;
    },
    convertToListIdValueExpenseCategory() {
      var expenseCategory = this.$store.state.expense_category.expenseCategory;
      var list = [];
      for (const item of expenseCategory) {
        list.push({
          id: item.exc_id,
          value: item.exc_name,
        });
      }

      return list;
    },
  },

  methods: {
    onChange(e) {
      this.ex_image = e.target.files[0];
      e.target.value = "";
    },
    formatDate(date) {
      return formatDate(date);
    },

    async addSaleItem(e) {
      e.preventDefault();

      if (this.$store.state.add_expense.isBtnLoading) return;

      this.isSubmited = true;
      if (
        this.checkRulesIsError(this.cur_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.exc_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.bo_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.ex_amount, "add", this.rules).isError
      ) {
        return;
      }
      this.$store.state.add_expense.isBtnLoading = true;
      var res = await this.$store.dispatch("add_expense/addNewItem", {
        cur_id: this.cur_id,
        exc_id: this.exc_id,
        bo_id: this.bo_id,
        cu_id: this.cu_id.id,
        ex_note: this.ex_note,
        ex_wasl: this.ex_wasl,
        ex_amount: this.ex_amount,
        ex_main_currency_price: this.$store.state.navbar.dp_price,
        created_at: this.created_at,
        ex_image: this.ex_image,
      });
      if (res.status == 200) {
        this.ex_amount = "";
        this.ex_note = "";
        this.ex_wasl = "";

        this.isSubmited = false;
      }
      this.isSubmited = false;
    },
    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
  },
};
</script>
