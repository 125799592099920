<template>
  <popup-modal ref="popup">
    <!-- Main modal -->
    <div class="relative mx-auto p-5  w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
      <div class="mt-3 text-center">
        <div
          :class="
            'mx-auto flex items-center justify-center h-12 w-12 rounded-full ' +
            (isSuccess ? 'bg-green-100' : 'bg-red-100') +
            ' dark:bg-gray-800'
          "
          @click="_cancel"
        >
          <i
            :class="icon + (isSuccess ? ' text-green-500 ' : ' text-red-500 ')"
          ></i>
        </div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">{{ title }}</h3>
        <div class="mt-2 px-7 py-3">
          <p class="text-sm text-gray-500">
            {{ message }}
          </p>
        </div>
        <div class="items-center px-4 py-3">
          <button
            id="ok-btn"
            :class="
              'px-4 py-2 text-white text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 ' +
              (isSuccess
                ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
                : 'bg-red-600 hover:bg-red-700 focus:ring-red-500') +
              ' focus:ring-opacity-50'
            "
            @click="_cancel"
          >
            {{ okButton }}
          </button>
        </div>
      </div>
    </div>
  </popup-modal>
</template>
<script>
import PopupModal from "./PopupModal.vue";

export default {
  name: "ConfirmDialogue",

  components: { PopupModal },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "پاشگەزبوونەوە", // text for cancel button
    icon: undefined,
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
    isSuccess: false,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      this.isSuccess = opts.isSuccess;
      this.icon = opts.isSuccess
        ? "fa-solid fa-check"
        : "fa-solid fa-exclamation";
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User canceled the dialogue'))
    },
  },
};
</script>
