import { post } from "../../helpers/api";
import { showToast as toast } from "../../components/Modals/toast";
import { i18n } from "../../main"; // Adjust the import path accordingly

const state = {
  isLoadingData: true,
  isBtnLoading: false,
  isBtnEditLoading: false,
  search: "",
  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
  searchBarcode: "",
};

const mutations = {
  //isLoadingData
  SET_IS_LOADING_DATA(state, isLoadingData) {
    state.isLoadingData = isLoadingData;
  },
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },
  SET_SEARCH_BARCODE(state, searchBarcode) {
    state.searchBarcode = searchBarcode;
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  SET_DELETE_SUCCESS(state, value) {
    state.isShowResultDialog = value.state;
    state.resultMsg = value.msg;
    state.isSuccess = value.isSuccess;
  },
};

const actions = {
  async getItems({ commit }, payload) {
    commit("SET_IS_LOADING_DATA", true);
    try {
      return post("returns-suplier/index?page=" + payload.page, payload, {
        id: 18,
        action: "view",
      })
        .then((response) => {
          commit("SET_IS_BTN_LOADING", false);
          commit("SET_IS_BTN_EDIT_LOADING", false);
          commit("SET_IS_LOADING_DATA", false);

          return response;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 408) {
            window.location.href = "/404";
          }
          return error;
        });
    } catch (error) {
      console.log(error);
    }
  },
  async deleteItem({ commit }, id) {
    return post(
      "returns-suplier/delete/" + id,
      {},
      {
        id: 18,
        action: "delete",
      }
    )
      .then((response) => {
        this.dispatch("return_purchases/getItems");

        const t = i18n.global.t;
        toast("success", t("deleted_successfully"));
        return response;
      })
      .catch((error) => {
        const t = i18n.global.t;
        this.dispatch("return_purchases/getItems");
        commit("SET_IS_BTN_LOADING", false);
        console.log(error);
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }
        if (
          error.response.data.message.includes(
            "Cannot delete or update a parent row"
          )
        ) {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t(
              "you_can_not_delete_this_item_because_there_are_related_items"
            ),
            isSuccess: false,
          });
        } else {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t("something_wrong"),
            isSuccess: false,
          });
        }
        return error;
      });
  },
};

const getters = {
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
