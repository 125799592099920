<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div
      class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
    >
      <div
        class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
      >
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
          >
            {{ $t("login_to_your_account") }}
          </h1>
          <form class="space-y-4 md:space-y-6" @submit="login">
            <div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >{{ $t("gmail") }}</label
              >
              <input
                v-model="data.email"
                type="email"
                name="email"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required=""
              />
            </div>
            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >{{ $t("password") }}</label
              >
              <input
                v-model="data.password"
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
              />
            </div>

            <button
              type="button"
              class="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              @click="login"
              v-if="!isLoading"
            >
              {{ $t("login") }}
            </button>
            <button
              type="button"
              class="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 flex justify-center items-center text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              v-if="isLoading"
            >
              <svg
                width="20"
                height="20"
                fill="currentColor"
                class="mr-2 animate-spin"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                ></path>
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { SERVER_URL } from "../../constants/config";
import { toast } from "vue3-toastify";
export default {
  name: "HelloWorld",
  data() {
    return {
      data: { email: "", password: "" },
      isLoading: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  created() {},
  methods: {
    login(e) {
      e.preventDefault();
      this.isLoading = true;
      axios
        .post(SERVER_URL + "login", this.data)
        .then((response) => {
          // this.items = response.data;

          localStorage.setItem("token_wise_manager", response.data.token);
          localStorage.setItem(
            "user_wise_manager",
            JSON.stringify(response.data.user)
          );

          this.isLoading = false;
          window.location.href = "/";
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          //check if 401 error
          if (error.response.status == 402) {
            toast(this.$t("your_account_id_not_active"), {
              closeButton: true,
              position: "top-left",
              contentColor: "#000",
              content: "error",
              type: "error",
            });
            return;
          }

          toast(this.$t("email_or_password_wrong"), {
            closeButton: true,
            position: "top-left",
            contentColor: "#000",
            content: "error",
            type: "error",
          });
        });
    },
  },
};
</script>
