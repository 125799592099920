<template>
  <HeaderApp class="no-print" />
  <div
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl dir-rtl text-right' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
    id="printDev"
  >
    <InvoiceHeader invoice_type="box_detail" v-if="isHide == true" />
    <div class="flex lg:flex-row flex-col mb-4 justify-between">
      <div class="flex justify-between">
        <p class="font-semibold text-xl text-textBlack dark:text-textWhite">
          {{ $t("box_detail") }} - {{ box.bo_name }}
        </p>

        <p
          class="font-semibold text-xl text-textBlack dark:text-textWhite"
          v-if="isHide == true"
        >
          {{ startDate }} - {{ endDate }}
        </p>
      </div>

      <div class="flex lg:flex-row flex-col" v-if="isHide != true">
        <div class="md:mx-2 my-1 md:my-0">
          <label
            for="debtDate"
            class="block mb-2 text-sm font-medium text-textBlack1 dark:text-textWhite1"
            >{{ $t("start_date") }}
          </label>
          <input
            type="date"
            id="debtDate"
            class="bg-white border text-sm border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
            v-model="startDate"
            @change="getData"
          />
        </div>

        <div class="md:mx-2 my-1 md:my-0">
          <label
            for="debtDate"
            class="block mb-2 text-sm font-medium text-textBlack1 dark:text-textWhite1"
            >{{ $t("end_date") }}</label
          >
          <input
            type="date"
            id="debtDate"
            class="bg-white border text-sm border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
            v-model="endDate"
            @change="getData"
          />
        </div>

        <div class="md:mx-2 my-1 md:my-0">
          <label
            for="boxType"
            class="block mb-2 text-sm text-textBlack1 dark:text-textWhite1"
          >
            {{ $t("choose_period") }}
          </label>
          <select
            id="countries"
            class="bg-white border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block xl:w-40 lg:w-36 w-4/6 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="period"
            @change="periodChanged(period)"
          >
            <option value="today" class="my_font">{{ $t("today") }}</option>
            <option value="yesterday" class="my_font">
              {{ $t("yesterday") }}
            </option>
            <option value="last7days" class="my_font">
              {{ $t("last7days") }}
            </option>
            <option value="last30days" class="my_font">
              {{ $t("last30days") }}
            </option>
            <option value="thismonth" class="my_font">
              {{ $t("thismonth") }}
            </option>
            <option value="lastmonth" class="my_font">
              {{ $t("lastmonth") }}
            </option>
            <option value="lastyear" class="my_font">
              {{ $t("lastyear") }}
            </option>
            <option value="thisyear" class="my_font">
              {{ $t("thisyear") }}
            </option>
            <option value="all" class="my_font">{{ $t("all_time") }}</option>
          </select>
        </div>
      </div>
    </div>

    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 my-6 gap-x-4 gap-y-2"
    >
      <!--
      <ReportComponent
        :labelDolar="formatNumber(box.bo_amount_dollar)"
        :labelDinar="formatNumber(box.bo_amount_dinar)"
        :labelTitle="$t('total_box_money')"
        classColor="from-blue-700 "
      />-->
      <ReportComponent
        :labelDolar="formatNumber(total_usd)"
        :labelDinar="formatNumber(total_iqd)"
        :labelTitle="$t('total_box_money_in_selected_period')"
        classColor="from-purple-700 "
      />
    </div>
    <button
      class="mx-2 my_font bg-primaryColor hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
      @click="printReportFunc"
      id="button_print"
    >
      {{ $t("print") }}
    </button>
    <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="relative overflow-x-auto mt-4 mb-6">
        <div class="flex items-center justify-between pb-4 mx-5 no-print">
          <label for="table-search" class="sr-only"> {{ $t("search") }}</label>
          <div class="relative">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <input
              type="text"
              id="table-search"
              class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :placeholder="$t('search')"
              @input="
                $store.commit('box_detail/SET_SEARCH', $event.target.value)
              "
            />
          </div>
        </div>
        <!--in ltr change (text-right to text-left)-->
        <EasyDataTable
          :headers="headers"
          :items="filterItems"
          :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
          alternating
          :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
          :loading="isLoadingData"
          :class="isDark ? 'customize-table ' : 'light-mode-table'"
          :hide-footer="isHide"
          :rows-items="[25, 50, 100, filterItems.length]"
          rows-per-page="10"
        >
          <template #default="{ column, sortDirection }">
            <span class="custom-sort-icon" v-if="column.sortable">
              <i
                v-if="sortDirection === 'asc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i
                v-else-if="sortDirection === 'desc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
            </span>
            {{ column.label }}
          </template>

          <template #loading>
            <img
              src="../../assets/loading.webp"
              style="width: 70px; height: 70px"
            />
          </template>
          <template #item-total_expense_number="item">
            <span
              :class="
                selectedReport == 'total_expense_number'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.total_expense_number) }}
            </span>
          </template>
          <template #item-amount="item">
            <span>
              {{ formatNumber(item.amount) }}
            </span>
          </template>
          <template #item-balance_IQD="item">
            <span>
              {{ formatNumber(item.balance.IQD) }}
            </span>
          </template>
          <template #item-balance_USD="item">
            <span>
              {{ formatNumber(item.balance.$) }}
            </span>
          </template>

          <template #item-dolar_price="item">
            <span>
              {{ formatNumber(item.dolar_price) }}
            </span> </template
          ><template #item-type="item">
            <span>
              {{ item.type }}
            </span> </template
          ><template #item-money_direction="item">
            <span
              class="bg-red-500 px-1 rounded text-white"
              v-if="item.money_direction == $t('out_money')"
            >
              {{ item.money_direction }}
            </span>
            <span
              class="bg-green-500 px-1 rounded text-white"
              v-if="item.money_direction == $t('in_money')"
            >
              {{ item.money_direction }}
            </span>
            <span
              class="bg-gray-500 px-1 rounded text-white"
              v-if="item.money_direction == $t('exchange')"
            >
              {{ item.money_direction }}
            </span>
          </template>
          <template #item-total_amount_dollar="item">
            <span
              :class="
                selectedReport == 'total_amount_dollar'
                  ? 'text-primaryColor font-bold'
                  : ''
              "
            >
              {{ formatNumber(item.total_amount_dollar) }}
            </span>
          </template>
          <template #item-wasl="item">
            <span
              v-if="item.wasl == null || item.wasl == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.wasl }}
            </span>
          </template>
          <template #item-note="item">
            <span
              v-if="item.note == null || item.note == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.note }}
            </span>
          </template>
          <template #item-created_at="item">
            <span>
              {{ formatDate(item.created_at) }}
            </span>
          </template>

          <template #empty-message>
            <span class="text-textBlack1 dark:text-white">
              {{ $t("no_data_found") }}
            </span>
          </template>
          <template
            #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
          >
            <button :disabled="isFirstPage" @click="prevPage">
               <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-right'
                    : ' fa-angle-left ')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-left'
                    : ' fa-angle-right')
                "
              ></i>
            </button>
          </template>
        </EasyDataTable>
      </div>
    </div>
  </div>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import InvoiceHeader from "../../components/Navbar/InvoiceHeader.vue";
import { formatDate } from "../../utils/functions";
import { formatNumber } from "../../utils/functions";
import ReportComponent from "../../components/Report/ReportComponent.vue";

import moment from "moment";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    ReportComponent,
    InvoiceHeader,
  },
  data() {
    return {
      selectedReport: "total_expense_number",

      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],

      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      startDate: "",
      endDate: "",
      period: "last30days",
      invoiceType: "0",
      isHide: false,
    };
  },
  created() {
    //start date and end date set to last 30 days

    this.startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");

    this.getData();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.box_detail.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.box_detail.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("box_detail/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    filterItems() {
      return this.$store.getters["box_detail/filterItems"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.box_detail.isLoadingData;
    },

    //total
    items() {
      return this.$store.state.box_detail.items;
    },
    total_iqd() {
      return this.$store.state.box_detail.total_iqd;
    },
    total_usd() {
      return this.$store.state.box_detail.total_usd;
    },
    box() {
      return this.$store.state.box_detail.box;
    },
    headers() {
      return [
        { text: "#", value: "id", sortable: true },
        {
          text: this.$t("invoice_number"),
          value: "wasl",
          sortable: true,
        },
        {
          text: this.$t("transaction_type"),
          value: "type",
          sortable: true,
        },
        {
          text: this.$t("money_direction"),
          value: "money_direction",
          sortable: true,
        },
        {
          text: this.$t("amount"),
          value: "amount",
          sortable: true,
        },
        {
          text: this.$t("currency_type"),
          value: "currency",
          sortable: true,
        },
        {
          text: this.$t("current_box_amount_dinar"),
          value: "balance_IQD",
          sortable: true,
        },
        {
          text: this.$t("current_box_amount_dollar"),
          value: "balance_USD",
          sortable: true,
        },
        {
          text: this.$t("note"),
          value: "note",
          sortable: true,
        },
        {
          text: this.$t("dollar_price_in_that_time"),
          value: "dolar_price",
          sortable: true,
        },
        {
          text: this.$t("date"),
          value: "created_at",
          sortable: true,
        },
      ];
    },
  },

  methods: {
    printReportFunc() {
      this.isHide = true;
      //wait 1 second
      setTimeout(() => {
        var printContents = document.getElementById("printDev").innerHTML;

        // Replace the body's innerHTML with the print contents
        document.body.innerHTML = printContents;
        var isRtl = this.$store.getters["isRtl"];
        // Set direction based on the parameter
        if (isRtl) {
          document.body.style.direction = "rtl";
        } else {
          document.body.style.direction = "ltr";
        }

        // Print the content
        window.print();
        //reload page
      }, 100);
      //print element id invoice_print
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    async getData() {
      await this.$store.dispatch("box_detail/getItems", {
        startDate: this.startDate,
        endDate: this.endDate,
        bo_id: this.$route.params.id,
      });
    },

    periodChanged(action) {
      this.period = action;

      if (action == "today") {
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "yesterday") {
        this.startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (action == "last7days") {
        this.startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "last30days") {
        this.startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "thismonth") {
        this.startDate = moment().startOf("month").format("YYYY-MM-DD");
        this.endDate = moment().endOf("month").format("YYYY-MM-DD");
      } else if (action == "lastmonth") {
        this.startDate = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.endDate = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (action == "lastyear") {
        this.startDate = moment()
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.endDate = moment()
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
      } else if (action == "thisyear") {
        this.startDate = moment().startOf("year").format("YYYY-MM-DD");
        this.endDate = moment().endOf("year").format("YYYY-MM-DD");
      } else if (action == "all") {
        this.startDate = "2000-01-01";
        this.endDate = moment().endOf("year").format("YYYY-MM-DD");
      }

      this.getData();
    },
  },
};
</script>
<style>
@media print {
  .no-print {
    display: none;
  }
}
</style>
