import { post } from "../../helpers/api";

const state = {
  items: [],
  total: {},
  isLoadingData: true,
  isBtnLoading: false,
  isBtnEditLoading: false,
  search: "",
  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
};

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items;

    state.isLoadingData = false;
  },
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },
};

const actions = {
  async getItems({ commit }, payload) {
    try {
      return post("dashboard/get-report", payload, {
        id: 1,
        action: "view",
      })
        .then((response) => {
          commit("SET_ITEMS", response.data);
          commit("SET_IS_BTN_LOADING", false);

          return response.data;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            window.location.href = "/login";
          }
          if (error.response.status == 408) {
           //redirect to home
            window.location.href = "/home";
          }
          return error;
        });
    } catch (error) {
      console.log(error);
    }
  },
};

const getters = {
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
