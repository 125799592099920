<template>
  <HeaderApp />
  <div class="mt-14"></div>

  <div
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      (isHide == true
        ? '  '
        : $store.getters['isRtl']
        ? ' md:mr-60 '
        : ' md:ml-60  ')
    "
    id="printDev"
  >
    <InvoiceHeader invoice_type="supliers_list" v-if="isHide == true" />
    <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
      {{ $t("items_list") }}
    </p>
    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 my-6 gap-x-4 gap-y-2"
    >
      <ReportComponent
        :labelDolar="formatNumber(total['sumCostAllDolar'])"
        :labelDinar="formatNumber(total['sumCostAllDinar'])"
        :labelTitle="$t('total_cost')"
        classColor="from-red-500 "
      />
      <ReportComponent
        :labelDolar="formatNumber(total['sumSaleAllDolar'])"
        :labelDinar="formatNumber(total['sumSaleAllDinar'])"
        :labelTitle="$t('total_sale')"
        classColor="from-blue-500 "
      />
<!--
      <ReportComponent
        :labelDolar="formatNumber(total['sumProfitAllDolar'])"
        :labelDinar="formatNumber(total['sumProfitAllDinar'])"
        :labelTitle="$t('total_profit')"
        classColor="from-green-500 "
      />-->
    </div>
    <div class="flex items-center space-x-4 mb-4" v-if="isHide != true">
      <label
        for="selectedHeaders"
        class="text-sm font-semibold"
        @click="columnsControl = !columnsControl"
      >
        <span class="flex">
          {{ $t("columns") }} <i class="fa fa-close my-1 mx-2"></i
        ></span>
      </label>
      <multi-select
        v-model="selectedHeaders"
        :options="headers"
        track-by="value"
        label="text"
        multiple
        placeholder="Select Columns"
        class="custom-multiselect"
        @input="printCall"
        v-if="columnsControl"
      >
      </multi-select>
    </div>
    <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="relative overflow-x-auto mt-4 mb-6">
        <div class="flex items-center justify-between pb-4 mx-5 no-print">
          <label for="table-search" class="sr-only">{{ $t("search") }}</label>
          <div class="relative">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <input
              type="text"
              id="table-search"
              class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :placeholder="$t('search')"
              @input="
                $store.commit('item_list/SET_SEARCH', $event.target.value),
                  loadFromServer(),
                  (serverOptions.page = 1)
              "
            />
          </div>
          <button
            class="mx-2 my_font bg-primaryColor hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
            @click="printReportFunc"
            id="button_print"
            v-if="$store.getters['checkPrintAccess']"
          >
            {{ $t("print") }}
          </button>
        </div>

        <EasyDataTable
          v-model:server-options="serverOptions"
          :headers="showHeaders"
          :items="items"
          :server-items-length="serverItemsLength"
          :loading="isLoadingData"
          alternating
          :class="isDark ? 'customize-table ' : 'light-mode-table'"
          :buttons-pagination="true"
          :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
          :hide-footer="isHide"
          :rows-items="[25, 50, 100, serverItemsLength]"
          rows-per-page="10"
        >
          <template #default="{ column, sortDirection }">
            <span class="custom-sort-icon" v-if="column.sortable">
              <i
                v-if="sortDirection === 'asc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i
                v-else-if="sortDirection === 'desc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
            </span>
            {{ column.label }}
          </template>
          <template #item-actions="item">
            <button
              class="mx-1 my-1"
              @click="showModal(item)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '8',
                  action: 'edit',
                })
              "
            >
              <i
                class="fa-solid fa-pen text-center p-1 w-7 h-7 text-sm bg-blue-200 dark:bg-blue-300 rounded-md text-blue-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </button>
            <button
              class="mx-1 my-1"
              @click="deleteItem(item.i_id)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '8',
                  action: 'delete',
                })
              "
            >
              <i
                class="fa-solid fa-trash text-center p-1 w-7 h-7 text-sm bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto"
              ></i>
            </button>
            <a
              class="mx-1 my-1"
              :href="'item-detail/' + item.i_id"
              target="_blank"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '8',
                  action: 'detail',
                })
              "
            >
              <i
                class="m-1 fa-solid fa-eye text-center p-1 w-7 h-7 text-sm bg-green-200 dark:bg-blue-300 rounded-md text-green-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </a> </template
          ><template #item-i_image="item">
            <img
              :src="file_url + item.i_image"
              class="inline-flex items-center justify-center text-white transition-all duration-200 ease-in-out h-16 w-16 rounded-xl"
              alt="xd"
              v-if="item.i_image"
              @click="openModal(file_url + item.i_image)"
            />
            <span v-if="item.i_image == '' || item.i_image == null">
              <i class="fa-solid fa-image text-primaryColor"></i>
            </span>
          </template>
          <template #item-suplier_su_name="item">
            <span
              v-if="item.suplier == null || item.suplier == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.suplier.su_name }}
            </span>
          </template>
          <template #item-currency_cur_name="item">
            <span>
              {{ $t(item.currency.cur_name) }}
            </span>
          </template>
          <template #item-i_sale_price="item">
            <span>
              {{ formatNumber(item.i_sale_price) }}
              {{ $t(item.currency.cur_name) }}
            </span>
          </template>
          <template #item-i_sale_price_jwmla="item">
            <span>
              {{ formatNumber(item.i_sale_price_jwmla) }}
              {{ $t(item.currency.cur_name) }}
            </span>
          </template>
          <template #item-i_desc="item">
            <span
              v-if="item.i_desc == null || item.i_desc == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.i_desc }}
            </span>
          </template>
          <template #item-i_expire_date="item">
            <span
              v-if="item.i_expire_date == null || item.i_expire_date == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.i_expire_date }}
            </span>
          </template>
          <template #loading>
            <img
              src="../../assets/loading.webp"
              style="width: 70px; height: 70px"
            />
          </template>
          <template #item-created_at="item">
            {{ formatDate(item.created_at) }} </template
          ><template #empty-message>
            <span class="text-textBlack1 dark:text-white">
              {{ $t("no_data_found") }}
            </span>
          </template>

          <template
            #pagination="{
              prevPage,
              nextPage,
              isFirstPage,
              isLastPage,
              currentPaginationNumber,
              maxPaginationNumber,
            }"
          >
            <button :disabled="isFirstPage" @click="goToPage(1)">
             
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-double-right'
                    : ' fa-angle-double-left')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isFirstPage" @click="prevPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-chevron-right'
                    : ' fa-chevron-left')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button
              v-for="page in getVisiblePages(
                currentPaginationNumber,
                maxPaginationNumber
              )"
              :key="page"
              @click="goToPage(page)"
              :class="getPageButtonClasses(page, currentPaginationNumber)"
            >
              {{ page }}
            </button>

            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-left'
                    : ' fa-angle-right')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button
              :disabled="isLastPage"
              @click="goToPage(maxPaginationNumber)"
            >
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-double-left'
                    : ' fa-angle-double-right')
                "
              ></i
              >
            </button> </template
        ></EasyDataTable>
      </div>
    </div>
  </div>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <edit-modal ref="editModal">
    <div class="items-center">
      <form @submit="editItem">
        <BaseInput
          v-model="current.i_name"
          :label="$t('item_name')"
          :placeholder="$t('item_name')"
          :rules="rules"
          :isError="
            checkRulesIsError(current.i_name, 'add', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.i_name, 'add', this.rules).errorMsg
          "
        /><DropdownInput
          v-model="current.ca_id"
          :placeholder="$t('choose_item_category')"
          :label="$t('item_category')"
          :items="convertToListIdValueCategory"
          :rules="rules_dropdown"
          :isError="
            checkRulesIsError(current.ca_id, 'add', this.rules_dropdown).isError
          "
          :errorMsg="
            checkRulesIsError(current.ca_id, 'add', this.rules_dropdown)
              .errorMsg
          "
        /><DropdownInput
          v-model="current.un_id"
          :label="$t('item_unit')"
          :items="convertToListIdValueUnits"
          :placeholder="$t('choose_item_unit')"
          :rules="rules_dropdown"
          :isError="
            checkRulesIsError(current.un_id, 'add', this.rules_dropdown).isError
          "
          :errorMsg="
            checkRulesIsError(current.un_id, 'add', this.rules_dropdown)
              .errorMsg
          "
        />
        <!-- 
        <div>
          <label
            for="saleType"
            class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
            >{{ $t("suplier") }}</label
          >
          <VueMultiselect
            v-model="current.su_id"
            :options="convertToListIdValueSupliers"
            :multiple="false"
            :placeholder="$t('choose_suplier')"
            label="value"
            track-by="id"
            :taggable="true"
          >
          </VueMultiselect>
        </div>-->
        <DropdownInput
          v-model="current.cur_id"
          :label="$t('currency_type')"
          :items="convertToListIdValue"
          :placeholder="$t('choose_currency_type')"
          :rules="rules_dropdown"
          :isError="
            checkRulesIsError(current.cur_id, 'add', this.rules_dropdown)
              .isError
          "
          :errorMsg="
            checkRulesIsError(current.cur_id, 'add', this.rules_dropdown)
              .errorMsg
          "
        />
        <BaseInput
          v-model="current.i_sale_price"
          :label="$t('sale_item_price')"
          :placeholder="$t('sale_item_price')"
          :rules="rules"
          type="number"
          :isError="
            checkRulesIsError(current.i_sale_price, 'add', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.i_sale_price, 'add', this.rules).errorMsg
          "
        />
        <BaseInput
          v-model="current.i_sale_price_jwmla"
          :label="$t('sale_jwmla_price')"
          :placeholder="$t('sale_jwmla_price')"
          :rules="rules"
          type="number"
          :isError="
            checkRulesIsError(current.i_sale_price_jwmla, 'add', this.rules)
              .isError
          "
          :errorMsg="
            checkRulesIsError(current.i_sale_price_jwmla, 'add', this.rules)
              .errorMsg
          "
        />

        <BaseInput
          v-model="current.i_main_currency_price"
          :label="$t('dolar_price_in_bought_item_date')"
          :placeholder="$t('dolar_price_in_bought_item_date')"
          :rules="rules"
          type="number"
          :isError="
            checkRulesIsError(current.i_main_currency_price, 'add', this.rules)
              .isError
          "
          :errorMsg="
            checkRulesIsError(current.i_main_currency_price, 'add', this.rules)
              .errorMsg
          "
        />
        <BaseInput
          v-model="current.i_expire_date"
          type="date"
          :label="$t('expire_Date')"
          :placeholder="$t('write_expire_date_if_has')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.i_expire_date, 'add', this.no_rule)
              .isError
          "
          :errorMsg="
            checkRulesIsError(current.i_expire_date, 'add', this.no_rule)
              .errorMsg
          "
        />

        <div>
          <label
            for="image"
            class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
          >
            {{ $t("item_photo") }}</label
          >
          <label
            for="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          >
            <i class="fa fa-cloud-upload mx-1"></i>
          
            {{ i_image == "" ? $t("choose_item_photo_if_you_want_change") : i_image.name }}
          </label>
          <input
            type="file"
            v-on:change="onChange"
            :placeholder="$t('choose_item_photo_if_you_want_change')"
            id="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          />
        </div>

        <TextAreaInput
          v-model="current.i_desc"
          :label="$t('item_description')"
          :placeholder="$t('item_description')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.i_desc, 'add', this.no_rule).isError
          "
          :errorMsg="
            checkRulesIsError(current.i_desc, 'add', this.no_rule).errorMsg
          "
        />
        <center>
          <ButtonInput
            type="button"
            @click="editItem"
            :label="$t('edit')"
            :isLoading="isBtnEditLoading"
          />
        </center>
      </form>
    </div> </edit-modal
  ><image-modal ref="imageModal" />
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import ReportComponent from "../../components/Report/ReportComponent.vue";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import EditModal from "../../components/Modals/EditModal.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
import { formatNumber } from "../../utils/functions";
import { FILE_URL } from "../../constants/config";
import { CURRENCYS } from "../../constants/config";
import ImageModal from "../../components/Modals/ImageModal2.vue";
import InvoiceHeader from "../../components/Navbar/InvoiceHeader.vue";
//import VueMultiselect from "vue-multiselect";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ConfirmDialogue,
    ResultDialog,
    EditModal,
    DropdownInput,
    TextAreaInput,
    ReportComponent,
    ImageModal,
    InvoiceHeader,
    // VueMultiselect,
  },
  data() {
    return {
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      no_rule: [],
      firstName: "",
      rules: [
        (value) => {
          if (value || value == 0) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      currencys: CURRENCYS,
      ic_name: "",
      inputType: "text",
      placeholderText: "ناوی جۆری داهات",
      isSubmited: false,
      isSubmitedEdit: false,
      i_image: "",
      current: {},
      file_url: FILE_URL,
      serverItemsLength: 0,
      serverOptions: {
        page: 1,
        rowsPerPage: 5,
      },
      loading: false,
      items: [],
      total: {},
      isHide: false,
      selectedHeaders: [],
      columnsControl: false,
    };
  },
  mounted() {
    // First load when the component is mounted
    this.loadFromServer();
    this.selectedHeaders = [...this.headers];
  },
  created() {
    this.$store.dispatch("item_category/getCategory");

    this.$store.dispatch("item_unit/getUnits");
    
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.item_list.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.item_list.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("item_list/SET_DELETE_SUCCESS", false);
      }
    },
    serverOptions: {
      handler() {
        this.loadFromServer();
      },
      deep: true,
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.item_list.isShowResultDialog;
    },

    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.item_list.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.item_list.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.item_list.isBtnEditLoading;
    },
    headers() {
      var listHeader = [
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
          hidden: true,
        },
        { text: this.$t("item_barcode"), value: "i_id", sortable: true },
        { text: this.$t("item_name"), value: "i_name", sortable: true },
        {
          text: this.$t("photo"),
          value: "i_image",
          sortable: true,
        },
        {
          text: this.$t("item_category"),
          value: "category.ca_name",
          sortable: true,
        },
        {
          text: this.$t("last_suplier"),
          value: "suplier_su_name",
          sortable: true,
        },
        {
          text: this.$t("unit"),
          value: "unit.un_name",
          sortable: true,
        },

        {
          text: this.$t("last_cost"),
          value: "i_cost_price",
          sortable: true,
        },
        {
          text: this.$t("sale_item_price"),
          value: "i_sale_price",
          sortable: true,
        },
        {
          text: this.$t("sale_jwmla_price"),
          value: "i_sale_price_jwmla",
          sortable: true,
        },

        {
          text: this.$t("item_quantity_in_storage"),
          value: "i_quantity",
          sortable: true,
        },
        {
          text: this.$t("item_bought_quantity_till_now"),
          value: "i_in_quantity",
          sortable: true,
        },
        {
          text: this.$t("item_sold_quantity_till_now"),
          value: "i_out_quantity",
          sortable: true,
        },

        {
          text: this.$t("dolar_price"),
          value: "i_main_currency_price",
          sortable: true,
        },
        {
          text: this.$t("item_expire_date"),
          value: "i_expire_date",
          sortable: true,
        },
        { text: this.$t("note"), value: "i_desc", sortable: true },
        { text: this.$t("admin"), value: "users.name", sortable: true },
        { text: this.$t("created_at"), value: "created_at", sortable: true },
      ];
      //remove created_at,admin,note,actions if isHide == true
      if (this.isHide == true) {
        listHeader.splice(0, 3);
        listHeader.splice(1, 1);
      }
      return listHeader;
    },
    showHeaders() {


      var temp = [];
      //cretae a for check all selectedHeaders add to temp
      for (let i = 0; i < this.selectedHeaders.length; i++) {
        for (let j = 0; j < this.headers.length; j++) {
          if (this.selectedHeaders[i].text == this.headers[j].text) {
            temp.push(this.headers[j]);
          }
        }
      }

      if (temp.length == 0) {
        temp = this.headers;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedHeaders = temp;
      }

      return temp;
    },
    convertToListIdValue() {
      var list = [];
      for (const item of this.currencys) {
        list.push({
          id: item.cur_id,
          value: this.$t(item.cur_name),
        });
      }

      return list;
    },
    convertToListIdValueUnits() {
      var boxes = this.$store.state.item_unit.units;
      var list = [];
      for (const item of boxes) {
        list.push({
          id: item.un_id,
          value: item.un_name,
        });
      }

      return list;
    },


    convertToListIdValueCategory() {
      var categorys = this.$store.state.item_category.categorys;
      var list = [];
      for (const item of categorys) {
        list.push({
          id: item.ca_id,
          value: item.ca_name,
        });
      }

      return list;
    },
  },

  methods: {
    printReportFunc() {
      this.isHide = true;
      //wait 1 second
      setTimeout(() => {
        var printContents = document.getElementById("printDev").innerHTML;

        // Replace the body's innerHTML with the print contents
        document.body.innerHTML = printContents;
        var isRtl = this.$store.getters["isRtl"];
        // Set direction based on the parameter
        if (isRtl) {
          document.body.style.direction = "rtl";
        } else {
          document.body.style.direction = "ltr";
        }

        // Print the content
        window.print();
        //reload page
      }, 100);
      //print element id invoice_print
    },
    getVisiblePages(currentPage, totalPages) {
      const numVisibleButtons = 3; // Adjust this number based on your design preference
      const pages = [];

      // Display pages 1 to numVisibleButtons (e.g., 1, 2, 3)
      if (currentPage <= Math.floor(numVisibleButtons / 2) + 1) {
        for (let i = 1; i <= Math.min(totalPages, numVisibleButtons); i++) {
          pages.push(i);
        }
      }
      // Display pages around the current page (e.g., 1, ..., 10, ..., 20)
      else {
        const startPage = Math.max(
          1,
          currentPage - Math.floor(numVisibleButtons / 2)
        );
        const endPage = Math.min(totalPages, startPage + numVisibleButtons - 1);

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
      }

      return pages;
    },

    // Function to get Tailwind CSS classes for each page button
    getPageButtonClasses(page, currentPage) {
      return {
        "bg-blue-500 text-white": page === currentPage,
        "bg-gray-300 text-gray-700 hover:bg-gray-400": page !== currentPage,
        "p-2 rounded-md mx-1": true, // Adjust padding and rounding based on your design
      };
    },

    goToPage(page) {
      // Implement your logic to update the current page
      //update page
      this.serverOptions.page = page;
      this.loadFromServer();
    },
    async loadFromServer() {
      this.loading = true;

      var tempObj = { ...this.serverOptions };
      //add new field to object search
      tempObj.search = this.$store.state.item_list.search;

      const res = await this.$store.dispatch("item_list/getItems", tempObj);

      this.items = res.data.items.data;
      this.total = res.data.total;
      this.serverItemsLength = res.data.items.total;
      this.loading = false;
    },
    openModal(image) {
      this.$refs.imageModal.show(image);
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    getData() {
      this.$store.dispatch("item_list/getItems");
    },

    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";

      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    async deleteItem(id) {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$t("are_you_sure_remove_this_item") + id,
        okButton: this.$t("delete"),
      });
      if (ok) {
        this.$store.commit("item_list/REMOVE_ITEM", id);
       await this.$store.dispatch("item_list/deleteItem", id);
        this.loadFromServer();
      }
    },
    //edit
    async showModal(item) {
      this.current = item;
      if (this.current.su_id != null) {
        this.current.su_id = {
          id: item.su_id,
          value: item.suplier.su_name,
        };
      } else {
        this.current.su_id = {
          id: null,
          value: null,
        };
      }
      await this.$refs.editModal.show();
    },
    async editItem(e) {
      e.preventDefault();
      this.isSubmitedEdit = true;
      if (
        this.checkRulesIsError(this.current.i_name, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(this.current.i_sale_price, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(
          this.current.i_sale_price_jwmla,
          "edit",
          this.rules
        ).isError
      ) {
        return;
      }
      this.$store.state.item_list.isBtnEditLoading = true;

      var res = await this.$store.dispatch("item_list/editItem", {
        i_name: this.current.i_name,
        i_id: this.current.i_id,
        ca_id: this.current.ca_id,
        //   su_id: this.current.su_id.id,
        un_id: this.current.un_id,
        cur_id: this.current.cur_id,

        i_sale_price: this.current.i_sale_price,
        i_sale_price_jwmla: this.current.i_sale_price_jwmla,

        i_main_currency_price: this.current.i_main_currency_price,
        i_expire_date: this.current.i_expire_date,
        i_image: this.i_image,
        i_desc: this.current.i_desc,
      });

      this.isSubmitedEdit = false;
      this.$store.state.item_list.isBtnEditLoading = false;
      if (res.status == 200) {
        this.i_image = "";
        this.$refs.editModal.hide();
        this.loadFromServer();
      }
    },
    onChange(e) {
      this.i_image = e.target.files[0];
      e.target.value = "";
    },
  },
};
</script>
<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(40%) sepia(96%) saturate(694%) hue-rotate(195deg)
    brightness(85%) contrast(88%);
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
} /* Add your gallery styles here */
.gallery {
  cursor: pointer;
  margin: 5px;
}

.gallery img {
  width: 100px;
  height: auto;
}
</style>
