import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Auth/Login.vue";
import Home from "../views/Home/Home.vue";
import EmptyHome from "../views/Home/EmptyHome.vue";
import AddSaleType from "../views/SaleSetting/AddSaleType.vue";
import SalePlatform from "../views/SaleSetting/SalePlatform.vue";
import SaleCity from "../views/SaleSetting/SaleCity.vue";
import ItemUnit from "../views/ItemSetting/ItemUnit.vue";
import ItemCategory from "../views/ItemSetting/ItemCategory.vue";
import isAuthenticated from "../utils/Auth/IsUserAuthenticated";
import ItemWasteCategory from "../views/ItemSetting/ItemWasteCategory.vue";
import ExpenseCategory from "../views/Expense/ExpenseCategory.vue";
import IncomeCategory from "../views/Income/IncomeCategory.vue";
import DolarPrice from "../views/DolarPrice/DolarPrice.vue";
import Note from "../views/Note/Note.vue";
import AllBox from "../views/Box/AllBox.vue";
import ExchangeBox from "../views/Box/ExchangeBox.vue";
import TransferBox from "../views/Box/TransferBox.vue";
import Customer from "../views/Customer/Customer.vue";
//suplier
import Suplier from "../views/Suplier/Suplier.vue";
import AddExpense from "../views/Expense/AddExpense.vue";
import ExpenseList from "../views/Expense/ExpenseList.vue";
import AddIncome from "../views/Income/AddIncome.vue";
import IncomeList from "../views/Income/IncomeList.vue";
import AddDebt from "../views/Debt/AddDebt.vue";
import DebtList from "../views/Debt/DebtList.vue";
import AddItem from "../views/Item/AddItem.vue";
import ItemList from "../views/Item/ItemList.vue";
import ShortageItem from "../views/Item/ShortageItem.vue";
import ExpireItem from "../views/Item/ExpireItem.vue";
import WasteItem from "../views/Item/WasteItem.vue";
import SalePos from "../views/Sale/SalePos.vue";
import EditSale from "../views/Sale/EditSale.vue";
//ReturnInvoice
import ReturnInvoice from "../views/Sale/ReturnInvoice.vue";
import SaleList from "../views/Sale/SaleList.vue";
import CategoryReport from "../views/Report/CategoryReport.vue";
import CityReport from "../views/Report/CityReport.vue";
import PlatformReport from "../views/Report/PlatformReport.vue";
import SaleReport from "../views/Report/SaleReport.vue";
import EmployeeReport from "../views/Report/EmployeeReport.vue";
import ItemReport from "../views/Report/ItemReport.vue";
import CustomerReport from "../views/Report/CustomerReport.vue";
import WasteCategoryReport from "../views/Report/WasteCategoryReport.vue";
import WasteItemReport from "../views/Report/WasteItemReport.vue";
import ExpenseCategoryReport from "../views/Report/ExpenseCategoryReport.vue";
import ExpenseCustomerReport from "../views/Report/ExpenseCustomerReport.vue";
import IncomeCustomerReport from "../views/Report/IncomeCustomerReport.vue";
//income
import IncomeCategoryReport from "../views/Report/IncomeCategoryReport.vue";
import DebtReport from "../views/Report/DebtReport.vue";
//suplier
import SuplierReport from "../views/Report/SuplierReport.vue";
import PurchaseItemReport from "../views/Report/PurchaseItemReport.vue";
import CustomerAccount from "../views/Customer/CustomerAccount.vue";
import SuplierAccount from "../views/Suplier/SuplierAccount.vue";
import Barcode from "../views/Barcode/Barcode.vue";
import NewPurchase from "../views/Purchase/NewPurchase.vue";
import PurchaseList from "../views/Purchase/PurchaseList.vue";
import EditPurchase from "../views/Purchase/EditPurchase.vue";
import AllYearlyReport from "../views/Report/AllYearlyReport.vue";
import ExpenseDetail from "../views/Expense/ExpenseDetail.vue";
import IncomeDetail from "../views/Income/IncomeDetail.vue";
import DebtDetail from "../views/Debt/DebtDetail.vue";
import ChangePassword from "../views/ChangePassword/ChangePassword.vue";
import ItemDetail from "../views/Item/ItemDetail.vue";
import ReturnPurchases from "../views/Purchase/ReturnPurchases.vue";
import NewReturnPurchase from "../views/Purchase/NewReturnPurchase.vue";
import EditReturnPurchase from "../views/Purchase/EditReturnPurchase.vue";
import Settings from "../views/Settings/Settings.vue";
import Faq from "../views/Settings/Faq.vue";
import Employee from "../views/Settings/Employee.vue";
import BoxDetail from "../views/Box/BoxDetail.vue";
import AddItemFromCsvFile from "../views/Item/AddItemFromCsvFile.vue";
import AddRole from "../views/Role/AddRole.vue";
//RoleList
import RoleList from "../views/Role/RoleList.vue";
//editrole
import EditRole from "../views/Role/EditRole.vue";
//404 from settings
import NotFound from "../views/Settings/404.vue";
//HistoryLog
import HistoryLog from "../views/HistoryLog/HistoryLog.vue";
import store from "../store/index";
const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    id: 1,
    permisions: ["view"],
  },
  {
    path: "/add-sale-type",
    name: "AddSaleType",
    component: AddSaleType,
    id: 5,
    permisions: ["add", "view"],
  },
  {
    path: "/sale-platform",
    name: "SalePlatform",
    component: SalePlatform,
    id: 6,
    permisions: ["add", "view"],
  },
  {
    path: "/sale-city",
    name: "SaleCity",
    component: SaleCity,
    id: 7,
    permisions: ["add", "view"],
  },
  {
    path: "/item-unit",
    name: "ItemUnit",
    component: ItemUnit,
    id: 2,
    permisions: ["add", "view"],
  },
  {
    path: "/item-category",
    name: "ItemCategory",
    component: ItemCategory,
    id: 3,
    permisions: ["add", "view"],
  },
  {
    path: "/add-item-from-csv",
    name: "AddItemFromCsvFile",
    component: AddItemFromCsvFile,
    id: 9,
    permisions: ["add"],
  },
  {
    path: "/item-waste-category",
    name: "ItemWasteCategory",
    component: ItemWasteCategory,
    id: 4,
    permisions: ["add", "view"],
  },
  {
    path: "/expense-category",
    name: "ExpenseCategory",
    component: ExpenseCategory,
    id: 22,
    permisions: ["add", "view"],
  },
  {
    path: "/income-category",
    name: "IncomeCategory",
    component: IncomeCategory,
    id: 24,
    permisions: ["add", "view"],
  },
  {
    path: "/dolar-price",
    name: "DolarPrice",
    component: DolarPrice,
    id: 31,
    permisions: ["add", "view"],
  },
  {
    path: "/note",
    name: "Note",
    component: Note,
    id: 32,
    permisions: ["add", "view"],
  },
  {
    path: "/all-box",
    name: "AllBox",
    component: AllBox,
    id: 14,
    permisions: ["add", "view"],
  },
  {
    path: "/exchange-box",
    name: "ExchangeBox",
    component: ExchangeBox,
    id: 15,
    permisions: ["add", "view"],
  },
  {
    path: "/transfer-box",
    name: "TransferBox",
    component: TransferBox,
    id: 16,
    permisions: ["add", "view"],
  },
  {
    path: "/customer",
    name: "Customer",
    component: Customer,
    id: 29,
    permisions: ["add", "view"],
  },
  {
    path: "/suplier",
    name: "Suplier",
    component: Suplier,
    id: 30,
    permisions: ["add", "view"],
  },
  {
    path: "/add-expense",
    name: "AddExpense",
    component: AddExpense,
    id: 23,
    permisions: ["add"],
  },
  {
    path: "/expense-list",
    name: "ExpenseList",
    component: ExpenseList,
    id: 23,
    permisions: ["view"],
  },
  {
    path: "/add-income",
    name: "AddIncome",
    component: AddIncome,
    id: 25,
    permisions: ["add"],
  },
  {
    path: "/income-list",
    name: "IncomeList",
    component: IncomeList,
    id: 25,
    permisions: ["view"],
  },
  {
    path: "/add-debt",
    name: "AddDebt",
    component: AddDebt,
    id: 21,
    permisions: ["add"],
  },
  {
    path: "/debt-list",
    name: "DebtList",
    component: DebtList,
    id: 21,
    permisions: ["view"],
  },
  {
    path: "/add-item",
    name: "AddItem",
    component: AddItem,
    id: 8,
    permisions: ["add"],
  },
  {
    path: "/item-list",
    name: "ItemList",
    component: ItemList,
    id: 8,
    permisions: ["view"],
  },
  {
    path: "/shortage-item",
    name: "ShortageItem",
    component: ShortageItem,
    id: 11,
    permisions: ["view"],
  },
  {
    path: "/expire-item",
    name: "ExpireItem",
    component: ExpireItem,
    id: 12,
    permisions: ["view"],
  },
  {
    path: "/waste-item",
    name: "WasteItem",
    component: WasteItem,
    id: 13,
    permisions: ["add", "view"],
  },
  {
    path: "/sale-pos",
    name: "SalePos",
    component: SalePos,
    id: 19,
    permisions: ["add"],
  },
  {
    path: "/sale-pos/:id",
    name: "EditSale",
    component: EditSale,
    id: 19,
    permisions: ["detail"],
  },
  {
    path: "/return-invoice",
    name: "ReturnInvoice",
    component: ReturnInvoice,
    id: 20,
    permisions: ["add", "view"],
  },
  {
    path: "/sold-list",
    name: "SaleList",
    component: SaleList,
    id: 19,
    permisions: ["view"],
  },
  {
    path: "/category-report",
    name: "CategoryReport",
    component: CategoryReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/city-report",
    name: "CityReport",
    component: CityReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/platform-report",
    name: "PlatformReport",
    component: PlatformReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/sale-report",
    name: "SaleReport",
    component: SaleReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/employee-report",
    name: "EmployeeReport",
    component: EmployeeReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/item-report",
    name: "ItemReport",
    component: ItemReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/customer-report",
    name: "CustomerReport",
    component: CustomerReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/waste-category-report",
    name: "WasteCategoryReport",
    component: WasteCategoryReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/waste-item-report",
    name: "WasteItemReport",
    component: WasteItemReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/expense-category-report",
    name: "ExpenseCategoryReport",
    component: ExpenseCategoryReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/expense-customer-report",
    name: "ExpenseCustomerReport",
    component: ExpenseCustomerReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/income-customer-report",
    name: "IncomeCustomerReport",
    component: IncomeCustomerReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/income-category-report",
    name: "IncomeCategoryReport",
    component: IncomeCategoryReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/debt-report",
    name: "DebtReport",
    component: DebtReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/suplier-report",
    name: "SuplierReport",
    component: SuplierReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/purchase-item-report",
    name: "PurchaseItemReport",
    component: PurchaseItemReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/customer-account/:id",
    name: "CustomerAccount",
    component: CustomerAccount,
    id: 29,
    permisions: ["detail"],
  },
  {
    path: "/suplier-account/:id",
    name: "SuplierAccount",
    component: SuplierAccount,
    id: 30,
    permisions: ["detail"],
  },
  {
    path: "/barcode",
    name: "Barcode",
    component: Barcode,
    id: 33,
    permisions: ["add"],
  },
  {
    path: "/new-purchase",
    name: "NewPurchase",
    component: NewPurchase,
    id: 17,
    permisions: ["add"],
  },
  {
    path: "/purchase-list",
    name: "PurchaseList",
    component: PurchaseList,
    id: 17,
    permisions: ["view"],
  },
  {
    path: "/edit-purchase/:id",
    name: "EditPurchase",
    component: EditPurchase,
    id: 17,
    permisions: ["detail"],
  },
  {
    path: "/all-yearly-report",
    name: "AllYearlyReport",
    component: AllYearlyReport,
    id: 26,
    permisions: ["view"],
  },
  {
    path: "/expense-detail/:id",
    name: "ExpenseDetail",
    component: ExpenseDetail,
    id: 23,
    permisions: ["detail"],
  },
  {
    path: "/income-detail/:id",
    name: "IncomeDetail",
    component: IncomeDetail,
    id: 25,
    permisions: ["detail"],
  },
  {
    path: "/debt-detail/:id",
    name: "DebtDetail",
    component: DebtDetail,
    id: 21,
    permisions: ["detail"],
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    id: 34,
    permisions: ["edit"],
  },
  {
    path: "/item-detail/:id",
    name: "ItemDetail",
    component: ItemDetail,
    id: 8,
    permisions: ["detail"],
  },
  {
    path: "/return-purchases",
    name: "ReturnPurchases",
    component: ReturnPurchases,
    id: 18,
    permisions: ["view"],
  },
  {
    path: "/new-return-purchase",
    name: "NewReturnPurchase",
    component: NewReturnPurchase,
    id: 18,
    permisions: ["add"],
  },
  {
    path: "/edit-return-purchase/:id",
    name: "EditReturnPurchase",
    component: EditReturnPurchase,
    id: 18,
    permisions: ["detail"],
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    id: 35,
    permisions: ["edit"],
  },

  {
    path: "/faq",
    name: "Faq",
    component: Faq,
    id: 36,
    permisions: ["view"],
  },
  {
    path: "/employee",
    name: "Employee",
    component: Employee,
    id: 28,
    permisions: ["add", "view"],
  },
  {
    path: "/box-detail/:id",
    name: "BoxDetail",
    component: BoxDetail,
    id: 14,
    permisions: ["detail"],
  }, //not found page
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    //redirect to home page
    component: NotFound,
  },
  {
    path: "/404",
    name: "NotFound",
    //redirect to home page
    component: NotFound,
  },
  {
    path: "/add-role",
    name: "AddRole",
    component: AddRole,
    id: 27,
    permisions: ["add"],
  },
  {
    path: "/role-list",
    name: "RoleList",
    component: RoleList,
    id: 27,
    permisions: ["view"],
  },
  {
    path: "/role-list/:id",
    name: "EditRole",
    component: EditRole,
    id: 27,
    permisions: ["edit", "detail"],
  },
  {
    path: "/home",
    name: "EmptyHome",
    component: EmptyHome,
  },
  {
    path: "/history-log",
    name: "HistoryLog",
    component: HistoryLog,
    id: 37,
    permisions: ["view"],
  },
];
//add beforeEnter guard to routes
routes.forEach((route) => {
  route.beforeEnter = (to, from, next) => {
    //show loading overlay
    store.state.loading = true;
    //check route id
    if (route.id) {
      //check if user has permission

      //use store.getters to get user permissions
      if (route.id == 1) {
        next();
      }
      var permisions = route.permisions;
      for (let i = 0; i < permisions.length; i++) {
        var hasAccess = store.getters["checkUserAccess"]({
          id: route.id,
          action: permisions[i],
        });
        if (hasAccess) {
          next();
          break;
        }
      }

      next("/404");
    }

    //check if user is authenticated
    if (to.path == "/login") {
      isAuthenticated().then((isAuth) => {
        if (isAuth) {
          next("/");
        } else {
          next();
        }
      });
    }
    isAuthenticated().then((isAuth) => {
      if (isAuth) {
        next();
      } else {
        next("/login");
      }
    });
  };
  //afterEach
  // eslint-disable-next-line no-unused-vars
});
const router = createRouter({
  history: createWebHistory(),
  routes,
});
//afterEach
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  store.state.loading = false;
  //go to top of page
  window.scrollTo(0, 0);
});
//export default router;
export default router;
