export function formatNumber(value) {
  //i want only 2 digits after point
  let val = (value / 1).toFixed(2).replace(",", ".");
  //if value is not a number return 0
  if (isNaN(val)) {
    return 0;
  }
  //if digit after point is 0 return value without point and digits after point
  if (val.indexOf(".00") > 0) {
    return val
      .substring(0, val.length - 3)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function checkDolarDinar(from, amount, price_dolar) {
  var result = 0;
  //if from is 1 then convert from dinar to dollar
  if (from == 1) {
    //if to is 2 then convert from dinar to dollar
    result = amount / price_dolar;
  } else if (from == 2) {
    //if to is 1 then convert from dollar to dinar
    result = amount * price_dolar;
  }

  return result;
}
export function formatDate(value) {
  let date = new Date(value);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  //hours
  let hours = date.getHours();
  //minutes
  let minutes = date.getMinutes();
  //time zone
  //check if day is 1-9 then add 0 before day
  if (day < 10) {
    day = "0" + day;
  }
  //check if month is 1-9 then add 0 before month
  if (month < 10) {
    month = "0" + month;
  }

  return year + "-" + month + "-" + day + " " + hours + ":" + minutes;
}
