<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unused-vars -->
<template>
  <div class="gap-6 mb-6">
    <div>
      <div class="flex">
        <div class="w-full">
          <label
            for="saleType"
            class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
            v-if="label != ''"
            >{{ label }}</label
          >

          <select
            @change="checkInput"
            :value="modelValue"
            v-bind="$attrs"
            :placeholder="placeholder"
            :disabled="isDisabled"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected disabled="disabled">{{ placeholder }}</option>
            <option></option>
            <option
              v-for="(item, index) in items"
              :key="index"
              :value="item.id"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
        <div class="w-2" v-if="linkDrop != ''"></div>
        <a
          v-if="linkDrop != ''"
          class="w-2/12 lg:w-1/12 text-white text-2xl bg-primaryColor p-1 my-8 rounded-lg hover:shadow-md text-center"
          target="_blank"
          :href="linkDrop"
        >
          +
        </a>
      </div>

      <!-- Error message -->
      <div v-if="isError" class="text-red-500 text-sm mt-2">
        {{ errorMsg }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  modelValue: {
    type: String,
    default: "",
  },
  isError: {
    type: Boolean,
    default: false,
  },
  errorMsg: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  items: {
    type: Array,
    default: () => [],
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  linkDrop: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["update:isError", "update:modelValue"]);
//check modelValue if number convert to string 1->"1"
//created function for check input

const checkInput = (e) => {
  emit("update:modelValue", e.target.value);
};
</script>
