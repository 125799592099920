import { post } from "../../helpers/api";
import { showToast as toast } from "../../components/Modals/toast";
import { i18n } from "../../main"; // Adjust the import path accordingly

const state = {
  isBtnLoading: false,

  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
};

const mutations = {
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },
};

const actions = {
  async updatePassword({ commit }, payload) {
    return post("employee/update-password", payload, {
      id: 34,
      action: "edit",
    })
      .then((response) => {
        const t = i18n.global.t;
        toast("success", t("added_successfully"));

        commit("SET_IS_BTN_LOADING", false);
        return response;
      })
      .catch((error) => {
        commit("SET_IS_BTN_LOADING", false);
        const t = i18n.global.t;
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }if(error.response.status == 401){
          toast("error", t("old_password_is_incorrect"));
          return error;
        }
        toast("error", t("something_wrong"));
        console.log(error);
        return error;
      });
  },
};

const getters = {
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
