<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl no-print ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("add_customers") }}
      </p>
    </div>

    <div
      class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl"
      v-if="
        $store.getters['checkUserAccess']({
          id: '29',
          action: 'add',
        })
      "
    >
      <div class="">
        <form @submit="addSaleItem">
          <div class="grid gap-x-6 mb-6 md:grid-cols-2">
            <BaseInput
              v-model="cu_name"
              :label="$t('customer_name')"
              :placeholder="$t('customer_name')"
              :rules="rules"
              :isError="checkRulesIsError(cu_name, 'add', this.rules).isError"
              :errorMsg="checkRulesIsError(cu_name, 'add', this.rules).errorMsg"
            />
            <BaseInput
              v-model="cu_phone"
              :label="$t('phone_number')"
              :placeholder="$t('phone_number')"
              :rules="rules"
              :isError="checkRulesIsError(cu_phone, 'add', this.rules).isError"
              :errorMsg="
                checkRulesIsError(cu_phone, 'add', this.rules).errorMsg
              "
            /><BaseInput
              v-model="cu_address"
              :label="$t('address')"
              :placeholder="$t('address')"
              :rules="rules"
              :isError="
                checkRulesIsError(cu_address, 'add', this.rules).isError
              "
              :errorMsg="
                checkRulesIsError(cu_address, 'add', this.rules).errorMsg
              "
            />
            <BaseInput
              v-model="cu_email"
              :label="$t('gmail')"
              :placeholder="$t('gmail')"
              :rules="no_rule"
              :isError="
                checkRulesIsError(cu_email, 'add', this.no_rule).isError
              "
              :errorMsg="
                checkRulesIsError(cu_email, 'add', this.no_rule).errorMsg
              "
            />
            <DropdownInput
              v-model="ci_id"
              :label="$t('choose_city')"
              :placeholder="$t('choose_city')"
              :rules="rules_dropdown"
              :items="convertToListIdValueCitys"
              :isError="
                checkRulesIsError(ci_id, 'add', this.rules_dropdown).isError
              "
              :errorMsg="
                checkRulesIsError(ci_id, 'add', this.rules_dropdown).errorMsg
              "
              linkDrop="/sale-city"
            />
            <div>
              <label
                for="image"
                class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
              >
                {{ $t("customer_photo") }}</label
              >
              <label
                for="image"
                class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              >
                <i class="fa fa-cloud-upload mx-1"></i>
                {{
                  cu_image == "" ? $t("choose_customer_photo") : cu_image.name
                }}
              </label>
              <input
                type="file"
                v-on:change="onChange"
                :placeholder="$t('choose_customer_photo')"
                id="image"
                class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              />
            </div>
            <TextAreaInput
              v-model="cu_note"
              :label="$t('note')"
              :placeholder="$t('note')"
              :rules="no_rule"
              :isError="checkRulesIsError(cu_note, 'add', this.no_rule).isError"
              :errorMsg="
                checkRulesIsError(cu_note, 'add', this.no_rule).errorMsg
              "
            />
          </div>

          <ButtonInput
            type="button"
            @click="addSaleItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>
  <div
    class="w-full"
    id="printDev"
    v-if="
      $store.getters['checkUserAccess']({
        id: '29',
        action: 'view',
      })
    "
  >
    <InvoiceHeader invoice_type="customers_list" v-if="isHide == true" />

    <div
      :class="
        ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
        (isHide == true
          ? '  '
          : $store.getters['isRtl']
          ? ' md:mr-60 '
          : ' md:ml-60  ')
      "
    >
      <div class="flex items-center space-x-4 mb-4" v-if="isHide != true">
        <label
          for="selectedHeaders"
          class="text-sm font-semibold"
          @click="columnsControl = !columnsControl"
        >
          <span class="flex">
            {{ $t("columns") }} <i class="fa fa-close my-1 mx-2"></i
          ></span>
        </label>
        <multi-select
          v-model="selectedHeaders"
          :options="headers"
          track-by="value"
          label="text"
          multiple
          placeholder="Select Columns"
          class="custom-multiselect"
          @input="printCall"
          v-if="columnsControl"
        >
        </multi-select>
      </div>
      <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
        <div class="relative overflow-x-auto mt-4 mb-6">
          <div class="flex items-center justify-between pb-4 mx-5 no-print">
            <label for="table-search" class="sr-only">{{ $t("search") }}</label>
            <div class="relative">
              <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
              >
                <svg
                  class="w-5 h-5 text-gray-400 dark:text-gray-500"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <!--in ltr change (pr-10 to pl-10)-->
              <input
                type="text"
                id="table-search"
                class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                :placeholder="$t('search')"
                @input="
                  $store.commit('customer/SET_SEARCH', $event.target.value),
                    loadFromServer(),
                    (serverOptions.page = 1)
                "
              />
            </div>
            <button
              class="mx-2 my_font bg-primaryColor hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
              @click="printReportFunc"
              id="button_print"
              v-if="$store.getters['checkPrintAccess']"
            >
              {{ $t("print") }}
            </button>
          </div>

          <EasyDataTable
            v-model:server-options="serverOptions"
            :headers="showHeaders"
            :items="items"
            :server-items-length="serverItemsLength"
            :loading="isLoadingData"
            alternating
            :class="isDark ? 'customize-table ' : 'light-mode-table'"
            :buttons-pagination="true"
            :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
            :hide-footer="isHide"
            :rows-items="[25, 50, 100, serverItemsLength]"
            rows-per-page="10"
            
          >
            <template #default="{ column, sortDirection }">
              <span class="custom-sort-icon" v-if="column.sortable">
                <i
                  v-if="sortDirection === 'asc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i
                  v-else-if="sortDirection === 'desc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
              </span>
              {{ column.label }}
            </template>
            <template #item-actions="item">
              <button
                class="mx-1 my-1"
                @click="showModal(item)"
                v-if="
                  $store.getters['checkUserAccess']({
                    id: '29',
                    action: 'edit',
                  })
                "
              >
                <i
                  class="fa-solid fa-pen text-center p-1 w-7 h-7 text-sm bg-blue-200 dark:bg-blue-300 rounded-md text-blue-500 mx-auto"
                >
                  {{ item.text }}
                </i>
              </button>
              <button
                class="mx-1 my-1"
                @click="deleteItem(item.cu_id)"
                v-if="
                  $store.getters['checkUserAccess']({
                    id: '29',
                    action: 'delete',
                  })
                "
              >
                <i
                  class="fa-solid fa-trash text-center p-1 w-7 h-7 text-sm bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto"
                ></i>
              </button>
              <a
                class="mx-1 my-1"
                :href="'customer-account/' + item.cu_id"
                v-if="
                  $store.getters['checkUserAccess']({
                    id: '29',
                    action: 'detail',
                  })
                "
                target="_blank"
              >
                <i
                  class="m-1 fa-solid fa-eye text-center p-1 w-7 h-7 text-sm bg-green-200 dark:bg-blue-300 rounded-md text-green-500 mx-auto"
                >
                  {{ item.text }}
                </i>
              </a>
            </template>
            <template #loading>
              <img
                src="../../assets/loading.webp"
                style="width: 70px; height: 70px"
              />
            </template>
            <template #item-total_iqd="item">
              <span
                :class="item.total_iqd > 0 ? 'text-red-500' : 'text-green-500'"
              >
                {{ formatNumber(item.total_iqd) }} {{ $t("dinar") }}
              </span>
            </template>
            <template #item-cu_image="item">
              <img
                :src="file_url + item.cu_image"
                class="inline-flex items-center justify-center text-white transition-all duration-200 ease-in-out text-size-sm h-16 w-16 rounded-xl"
                v-if="item.cu_image"
                @click="openModal(file_url + item.cu_image)"
              />
              <span v-if="item.cu_image == '' || item.cu_image == null">
                <i class="fa-solid fa-image text-primaryColor"></i>
              </span>
            </template>
            <template #item-total_usd="item">
              <span
                :class="item.total_usd > 0 ? 'text-red-500' : 'text-green-500'"
              >
                {{ formatNumber(item.total_usd) }} $
              </span>
            </template>
            <template #item-cu_email="item">
              <span
                v-if="item.cu_email == null || item.cu_email == ''"
                class="text-lg font-bold"
              >
                __
              </span>
              <span v-else>
                {{ item.cu_email }}
              </span>
            </template>
            <template #item-cu_note="item">
              <span
                v-if="item.cu_note == null || item.cu_note == ''"
                class="text-lg font-bold"
              >
                __
              </span>
              <span v-else>
                {{ item.cu_note }}
              </span>
            </template>
            <template #item-created_at="item">
              {{ formatDate(item.created_at) }} </template
            ><template #empty-message>
              <span class="text-textBlack1 dark:text-white">
                {{ $t("no_data_found") }}
              </span>
            </template>
            <template
              #pagination="{
                prevPage,
                nextPage,
                isFirstPage,
                isLastPage,
                currentPaginationNumber,
                maxPaginationNumber,
              }"
            >
              <button :disabled="isFirstPage" @click="goToPage(1)">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-angle-double-right'
                      : ' fa-angle-double-left')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button :disabled="isFirstPage" @click="prevPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-angle-right'
                      : ' fa-angle-left ')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button
                v-for="page in getVisiblePages(
                  currentPaginationNumber,
                  maxPaginationNumber
                )"
                :key="page"
                @click="goToPage(page)"
                :class="getPageButtonClasses(page, currentPaginationNumber)"
              >
                {{ page }}
              </button>

              <div class="w-5"></div>
              <button :disabled="isLastPage" @click="nextPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-angle-left'
                      : ' fa-angle-right')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button
                :disabled="isLastPage"
                @click="goToPage(maxPaginationNumber)"
              >
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-angle-double-left'
                      : ' fa-angle-double-right')
                  "
                ></i>
              </button> </template
          ></EasyDataTable>
        </div>
      </div>
    </div>
  </div>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <edit-modal ref="editModal">
    <div class="items-center">
      <form @submit="editItem">
        <BaseInput
          v-model="current.cu_name"
          :label="$t('customer_name')"
          :placeholder="$t('customer_name')"
          :rules="rules"
          :isError="
            checkRulesIsError(current.cu_name, 'edit', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.cu_name, 'edit', this.rules).errorMsg
          "
        />

        <BaseInput
          v-model="current.cu_phone"
          :label="$t('phone_number')"
          :placeholder="$t('phone_number')"
          :rules="rules"
          :isError="
            checkRulesIsError(current.cu_phone, 'edit', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.cu_phone, 'edit', this.rules).errorMsg
          "
        /><BaseInput
          v-model="current.cu_address"
          :label="$t('address')"
          :placeholder="$t('address')"
          :rules="rules"
          :isError="
            checkRulesIsError(current.cu_address, 'edit', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.cu_address, 'edit', this.rules).errorMsg
          "
        />
        <BaseInput
          v-model="current.cu_email"
          :label="$t('gmail')"
          :placeholder="$t('gmail')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.cu_email, 'edit', this.no_rule).isError
          "
          :errorMsg="
            checkRulesIsError(current.cu_email, 'edit', this.no_rule).errorMsg
          "
        />
        <DropdownInput
          v-model="current.ci_id"
          :label="$t('choose_city')"
          :placeholder="$t('choose_city')"
          :rules="rules_dropdown"
          :items="convertToListIdValueCitys"
          :isError="
            checkRulesIsError(current.ci_id, 'edit', this.rules_dropdown)
              .isError
          "
          :errorMsg="
            checkRulesIsError(current.ci_id, 'edit', this.rules_dropdown)
              .errorMsg
          "
        />
        <div>
          <label
            for="image"
            class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
          >
            {{ $t("customer_photo") }}</label
          >
          <label
            for="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          >
            <i class="fa fa-cloud-upload mx-1"></i>
            {{ cu_image == "" ? $t("choose_customer_photo") : cu_image.name }}
          </label>
          <input
            type="file"
            v-on:change="onChange"
            :placeholder="$t('choose_customer_photo')"
            id="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          />
        </div>
        <TextAreaInput
          v-model="current.cu_note"
          :label="$t('note')"
          :placeholder="$t('note')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.cu_note, 'edit', this.no_rule).isError
          "
          :errorMsg="
            checkRulesIsError(current.cu_note, 'edit', this.no_rule).errorMsg
          "
        />
        <center>
          <ButtonInput
            type="button"
            @click="editItem"
            :label="$t('edit')"
            :isLoading="isBtnEditLoading"
          />
        </center>
      </form>
    </div>
  </edit-modal>
  <image-modal ref="imageModal" />
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import { formatNumber } from "../../utils/functions";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import EditModal from "../../components/Modals/EditModal.vue";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
import { FILE_URL } from "../../constants/config";
import ImageModal from "../../components/Modals/ImageModal2.vue";
import InvoiceHeader from "../../components/Navbar/InvoiceHeader.vue";
import jsPDF from "jspdf";

import { applyPlugin } from "jspdf-autotable";
applyPlugin(jsPDF);
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ConfirmDialogue,
    ResultDialog,
    EditModal,
    TextAreaInput,
    DropdownInput,
    ImageModal,
    InvoiceHeader,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_rule: [],
      cu_name: "",
      cu_note: "",
      cu_phone: "",
      cu_address: "",
      cu_email: "",
      inputType: "text",

      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      ci_id: "",
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      file_url: FILE_URL,
      cu_image: "",
      serverItemsLength: 0,
      serverOptions: {
        page: 1,
        rowsPerPage: 5,
      },
      loading: false,
      items: [],
      isHide: false,
      selectedHeaders: [],
      columnsControl: false,
    };
  },
  mounted() {
    // First load when the component is mounted
    if (
      this.$store.getters["checkUserAccess"]({
        id: "29",
        action: "view",
      })
    ) {
      this.loadFromServer();
    }
    this.selectedHeaders = [...this.headers];
  },
  created() {
    this.$store.dispatch("sale_city/getCitys");
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.customer.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.customer.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("customer/SET_DELETE_SUCCESS", false);
      }
    },
    serverOptions: {
      handler() {
        this.loadFromServer();
      },
      deep: true,
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.customer.isShowResultDialog;
    },

    filterItems() {
      return this.$store.getters["customer/filterItems"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.customer.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.customer.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.customer.isBtnEditLoading;
    },
    headers() {
      var listHeader = [
        { text: "#", value: "cu_id", sortable: true },
        { text: this.$t("customer_name"), value: "cu_name", sortable: true },
        { text: this.$t("photo"), value: "cu_image", sortable: false },
        { text: this.$t("city"), value: "city.ci_name", sortable: true },
        { text: this.$t("phone_number"), value: "cu_phone", sortable: true },
        { text: this.$t("address"), value: "cu_address", sortable: true },
        {
          text: this.$t("debt_result_dinar"),
          value: "total_iqd",
          sortable: true,
        },
        {
          text: this.$t("debt_result_dollar"),
          value: "total_usd",
          sortable: true,
        },
        { text: this.$t("gmail"), value: "cu_email", sortable: true },
        { text: this.$t("note"), value: "cu_note", sortable: true },
        { text: this.$t("admin"), value: "users.name", sortable: true },
        { text: this.$t("created_at"), value: "created_at", sortable: true },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ];
      if (this.isHide == true) {
        listHeader.pop();
      }
      return listHeader;
    },
    showHeaders() {
   

      var temp = [];
      //cretae a for check all selectedHeaders add to temp
      for (let i = 0; i < this.selectedHeaders.length; i++) {
        for (let j = 0; j < this.headers.length; j++) {
          if (this.selectedHeaders[i].text == this.headers[j].text) {
            temp.push(this.headers[j]);
          }
        }
      }

      if (temp.length == 0) {
        temp = this.headers;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedHeaders = temp;
      }

      return temp;
    },
    convertToListIdValueCitys() {
      var citys = this.$store.state.sale_city.citys;
      var list = [];
      for (const item of citys) {
        list.push({
          id: item.ci_id,
          value: item.ci_name,
        });
      }

      return list;
    },
  },

  methods: {
    getVisiblePages(currentPage, totalPages) {
      const numVisibleButtons = 3; // Adjust this number based on your design preference
      const pages = [];

      // Display pages 1 to numVisibleButtons (e.g., 1, 2, 3)
      if (currentPage <= Math.floor(numVisibleButtons / 2) + 1) {
        for (let i = 1; i <= Math.min(totalPages, numVisibleButtons); i++) {
          pages.push(i);
        }
      }
      // Display pages around the current page (e.g., 1, ..., 10, ..., 20)
      else {
        const startPage = Math.max(
          1,
          currentPage - Math.floor(numVisibleButtons / 2)
        );
        const endPage = Math.min(totalPages, startPage + numVisibleButtons - 1);

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
      }

      return pages;
    },

    // Function to get Tailwind CSS classes for each page button
    getPageButtonClasses(page, currentPage) {
      return {
        "bg-blue-500 text-white": page === currentPage,
        "bg-gray-300 text-gray-700 hover:bg-gray-400": page !== currentPage,
        "p-2 rounded-md mx-1": true, // Adjust padding and rounding based on your design
      };
    },

    goToPage(page) {
      // Implement your logic to update the current page
      //update page
      this.serverOptions.page = page;
      this.loadFromServer();
    },
    async loadFromServer() {
      this.loading = true;

      var tempObj = { ...this.serverOptions };
      //add new field to object search
      tempObj.search = this.$store.state.customer.search;

      const res = await this.$store.dispatch("customer/getItems", tempObj);

      this.items = res.data.data;
      this.serverItemsLength = res.data.total;
      this.loading = false;
    },
    openModal(image) {
      this.$refs.imageModal.show(image);
    },
    printReportFunc() {
      this.isHide = true;
      //wait 1 second
      setTimeout(() => {
        var printContents = document.getElementById("printDev").innerHTML;

        // Replace the body's innerHTML with the print contents
        document.body.innerHTML = printContents;
        var isRtl = this.$store.getters["isRtl"];
        // Set direction based on the parameter
        if (isRtl) {
          document.body.style.direction = "rtl";
        } else {
          document.body.style.direction = "ltr";
        }

        // Print the content
        window.print();
        //reload page
      }, 100);
      //print element id invoice_print
    },
    exportData() {
      /* var blob = new Blob([Papa.unparse(this.filterItems)], {
        type: "text/csv;charset=utf-8;",
      });

      var link = document.createElement("a");

      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "filename.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);*/
      //now export to pdf
      const doc = new jsPDF();

      doc.setProperties({
        title: "My PDF Document",
        subject: "Using jsPDF in Vue.js",
        author: "Your Name",
        keywords: "pdf, vue.js, jsPDF",
      });
      doc.setFontSize(12);

      doc.autoTable({
        startY: 20, // Starting position from top (adjust as needed)
        head: [this.headers],
        body: this.filterItems.map((data) => Object.values(data)),
      });

      doc.save("table.pdf");
    },

    onChange(e) {
      this.cu_image = e.target.files[0];
      e.target.value = "";
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    getData() {
      this.$store.dispatch("customer/getItems");
    },
    async addSaleItem() {
      //check form validation
      if (this.$store.state.customer.isBtnLoading) return;

      this.isSubmited = true;
      if (
        this.checkRulesIsError(this.cu_name, "add", this.rules).isError ||
        this.checkRulesIsError(this.cu_phone, "add", this.rules).isError ||
        this.checkRulesIsError(this.cu_address, "add", this.rules).isError ||
        this.checkRulesIsError(this.ci_id, "add", this.rules_dropdown).isError
      ) {
        return;
      }
      this.$store.state.customer.isBtnLoading = true;
      var res = await this.$store.dispatch("customer/addNewItem", {
        cu_name: this.cu_name,
        cu_note: this.cu_note,
        cu_phone: this.cu_phone,
        cu_address: this.cu_address,
        cu_email: this.cu_email,
        ci_id: this.ci_id,
        cu_image: this.cu_image,
      });
      if (res.status == 200) {
        this.cu_name = "";
        this.cu_note = "";
        this.cu_phone = "";
        this.cu_address = "";
        this.cu_email = "";
        this.cu_image = "";
        this.ci_id = "";
        this.isSubmited = false;
        this.$store.state.customer.isBtnLoading = false;
        this.loadFromServer();
      }
      this.isSubmited = false;
    },
    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    async deleteItem(id) {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$t("are_you_sure_remove_this_item") + id,
        okButton: this.$t("delete"),
      });
      if (ok) {
        this.$store.commit("customer/REMOVE_ITEM", id);
        await this.$store.dispatch("customer/deleteItem", id);
        this.loadFromServer();
      }
    },
    //edit
    async showModal(item) {
      this.current = item;

      this.current.ci_id = this.current.ci_id.toString();
      await this.$refs.editModal.show();
    },
    async editItem(e) {
      e.preventDefault();
      this.isSubmitedEdit = true;
      if (
        this.checkRulesIsError(this.current.cu_name, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(this.current.cu_phone, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(this.current.cu_address, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(this.current.ci_id, "edit", this.rules_dropdown)
          .isError
      ) {
        return;
      }
      this.$store.state.customer.isBtnEditLoading = true;
      var res = await this.$store.dispatch("customer/editItem", {
        cu_id: this.current.cu_id,
        cu_name: this.current.cu_name,
        cu_note: this.current.cu_note,
        cu_phone: this.current.cu_phone,
        cu_address: this.current.cu_address,
        cu_email: this.current.cu_email,
        ci_id: this.current.ci_id,
        cu_image: this.cu_image,
      });

      this.isSubmitedEdit = false;
      this.$store.state.customer.isBtnEditLoading = false;
      this.cu_image = "";
      this.loadFromServer();
      if (res.status == 200) {
        this.$refs.editModal.hide();
      }
    },
  },
};
</script>
