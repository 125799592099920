<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("add_platforms") }}
      </p>
    </div>

    <div
      class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl"
      v-if="
        $store.getters['checkUserAccess']({
          id: '6',
          action: 'add',
        })
      "
    >
      <div class="grid md:grid-cols-2">
        <form @submit="addSaleItem">
          <BaseInput
            v-model="pl_name"
            :label="$t('platform_name')"
            :placeholder="$t('platform_name')"
            :rules="rules"
            :isError="checkRulesIsError(pl_name, 'add').isError"
            :errorMsg="checkRulesIsError(pl_name, 'add').errorMsg"
          />
          <ButtonInput
            type="button"
            @click="addSaleItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>

  <div
    :class="
      ' mx-2 mt-4 mb-5 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
    v-if="
      $store.getters['checkUserAccess']({
        id: '6',
        action: 'view',
      })
    "
  >
    <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="relative overflow-x-auto mt-4 mb-6">
        <div class="flex items-center justify-between pb-4 mx-5">
          <label for="table-search" class="sr-only">{{ $t("search") }}</label>
          <div class="relative">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <input
              type="text"
              id="table-search"
              class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :placeholder="$t('search')"
              @input="
                $store.commit('sale_platform/SET_SEARCH', $event.target.value)
              "
            />
          </div>
        </div>
        <!--in ltr change (text-right to text-left)-->
        <EasyDataTable
          :headers="headers"
          :items="filterItems"
          :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
          alternating
          :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
          :loading="isLoadingData"
          :class="isDark ? 'customize-table ' : 'light-mode-table'"
        >
          <template #default="{ column, sortDirection }">
            <span class="custom-sort-icon" v-if="column.sortable">
              <i
                v-if="sortDirection === 'asc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i
                v-else-if="sortDirection === 'desc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
            </span>
            {{ column.label }}
          </template>
          <template #item-actions="item">
            <button
              class="mx-1 my-1"
              @click="showModal(item)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '6',
                  action: 'edit',
                })
              "
            >
              <i
                class="fa-solid fa-pen text-center p-1 w-7 h-7 text-sm bg-blue-200 dark:bg-blue-300 rounded-md text-blue-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </button>
            <button
              class="mx-1 my-1"
              @click="deleteItem(item.pl_id)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '6',
                  action: 'delete',
                })
              "
            >
              <i
                class="fa-solid fa-trash text-center p-1 w-7 h-7 text-sm bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto"
              ></i>
            </button>
          </template>
          <template #loading>
            <img
              src="../../assets/loading.webp"
              style="width: 70px; height: 70px"
            />
          </template>
          <template #item-created_at="item">
            {{ formatDate(item.created_at) }} </template
          ><template #empty-message>
            <span class="text-textBlack1 dark:text-white">
              {{ $t("no_data_found") }}
            </span>
          </template>
          <template
            #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
          >
            <button :disabled="isFirstPage" @click="prevPage">
               <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-right'
                    : ' fa-angle-left ')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-left'
                    : ' fa-angle-right')
                "
              ></i>
            </button>
          </template>
        </EasyDataTable>
      </div>
    </div>
  </div>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <edit-modal ref="editModal">
    <div class="items-center">
      <form @submit="editItem">
        <BaseInput
          v-model="current.pl_name"
          :label="$t('platform_name')"
          :placeholder="$t('platform_name')"
          :rules="rules"
          :isError="checkRulesIsError(current.pl_name, 'edit').isError"
          :errorMsg="checkRulesIsError(current.pl_name, 'edit').errorMsg"
        />
        <center>
          <ButtonInput
            type="button"
            @click="editItem"
            :label="$t('edit')"
            :isLoading="isBtnEditLoading"
          />
        </center>
      </form>
    </div>
  </edit-modal>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import EditModal from "../../components/Modals/EditModal.vue";

export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ConfirmDialogue,
    ResultDialog,
    EditModal,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      pl_name: "",
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
    };
  },
  created() {
    if (
      this.$store.getters["checkUserAccess"]({
        id: "6",
        action: "view",
      })
    ) {
      this.getData();
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.sale_platform.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.sale_platform.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("sale_platform/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.sale_platform.isShowResultDialog;
    },
    items() {
      return this.$store.state.sale_platform.items;
    },
    filterItems() {
      return this.$store.getters["sale_platform/filterItems"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.sale_platform.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.sale_platform.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.sale_platform.isBtnEditLoading;
    },
    headers() {
      return [
        { text: "#", value: "pl_id", sortable: true },
        { text: this.$t("platform_name"), value: "pl_name", sortable: true },
        { text: this.$t("admin"), value: "users.name", sortable: true },
        { text: this.$t("created_at"), value: "created_at", sortable: true },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ];
    },
  },

  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    getData() {
      this.$store.dispatch("sale_platform/getItems");
    },
    async addSaleItem(e) {
      e.preventDefault();
      //check form validation
      if (this.$store.state.sale_platform.isBtnLoading) return;
     
      this.isSubmited = true;
      if (this.checkRulesIsError(this.pl_name, "add").isError) {
        return;
      }
      this.$store.state.sale_platform.isBtnLoading = true;
      var res = await this.$store.dispatch(
        "sale_platform/addNewItem",
        this.pl_name
      );
      if (res.status == 200) {
        this.pl_name = "";
        this.isSubmited = false;
        this.$store.state.sale_platform.isBtnLoading = false;
      }
    },
    checkRulesIsError(value, type) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of this.rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    async deleteItem(id) {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$t("are_you_sure_remove_this_item") + id,
        okButton: this.$t("delete"),
      });
      if (ok) {
        this.$store.commit("sale_platform/REMOVE_ITEM", id);
        this.$store.dispatch("sale_platform/deleteItem", id);
      }
    },
    //edit
    async showModal(item) {
      this.current = item;
      await this.$refs.editModal.show();
    },
    async editItem(e) {
      e.preventDefault();
      this.isSubmitedEdit = true;
      if (this.checkRulesIsError(this.current.pl_name, "edit").isError) {
        return;
      }
      this.$store.state.sale_platform.isBtnEditLoading = true;
      var res = await this.$store.dispatch("sale_platform/editItem", {
        pl_id: this.current.pl_id,
        pl_name: this.current.pl_name,
      });

      this.isSubmitedEdit = false;
      this.$store.state.sale_platform.isBtnEditLoading = false;
      if (res.status == 200) {
        this.$refs.editModal.hide();
      }
    },
  },
};
</script>
