import axios from "axios";
import { SERVER_URL } from "../constants/config";

// Function to make a GET request
export async function post(endpoint, data, obj = {}) {
  const token = localStorage.getItem("token_wise_manager");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  //auth access id
  if (obj) {
    axios.defaults.headers.common["id"] = obj["id"];
    axios.defaults.headers.common["action"] = obj["action"];
  }

  var res = await axios.post(SERVER_URL + endpoint, data);

  return res;
}
export function postFile(endpoint, data, obj = {}) {
  const token = localStorage.getItem("token_wise_manager");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }; //auth access id
  if (obj) {
    axios.defaults.headers.common["id"] = obj["id"];
    axios.defaults.headers.common["action"] = obj["action"];
  }
  return axios.post(SERVER_URL + endpoint, data, config);
}
// Function to make a POST request
export function get(endpoint, obj = {}) {
  const token = localStorage.getItem("token_wise_manager");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  //auth access id
  if (obj) {
    axios.defaults.headers.common["id"] = obj["id"];
    axios.defaults.headers.common["action"] = obj["action"];
  }
  return axios.get(SERVER_URL + endpoint);
}
