import { post } from "../../helpers/api";
import { showToast as toast } from "../../components/Modals/toast";
import { i18n } from "../../main"; // Adjust the import path accordingly

const state = {
  items: [],
  isLoadingData: true,
  isBtnLoading: false,
  isBtnEditLoading: false,
  search: "",
  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
  units: [],
};

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items;
    state.isLoadingData = false;
  },
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },

  REMOVE_ITEM(state, id) {
    state.items = state.items.filter((item) => {
      return item.un_id !== id;
    });
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  SET_DELETE_SUCCESS(state, value) {
    state.isShowResultDialog = value.state;
    state.resultMsg = value.msg;
    state.isSuccess = value.isSuccess;
  },
  SET_UNITS(state, units) {
    state.units = units;
  },
};

const actions = {
  async getUnits({ commit }) {
    try {
      post("unit/get-units", {})
        .then((response) => {
          commit("SET_UNITS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  },
  async getItems({ commit }) {
    try {
      post(
        "unit/index",
        {},
        {
          id: 2,
          action: "view",
        }
      )
        .then((response) => {
          commit("SET_ITEMS", response.data);
          commit("SET_IS_BTN_LOADING", false);
          commit("SET_IS_BTN_EDIT_LOADING", false);
        })
        .catch((error) => {
   
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  },
  async addNewItem({ commit }, payload) {
    return post(
      "unit/store",
      { un_name: payload },
      {
        id: 2,
        action: "add",
      }
    )
      .then((response) => {
        this.dispatch("item_unit/getItems");

        const t = i18n.global.t;
        toast("success", t("added_successfully"));
        return response;
      })
      .catch((error) => {
        commit("SET_IS_BTN_LOADING", false);
        const t = i18n.global.t;
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }

        toast("error", t("something_wrong"));
        console.log(error);
        return error;
      });
  },
  async deleteItem({ commit }, id) {
    post(
      "unit/delete/" + id,
      {},
      {
        id: 2,
        action: "delete",
      }
    )
      .then((response) => {
        this.dispatch("item_unit/getItems");

        const t = i18n.global.t;
        toast("success", t("deleted_successfully"));
        return response;
      })
      .catch((error) => {
        const t = i18n.global.t;
        this.dispatch("item_unit/getItems");
        commit("SET_IS_BTN_LOADING", false);
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast

          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return;
        }
        if (
          error.response.data.message.includes(
            "Cannot delete or update a parent row"
          )
        ) {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t(
              "you_can_not_delete_this_item_because_there_are_related_items"
            ),
            isSuccess: false,
          });
        } else {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t("something_wrong"),
            isSuccess: false,
          });
        }
      });
  },
  async editItem({ commit }, payload) {
    return post(
      "unit/edit/" + payload.un_id,
      { un_name: payload.un_name },
      {
        id: 2,
        action: "edit",
      }
    )
      .then((response) => {
        this.dispatch("item_unit/getItems");

        const t = i18n.global.t;
        toast("success", t("edited_successfully"));
        return response;
      })
      .catch((error) => {
        commit("SET_IS_BTN_EDIT_LOADING", false);

        const t = i18n.global.t;
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast

          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }
        toast("error", t("something_wrong"));
        console.log(error);
        return error;
      });
  },
};

const getters = {
  filterItems(state) {
    function deepSearch(obj, searchValue) {
      for (const key in obj) {
        const value = obj[key];

        if (value !== null && typeof value === "object") {
          // If the value is an object, recursively search inside it
          if (deepSearch(value, searchValue)) {
            return true; // Found a match in a nested object
          }
        } else {
          // If the value is not an object, check if it includes the search value
          if (
            value != null &&
            value.toString().toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return true; // Found a match
          }
        }
      }
      return false; // No match found in this object
    }

    return state.items.filter((item) => {
      // Search in all fields recursively
      return deepSearch(item, state.search.toLowerCase());
    });
  },
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
