<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("add_notes") }}
      </p>
    </div>

    <div
      class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl"
      v-if="
        $store.getters['checkUserAccess']({
          id: '32',
          action: 'add',
        })
      "
    >
      <div class="grid md:grid-cols-2">
        <form @submit="addSaleItem">
          <BaseInput
            v-model="no_title"
            :label="$t('note_title')"
            :placeholder="$t('note_title')"
            :rules="rules"
            :isError="checkRulesIsError(no_title, 'add').isError"
            :errorMsg="checkRulesIsError(no_title, 'add').errorMsg"
          />
          <TextAreaInput
            v-model="no_description"
            :label="$t('note_description')"
            :placeholder="$t('note_description')"
            :rules="rules"
            :isError="checkRulesIsError(no_description, 'add').isError"
            :errorMsg="checkRulesIsError(no_description, 'add').errorMsg"
          />
          <ButtonInput
            type="button"
            @click="addSaleItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>

  <div
    :class="
      ' mx-2 mt-4 mb-5 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
    v-if="
      $store.getters['checkUserAccess']({
        id: '32',
        action: 'view',
      })
    "
  >
    <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="relative overflow-x-auto mt-4 mb-6">
        <div class="flex items-center justify-between pb-4 mx-5">
          <label for="table-search" class="sr-only">{{ $t("search") }}</label>
          <div class="relative">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <input
              type="text"
              id="table-search"
              class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :placeholder="$t('search')"
              @input="
                $store.commit('note/SET_SEARCH', $event.target.value),
                  loadFromServer(),
                  (serverOptions.page = 1)
              "
            />
          </div>
        </div>
        <EasyDataTable
          v-model:server-options="serverOptions"
          :headers="headers"
          :items="items"
          :server-items-length="serverItemsLength"
          :loading="isLoadingData"
          alternating
          :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
          :class="isDark ? 'customize-table ' : 'light-mode-table'"
          :buttons-pagination="true"
          :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
          :rows-items="[25, 50, 100, serverItemsLength]"
          rows-per-page="10"
          :hide-footer="isHide"
        >
          <template #default="{ column, sortDirection }">
            <span class="custom-sort-icon" v-if="column.sortable">
              <i
                v-if="sortDirection === 'asc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i
                v-else-if="sortDirection === 'desc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
            </span>
            {{ column.label }}
          </template>
          <template #item-actions="item">
            <button
              class="mx-1  my-1"
              @click="showModal(item)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '32',
                  action: 'edit',
                })
              "
            >
              <i
                class="fa-solid fa-pen text-center p-1 w-7 h-7 text-sm bg-blue-200 dark:bg-blue-300 rounded-md text-blue-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </button>
            <button
              class="mx-1 my-1"
              @click="deleteItem(item.no_id)"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '32',
                  action: 'delete',
                })
              "
            >
              <i
                class="fa-solid fa-trash text-center p-1 w-7 h-7 text-sm bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto"
              ></i>
            </button>
          </template>
          <template #item-id="item">
            {{ $t(item.users.name) }}
          </template>

          <template #loading>
            <img
              src="../../assets/loading.webp"
              style="width: 70px; height: 70px"
            />
          </template>

          <template #item-created_at="item">
            {{ formatDate(item.created_at) }} </template
          ><template #empty-message>
            <span class="text-textBlack1 dark:text-white">
              {{ $t("no_data_found") }}
            </span>
          </template>
          <template
            #pagination="{
              prevPage,
              nextPage,
              isFirstPage,
              isLastPage,
              currentPaginationNumber,
              maxPaginationNumber,
            }"
          >
            <button :disabled="isFirstPage" @click="goToPage(1)">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-double-right'
                    : ' fa-angle-double-left')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isFirstPage" @click="prevPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-right'
                    : ' fa-angle-left ')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button
              v-for="page in getVisiblePages(
                currentPaginationNumber,
                maxPaginationNumber
              )"
              :key="page"
              @click="goToPage(page)"
              :class="getPageButtonClasses(page, currentPaginationNumber)"
            >
              {{ page }}
            </button>

            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-left'
                    : ' fa-angle-right')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button
              :disabled="isLastPage"
              @click="goToPage(maxPaginationNumber)"
            >
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-double-left'
                    : ' fa-angle-double-right')
                "
              ></i>
            </button> </template
        ></EasyDataTable>
      </div>
    </div>
  </div>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <edit-modal ref="editModal">
    <div class="items-center">
      <form @submit="editItem">
        <BaseInput
          v-model="current.no_title"
          :label="$t('note_title')"
          :placeholder="$t('note_title')"
          :rules="rules"
          :isError="checkRulesIsError(current.no_title, 'edit').isError"
          :errorMsg="checkRulesIsError(current.no_title, 'edit').errorMsg"
        />
        <TextAreaInput
          v-model="current.no_description"
          :label="$t('note_description')"
          :placeholder="$t('note_description')"
          :rules="rules"
          :isError="checkRulesIsError(current.no_description, 'edit').isError"
          :errorMsg="checkRulesIsError(current.no_description, 'edit').errorMsg"
        />
        <center>
          <ButtonInput
            type="button"
            @click="editItem"
            :label="$t('edit')"
            :isLoading="isBtnEditLoading"
          />
        </center>
      </form>
    </div>
  </edit-modal>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import EditModal from "../../components/Modals/EditModal.vue";
import jsPDF from "jspdf";
import "../../assets/font/Rabar_021-normal";
import { applyPlugin } from "jspdf-autotable";
import Papa from "papaparse";
import * as XLSX from "xlsx";

applyPlugin(jsPDF);
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ConfirmDialogue,
    ResultDialog,
    EditModal,
    TextAreaInput,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_title: "",
      no_description: "",
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      serverItemsLength: 0,
      serverOptions: {
        page: 1,
        rowsPerPage: 5,
      },
      loading: false,
      items: [],
    };
  },
  mounted() {
    // First load when the component is mounted
    if (
      this.$store.getters["checkUserAccess"]({
        id: "32",
        action: "view",
      })
    ) {
      this.loadFromServer();
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.note.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.note.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("note/SET_DELETE_SUCCESS", false);
      }
    },
    serverOptions: {
      handler() {
        this.loadFromServer();
      },
      deep: true,
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.note.isShowResultDialog;
    },

    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.note.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.note.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.note.isBtnEditLoading;
    },
    headers() {
      return [
        { text: "#", value: "no_id", sortable: true },
        { text: this.$t("note_title"), value: "no_title", sortable: true },
        {
          text: this.$t("note_description"),
          value: "no_description",
          sortable: true,
        },
        { text: this.$t("admin"), value: "users.name", sortable: true },
        { text: this.$t("created_at"), value: "created_at", sortable: true },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ];
    },
  },

  methods: {
    getVisiblePages(currentPage, totalPages) {
      const numVisibleButtons = 3; // Adjust this number based on your design preference
      const pages = [];

      // Display pages 1 to numVisibleButtons (e.g., 1, 2, 3)
      if (currentPage <= Math.floor(numVisibleButtons / 2) + 1) {
        for (let i = 1; i <= Math.min(totalPages, numVisibleButtons); i++) {
          pages.push(i);
        }
      }
      // Display pages around the current page (e.g., 1, ..., 10, ..., 20)
      else {
        const startPage = Math.max(
          1,
          currentPage - Math.floor(numVisibleButtons / 2)
        );
        const endPage = Math.min(totalPages, startPage + numVisibleButtons - 1);

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
      }

      return pages;
    },

    // Function to get Tailwind CSS classes for each page button
    getPageButtonClasses(page, currentPage) {
      return {
        "bg-blue-500 text-white": page === currentPage,
        "bg-gray-300 text-gray-700 hover:bg-gray-400": page !== currentPage,
        "p-2 rounded-md mx-1": true, // Adjust padding and rounding based on your design
      };
    },

    goToPage(page) {
      // Implement your logic to update the current page
      //update page
      this.serverOptions.page = page;
      this.loadFromServer();
    },
    async loadFromServer() {
      this.loading = true;

      var tempObj = { ...this.serverOptions };
      //add new field to object search
      tempObj.search = this.$store.state.note.search;

      const res = await this.$store.dispatch("note/getItems", tempObj);
   
      this.items = res.data.data;
      this.serverItemsLength = res.data.total;
      this.loading = false;
    },
    exportData(type) {
      if (type == "csv") {
        var blob = new Blob([Papa.unparse(this.items)], {
          type: "text/csv;charset=utf-8;",
        });

        var link = document.createElement("a");

        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "filename.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (type == "pdf") {
        //now export to pdf
        const doc = new jsPDF();
        //support arabic utf8
        doc.addFont(
          "../../assets/font/Rabar_021.ttf",
          "Rabar_021-normal",
          "normal"
        );

        //create temp header add headers.text
        var tempHeader = [];
        this.headers.forEach((element) => {
          tempHeader.push(element.text);
        });
        //utf8

        doc.autoTable({
          startY: 20, // Starting position from top (adjust as needed)
          head: [tempHeader],
          body: this.items.map((data) => Object.values(data)),
        });

        doc.save("table.pdf");
      } else {
        const ws = XLSX.utils.json_to_sheet(this.items);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        //crewate blob without s2ab
        const blob = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "filename.xlsx");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    formatDate(date) {
      return formatDate(date);
    },

    async addSaleItem() {
      if (this.$store.state.note.isBtnLoading) return;
      //check form validation
      this.isSubmited = true;
      if (
        this.checkRulesIsError(this.no_title, "add").isError ||
        this.checkRulesIsError(this.no_description, "add").isError
      ) {
        return;
      }
      this.$store.state.note.isBtnLoading = true;
      var res = await this.$store.dispatch("note/addNewItem", {
        no_title: this.no_title,
        no_description: this.no_description,
      });
      if (res.status == 200) {
        this.no_title = "";
        this.no_description = "";
        this.isSubmited = false;
        this.$store.state.note.isBtnLoading = false;
        this.loadFromServer();
      }
      this.isSubmited = false;
    },
    checkRulesIsError(value, type) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of this.rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    async deleteItem(id) {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$t("are_you_sure_remove_this_item") + id,
        okButton: this.$t("delete"),
      });
      if (ok) {
        this.$store.commit("note/REMOVE_ITEM", id);
        this.$store.dispatch("note/deleteItem", id);
        this.loadFromServer();
      }
    },
    //edit
    async showModal(item) {
      this.current = item;
      await this.$refs.editModal.show();
    },
    async editItem(e) {
      e.preventDefault();
      this.isSubmitedEdit = true;
      if (
        this.checkRulesIsError(this.current.no_title, "edit").isError ||
        this.checkRulesIsError(this.current.no_description, "edit").isError
      ) {
        return;
      }
      this.$store.state.note.isBtnEditLoading = true;
      var res = await this.$store.dispatch("note/editItem", {
        no_id: this.current.no_id,
        no_title: this.current.no_title,
        no_description: this.current.no_description,
      });

      this.isSubmitedEdit = false;
      this.$store.state.note.isBtnEditLoading = false;
      if (res.status == 200) {
        this.$refs.editModal.hide();
        this.loadFromServer();
      }
    },
  },
};
</script>
