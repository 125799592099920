<template>
  <div :class="{ 'dir-rtl ': isRtl, dark: isDark }">
    <LoadingProgress v-show="loading" />
    <router-view />
  </div>
</template>

<script>
import LoadingProgress from "./components/loading-progress.vue";
export default {
  name: "App",

  components: {
    LoadingProgress,
  },
  data() {
    return {
      isLoad: false,
    };
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang_wise_manager") == "ar" ||
        localStorage.getItem("lang_wise_manager") == "ku" ||
        //if null
        localStorage.getItem("lang_wise_manager") == null ||
        //if empty
        localStorage.getItem("lang_wise_manager") == ""
      );
    },

    isDark() {
      return localStorage.getItem("theme") == "dark";
    },
    //function to check if loading or not
    loading() {
      return this.$store.state.loading; // Access the loading state from the Vuex store
    },
  },
  created() {
    //this.isLoad = true;
  },
  mounted() {},
};
</script>

<style>
.dir-rtl {
  direction: rtl !important;
}
@media print {
  .no-print {
    display: none;
  }
 
}
</style>
