import { post } from "../../helpers/api";
import { showToast as toast } from "../../components/Modals/toast";
import { i18n } from "../../main"; // Adjust the import path accordingly
import { postFile } from "../../helpers/api";
const state = {
  isBtnLoading: false,

  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
  settings: {},
};

const mutations = {
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },
  SET_SETTINGS(state, data) {
    state.settings = data;
  },
};

const actions = {
  async getSettings({ commit }) {
    try {
      return post(
        "setting/index"
      )
        .then((response) => {
          commit("SET_SETTINGS", response.data);
          return response;
        })
        .catch((error) => {
          console.log(error);
         
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async updateSettings({ commit }, payload) {
    return postFile("setting/edit/" + payload.set_id, payload, {
      id: 35,
      action: "edit",
    })
      .then((response) => {

        const t = i18n.global.t;
        toast("success", t("edited_successfully"));

        commit("SET_IS_BTN_LOADING", false);
        return response;
      })
      .catch((error) => {
        commit("SET_IS_BTN_LOADING", false);
        const t = i18n.global.t;
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }
        

        toast("error", t("something_wrong"));
        console.log(error);
        return error;
      });
  },
};

const getters = {
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
