<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("add_item_from_csv") }}
      </p>
    </div>

    <div class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <label for="image" class="block text-red-500 mb-2 text-lg font-medium">
        {{ $t("csv_note") }}
      </label>
      <div class="">
        <form @submit="addItem">
          <div class="grid gap-x-6 mb-6 md:grid-cols-2">
            <div>
              <label
                for="image"
                class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
              >
                {{ $t("csv_file") }}</label
              >
              <label
                for="image"
                class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              >
                <i class="fa fa-cloud-upload mx-1"></i>

                {{ x_file == "" ? $t("choose_csv_file") : x_file.name }}
              </label>
              <input
                type="file"
                v-on:change="onChange"
                :placeholder="$t('choose_csv_file')"
                id="image"
                class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              />
            </div>
          </div>

          <ButtonInput
            type="button"
            @click="addItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>

  <ResultDialog ref="ResultDialog"></ResultDialog>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";

import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import { CURRENCYS } from "../../constants/config";
import * as XLSX from "xlsx";

export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,

    ButtonInput,
    ResultDialog,
  },
  data() {
    return {
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_rule: [],
      isSubmited: false,
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      currencys: CURRENCYS,
      ca_id: "",
      un_id: "",
      i_desc: "",
      i_name: "",

      i_expire_date: "",
      x_file: "",
      i_quantity: "",
      i_id: "",
      i_sale_price: "",
      cur_id: "",
    };
  },
  created() {
    this.$store.dispatch("item_category/getCategory");
    this.$store.dispatch("item_unit/getUnits");
  },

  computed: {
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isBtnLoading() {
      return this.$store.state.add_item.isBtnLoading;
    },
    convertToListIdValue() {
      var list = [];
      for (const item of this.currencys) {
        list.push({
          id: item.cur_id,
          value: this.$t(item.cur_name),
        });
      }

      return list;
    },
    convertToListIdValueUnits() {
      var boxes = this.$store.state.item_unit.units;
      var list = [];
      for (const item of boxes) {
        list.push({
          id: item.un_id,
          value: item.un_name,
        });
      }

      return list;
    },

    convertToListIdValueCategory() {
      var categorys = this.$store.state.item_category.categorys;
      var list = [];
      for (const item of categorys) {
        list.push({
          id: item.ca_id,
          value: item.ca_name,
        });
      }

      return list;
    },
  },

  methods: {
    formatDate(date) {
      return formatDate(date);
    },

    async addItem() {
      if (this.$store.state.add_item.isBtnLoading) return;

      this.isSubmited = true;
      if (this.checkRulesIsError(this.x_file, "add", this.rules).isError) {
        return;
      }

      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);

        // Explicitly specify UTF-8 encoding when reading the file
        const text = new TextDecoder("utf-8").decode(data);

        const workbook = XLSX.read(text, { type: "string", encoding: "utf-8" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Extract data from the sheet and send it to the backend
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  

        this.$store.state.add_item.isBtnLoading = true;

        try {
          // Specify UTF-8 encoding when sending data to the backend
          const res = await this.$store.dispatch("add_item/addCsvFile", {
            json: jsonData,
          });

          if (res.status === 200) {
            this.x_file = "";
          }
        } catch (error) {
          console.error("Error sending data to the backend:", error);
        }
      };

      // Specify UTF-8 encoding when reading the file
      reader.readAsArrayBuffer(this.x_file);
      this.isSubmited = false;
    },

    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    onChange(e) {
      this.x_file = e.target.files[0];
      e.target.value = "";
    },
  },
};
</script>
<style>
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(40%) sepia(96%) saturate(694%) hue-rotate(195deg)
    brightness(85%) contrast(88%);
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>
