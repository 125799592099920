<template>
    <popup-modal ref="popup">
      <!-- Main modal -->
      <div
        class="relative mx-auto shadow-lg rounded-md bg-white dark:bg-gray-800 overflow-y-auto"
        style="max-height: 95vh"
      >
        <div class="mt-3">
          <!-- Modal content -->
  
          <button
            type="button"
            class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm  ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="editModal"
            @click="_cancel"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
          <div class="h-2"></div>
          <slot></slot>
        </div>
      </div>
    </popup-modal>
  </template>
  <script>
  import PopupModal from "./PopupModal.vue";
  
  export default {
    name: "ConfirmDialogue",
  
    components: { PopupModal },
  
    data: () => ({
      // Parameters that change depending on the type of dialogue
      title: undefined,
      message: undefined, // Main text content
      okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
      cancelButton: "پاشگەزبوونەوە", // text for cancel button
  
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined,
    }),
  
    methods: {
      show(opts = {}) {
        this.title = opts.title;
        this.message = opts.message;
        this.okButton = opts.okButton;
        if (opts.cancelButton) {
          this.cancelButton = opts.cancelButton;
        }
        // Once we set our config, we tell the popup modal to open
        this.$refs.popup.open();
        // Return promise so the caller can get results
        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve;
          this.rejectPromise = reject;
        });
      },
      hide() {
        this.$refs.popup.close();
      },
  
      _confirm() {
        this.$refs.popup.close();
        this.resolvePromise(true);
      },
  
      _cancel() {
        this.$refs.popup.close();
        this.resolvePromise(false);
        // Or you can throw an error
        // this.rejectPromise(new Error('User canceled the dialogue'))
      },
    },
  };
  </script>
  