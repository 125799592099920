import { post } from "../../helpers/api";

const state = {
  items: [],
  total: {},
  isLoadingData: true,
  isBtnLoading: false,
  isBtnEditLoading: false,
  search: "",
  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
};

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items[0];
    state.total = items[1];
    state.isLoadingData = false;
  },
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },

  REMOVE_ITEM(state, id) {
    state.items = state.items.filter((item) => {
      return item.no_id !== id;
    });
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  SET_DELETE_SUCCESS(state, value) {
    state.isShowResultDialog = value.state;
    state.resultMsg = value.msg;
    state.isSuccess = value.isSuccess;
  },
};

const actions = {
  async getItems({ commit }, payload) {
    try {
      return post("purchase-item/get-report", payload, {
        id: 26,
        action: "view",
      })
        .then((response) => {
       
          commit("SET_ITEMS", response.data);
          commit("SET_IS_BTN_LOADING", false);
          commit("SET_IS_BTN_EDIT_LOADING", false);
          return response.data;
        })
        .catch((error) => {
          if (error.response.status == 408) {
            window.location.href = "/404";
          }
          return error;
        });
    } catch (error) {
      console.log(error);
    }
  },
};

const getters = {
  filterItems(state) {
    function deepSearch(obj, searchValue) {
      for (const key in obj) {
        const value = obj[key];

        if (value !== null && typeof value === "object") {
          // If the value is an object, recursively search inside it
          if (deepSearch(value, searchValue)) {
            return true; // Found a match in a nested object
          }
        } else {
          // If the value is not an object, check if it includes the search value
          if (
            value != null &&
            value.toString().toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return true; // Found a match
          }
        }
      }
      return false; // No match found in this object
    }

    return state.items.filter((item) => {
      // Search in all fields recursively
      return deepSearch(item, state.search.toLowerCase());
    });
  },
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
