import { post } from "../../helpers/api";
import { postFile } from "../../helpers/api";
import { showToast as toast } from "../../components/Modals/toast";
import { i18n } from "../../main"; // Adjust the import path accordingly

const state = {
  items: [],
  isLoadingData: true,
  isBtnLoading: false,
  isBtnEditLoading: false,
  search: "",
  isShowResultDialog: false,
  resultMsg: "",
  isSuccess: false,
  isShowDebtDetail: false,
  debtDetail: {},
};

const mutations = {
  SET_ITEMS(state, items) {
    var tempList = [];
    const t = i18n.global.t;
    //loop through the items set every deb_type to new value by checking the value of the deb_type
    items.forEach((element) => {
      if (element.deb_type == 0) {
        element.deb_type = t("borrow");
      } else if (element.deb_type == 1) {
        element.deb_type = t("lend");
      } else if (element.deb_type == 2) {
        element.deb_type = t("purchase_due");
      } else if (element.deb_type == 3) {
        element.deb_type = t("sale_due");
      } else if (element.deb_type == 4) {
        element.deb_type = t("return_to_suplier_by_due");
      }
      tempList.push(element);
    });
    state.items = items;
    state.isLoadingData = false;
  },
  SET_IS_BTN_LOADING(state, isBtnLoading) {
    state.isBtnLoading = isBtnLoading;
  },

  REMOVE_ITEM(state, id) {
    state.items = state.items.filter((item) => {
      return item.deb_id !== id;
    });
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  SET_DELETE_SUCCESS(state, value) {
    state.isShowResultDialog = value.state;
    state.resultMsg = value.msg;
    state.isSuccess = value.isSuccess;
  },
  SET_DEBT_DETAIL(state, value) {
    state.debtDetail = value;
  },
  SET_IS_SHOW_DEBT_DETAIL(state, isShowDebtDetail) {
    state.isShowDebtDetail = isShowDebtDetail;
  },
  SET_IS_LOADING_DATA(state, isLoadingData) {
    state.isLoadingData = isLoadingData;
  },
};

const actions = {
  async showDebt({ commit }, id) {
    try {
      return post(
        "debt/show/" + id,
        {},
        {
          id: 21,
          action: "detail",
        }
      )
        .then((response) => {
          commit("SET_DEBT_DETAIL", response.data);
          commit("SET_IS_SHOW_DEBT_DETAIL", true);
          return response;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 408) {
            window.location.href = "/404";
          }
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async getItems({ commit }, payload) {
    commit("SET_IS_LOADING_DATA", true);
    try {
      return post("debt/index?page=" + payload.page, payload, {
        id: 21,
        action: "view",
      })
        .then((response) => {
          commit("SET_IS_BTN_LOADING", false);
          commit("SET_IS_BTN_EDIT_LOADING", false);
          commit("SET_IS_LOADING_DATA", false);

          return response;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 408) {
            window.location.href = "/404";
          }
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async getDebtCategory({ commit }) {
    try {
      post("debt/get-categorys", {})
        .then((response) => {
          commit("SET_DEBT_CATEGORY", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  },

  async deleteItem({ commit }, id) {
    return post(
      "debt/delete/" + id,
      {},
      {
        id: 21,
        action: "delete",
      }
    )
      .then((response) => {
        this.dispatch("debt_list/getItems");

        const t = i18n.global.t;
        toast("success", t("deleted_successfully"));
        return response;
      })
      .catch((error) => {
        const t = i18n.global.t;
        this.dispatch("debt_list/getItems");
        commit("SET_IS_BTN_LOADING", false);
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }
        if (
          error.response.data.message.includes(
            "Cannot delete or update a parent row"
          )
        ) {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t(
              "you_can_not_delete_this_item_because_there_are_related_items"
            ),
            isSuccess: false,
          });
        } else {
          commit("SET_DELETE_SUCCESS", {
            state: true,
            msg: t("something_wrong"),
            isSuccess: false,
          });
        }
        return error;
      });
  },
  async editItem({ commit }, payload) {
    return postFile("debt/edit/" + payload.deb_id, payload, {
      id: 21,
      action: "edit",
    })
      .then((response) => {
        this.dispatch("debt_list/getItems");

        const t = i18n.global.t;
        toast("success", t("edited_successfully"));
        return response;
      })
      .catch((error) => {
        commit("SET_IS_BTN_EDIT_LOADING", false);

        const t = i18n.global.t;
        if (error.response.status == 408) {
          //you don't have permission to add new item message in toast
          toast("error", t("you_dont_have_permission_to_do_this_action"));
          return error;
        }
        toast("error", t("something_wrong"));
        console.log(error);
        return error;
      });
  },
};

const getters = {
  filterItems(state) {
    function deepSearch(obj, searchValue) {
      for (const key in obj) {
        const value = obj[key];

        if (value !== null && typeof value === "object") {
          // If the value is an object, recursively search inside it
          if (deepSearch(value, searchValue)) {
            return true; // Found a match in a nested object
          }
        } else {
          // If the value is not an object, check if it includes the search value
          if (
            value != null &&
            value.toString().toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return true; // Found a match
          }
        }
      }
      return false; // No match found in this object
    }

    return state.items.filter((item) => {
      // Search in all fields recursively
      return deepSearch(item, state.search.toLowerCase());
    });
  },
  getResultDialog: (state) => state.isShowResultDialog,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
