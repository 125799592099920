<template>
  <HeaderApp />
  <div class="mt-14"></div>

  <div
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      (isHide == true
        ? '  '
        : $store.getters['isRtl']
        ? ' md:mr-60 '
        : ' md:ml-60  ')
    "
    id="printDev"
  >
    <InvoiceHeader invoice_type="shortage_items_list" v-if="isHide == true" />
    <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
      {{ this.$t("shortage_items_list") }}
    </p>
    <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="relative overflow-x-auto mt-4 mb-6">
        <div class="flex items-center justify-between pb-4 mx-5 no-print">
          <label for="table-search" class="sr-only">{{
            this.$t("search")
          }}</label>
          <div class="relative">
            <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <!--in ltr change (pr-10 to pl-10)-->
            <input
              type="text"
              id="table-search"
              class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              :placeholder="$t('search')"
              @input="
                $store.commit('shortage_item/SET_SEARCH', $event.target.value),
                  loadFromServer(),
                  (serverOptions.page = 1)
              "
            />
          </div>
          <button
            class="mx-2 my_font bg-primaryColor hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
            @click="printReportFunc"
            id="button_print"
            v-if="$store.getters['checkPrintAccess']"
          >
            {{ $t("print") }}
          </button>
        </div>

        <EasyDataTable
          v-model:server-options="serverOptions"
          :headers="headers"
          :items="items"
          :server-items-length="serverItemsLength"
          :loading="isLoadingData"
          alternating
          :class="isDark ? 'customize-table ' : 'light-mode-table'"
          :buttons-pagination="true"
          :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
          :hide-footer="isHide"
          :rows-items="[25, 50, 100, serverItemsLength]"
          rows-per-page="10"
        >
          <template #default="{ column, sortDirection }">
            <span class="custom-sort-icon" v-if="column.sortable">
              <i
                v-if="sortDirection === 'asc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i
                v-else-if="sortDirection === 'desc'"
                class="fa-solid fa-arrow-up-wide-short"
              ></i>
              <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
            </span>
            {{ column.label }}
          </template>
          <template #item-actions="item">
            <a
              class="mx-1 my-1"
              :href="'item-detail/' + item.i_id"
              target="_blank"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '8',
                  action: 'detail',
                })
              "
            >
              <i
                class="m-1 fa-solid fa-eye text-center p-1 w-7 h-7 text-sm bg-green-200 dark:bg-blue-300 rounded-md text-green-500 mx-auto"
              >
                {{ item.text }}
              </i>
            </a> </template
          ><template #item-currency_cur_name="item">
            {{ $t(item.currency.cur_name) }} </template
          ><template #item-i_image="item">
            <img
              :src="file_url + item.i_image"
              class="inline-flex items-center justify-center ml-4 text-white transition-all duration-200 ease-in-out text-size-sm h-16 w-16 rounded-xl"
              alt="xd"
              v-if="item.i_image"
              @click="openModal(file_url + item.i_image)"
            />
            <span v-if="item.i_image == '' || item.i_image == null">
              <i class="fa-solid fa-image text-primaryColor"></i>
            </span> </template
          ><template #item-i_quantity="item">
            <span
              class="bg-warning text-white px-3 py-1 rounded"
              v-show="item.i_quantity != 0"
            >
              {{ item.i_quantity }}</span
            ><span
              class="bg-red-500 text-white px-3 py-1 rounded"
              v-show="item.i_quantity == 0"
            >
              {{ this.$t("item_not_available") }}</span
            >
          </template>
          <template #item-i_desc="item">
            <span
              v-if="item.i_desc == null || item.i_desc == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.i_desc }}
            </span>
          </template>
          <template #item-i_expire_date="item">
            <span
              v-if="item.i_expire_date == null || item.i_expire_date == ''"
              class="text-lg font-bold"
            >
              __
            </span>
            <span v-else>
              {{ item.i_expire_date }}
            </span>
          </template>
          <template #loading>
            <img
              src="../../assets/loading.webp"
              style="width: 70px; height: 70px"
            />
          </template>
          <template #item-created_at="item">
            {{ formatDate(item.created_at) }} </template
          ><template #empty-message>
            <span class="text-textBlack1 dark:text-white">
              {{ $t("no_data_found") }}
            </span>
          </template>
             <template
            #pagination="{
              prevPage,
              nextPage,
              isFirstPage,
              isLastPage,
              currentPaginationNumber,
              maxPaginationNumber,
            }"
          >
            <button :disabled="isFirstPage" @click="goToPage(1)">
             
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-double-right'
                    : ' fa-angle-double-left')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button :disabled="isFirstPage" @click="prevPage">
               <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-right'
                    : ' fa-angle-left ')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button
              v-for="page in getVisiblePages(
                currentPaginationNumber,
                maxPaginationNumber
              )"
              :key="page"
              @click="goToPage(page)"
              :class="getPageButtonClasses(page, currentPaginationNumber)"
            >
              {{ page }}
            </button>

            <div class="w-5"></div>
            <button :disabled="isLastPage" @click="nextPage">
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-left'
                    : ' fa-angle-right')
                "
              ></i>
            </button>
            <div class="w-5"></div>
            <button
              :disabled="isLastPage"
              @click="goToPage(maxPaginationNumber)"
            >
              <i
                :class="
                  'fa-sharp fa-solid ' +
                  ($store.getters['isRtl']
                    ? ' fa-angle-double-left'
                    : ' fa-angle-double-right')
                "
              ></i
              >
            </button> </template
        ></EasyDataTable>
      </div>
    </div>
  </div>
  <image-modal ref="imageModal" />
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import { FILE_URL } from "../../constants/config";
import { formatDate } from "../../utils/functions";
import ImageModal from "../../components/Modals/ImageModal2.vue";
import InvoiceHeader from "../../components/Navbar/InvoiceHeader.vue";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    ImageModal,
    InvoiceHeader,
  },
  data() {
    return {
      isSubmited: false,
      isSubmitedEdit: false,
      file_url: FILE_URL,
      current: {},
      serverItemsLength: 0,
      serverOptions: {
        page: 1,
        rowsPerPage: 5,
      },
      loading: false,
      items: [],
      isHide: false,
    };
  },
  mounted() {
    // First load when the component is mounted
    this.loadFromServer();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.note.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.note.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("note/SET_DELETE_SUCCESS", false);
      }
    },
    serverOptions: {
      handler() {
        this.loadFromServer();
      },
      deep: true,
    },
  },
  computed: {
    filterItems() {
      return this.$store.getters["shortage_item/filterItems"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.shortage_item.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.shortage_item.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.shortage_item.isBtnEditLoading;
    },
    headers() {
      var listHeader = [
        { text: this.$t("actions"), value: "actions", sortable: false },
        { text: this.$t("item_barcode"), value: "i_id", sortable: true },
        { text: this.$t("item_name"), value: "i_name", sortable: true },
        {
          text: this.$t("photo"),
          value: "i_image",
          sortable: true,
        },
        {
          text: this.$t("item_category"),
          value: "category.ca_name",
          sortable: true,
        },

        {
          text: this.$t("item_quantity_in_storage"),
          value: "i_quantity",
          sortable: true,
        },
        {
          text: this.$t("item_bought_quantity_till_now"),
          value: "i_in_quantity",
          sortable: true,
        },
        {
          text: this.$t("item_sold_quantity_till_now"),
          value: "i_out_quantity",
          sortable: true,
        },

        {
          text: this.$t("item_expire_date"),
          value: "i_expire_date",
          sortable: true,
        },
        { text: this.$t("note"), value: "i_desc", sortable: true },
        { text: this.$t("admin"), value: "users.name", sortable: true },
        { text: this.$t("created_at"), value: "created_at", sortable: true },
      ];
      //remove created_at,id,actions if isHide = true
      if (this.isHide == true) {
        listHeader = listHeader.filter(
          (item) =>
            item.value != "created_at" &&
            item.value != "actions" &&
            item.value != "users.name"
        );
      }
      return listHeader;
    },
  },

  methods: {
    printReportFunc() {
      this.isHide = true;
      //wait 1 second
      setTimeout(() => {
        var printContents = document.getElementById("printDev").innerHTML;

        // Replace the body's innerHTML with the print contents
        document.body.innerHTML = printContents;
        var isRtl = this.$store.getters["isRtl"];
        // Set direction based on the parameter
        if (isRtl) {
          document.body.style.direction = "rtl";
        } else {
          document.body.style.direction = "ltr";
        }

        // Print the content
        window.print();
        //reload page
      }, 100);
      //print element id invoice_print
    },
    getVisiblePages(currentPage, totalPages) {
      const numVisibleButtons = 3; // Adjust this number based on your design preference
      const pages = [];

      // Display pages 1 to numVisibleButtons (e.g., 1, 2, 3)
      if (currentPage <= Math.floor(numVisibleButtons / 2) + 1) {
        for (let i = 1; i <= Math.min(totalPages, numVisibleButtons); i++) {
          pages.push(i);
        }
      }
      // Display pages around the current page (e.g., 1, ..., 10, ..., 20)
      else {
        const startPage = Math.max(
          1,
          currentPage - Math.floor(numVisibleButtons / 2)
        );
        const endPage = Math.min(totalPages, startPage + numVisibleButtons - 1);

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
      }

      return pages;
    },

    // Function to get Tailwind CSS classes for each page button
    getPageButtonClasses(page, currentPage) {
      return {
        "bg-blue-500 text-white": page === currentPage,
        "bg-gray-300 text-gray-700 hover:bg-gray-400": page !== currentPage,
        "p-2 rounded-md mx-1": true, // Adjust padding and rounding based on your design
      };
    },

    goToPage(page) {
      // Implement your logic to update the current page
      //update page
      this.serverOptions.page = page;
      this.loadFromServer();
    },
    async loadFromServer() {
      this.loading = true;

      var tempObj = { ...this.serverOptions };
      //add new field to object search
      tempObj.search = this.$store.state.shortage_item.search;

      const res = await this.$store.dispatch("shortage_item/getItems", tempObj);

      this.items = res.data.data;
      this.serverItemsLength = res.data.total;
      this.loading = false;
    },
    openModal(image) {
      this.$refs.imageModal.show(image);
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
};
</script>
