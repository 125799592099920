const state = {
  user: {
    name: "John Doe",
  },
};

const mutations = {};

const actions = {
  addOrder({ commit }, order) {
    commit("ADD_ORDER", order);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
