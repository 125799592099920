<template>
  <HeaderApp /> <LoadingProgress v-if="items == null" />
  <div
    id="printDev"
    class="sm:mr-60 mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl"
    v-else
  >
    <InvoiceHeader
      invoice_type="suplier_account_detail"
      v-if="isHide == true"
    />
    <div class="flex lg:flex-row flex-col mb-4 justify-between">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite">
        {{ $t("item_detail") }}
      </p>
      <div class="w-10"></div>
      <p
        class="font-semibold text-xl text-textBlack dark:text-textWhite"
        v-if="isHide == true"
      >
        {{ startDate }} - {{ endDate }}
      </p>
      <div class="flex lg:flex-row flex-col no-print">
        <div class="lg:flex">
          <div class="md:mx-2 mt-4 lg:mt-0">
            <label
              for="boxType"
              class="block mb-2 text-sm text-textBlack1 dark:text-textWhite1"
              >{{ $t("sale_type") }}
            </label>
            <select
              id="boxType"
              class="bg-white border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              v-model="invoiceType"
              @change="getData"
            >
              <option value="1" selected>{{ $t("sale_tak") }}</option>
              <option value="2">{{ $t("sale_jwmla") }}</option>
              <option value="0">{{ $t("all") }}</option>
            </select>
          </div>
        </div>
        <div class="flex lg:flex-row flex-col">
          <div class="md:mx-2 my-1 md:my-0">
            <label
              for="debtDate"
              class="block mb-2 text-sm font-medium text-textBlack1 dark:text-textWhite1"
              >{{ $t("start_date") }}
            </label>
            <input
              type="date"
              id="debtDate"
              class="bg-white border text-sm border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              v-model="startDate"
              @change="getData"
            />
          </div>

          <div class="md:mx-2 my-1 md:my-0">
            <label
              for="debtDate"
              class="block mb-2 text-sm font-medium text-textBlack1 dark:text-textWhite1"
              >{{ $t("end_date") }}</label
            >
            <input
              type="date"
              id="debtDate"
              class="bg-white border text-sm border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/6 xl:w-40 lg:w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              v-model="endDate"
              @change="getData"
            />
          </div>

          <div class="md:mx-2 my-1 md:my-0">
            <label
              for="boxType"
              class="block mb-2 text-sm text-textBlack1 dark:text-textWhite1"
            >
              {{ $t("choose_period") }}
            </label>
            <select
              id="countries"
              class="bg-white border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block xl:w-40 lg:w-36 w-4/6 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              v-model="period"
              @change="periodChanged(period)"
            >
              <option value="today" class="my_font">{{ $t("today") }}</option>
              <option value="yesterday" class="my_font">
                {{ $t("yesterday") }}
              </option>
              <option value="last7days" class="my_font">
                {{ $t("last7days") }}
              </option>
              <option value="last30days" class="my_font">
                {{ $t("last30days") }}
              </option>
              <option value="thismonth" class="my_font">
                {{ $t("thismonth") }}
              </option>
              <option value="lastmonth" class="my_font">
                {{ $t("lastmonth") }}
              </option>
              <option value="lastyear" class="my_font">
                {{ $t("lastyear") }}
              </option>
              <option value="thisyear" class="my_font">
                {{ $t("thisyear") }}
              </option>
              <option value="all" class="my_font">{{ $t("all_time") }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:flex w-full mb-12">
      <div class="w-full lg:w-2/5 h-full py-5">
        <img
          :src="file_url + item.item.i_image"
          alt=""
          class="rounded-lg h-96"
          v-if="item.item.i_image != '' && item.item.i_image != null"
        />
        <img
          src="../../assets/image/image_not_found.png"
          alt=""
          class="rounded-lg"
          v-if="item.item.i_image == '' || item.item.i_image == null"
        />
      </div>

      <div class="flex-col py-2 px-4 w-full lg:w-3/5 h-full">
        <p class="text-textBlack1 dark:text-textWhite1 text-3xl font-semibold">
          {{ item.item.i_name }}
        </p>
        <p class="text-textBlack3 dark:text-textWhite3 text-base pt-4">
          {{ item.item.i_desc }}
        </p>

        <div
          class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 gap-x-4 gap-y-2"
        >
          <div
            class="flex px-4 py-2 bg-gray-200 items-center rounded-lg w-36 md:w-44 h-20"
          >
            <i class="fa-solid fa-building text-2xl text-gray-500"></i>
            <div class="w-2 md:w-3"></div>
            <div class="flex-col text-sm md:text-base">
              <p class="text-gray-600 font-medium">{{ $t("last_suplier") }}</p>
              <p
                class="text-gray-600 font-semibold"
                v-if="item.item.suplier != null"
              >
                {{ item.item.suplier.su_name }}
              </p>
            </div>
          </div>

          <div
            class="flex px-4 py-2 bg-blue-100 items-center rounded-lg w-36 md:w-44 h-20"
          >
            <i class="fa-solid fa-user text-2xl text-blue-500"></i>
            <div class="w-2 md:w-3"></div>
            <div class="flex-col text-sm md:text-base">
              <p class="text-blue-500 font-medium">{{ $t("bought_by") }}</p>
              <p class="text-blue-500 font-semibold">
                {{ item.item.users.name }}
              </p>
            </div>
          </div>

          <div
            class="flex px-4 py-2 bg-pink-200 items-center rounded-lg w-36 md:w-44 h-20"
          >
            <i class="fa-solid fa-tag text-2xl text-pink-700"></i>
            <div class="w-3"></div>
            <div class="flex-col text-sm md:text-base">
              <p class="text-pink-700 font-medium">{{ $t("item_category") }}</p>
              <p class="text-pink-700 font-semibold">
                {{ item.item.category.ca_name }}
              </p>
            </div>
          </div>

          <div
            class="flex px-4 py-2 bg-red-200 items-center rounded-lg w-36 md:w-44 h-20"
          >
            <i class="fa-solid fa-barcode text-2xl text-red-700"></i>
            <div class="w-2 md:w-3"></div>
            <div class="flex-col text-sm md:text-base">
              <p class="text-red-700 font-medium">{{ $t("barcode") }}</p>
              <p class="text-red-700 font-semibold">{{ item.item.i_id }}</p>
            </div>
          </div>

          <div
            class="flex px-4 py-2 bg-orange-100 items-center rounded-lg w-36 md:w-44 h-20"
          >
            <i class="fa-solid fa-sack-dollar text-2xl text-orange-500"></i>
            <div class="w-3"></div>
            <div class="flex-col text-sm md:text-base">
              <p class="text-orange-500 font-medium">{{ $t("last_cost") }}</p>
              <p class="text-orange-500 font-semibold">
                {{ item.item.i_cost_price }}
                {{
                  filterItems[0] != null
                    ? $t(filterItems[0].currency.cur_name) != null
                      ? $t(filterItems[0].currency.cur_name)
                      : ""
                    : ""
                }}
              </p>
            </div>
          </div>

          <div
            class="flex px-4 py-2 bg-green-200 items-center rounded-lg w-36 md:w-44 h-20"
          >
            <i
              class="fa-solid fa-money-check-dollar text-2xl text-green-700"
            ></i>
            <div class="w-3"></div>
            <div class="flex-col text-sm md:text-base">
              <p class="text-green-700 font-medium">{{ $t("sale_price") }}</p>
              <p class="text-green-700 font-semibold">
                {{ item.item.i_sale_price }}
                {{
                  $t(
                    item.item.currency != null
                      ? item.item.currency.cur_name
                      : ""
                  )
                }}
              </p>
            </div>
          </div>

          <div
            class="flex px-4 py-2 bg-indigo-200 items-center rounded-lg w-36 md:w-44 h-20"
          >
            <i class="fa-solid fa-dollar text-2xl text-indigo-500"></i>
            <div class="w-3"></div>
            <div class="flex-col text-sm md:text-base">
              <p class="text-indigo-500 font-medium">
                {{ $t("sale_jwmla_price") }}
              </p>
              <p class="text-indigo-500 font-semibold">
                {{ item.item.i_sale_price_jwmla }}
                {{
                  $t(
                    item.item.currency != null
                      ? item.item.currency.cur_name
                      : ""
                  )
                }}
              </p>
            </div>
          </div>

          <div
            class="flex px-4 py-2 bg-purple-200 items-center rounded-lg w-36 md:w-44 h-20"
          >
            <i class="fa-solid fa-weight-scale text-2xl text-purple-500"></i>
            <div class="w-3"></div>
            <div class="flex-col text-sm md:text-base">
              <p class="text-purple-600 font-medium">{{ $t("unit") }}</p>
              <p class="text-purple-600 font-semibold">
                {{ item.item.unit.un_name }}
              </p>
            </div>
          </div>
          <div
            class="flex px-4 py-2 bg-green-200 items-center rounded-lg w-36 md:w-44 h-20"
          >
            <i class="fa-solid fa-dollar text-2xl text-green-500"></i>
            <div class="w-3"></div>
            <div class="flex-col text-sm md:text-sm">
              <p class="text-green-700 font-medium">
                {{ $t("dollar_price_in_bought_item_date") }}
              </p>
              <p class="text-green-700 font-semibold">
                {{ item.item.i_main_currency_price }} {{ $t("dinar") }}
              </p>
            </div>
          </div>

          <div
            class="flex px-4 py-2 bg-red-200 items-center rounded-lg w-36 md:w-44 h-20"
          >
            <i class="fa-regular fa-calendar text-2xl text-red-700"></i>
            <div class="w-3"></div>
            <div class="flex-col text-sm md:text-base">
              <p class="text-red-700 font-medium">{{ $t("expire_date") }}</p>
              <p class="text-red-700 font-semibold">
                {{
                  item.item.i_expire_date != null
                    ? item.item.i_expire_date.toString().slice(0, 10)
                    : ""
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="mx-2 my_font bg-primaryColor hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
      @click="printReportFunc"
      id="button_print"
    >
      {{ $t("print") }}
    </button>
    <button
      class="mx-2 my_font bg-green-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
      @click="showModal()"
      id="button_print"
    >
      {{ $t("edit") }}
    </button>
    <div v-if="isHide">
      <br />
      <br />
      <br /> <br />
      <br />
      <br />
    </div>
    <p class="font-semibold text-xl text-textBlack dark:text-textWhite mt-12">
      {{ $t("sale_detail") }}
    </p>

    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-4 mb-6 gap-x-4 gap-y-8"
    >
      <div
        class="bg-gray-700 bg-gradient-to-br from-gray-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <p class="text-white text-2xl">{{ item.item.i_in_quantity }}</p>
          <i class="fa-solid fa-sack-dollar text-2xl text-white"></i>
        </div>
        <p class="text-gray-300 text-sm py-1">
          {{ $t("item_bought_quantity_till_now") }}
        </p>
      </div>

      <div
        class="bg-purple-900 bg-gradient-to-br from-purple-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <p class="text-white text-2xl">{{ item.item.i_out_quantity }}</p>
          <i class="fa-solid fa-money-bill-trend-up text-2xl text-white"></i>
        </div>
        <p class="text-gray-300 text-sm py-1">
          {{ $t("item_sold_quantity_till_now") }}
        </p>
      </div>

      <div
        class="bg-blue-900 bg-gradient-to-br from-primaryColor to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <p class="text-white text-2xl">{{ item.item.i_quantity }}</p>
          <i class="fa-solid fa-tag text-2xl text-white"></i>
        </div>

        <p class="text-gray-300 text-sm py-1">
          {{ $t("item_quantity_in_storage") }}
        </p>
      </div>
      <div
        class="bg-red-900 bg-gradient-to-br from-red-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <p class="text-white text-2xl">
            {{
              formatNumber(
                item.result_wasted.length > 0
                  ? item.result_wasted[0].total_waste_quantity
                  : 0
              )
            }}
          </p>
          <i class="fa-solid fa-arrow-trend-down text-2xl text-white"></i>
        </div>

        <p class="text-gray-300 text-sm py-1">
          {{ $t("total_wasted_quantity_in_this_period") }}
        </p>
      </div>
      <div
        class="bg-red-600 bg-gradient-to-br from-red-300 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <div class="">
            <p class="text-white text-2xl">
              {{
                formatNumber(
                  item.result_wasted.length > 0
                    ? item.result_wasted[0].total_cost_price_dollar
                    : 0
                )
              }}
              <i class="fa-solid fa-dollar-sign text-white text-xl"></i>
            </p>
            <p class="text-white text-2xl">
              {{
                formatNumber(
                  item.result_wasted.length > 0
                    ? item.result_wasted[0].total_cost_price_dinar
                    : 0
                )
              }}
              {{ $t("dinar") }}
            </p>
          </div>
          <i class="fa-solid fa-arrow-trend-down text-2xl text-white"></i>
        </div>
        <p class="text-gray-300 text-sm py-1">
          {{ $t("total_loss_of_wasted_item_in_this_period") }}
        </p>
      </div>

      <div
        class="bg-red-900 bg-gradient-to-br from-red-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <div class="">
            <p class="text-white text-2xl">
              {{ formatNumber(item.cost["2"]) }}
              <i class="fa-solid fa-dollar-sign text-white text-xl"></i>
            </p>
            <p class="text-white text-2xl">
              {{ formatNumber(item.cost["1"]) }}
              {{ $t("dinar") }}
            </p>
          </div>
          <i class="fa-solid fa-store text-2xl text-white"></i>
        </div>

        <p class="text-gray-300 text-sm py-1">
          {{ $t("total_cost") }} {{ $t("item_quantity_in_storage") }}
        </p>
      </div>

      <div
        class="bg-yellow-900 bg-gradient-to-br from-yellow-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <p class="text-white text-2xl">
            {{ formatNumber(item.item.total_sale_price_current_koga) }}
            {{
              $t(item.item.currency != null ? item.item.currency.cur_name : "")
            }}
          </p>
          <i class="fa-solid fa-store text-2xl text-white"></i>
        </div>
        <p class="text-gray-300 text-sm py-1">
          {{ $t("total_sale") }} {{ $t("item_quantity_in_storage") }}
          {{ $t("sale_tak") }}
        </p>
      </div>

      <div
        class="bg-green-900 bg-gradient-to-br from-green-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <p class="text-white text-2xl">
            {{
              formatNumber(
                item.item.total_sale_price_current_koga - item.remainingCost
              )
            }}
            {{
              $t(item.item.currency != null ? item.item.currency.cur_name : "")
            }}
          </p>
          <i class="fa-solid fa-coins text-2xl text-white"></i>
        </div>
        <p class="text-gray-300 text-sm py-1">
          {{ $t("total_profit") }} {{ $t("item_quantity_in_storage") }}
          {{ $t("sale_tak") }}
        </p>
      </div>
      <div
        class="bg-purple-900 bg-gradient-to-br from-purple-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <p class="text-white text-2xl">
            {{
              formatNumber(
                item.result.length > 0 ? item.result[0].item_quantity_sold : 0
              )
            }}
          </p>
          <i class="fa-solid fa-money-bill-trend-up text-2xl text-white"></i>
        </div>
        <p class="text-gray-300 text-sm py-1">
          {{ $t("sold_item_in_this_period") }}
        </p>
      </div>
      <div
        class="bg-red-600 bg-gradient-to-br from-red-300 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <div class="">
            <p class="text-white text-2xl">
              {{
                formatNumber(
                  item.result.length > 0
                    ? item.result[0].total_cost_price_dollar
                    : 0
                )
              }}
              <i class="fa-solid fa-dollar-sign text-white text-xl"></i>
            </p>
            <p class="text-white text-2xl">
              {{
                formatNumber(
                  item.result.length > 0
                    ? item.result[0].total_cost_price_dinar
                    : 0
                )
              }}
              {{ $t("dinar") }}
            </p>
          </div>
          <i class="fa-solid fa-cart-shopping text-2xl text-white"></i>
        </div>
        <p class="text-gray-300 text-sm py-1">
          {{ $t("total_cost_of_sale_item_in_this_period") }}
        </p>
      </div>
      <div
        class="bg-yellow-600 bg-gradient-to-br from-yellow-300 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <div class="">
            <p class="text-white text-2xl">
              {{
                formatNumber(
                  item.result.length > 0
                    ? item.result[0].total_sale_price_dollar
                    : 0
                )
              }}
              <i class="fa-solid fa-dollar-sign text-white text-xl"></i>
            </p>
            <p class="text-white text-2xl">
              {{
                formatNumber(
                  item.result.length > 0
                    ? item.result[0].total_sale_price_dinar
                    : 0
                )
              }}
              {{ $t("dinar") }}
            </p>
          </div>
          <i class="fa-solid fa-store text-2xl text-white"></i>
        </div>
        <p class="text-gray-300 text-sm py-1">
          {{ $t("total_item_sale_in_this_period") }}
        </p>
      </div>

      <div
        class="bg-green-600 bg-gradient-to-br from-green-300 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <div class="">
            <p class="text-white text-2xl">
              {{
                formatNumber(
                  item.result.length > 0
                    ? item.result[0].total_profit_dollar
                    : 0
                )
              }}
              <i class="fa-solid fa-dollar-sign text-white text-xl"></i>
            </p>
            <p class="text-white text-2xl">
              {{
                formatNumber(
                  item.result.length > 0 ? item.result[0].total_profit_dinar : 0
                )
              }}
              {{ $t("dinar") }}
            </p>
          </div>
          <i class="fa-solid fa-coins text-2xl text-white"></i>
        </div>
        <p class="text-gray-300 text-sm py-1">
          {{ $t("total_profit_of_sale_item_in_this_period") }}
        </p>
      </div>
      <div
        class="bg-gray-700 bg-gradient-to-br from-gray-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-col justify-between rounded-xl h-32 items-start px-4 py-3 shadow-lg"
      >
        <div class="flex justify-between w-full">
          <div class="">
            <p class="text-white text-2xl">
              {{
                formatNumber(
                  item.result.length > 0
                    ? item.result[0].total_discount_dollar
                    : 0
                )
              }}
              <i class="fa-solid fa-dollar-sign text-white text-xl"></i>
            </p>
            <p class="text-white text-2xl">
              {{
                formatNumber(
                  item.result.length > 0
                    ? item.result[0].total_discount_dinar
                    : 0
                )
              }}
              {{ $t("dinar") }}
            </p>
          </div>
          <i class="fa-solid fa-arrow-trend-down text-2xl text-white"></i>
        </div>
        <p class="text-gray-300 text-sm py-1">
          {{ $t("total_discount_of_sale_item_in_this_period") }}
        </p>
      </div>
    </div>
    
    <p class="font-semibold text-xl text-textBlack dark:text-textWhite mt-12">
      {{ $t("purchases") }}
    </p>
    <br/>
    <EasyDataTable
      :headers="headers"
      :items="filterItems"
      :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
      alternating
      :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
      :loading="isLoadingData"
      :class="isDark ? 'customize-table ' : 'light-mode-table'"
    >
      <template #default="{ column, sortDirection }">
        <span class="custom-sort-icon" v-if="column.sortable">
          <i
            v-if="sortDirection === 'asc'"
            class="fa-solid fa-arrow-up-wide-short"
          ></i>
          <i
            v-else-if="sortDirection === 'desc'"
            class="fa-solid fa-arrow-up-wide-short"
          ></i>
          <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
        </span>
        {{ column.label }}
      </template>

      <template #loading>
        <img
          src="../../assets/loading.webp"
          style="width: 70px; height: 70px"
        />
      </template>
      <template #item-total_cost="item">
        {{
          formatNumber(
            (item.pui_quantity - item.pui_out_quantity) * item.pui_cost_price
          )
        }} </template
      ><template #item-pui_cost_price="item">
        {{ formatNumber(item.pui_cost_price) }}
      </template>
      <template #item-remaining_quantity="item">
        {{ formatNumber(item.pui_quantity - item.pui_out_quantity) }} </template
      ><template #item-currency_cur_name="item">
        {{ $t(item.currency.cur_name) }}
      </template>
      <template #item-created_at="item">
        {{ formatDate(item.created_at) }} </template
      ><template #empty-message>
        <span class="text-textBlack1 dark:text-white">
          {{ $t("no_data_found") }}
        </span>
      </template>
      <template #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }">
        <button :disabled="isFirstPage" @click="prevPage">
          <i
            :class="
              'fa-sharp fa-solid ' +
              ($store.getters['isRtl']
                ? ' fa-chevron-right'
                : ' fa-chevron-left')
            "
          ></i>
        </button>
        <div class="w-5"></div>
        <button :disabled="isLastPage" @click="nextPage">
          <i
            :class="
              'fa-sharp fa-solid ' +
              ($store.getters['isRtl']
                ? ' fa-chevron-left'
                : ' fa-chevron-right')
            "
          ></i>
        </button>
      </template>
    </EasyDataTable>
  </div>
  <edit-modal ref="editModal">
    <div class="items-center">
      <form @submit="editItem">
        <BaseInput
          v-model="current.i_name"
          :label="$t('item_name')"
          :placeholder="$t('item_name')"
          :rules="rules"
          :isError="
            checkRulesIsError(current.i_name, 'add', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.i_name, 'add', this.rules).errorMsg
          "
        /><DropdownInput
          v-model="current.ca_id"
          :placeholder="$t('choose_item_category')"
          :label="$t('item_category')"
          :items="convertToListIdValueCategory"
          :rules="rules_dropdown"
          :isError="
            checkRulesIsError(current.ca_id, 'add', this.rules_dropdown).isError
          "
          :errorMsg="
            checkRulesIsError(current.ca_id, 'add', this.rules_dropdown)
              .errorMsg
          "
        /><DropdownInput
          v-model="current.un_id"
          :label="$t('item_unit')"
          :items="convertToListIdValueUnits"
          :placeholder="$t('choose_item_unit')"
          :rules="rules_dropdown"
          :isError="
            checkRulesIsError(current.un_id, 'add', this.rules_dropdown).isError
          "
          :errorMsg="
            checkRulesIsError(current.un_id, 'add', this.rules_dropdown)
              .errorMsg
          "
        />
        <!-- 
        <div>
          <label
            for="saleType"
            class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
            >{{ $t("suplier") }}</label
          >
          <VueMultiselect
            v-model="current.su_id"
            :options="convertToListIdValueSupliers"
            :multiple="false"
            :placeholder="$t('choose_suplier')"
            label="value"
            track-by="id"
            :taggable="true"
          >
          </VueMultiselect>
        </div>-->
        <DropdownInput
          v-model="current.cur_id"
          :label="$t('currency_type')"
          :items="convertToListIdValue"
          :placeholder="$t('choose_currency_type')"
          :rules="rules_dropdown"
          :isError="
            checkRulesIsError(current.cur_id, 'add', this.rules_dropdown)
              .isError
          "
          :errorMsg="
            checkRulesIsError(current.cur_id, 'add', this.rules_dropdown)
              .errorMsg
          "
        />
        <BaseInput
          v-model="current.i_sale_price"
          :label="$t('sale_item_price')"
          :placeholder="$t('sale_item_price')"
          :rules="rules"
          type="number"
          :isError="
            checkRulesIsError(current.i_sale_price, 'add', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.i_sale_price, 'add', this.rules).errorMsg
          "
        />
        <BaseInput
          v-model="current.i_sale_price_jwmla"
          :label="$t('sale_jwmla_price')"
          :placeholder="$t('sale_jwmla_price')"
        
          type="number"
        
        />

        <BaseInput
          v-model="current.i_main_currency_price"
          :label="$t('dolar_price_in_bought_item_date')"
          :placeholder="$t('dolar_price_in_bought_item_date')"
          :rules="rules"
          type="number"
          :isError="
            checkRulesIsError(current.i_main_currency_price, 'add', this.rules)
              .isError
          "
          :errorMsg="
            checkRulesIsError(current.i_main_currency_price, 'add', this.rules)
              .errorMsg
          "
        />
        <BaseInput
          v-model="current.i_expire_date"
          type="date"
          :label="$t('expire_Date')"
          :placeholder="$t('write_expire_date_if_has')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.i_expire_date, 'add', this.no_rule)
              .isError
          "
          :errorMsg="
            checkRulesIsError(current.i_expire_date, 'add', this.no_rule)
              .errorMsg
          "
        />

        <div>
          <label
            for="image"
            class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
          >
            {{ $t("item_photo") }}</label
          >
          <label
            for="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          >
            <i class="fa fa-cloud-upload mx-1"></i>
            {{ i_image == "" ? $t("choose_item_photo_if_you_want_change") : i_image.name }}
          </label>
          <input
            type="file"
            v-on:change="onChange"
            :placeholder="$t('choose_item_photo_if_you_want_change')"
            id="image"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
          />
        </div>

        <TextAreaInput
          v-model="current.i_desc"
          :label="$t('item_description')"
          :placeholder="$t('item_description')"
          :rules="no_rule"
          :isError="
            checkRulesIsError(current.i_desc, 'add', this.no_rule).isError
          "
          :errorMsg="
            checkRulesIsError(current.i_desc, 'add', this.no_rule).errorMsg
          "
        />
        <center>
          <ButtonInput
            type="button"
            @click="editItem"
            :label="$t('edit')"
            :isLoading="isBtnEditLoading"
          />
        </center>
      </form>
    </div>
  </edit-modal>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import LoadingProgress from "../../components/loading-progress.vue";
import { formatDate } from "../../utils/functions";
import { formatNumber } from "../../utils/functions";
import moment from "moment";
import { FILE_URL } from "../../constants/config";
import InvoiceHeader from "../../components/Navbar/InvoiceHeader.vue";
import EditModal from "../../components/Modals/EditModal.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
import { CURRENCYS } from "../../constants/config";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    LoadingProgress,
    InvoiceHeader,
    EditModal,
    BaseInput,
    ButtonInput,
    DropdownInput,
    TextAreaInput,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_rule: [],
      su_name: "",
      su_note: "",
      su_phone: "",
      su_address: "",
      su_email: "",
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      ci_id: "",
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      file_url: FILE_URL,
      su_image: "",
      startDate: "",
      endDate: "",
      period: "last30days",
      items: null,
      invoiceType: "0",
      isHide: false,
      currencys: CURRENCYS,
      i_image: "",
    };
  },
  created() {
    this.startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
    this.getData();
    this.$store.dispatch("item_category/getCategory");

    this.$store.dispatch("item_unit/getUnits");
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.item_detail.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.item_detail.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("item_detail/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.item_detail.isShowResultDialog;
    },
    item() {
      return this.$store.state.item_detail.items;
    },
    filterItems() {
      return this.$store.getters["item_detail/filterItems"];
    },
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.item_detail.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.item_detail.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.item_detail.isBtnEditLoading;
    },
    convertToListIdValue() {
      var list = [];
      for (const item of this.currencys) {
        list.push({
          id: item.cur_id,
          value: this.$t(item.cur_name),
        });
      }

      return list;
    },
    convertToListIdValueUnits() {
      var boxes = this.$store.state.item_unit.units;
      var list = [];
      for (const item of boxes) {
        list.push({
          id: item.un_id,
          value: item.un_name,
        });
      }

      return list;
    },

    convertToListIdValueCategory() {
      var categorys = this.$store.state.item_category.categorys;
      var list = [];
      for (const item of categorys) {
        list.push({
          id: item.ca_id,
          value: item.ca_name,
        });
      }

      return list;
    },
    headers() {
      return [
        { text: this.$t("purchase_id"), value: "pu_id", sortable: true },
        {
          text: this.$t("suplier"),
          value: "purchase.suplier.su_name",
          sortable: true,
        },
        {
          text: this.$t("purchased_quantity"),
          value: "pui_quantity",
          sortable: true,
        },
        {
          text: this.$t("spent_quantity"),
          value: "pui_out_quantity",
          sortable: true,
        },
        {
          text: this.$t("remain"),
          value: "remaining_quantity",
          sortable: true,
        },
        {
          text: this.$t("cost"),
          value: "pui_cost_price",
          sortable: false,
        },
        {
          text: this.$t("total_cost_remains_item"),
          value: "total_cost",
          sortable: false,
        },
        {
          text: this.$t("currency_type"),
          value: "currency_cur_name",
          sortable: true,
        },
        {
          text: this.$t("dolar_price_when_item_purchased"),
          value: "purchase.pu_main_currency_price",
          sortable: true,
        },

        { text: this.$t("created_at"), value: "created_at", sortable: true },
        {
          text: this.$t("admin"),
          value: "purchase.users.name",
          sortable: true,
        },
      ];
    },
  },

  methods: {
    async showModal() {
      await this.$refs.editModal.show();
    },
    printReportFunc() {
      this.isHide = true;
      //wait 1 second
      setTimeout(() => {
        var printContents = document.getElementById("printDev").innerHTML;

        // Replace the body's innerHTML with the print contents
        document.body.innerHTML = printContents;
        var isRtl = this.$store.getters["isRtl"];
        // Set direction based on the parameter
        if (isRtl) {
          document.body.style.direction = "rtl";
        } else {
          document.body.style.direction = "ltr";
        }

        // Print the content
        window.print();
        //reload page
      }, 100);
      //print element id invoice_print
    },
    onChange(e) {
      this.i_image = e.target.files[0];
      e.target.value = "";
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    periodChanged(action) {
      this.period = action;

      if (action == "today") {
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "yesterday") {
        this.startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (action == "last7days") {
        this.startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "last30days") {
        this.startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "thismonth") {
        this.startDate = moment().startOf("month").format("YYYY-MM-DD");
        this.endDate = moment().endOf("month").format("YYYY-MM-DD");
      } else if (action == "lastmonth") {
        this.startDate = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.endDate = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (action == "lastyear") {
        this.startDate = moment()
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.endDate = moment()
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
      } else if (action == "thisyear") {
        this.startDate = moment().startOf("year").format("YYYY-MM-DD");
        this.endDate = moment().endOf("year").format("YYYY-MM-DD");
      }
      //all
      else if (action == "all") {
        this.startDate = "2000-01-01";
        this.endDate = moment().endOf("year").format("YYYY-MM-DD");
      }

      this.getData();
    },

    async getData() {
      var res = await this.$store.dispatch("item_detail/getItems", {
        i_id: this.$route.params.id,
        startDate: this.startDate,
        endDate: this.endDate,
        invoiceType: this.invoiceType,
      });
      //check res status

      if (res.status == 200) {
        this.items = res.data;
        this.current = this.items.item;
      } else if (res.response.status == 404) {
        window.open("/item-list", "_self");
      }
    },
    async editItem(e) {
      e.preventDefault();
      this.isSubmitedEdit = true;
      if (
        this.checkRulesIsError(this.current.i_name, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(this.current.i_sale_price, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(
          this.current.i_sale_price_jwmla,
          "edit",
          this.rules
        ).isError
      ) {
        return;
      }
      this.$store.state.item_detail.isBtnEditLoading = true;

      var res = await this.$store.dispatch("item_list/editItem", {
        i_name: this.current.i_name,
        i_id: this.current.i_id,
        ca_id: this.current.ca_id,
        //   su_id: this.current.su_id.id,
        un_id: this.current.un_id,
        cur_id: this.current.cur_id,

        i_sale_price: this.current.i_sale_price,
        i_sale_price_jwmla: this.current.i_sale_price_jwmla,

        i_main_currency_price: this.current.i_main_currency_price,
        i_expire_date: this.current.i_expire_date,
        i_image: this.i_image,
        i_desc: this.current.i_desc,
      });

      this.isSubmitedEdit = false;
      this.$store.state.item_detail.isBtnEditLoading = false;
      if (res.status == 200) {
        this.i_image = "";
        this.$refs.editModal.hide();
        this.getData();
      }
    },
    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";

      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
  },
};
</script>
