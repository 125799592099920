<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("add_debt") }}
      </p>
    </div>

    <div class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="">
        <form @submit="addSaleItem">
          <div class="grid gap-x-6 mb-6 md:grid-cols-2">
            <BaseInput
              v-model="deb_amount"
              :label="$t('debt_amount')"
              :placeholder="$t('debt_amount')"
              :rules="rules"
              type="number"
              :isError="
                checkRulesIsError(deb_amount, 'add', this.rules).isError
              "
              :errorMsg="
                checkRulesIsError(deb_amount, 'add', this.rules).errorMsg
              "
            />
            <DropdownInput
              v-model="cur_id"
              :label="$t('currency_type')"
              :items="convertToListIdValue"
              :placeholder="$t('currency_type')"
              :rules="rules_dropdown"
              :isError="
                checkRulesIsError(cur_id, 'add', this.rules_dropdown).isError
              "
              :errorMsg="
                checkRulesIsError(cur_id, 'add', this.rules_dropdown).errorMsg
              "
            /><DropdownInput
              v-model="deb_type"
              :label="$t('debt_type')"
              :placeholder="$t('debt_type')"
              :rules="rules_dropdown"
              :items="debtTypes"
              :isError="
                checkRulesIsError(deb_type, 'add', this.rules_dropdown).isError
              "
              :errorMsg="
                checkRulesIsError(deb_type, 'add', this.rules_dropdown).errorMsg
              "
            /><DropdownInput
              v-model="bo_id"
              :label="$t('box_debt')"
              :placeholder="$t('choose_box_if_debt_is_in_or_out_from_box')"
              :rules="no_rule"
              :items="convertToListIdValueBoxes"
              :isError="checkRulesIsError(bo_id, 'add', this.no_rule).isError"
              :errorMsg="checkRulesIsError(bo_id, 'add', this.no_rule).errorMsg"
              linkDrop="/all-box"
            />
            <div class="mb-6" v-if="su_id == '' || su_id == null">
              <div class="flex">
                <div class="w-full">
                  <label
                    for="saleType"
                    class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
                    >{{ $t("customer") }}</label
                  >
                  <VueMultiselect
                    v-model="cu_id"
                    :options="convertToListIdValueCustomers"
                    :multiple="false"
                    :placeholder="
                      $t('choose_customer_if_debt_is_in_or_out_from_customer')
                    "
                    label="value"
                    track-by="id"
                    :taggable="true"
                    class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-bgFullDark dark:border-gray-600"
                  >
                  </VueMultiselect>
                </div>
                <div class="w-2"></div>
                <a
                  class="w-2/12 lg:w-1/12 text-white text-2xl my-9 bg-primaryColor p-1 rounded-lg hover:shadow-md text-center"
                  target="_blank"
                  href="/customer"
                >
                  +
                </a>
              </div>
            </div>
            <div class="mb-6" v-if="cu_id == '' || cu_id == null">
              <div class="flex">
                <div class="w-full">
                  <label
                    for="saleType"
                    class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
                    >{{ $t("suplier") }}</label
                  >
                  <VueMultiselect
                    v-model="su_id"
                    :options="convertToListIdValueSupliers"
                    :multiple="false"
                    :placeholder="
                      $t('chhose_suplier_if_debt_is_in_or_out_from_suplier')
                    "
                    label="value"
                    track-by="id"
                    :taggable="true"
                    class="bg-slate-100 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-bgFullDark dark:border-gray-600"
                  >
                  </VueMultiselect>
                </div>
                <div class="w-2"></div>
                <a
                  class="w-2/12 lg:w-1/12 text-white text-2xl my-9 bg-primaryColor p-1 rounded-lg hover:shadow-md text-center"
                  target="_blank"
                  href="/suplier"
                >
                  +
                </a>
              </div>
            </div>

            <BaseInput
              v-model="deb_wasl"
              :label="$t('debt_invoice_number')"
              :placeholder="$t('debt_invoice_number')"
              :rules="no_rule"
              :isError="
                checkRulesIsError(deb_wasl, 'add', this.no_rule).isError
              "
              :errorMsg="
                checkRulesIsError(deb_wasl, 'add', this.no_rule).errorMsg
              "
            />
            <BaseInput
              v-model="created_at"
              type="date"
              :label="$t('debt_date')"
              :placeholder="$t('debt_date')"
              :rules="no_rule"
              :isError="
                checkRulesIsError(created_at, 'add', this.no_rule).isError
              "
              :errorMsg="
                checkRulesIsError(created_at, 'add', this.no_rule).errorMsg
              "
            />
            <BaseInput
              v-model="deb_return_date"
              type="date"
              :label="$t('debt_return_date')"
              :placeholder="$t('debt_return_date')"
              :rules="no_rule"
              :isError="
                checkRulesIsError(deb_return_date, 'add', this.no_rule).isError
              "
              :errorMsg="
                checkRulesIsError(deb_return_date, 'add', this.no_rule).errorMsg
              "
            />
            <div>
              <label
                for="image"
                class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
              >
                {{ $t("debt_photo") }}</label
              >
              <label
                for="image"
                class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              >
                <i class="fa fa-cloud-upload mx-1"></i>
                {{ deb_image == "" ? $t("choose_debt_photo") : deb_image.name }}
              </label>
              <input
                type="file"
                v-on:change="onChange"
                :placeholder="$t('choose_debt_photo')"
                id="image"
                class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
              />
            </div>
            <TextAreaInput
              v-model="deb_note"
              :label="$t('note')"
              :placeholder="$t('note')"
              :rules="no_rule"
              :isError="
                checkRulesIsError(deb_note, 'add', this.no_rule).isError
              "
              :errorMsg="
                checkRulesIsError(deb_note, 'add', this.no_rule).errorMsg
              "
            />
          </div>

          <ButtonInput
            type="button"
            @click="addSaleItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>

  <ResultDialog ref="ResultDialog"></ResultDialog>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import { CURRENCYS } from "../../constants/config";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import VueMultiselect from "vue-multiselect";
import DropdownInput from "../../components/Inputs/DropdownInput.vue";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ResultDialog,
    TextAreaInput,
    DropdownInput,
    VueMultiselect,
  },
  data() {
    return {
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_rule: [],
      isSubmited: false,
      currencys: CURRENCYS,
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      cur_id: "",
      su_id: "",
      bo_id: "",
      cu_id: "",
      deb_note: "",
      deb_wasl: "",
      deb_amount: "",
      created_at: "",
      deb_return_date: "",
      deb_type: "",
      deb_image: "",

      debtTypes: [
        {
          id: "1",
          value: this.$t("lend"),
        },
        {
          id: "0",
          value: this.$t("borrow"),
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("all_box/getBoxes");

    this.$store.dispatch("debt_category/getExpenseCategory");
    this.$store.dispatch("customer/getCustomers");
    this.$store.dispatch("suplier/getSupliers");
    this.$store.dispatch("navbar/getItems");
  },

  computed: {
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isBtnLoading() {
      return this.$store.state.add_debt.isBtnLoading;
    },
    convertToListIdValue() {
      var list = [];
      for (const item of this.currencys) {
        list.push({
          id: item.cur_id,
          value: this.$t(item.cur_name),
        });
      }

      return list;
    },
    convertToListIdValueBoxes() {
      var boxes = this.$store.state.all_box.boxes;
      var list = [];
      for (const item of boxes) {
        list.push({
          id: item.bo_id,
          value: item.bo_name,
        });
      }

      return list;
    },
    convertToListIdValueCustomers() {
      var customers = this.$store.state.customer.customers;
      var list = [];
      for (const item of customers) {
        list.push({
          id: item.cu_id,
          value: item.cu_name,
        });
      }

      return list;
    },
    convertToListIdValueSupliers() {
      var supliers = this.$store.state.suplier.supliers;
      var list = [];
      for (const item of supliers) {
        list.push({
          id: item.su_id,
          value: item.su_name,
        });
      }

      return list;
    },
  },

  methods: {
    onChange(e) {
      this.deb_image = e.target.files[0];
      e.target.value = "";
    },
    formatDate(date) {
      return formatDate(date);
    },

    async addSaleItem() {
      //check form validation
      if (this.$store.state.add_debt.isBtnLoading) return;

      this.isSubmited = true;
      if (
        this.checkRulesIsError(this.cur_id, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.deb_type, "add", this.rules_dropdown)
          .isError ||
        this.checkRulesIsError(this.deb_amount, "add", this.rules).isError
      ) {
        return;
      }
      //at least 1 of customer or suplier must be selected
      if (!this.checkIsEmpty(this.cu_id) && !this.checkIsEmpty(this.su_id)) {
        this.$refs.ResultDialog.show({
          message: this.$t("you_must_choose_only_customer_or_suplier"),
          okButton: this.$t("ok"),
          isSuccess: false,
        });
        return;
      }
      if (this.checkIsEmpty(this.cu_id) && this.checkIsEmpty(this.su_id)) {
        this.$refs.ResultDialog.show({
          message: this.$t("you_must_choose_at_least_one_customer_or_suplier"),
          okButton: this.$t("ok"),
          isSuccess: false,
        });
        return;
      }

      //
      if (this.cu_id == null) {
        this.cu_id = {
          id: "",
        };
      }
      if (this.su_id == null) {
        this.su_id = {
          id: "",
        };
      }
      this.$store.state.add_debt.isBtnLoading = true;
      var res = await this.$store.dispatch("add_debt/addNewItem", {
        cur_id: this.cur_id,
        deb_type: this.deb_type,
        bo_id: this.bo_id,
        cu_id: this.cu_id.id,
        deb_note: this.deb_note,
        deb_wasl: this.deb_wasl,
        deb_amount: this.deb_amount,
        deb_main_currency_price: this.$store.state.navbar.dp_price,
        created_at: this.created_at,
        deb_return_date: this.deb_return_date,
        su_id: this.su_id.id,
        deb_image: this.deb_image,
      });
      if (res.status == 200) {
        this.deb_amount = "";
        this.deb_note = "";
        this.deb_wasl = "";
        this.cur_id = "";
        this.deb_type = "";
        this.bo_id = "";
        this.cu_id = "";
        this.su_id = "";
        this.created_at = "";
        this.deb_return_date = "";
        this.$store.state.add_debt.isBtnLoading = false;
        this.isSubmited = false;
      }
      this.isSubmited = false;
    },
    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    checkIsEmpty(value) {
      if (value == "" || value == null) return true;
      return false;
    },
  },
};
</script>
<style>
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px none;

  font-size: 14px;
}
</style>
