<template>
  <HeaderApp />

  <!-- in ltr change (md:mr-60 to md:ml-60)-->
  <div
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
    v-if="!isLoadingData"
  >
    <div class="flex mb-4 justify-between">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite my-1">
        {{ $t("dashboard") }}
      </p>

      <div class="md:mx-2 my-1 md:my-0">
        <label
          for="boxType"
          class="block mb-2 text-sm text-textBlack1 dark:text-textWhite1"
        >
          {{ $t("choose_period") }}
        </label>
        <select
          id="countries"
          class="bg-white border border-gray-300 text-textBlack1 placeholder-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block xl:w-40 lg:w-36 w-36 p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          v-model="period"
          @change="periodChanged(period)"
        >
          <option value="today" class="my_font">{{ $t("today") }}</option>
          <option value="yesterday" class="my_font">
            {{ $t("yesterday") }}
          </option>
          <option value="last7days" class="my_font">
            {{ $t("last7days") }}
          </option>
          <option value="last30days" class="my_font">
            {{ $t("last30days") }}
          </option>
          <option value="thismonth" class="my_font">
            {{ $t("thismonth") }}
          </option>
          <option value="lastmonth" class="my_font">
            {{ $t("lastmonth") }}
          </option>
          <option value="lastyear" class="my_font">
            {{ $t("lastyear") }}
          </option>
          <option value="thisyear" class="my_font">
            {{ $t("thisyear") }}
          </option>
          <option value="all" class="my_font">{{ $t("all_time") }}</option>
        </select>
      </div>
    </div>

    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 mt-4 mb-6 gap-x-4 gap-y-8"
    >
      <div
        class="bg-red-800 bg-gradient-to-br from-red-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-row rounded-xl h-32 px-4 py-3 items-center shadow-lg"
      >
        <div
          class="w-14 h-14 flex bg-red-800 bg-gradient-to-br from-red-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 rounded-xl ml-3"
        >
          <i class="fa-solid fa-sack-dollar text-white text-xl m-auto"></i>
        </div>
        <div class="flex flex-col justify-around h-full">
          <div>
            <p class="text-white text-xl font-semibold">
              {{ formatNumber(items.expense.total_amount_dollar) }} $
            </p>
            <p class="text-white text-xl font-semibold">
              {{ formatNumber(items.expense.total_amount_dinar) }}
              {{ $t("dinar") }}
            </p>
          </div>
          <p class="text-white text-sm font-normal">
            {{ $t("total_expense") }}
          </p>
        </div>
      </div>
      <div
        class="bg-blue-800 bg-gradient-to-br from-blue-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-row rounded-xl h-32 px-4 py-3 items-center shadow-lg"
      >
        <div
          class="w-14 h-14 flex bg-blue-800 bg-gradient-to-br from-blue-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 rounded-xl ml-3"
        >
          <i
            class="fa-solid fa-hand-holding-dollar text-white text-xl m-auto"
          ></i>
        </div>
        <div class="flex flex-col justify-around h-full">
          <div>
            <p class="text-white text-xl font-semibold">
              {{ formatNumber(items.income.total_amount_dollar) }} $
            </p>
            <p class="text-white text-xl font-semibold">
              {{ formatNumber(items.income.total_amount_dinar) }}
              {{ $t("dinar") }}
            </p>
          </div>
          <p class="text-white text-sm font-normal">{{ $t("total_income") }}</p>
        </div>
      </div>
      <div
        class="bg-purple-800 bg-gradient-to-br from-purple-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-row rounded-xl h-32 px-4 py-3 items-center shadow-lg"
      >
        <div
          class="w-14 h-14 flex bg-purple-800 bg-gradient-to-br from-purple-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 rounded-xl ml-3"
        >
          <i class="fa-solid fa-coins text-white text-xl m-auto"></i>
        </div>
        <div class="flex flex-col justify-around h-full">
          <div>
            <p class="text-white text-xl font-semibold">
              {{ formatNumber(items.saleAndProfit.total_sale_price_dollar) }} $
            </p>
            <p class="text-white text-xl font-semibold">
              {{ formatNumber(items.saleAndProfit.total_sale_price_dinar) }}
              {{ $t("dinar") }}
            </p>
          </div>
          <p class="text-white text-sm font-normal">{{ $t("total_sale") }}</p>
        </div>
      </div>

      <div
        class="bg-green-700 bg-gradient-to-br from-green-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 flex flex-row rounded-xl h-32 px-4 py-3 items-center shadow-lg"
      >
        <div
          class="w-14 h-14 flex bg-green-700 bg-gradient-to-br from-green-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 rounded-xl ml-3"
        >
          <i
            class="fa-solid fa-circle-dollar-to-slot text-white text-xl m-auto"
          ></i>
        </div>
        <div class="flex flex-col justify-around h-full">
          <div>
            <p class="text-white text-xl font-semibold">
              {{ formatNumber(items.saleAndProfit.total_profit_dollar) }} $
            </p>
            <p class="text-white text-xl font-semibold">
              {{ formatNumber(items.saleAndProfit.total_profit_dinar) }}
              {{ $t("dinar") }}
            </p>
          </div>
          <p class="text-white text-sm font-normal">
            {{ $t("total_sale_profit") }}
          </p>
        </div>
      </div>

      <div
        class="bg-green-900 bg-gradient-to-br from-green-400 to-transparent border-b-2 border-r-2 border-white dark:border-green-600 flex flex-row rounded-xl h-32 px-4 py-3 items-center shadow-lg"
      >
        <!--in rtl change ml-3 to mr-3-->
        <div
          class="w-14 h-14 flex bg-green-900 bg-gradient-to-br from-green-400 to-transparent border-b-2 border-r-2 border-white dark:border-gray-600 rounded-xl ml-3"
        >
          <i
            class="fa-solid fa-circle-dollar-to-slot text-white text-xl m-auto"
          ></i>
        </div>
        <div class="flex flex-col justify-around h-full">
          <div>
            <p class="text-white text-xl font-semibold">
              {{ formatNumber(items.total_profit.total_profit_dollar) }} $
            </p>
            <p class="text-white text-xl font-semibold">
              {{ formatNumber(items.total_profit.total_profit_dinar) }}
              {{ $t("dinar") }}
            </p>
          </div>
          <p class="text-white text-sm font-normal">
            {{ $t("total_profit_in_this_period") }}
          </p>
        </div>
      </div>
    </div>

    <!--Charts-->
    <div class="lg:flex w-full">
      <div class="w-11/12 lg:w-6/12 mx-auto lg:mx-1">
        <span
          class="text-textBlack1 dark:text-textWhite1 text-base mx-1 font-semibold"
          >{{ $t("sale_types") }}</span
        >

        <div
          class="bg-white rounded-2xl shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2"
        >
          <div
            class="rounded-2xl text-center shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2 overflow-x-auto"
          >
            <center>
              <apex-chart
                width="300"
                type="donut"
                :options="chartOptions"
                :series="series"
                v-if="series.length > 0"
              ></apex-chart>
              <div>
                <center>
                  <p
                    class="text-lg font-semibold text-textBlack dark:text-textWhite"
                    v-if="series.length == 0 && !isLoadingData"
                  >
                    <span class="text-textBlack1 dark:text-white">
                      {{ $t("no_data_found") }}
                    </span>
                  </p>
                </center>
              </div>
              <div>
                <center>
                  <p
                    class="text-lg font-semibold text-textBlack dark:text-textWhite"
                    v-if="series.length == 0 && isLoadingData"
                  >
                    <img
                      src="../../assets/loading.webp"
                      style="width: 70px; height: 70px"
                    />
                  </p>
                </center>
              </div>
            </center>
          </div>
          <p
            class="text-textBlack1 dark:text-textWhite1 text-sm mx-auto text-center mt-6 mb-3"
          >
            {{ $t("sale_types") }}
          </p>
          <EasyDataTable
            :headers="headersSaleType"
            :items="items.sale_type"
            :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
            alternating
            :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
            :loading="isLoadingData"
            :class="isDark ? 'customize-table ' : 'light-mode-table'"
            :hide-footer="true"
          >
            <template #default="{ column, sortDirection }">
              <span class="custom-sort-icon" v-if="column.sortable">
                <i
                  v-if="sortDirection === 'asc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i
                  v-else-if="sortDirection === 'desc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
              </span>
              {{ column.label }}
            </template>

            <template #loading>
              <img
                src="../../assets/loading.webp"
                style="width: 70px; height: 70px"
              />
            </template>
            <template #item-item_quantity_sold="item">
              <span>
                {{ formatNumber(item.item_quantity_sold) }}
              </span>
            </template>
            <template #item-total_sale_price_dinar="item">
              <span>
                {{ formatNumber(item.total_sale_price_dinar) }}
              </span>
            </template>
            <template #item-total_profit_dinar="item">
              <span>
                {{ formatNumber(item.total_profit_dinar) }}
              </span>
            </template>
            <template #item-total_discount_dinar="item">
              <span>
                {{ formatNumber(item.total_discount_dinar) }}
              </span>
            </template>
            <template #item-total_sale_price_dollar="item">
              <span>
                {{ formatNumber(item.total_sale_price_dollar) }}
              </span>
            </template>
            <template #item-total_profit_dollar="item">
              <span>
                {{ formatNumber(item.total_profit_dollar) }}
              </span>
            </template>
            <template #item-total_discount_dollar="item">
              <span>
                {{ formatNumber(item.total_discount_dollar) }}
              </span>
            </template>
            <template #item-total_cost_price_dinar="item">
              <span>
                {{ formatNumber(item.total_cost_price_dinar) }}
              </span>
            </template>
            <template #item-total_cost_price_dollar="item">
              <span>
                {{ formatNumber(item.total_cost_price_dollar) }}
              </span>
            </template>
            <template #empty-message>
              <span class="text-textBlack1 dark:text-white">
                {{ $t("no_data_found") }}
              </span>
            </template>
            <template
              #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
            >
              <button :disabled="isFirstPage" @click="prevPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-chevron-right'
                      : ' fa-chevron-left')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button :disabled="isLastPage" @click="nextPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-chevron-left'
                      : ' fa-chevron-right')
                  "
                ></i>
              </button>
            </template>
          </EasyDataTable>
        </div>
      </div>
      <div class="md:w-4"></div>

      <div class="w-11/12 lg:w-6/12 mx-auto lg:mx-1 mt-10 lg:mt-0">
        <span
          class="text-textBlack1 dark:text-textWhite1 text-base mx-1 font-semibold"
          >{{ $t("most_sold_items") }}</span
        >

        <div
          class="bg-white rounded-2xl shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2"
        >
          <div
            class="rounded-2xl shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2 overflow-x-auto"
          >
            <center>
              <apex-chart
                width="300"
                type="donut"
                :options="chartOptionsItems"
                :series="seriesItems"
                v-if="seriesItems.length > 0"
              ></apex-chart>
              <div>
                <center>
                  <p
                    class="text-lg font-semibold text-textBlack dark:text-textWhite"
                    v-if="seriesItems.length == 0 && !isLoadingData"
                  >
                    <span class="text-textBlack1 dark:text-white">
                      {{ $t("no_data_found") }}
                    </span>
                  </p>
                </center>
              </div>
              <div>
                <center>
                  <p
                    class="text-lg font-semibold text-textBlack dark:text-textWhite"
                    v-if="seriesItems.length == 0 && isLoadingData"
                  >
                    <img
                      src="../../assets/loading.webp"
                      style="width: 70px; height: 70px"
                    />
                  </p>
                </center>
              </div>
            </center>
          </div>
          <p
            class="text-textBlack1 dark:text-textWhite1 text-sm mx-auto text-center mt-6 mb-3"
          >
            {{ $t("most_sold_items") }}
          </p>
          <EasyDataTable
            :headers="headersMostSoldItem"
            :items="items.item_sale"
            :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
            alternating
            :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
            :loading="isLoadingData"
            :class="isDark ? 'customize-table ' : 'light-mode-table'"
            :hide-footer="true"
          >
            <template #default="{ column, sortDirection }">
              <span class="custom-sort-icon" v-if="column.sortable">
                <i
                  v-if="sortDirection === 'asc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i
                  v-else-if="sortDirection === 'desc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
              </span>
              {{ column.label }}
            </template>

            <template #loading>
              <img
                src="../../assets/loading.webp"
                style="width: 70px; height: 70px"
              />
            </template>
            <template #item-item_quantity_sold="item">
              <span>
                {{ formatNumber(item.item_quantity_sold) }}
              </span>
            </template>
            <template #item-total_sale_price_dinar="item">
              <span>
                {{ formatNumber(item.total_sale_price_dinar) }}
              </span>
            </template>
            <template #item-total_profit_dinar="item">
              <span>
                {{ formatNumber(item.total_profit_dinar) }}
              </span>
            </template>
            <template #item-total_discount_dinar="item">
              <span>
                {{ formatNumber(item.total_discount_dinar) }}
              </span>
            </template>
            <template #item-total_sale_price_dollar="item">
              <span>
                {{ formatNumber(item.total_sale_price_dollar) }}
              </span>
            </template>
            <template #item-total_profit_dollar="item">
              <span>
                {{ formatNumber(item.total_profit_dollar) }}
              </span>
            </template>
            <template #item-total_discount_dollar="item">
              <span>
                {{ formatNumber(item.total_discount_dollar) }}
              </span>
            </template>
            <template #item-total_cost_price_dinar="item">
              <span>
                {{ formatNumber(item.total_cost_price_dinar) }}
              </span>
            </template>
            <template #item-total_cost_price_dollar="item">
              <span>
                {{ formatNumber(item.total_cost_price_dollar) }}
              </span>
            </template>
            <template #empty-message>
              <span class="text-textBlack1 dark:text-white">
                {{ $t("no_data_found") }}
              </span>
            </template>
            <template
              #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
            >
              <button :disabled="isFirstPage" @click="prevPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-chevron-right'
                      : ' fa-chevron-left')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button :disabled="isLastPage" @click="nextPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-chevron-left'
                      : ' fa-chevron-right')
                  "
                ></i>
              </button>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </div>
    <br />
    <!--Charts-->
    <div class="lg:flex w-full">
      <div class="w-11/12 lg:w-6/12 mx-auto lg:mx-1">
        <span
          class="text-textBlack1 dark:text-textWhite1 text-base mx-1 font-semibold"
          >{{ $t("notes") }}</span
        >

        <div
          class="bg-white rounded-2xl shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2"
        >
          <EasyDataTable
            :headers="headersNote"
            :items="items.notes"
            :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
            :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
            :loading="isLoadingData"
            :class="isDark ? 'customize-table ' : 'light-mode-table'"
            :hide-footer="true"
          >
            <template #default="{ column, sortDirection }">
              <span class="custom-sort-icon" v-if="column.sortable">
                <i
                  v-if="sortDirection === 'asc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i
                  v-else-if="sortDirection === 'desc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
              </span>
              {{ column.label }}
            </template>

            <template #loading>
              <img
                src="../../assets/loading.webp"
                style="width: 70px; height: 70px"
              />
            </template>

            <template #empty-message>
              <span class="text-textBlack1 dark:text-white">
                {{ $t("no_data_found") }}
              </span>
            </template>
            <template
              #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
            >
              <button :disabled="isFirstPage" @click="prevPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-chevron-right'
                      : ' fa-chevron-left')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button :disabled="isLastPage" @click="nextPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-chevron-left'
                      : ' fa-chevron-right')
                  "
                ></i>
              </button>
            </template>
          </EasyDataTable>
        </div>
      </div>
      <div class="md:w-4"></div>

      <div class="w-11/12 lg:w-6/12 mx-auto lg:mx-1 mt-10 lg:mt-0">
        <span
          class="text-textBlack1 dark:text-textWhite1 text-base mx-1 font-semibold"
          >{{ $t("waste_items") }}</span
        >

        <div
          class="bg-white rounded-2xl shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2"
        >
          <EasyDataTable
            :headers="headerWasteItem"
            :items="items.waste_item"
            :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
            alternating
            :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
            :loading="isLoadingData"
            :class="isDark ? 'customize-table ' : 'light-mode-table'"
            :hide-footer="true"
          >
            <template #default="{ column, sortDirection }">
              <span class="custom-sort-icon" v-if="column.sortable">
                <i
                  v-if="sortDirection === 'asc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i
                  v-else-if="sortDirection === 'desc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
              </span>
              {{ column.label }}
            </template>

            <template #loading>
              <img
                src="../../assets/loading.webp"
                style="width: 70px; height: 70px"
              />
            </template>
            <template #item-total_cost_price_dollar="item">
              <span>
                {{ formatNumber(item.total_cost_price_dollar) }}
              </span>
            </template>
            <template #item-total_cost_price_dinar="item">
              <span>
                {{ formatNumber(item.total_cost_price_dinar) }}
              </span>
            </template>

            <template #empty-message>
              <span class="text-textBlack1 dark:text-white">
                {{ $t("no_data_found") }}
              </span>
            </template>
            <template
              #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
            >
              <button :disabled="isFirstPage" @click="prevPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-chevron-right'
                      : ' fa-chevron-left')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button :disabled="isLastPage" @click="nextPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-chevron-left'
                      : ' fa-chevron-right')
                  "
                ></i>
              </button>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </div>
    <br />
    <!--Charts-->
    <div class="lg:flex w-full">
      <div class="w-11/12 lg:w-4/12 mx-auto lg:mx-1">
        <span
          class="text-textBlack1 dark:text-textWhite1 text-base mx-1 font-semibold"
          >{{ $t("incomes_categorys") }}</span
        >
        <div
          class="bg-white rounded-2xl shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2"
        >
          <div
            class="rounded-2xl text-center shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2 overflow-x-auto"
          >
            <center>
              <apex-chart
                width="300"
                type="donut"
                :options="chartOptionsIncomes"
                :series="seriesIncomes"
                v-if="seriesIncomes.length > 0"
              ></apex-chart>
              <div>
                <center>
                  <p
                    class="text-lg font-semibold text-textBlack dark:text-textWhite"
                    v-if="seriesIncomes.length == 0 && !isLoadingData"
                  >
                    <span class="text-textBlack1 dark:text-white">
                      {{ $t("no_data_found") }}
                    </span>
                  </p>
                </center>
              </div>
              <div>
                <center>
                  <p
                    class="text-lg font-semibold text-textBlack dark:text-textWhite"
                    v-if="seriesIncomes.length == 0 && isLoadingData"
                  >
                    <img
                      src="../../assets/loading.webp"
                      style="width: 70px; height: 70px"
                    />
                  </p>
                </center>
              </div>
            </center>
          </div>
        </div>
      </div>
      <div class="md:w-4"></div>

      <div class="w-11/12 lg:w-4/12 mx-auto lg:mx-1 mt-10 lg:mt-0">
        <span
          class="text-textBlack1 dark:text-textWhite1 text-base mx-1 font-semibold"
          >{{ $t("expenses_categorys") }}</span
        >
        <div
          class="bg-white rounded-2xl shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2"
        >
          <div
            class="rounded-2xl text-center shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2 overflow-x-auto"
          >
            <center>
              <apex-chart
                width="300"
                type="donut"
                :options="chartOptionsExpenses"
                :series="seriesExpenses"
                v-if="seriesExpenses.length > 0"
              ></apex-chart>
              <div>
                <center>
                  <p
                    class="text-lg font-semibold text-textBlack dark:text-textWhite"
                    v-if="seriesExpenses.length == 0 && !isLoadingData"
                  >
                    <span class="text-textBlack1 dark:text-white">
                      {{ $t("no_data_found") }}
                    </span>
                  </p>
                </center>
              </div>
              <div>
                <center>
                  <p
                    class="text-lg font-semibold text-textBlack dark:text-textWhite"
                    v-if="seriesExpenses.length == 0 && isLoadingData"
                  >
                    <img
                      src="../../assets/loading.webp"
                      style="width: 70px; height: 70px"
                    />
                  </p>
                </center>
              </div>
            </center>
          </div>
        </div>
      </div>
      <div class="md:w-4"></div>

      <div class="w-11/12 lg:w-4/12 mx-auto lg:mx-1 mt-10 lg:mt-0">
        <span
          class="text-textBlack1 dark:text-textWhite1 text-base mx-1 font-semibold"
          >{{ $t("debts") }}</span
        >

        <div
          class="bg-white rounded-2xl shadow dark:bg-bgMeduimDark p-2 md:p-3 mt-2"
        >
          <EasyDataTable
            :headers="headersDebt"
            :items="items.debt"
            :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
            alternating
            :header-props="{ 'sort-icon': 'fa-solid fa-arrow-up-wide-short' }"
            :loading="isLoadingData"
            :class="isDark ? 'customize-table ' : 'light-mode-table'"
            :hide-footer="true"
          >
            <template #default="{ column, sortDirection }">
              <span class="custom-sort-icon" v-if="column.sortable">
                <i
                  v-if="sortDirection === 'asc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i
                  v-else-if="sortDirection === 'desc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
              </span>
              {{ column.label }}
            </template>

            <template #loading>
              <img
                src="../../assets/loading.webp"
                style="width: 70px; height: 70px"
              />
            </template>
            <template #item-total_debt_number="item">
              <span
                :class="
                  selectedReport == 'total_debt_number'
                    ? 'text-primaryColor font-bold'
                    : ''
                "
              >
                {{ formatNumber(item.total_debt_number) }}
              </span>
            </template>
            <template #item-total_amount_dinar="item">
              <span
                :class="
                  selectedReport == 'total_amount_dinar'
                    ? 'text-primaryColor font-bold'
                    : ''
                "
              >
                {{ formatNumber(item.total_amount_dinar) }}
              </span>
            </template>

            <template #item-total_amount_dollar="item">
              <span
                :class="
                  selectedReport == 'total_amount_dollar'
                    ? 'text-primaryColor font-bold'
                    : ''
                "
              >
                {{ formatNumber(item.total_amount_dollar) }}
              </span>
            </template>
            <template #item-name="item">
              <span
                :class="item.name == 'invoice' ? 'text-red-500 font-bold' : ''"
                v-show="item.name == 'invoice'"
              >
                {{ $t("sale_due") }}
              </span>
              <span
                :class="
                  item.name == 'purchase' ? 'text-green-500 font-bold' : ''
                "
                v-show="item.name == 'purchase'"
              >
                {{ $t("purchase_due") }}
              </span>
              <span
                :class="item.name == 'borrow' ? 'text-green-500 font-bold' : ''"
                v-show="item.name == 'borrow'"
              >
                {{ $t("borrow") }}
              </span>
              <span
                :class="item.name == 'lend' ? 'text-red-500 font-bold' : ''"
                v-show="item.name == 'lend'"
              >
                {{ $t("lend") }}
              </span>
              <span
                :class="
                  item.name == 'returned_purchase'
                    ? 'text-red-500 font-bold'
                    : ''
                "
                v-show="item.name == 'returned_purchase'"
              >
                {{ $t("returned_purchase") }}
              </span>
            </template>
            <template #empty-message>
              <span class="text-textBlack1 dark:text-white">
                {{ $t("no_data_found") }}
              </span>
            </template>
            <template
              #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
            >
              <button :disabled="isFirstPage" @click="prevPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-chevron-right'
                      : ' fa-chevron-left')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button :disabled="isLastPage" @click="nextPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-chevron-left'
                      : ' fa-chevron-right')
                  "
                ></i>
              </button>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </div>
  </div>
  <LoadingProgress v-show="isLoadingData" />
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import moment from "moment";
import { formatDate } from "../../utils/functions";
import { formatNumber } from "../../utils/functions";
import LoadingProgress from "../../components/loading-progress.vue";
export default {
  name: "HelloWorld",
  components: {
    HeaderApp,
    LoadingProgress,
  },
  data() {
    return {
      series: [],
      seriesItems: [],
      seriesIncomes: [],
      seriesExpenses: [],

      selectedReport: "item_quantity_sold",
      chartOptions: {
        chart: {
          width: 380,
          type: "donut",
          fontFamily: "kurdish",
        },
        stroke: {
          width: 0,
        },

        labels: [],
        //change labels position to bottom
        legend: {
          position: "bottom",
          //labels colo dark and light
          labels: {
            colors: "#fff",
          },
        },
        dataLabels: {
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },

        states: {
          hover: {
            filter: "none",
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptionsItems: {
        chart: {
          width: 380,
          type: "donut",
          fontFamily: "kurdish",
        },
        stroke: {
          width: 0,
        },

        labels: [],
        //change labels position to bottom
        legend: {
          position: "bottom",
          //labels colo dark and light
          labels: {
            colors: "#fff",
          },
        },
        dataLabels: {
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },

        states: {
          hover: {
            filter: "none",
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptionsIncomes: {
        chart: {
          width: 380,
          type: "donut",
          fontFamily: "kurdish",
        },
        stroke: {
          width: 0,
        },

        labels: [],
        //change labels position to bottom
        legend: {
          position: "bottom",
          //labels colo dark and light
          labels: {
            colors: "#fff",
          },
        },
        dataLabels: {
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },

        states: {
          hover: {
            filter: "none",
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptionsExpenses: {
        chart: {
          width: 380,
          type: "donut",
          fontFamily: "kurdish",
        },
        stroke: {
          width: 0,
        },

        labels: [],
        //change labels position to bottom
        legend: {
          position: "bottom",
          //labels colo dark and light
          labels: {
            colors: "#fff",
          },
        },
        dataLabels: {
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },

        states: {
          hover: {
            filter: "none",
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      startDate: "",
      endDate: "",
      period: "last30days",
    };
  },
  computed: {
    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.home.isLoadingData;
    },
    items() {
      return this.$store.state.home.items;
    },
    headersSaleType() {
      return [
        { text: "#", value: "st_id", sortable: true },
        { text: this.$t("sale_type_name"), value: "st_name", sortable: true },
        {
          text: this.$t("sold_quantity"),
          value: "item_quantity_sold",
          sortable: true,
        },
        {
          text: this.$t("customer_count"),
          value: "invoice_count",
          sortable: true,
        },
        {
          text: this.$t("total_sale_dinar"),
          value: "total_sale_price_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_cost_dinar"),
          value: "total_cost_price_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_profit_dinar"),
          value: "total_profit_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_discount_dinar"),
          value: "total_discount_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_sale_dollar"),
          value: "total_sale_price_dollar",
          sortable: true,
        },
        {
          text: this.$t("total_cost_dollar"),
          value: "total_cost_price_dollar",
          sortable: true,
        },
        {
          text: this.$t("total_profit_dollar"),
          value: "total_profit_dollar",
          sortable: true,
        },
        {
          text: this.$t("total_discount_dollar"),
          value: "total_discount_dollar",
          sortable: true,
        },
      ];
    },
    headersMostSoldItem() {
      return [
        { text: "#", value: "i_id", sortable: true },
        { text: this.$t("item_name"), value: "i_name", sortable: true },
        {
          text: this.$t("sold_quantity"),
          value: "item_quantity_sold",
          sortable: true,
        },

        {
          text: this.$t("total_sale_dinar"),
          value: "total_sale_price_dinar",
          sortable: true,
        },

        {
          text: this.$t("total_profit_dinar"),
          value: "total_profit_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_discount_dinar"),
          value: "total_discount_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_sale_dollar"),
          value: "total_sale_price_dollar",
          sortable: true,
        },

        {
          text: this.$t("total_profit_dollar"),
          value: "total_profit_dollar",
          sortable: true,
        },
        {
          text: this.$t("total_discount_dollar"),
          value: "total_discount_dollar",
          sortable: true,
        },
      ];
    },
    headersNote() {
      return [
        { text: "#", value: "no_id", sortable: true },
        { text: this.$t("note_title"), value: "no_title", sortable: true },
        {
          text: this.$t("note_description"),
          value: "no_description",
          sortable: true,
        },
        { text: this.$t("admin"), value: "name", sortable: true },
        { text: this.$t("created_at"), value: "created_at", sortable: true },
      ];
    },
    headerWasteItem() {
      return [
        { text: "#", value: "i_id", sortable: true },
        {
          text: this.$t("wasted_item_name"),
          value: "i_name",
          sortable: true,
        },
        {
          text: this.$t("wasted_quantity"),
          value: "total_waste_quantity",
          sortable: true,
        },

        {
          text: this.$t("total_loss_in_dinar"),
          value: "total_cost_price_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_loss_in_dollar"),
          value: "total_cost_price_dollar",
          sortable: true,
        },
      ];
    },
    headersDebt() {
      return [
        { text: this.$t("debt_type"), value: "name", sortable: true },
        {
          text: this.$t("debts_count"),
          value: "total_debt_number",
          sortable: true,
        },
        {
          text: this.$t("total_debt_dinar"),
          value: "total_amount_dinar",
          sortable: true,
        },
        {
          text: this.$t("total_debt_dollar"),
          value: "total_amount_dollar",
          sortable: true,
        },
      ];
    },
  },
  async created() {
    /*console.log(await this.$store.dispatch("checkUserAccess", {
      id: "1",
      action: "view",
    }));*/
    /* console.log(
      this.$store.getters["checkUserAccess"]({
        id: "1",
        action: "view",
      })
    );*/
    this.startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
    this.getData(0); //change labelscolor dark and light
    if (this.isDark) {
      this.chartOptions.legend.labels.colors = "#fff";
      this.chartOptionsItems.legend.labels.colors = "#fff";
      this.chartOptionsIncomes.legend.labels.colors = "#fff";
      this.chartOptionsExpenses.legend.labels.colors = "#fff";
    } else {
      this.chartOptions.legend.labels.colors = "#000";
      this.chartOptionsItems.legend.labels.colors = "#000";
      this.chartOptionsIncomes.legend.labels.colors = "#000";
      this.chartOptionsExpenses.legend.labels.colors = "#000";
    }
  },
  methods: {
    getKurdishName(name) {
      if (name == "invoice") return this.$t("sale_due");
      else if (name == "purchase") return this.$t("purchase_due");
      else if (name == "borrow") return this.$t("borrow");
      else if (name == "lend") return this.$t("lend");
      else if (name == "returned_purchase") return this.$t("returned_purchase");
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    async getData(num) {
      await this.$store.dispatch("home/getItems", {
        startDate: this.startDate,
        endDate: this.endDate,
        invoiceType: this.invoiceType,
      });
      if (num == 0) {
        this.changeReportType();

        //wait 3 min
        setTimeout(() => {
          this.changeReportType();
        }, 100);
      } else {
        this.changeReportType();
      }
    },
    periodChanged(action) {
      this.period = action;

      if (action == "today") {
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "yesterday") {
        this.startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (action == "last7days") {
        this.startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "last30days") {
        this.startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
      } else if (action == "thismonth") {
        this.startDate = moment().startOf("month").format("YYYY-MM-DD");
        this.endDate = moment().endOf("month").format("YYYY-MM-DD");
      } else if (action == "lastmonth") {
        this.startDate = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.endDate = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (action == "lastyear") {
        this.startDate = moment()
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.endDate = moment()
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
      } else if (action == "thisyear") {
        this.startDate = moment().startOf("year").format("YYYY-MM-DD");
        this.endDate = moment().endOf("year").format("YYYY-MM-DD");
      } else if (action == "all") {
        this.startDate = "2000-01-01";
        this.endDate = moment().endOf("year").format("YYYY-MM-DD");
      }

      this.getData(1);
    },
    changeReportType() {
      this.saleType();
      this.mostSoldItem();
      this.incomes();
      this.expenses();
    },
    saleType() {
      var listServer = this.items.sale_type;
      //get top 5 items and for all other items make them one item sum
      if (listServer.length > 7) {
        var top7 = listServer.slice(0, 7);
        var other = listServer.slice(7);
        var sum = other.reduce((a, b) => {
          return {
            invoice_count:
              parseInt(a.invoice_count) + parseInt(b.invoice_count),
            st_name: this.$t("others"),
          };
        });
        top7.push(sum);
        listServer = top7;
      }

      var st_name = listServer.map((item) => item.st_name);
      var values = listServer.map((item) => {
        return parseFloat(item.invoice_count);
      });

      this.chartOptions = {
        ...this.chartOptions,
        labels: st_name,
      };
      this.series = values;
    },
    mostSoldItem() {
      var listServer = this.items.item_sale;
      //get top 5 items and for all other items make them one item sum
      if (listServer.length > 7) {
        var top7 = listServer.slice(0, 7);
        var other = listServer.slice(7);
        var sum = other.reduce((a, b) => {
          return {
            item_quantity_sold:
              parseInt(a.item_quantity_sold) + parseInt(b.item_quantity_sold),
            i_name: this.$t("others"),
          };
        });
        top7.push(sum);
        listServer = top7;
      }

      var i_name = listServer.map((item) => item.i_name);
      var values = listServer.map((item) => {
        return parseFloat(item.item_quantity_sold);
      });

      this.chartOptionsItems = {
        ...this.chartOptionsItems,
        labels: i_name,
      };
      this.seriesItems = values;
    },
    incomes() {
      var listServer = this.items.income_category;
      //get top 5 items and for all other items make them one item sum
      if (listServer.length > 7) {
        var top7 = listServer.slice(0, 7);
        var other = listServer.slice(7);
        var sum = other.reduce((a, b) => {
          return {
            total_income_number:
              parseInt(a.total_income_number) + parseInt(b.total_income_number),
            name: this.$t("others"),
          };
        });
        top7.push(sum);
        listServer = top7;
      }

      var name = listServer.map((item) => item.ic_name);
      var values = listServer.map((item) => {
        return parseFloat(item.total_income_number);
      });

      this.chartOptionsIncomes = {
        ...this.chartOptionsIncomes,
        labels: name,
      };
      this.seriesIncomes = values;
    },
    expenses() {
      var listServer = this.items.expense_category;
      //get top 5 items and for all other items make them one item sum
      if (listServer.length > 7) {
        var top7 = listServer.slice(0, 7);
        var other = listServer.slice(7);
        var sum = other.reduce((a, b) => {
          return {
            total_expense_number:
              parseInt(a.total_expense_number) +
              parseInt(b.total_expense_number),
            name: this.$t("others"),
          };
        });
        top7.push(sum);
        listServer = top7;
      }

      var name = listServer.map((item) => item.exc_name);
      var values = listServer.map((item) => {
        return parseFloat(item.total_expense_number);
      });

      this.chartOptionsExpenses = {
        ...this.chartOptionsExpenses,
        labels: name,
      };
      this.seriesExpenses = values;
    },
  },
};
</script>
