<template>
  <HeaderApp />
  <div
  :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("settings") }}
      </p>
    </div>

    <div class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="grid md:grid-cols-2">
        <form @submit="updateSettings">
          <BaseInput
            type="number"
            v-model="set_expire_day_reminder"
            :label="
              $t(
                'how_much_date_before_expire_date_you_want_to_get_notification'
              )
            "
            :placeholder="
              $t(
                'how_much_date_before_expire_date_you_want_to_get_notification'
              )
            "
            :rules="rules"
            :isError="checkRulesIsError(set_expire_day_reminder, 'add').isError"
            :errorMsg="
              checkRulesIsError(set_expire_day_reminder, 'add').errorMsg
            "
          />
          <BaseInput
            type="number"
            v-model="set_item_less"
            :label="
              $t(
                'how_much_item_quantity_before_shortage_you_want_to_get_notification'
              )
            "
            :placeholder="
              $t(
                'how_much_item_quantity_before_shortage_you_want_to_get_notification'
              )
            "
            :rules="rules"
            :isError="checkRulesIsError(set_item_less, 'add').isError"
            :errorMsg="checkRulesIsError(set_item_less, 'add').errorMsg"
          />
          <BaseInput
            type="text"
            v-model="app_name"
            :label="$t('shop_name')"
            :placeholder="$t('shop_name')"
            :rules="rules"
            :isError="checkRulesIsError(app_name, 'add').isError"
            :errorMsg="checkRulesIsError(app_name, 'add').errorMsg"
          />
          <BaseInput
            type="number"
            v-model="set_phone_number"
            :label="$t('phone_number_on_invoices')"
            :placeholder="$t('phone_number_on_invoices')"
            :rules="rules"
            :isError="checkRulesIsError(set_phone_number, 'add').isError"
            :errorMsg="checkRulesIsError(set_phone_number, 'add').errorMsg"
          />
          <BaseInput
            type="text"
            v-model="set_header_invoice"
            :label="$t('header_text_on_invoices')"
            :placeholder="$t('header_text_on_invoices')"
            :rules="rules"
            :isError="checkRulesIsError(set_header_invoice, 'add').isError"
            :errorMsg="checkRulesIsError(set_header_invoice, 'add').errorMsg"
          />
          <BaseInput
            type="text"
            v-model="set_header_purchase"
            :label="$t('header_text_on_purchases')"
            :placeholder="$t('header_text_on_purchases')"
            :rules="rules"
            :isError="checkRulesIsError(set_header_purchase, 'add').isError"
            :errorMsg="checkRulesIsError(set_header_purchase, 'add').errorMsg"
          />
          <BaseInput
            type="text"
            v-model="set_header_return_purchase"
            :label="$t('header_text_on_returned_purchases')"
            :placeholder="$t('header_text_on_returned_purchases')"
            :rules="rules"
            :isError="
              checkRulesIsError(set_header_return_purchase, 'add').isError
            "
            :errorMsg="
              checkRulesIsError(set_header_return_purchase, 'add').errorMsg
            "
          />
          <div>
            <label
              for="image"
              class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
            >
              {{ $t("logo_on_invoices") }}</label
            >
            <label
              for="image"
              class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
            >
              <i class="fa fa-cloud-upload mx-1"></i>
              {{ set_logo == "" ? $t("logo_on_invoices") : set_logo.name }}
            </label>
            <input
              type="file"
              v-on:change="onChange"
              :placeholder="$t('logo_on_invoices')"
              id="image"
              class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 remove-arrow"
            />
          </div>
          <br />
          <div class="flex justify-center">
            <img
              v-if="saved_logo"
              :src="file_url + saved_logo"
              class="w-40 h-40 rounded-full"
            />
          </div>
          <br />
          <ButtonInput
            type="button"
            @click="updateSettings"
            :label="$t('edit')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>

  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import { FILE_URL } from "../../constants/config";
export default {
  name: "CHANGE_PASSWRD",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ConfirmDialogue,
    ResultDialog,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      set_expire_day_reminder: "",
      set_item_less: "",
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      set_phone_number: "",
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      set_header_invoice: "",
      set_header_purchase: "",
      set_header_return_purchase: "",
      set_logo: "",
      saved_logo: "",
      app_name: "",
      file_url: FILE_URL,
    };
  },
  created() {
    this.getSettings();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.settings.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.settings.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("settings/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.settings.isShowResultDialog;
    },
    currentSetting() {
      return this.$store.state.settings.settings;
    },

    isDark() {
      return this.$store.state.theme == "dark";
    },
    isRtl() {
      return (
        localStorage.getItem("lang_wise_manager") == "ar" ||
        localStorage.getItem("lang_wise_manager") == "ku" ||
        //if null
        localStorage.getItem("lang_wise_manager") == null ||
        //if empty
        localStorage.getItem("lang_wise_manager") == ""
      );
    },
    isBtnLoading() {
      return this.$store.state.settings.isBtnLoading;
    },
  },

  methods: {
    onChange(e) {
      this.set_logo = e.target.files[0];e.target.value = '';
    },
    async getSettings() {
      await this.$store.dispatch("settings/getSettings", {});

      this.set_expire_day_reminder =
        this.currentSetting.set_expire_day_reminder;
      this.set_item_less = this.currentSetting.set_item_less;
      this.app_name = this.currentSetting.app_name;
      this.set_phone_number = this.currentSetting.set_phone_number;
      this.set_header_invoice = this.currentSetting.set_header_invoice;
      this.set_header_purchase = this.currentSetting.set_header_purchase;
      this.set_header_return_purchase =
        this.currentSetting.set_header_return_purchase;
      this.saved_logo = this.currentSetting.set_logo;
      localStorage.setItem(
        "currentSettingWiseManager",
        JSON.stringify(this.currentSetting)
      );
    },
    async updateSettings(e) {
      e.preventDefault();
      //check form validation
      if (this.$store.state.settings.isBtnLoading) return;
     
      this.isSubmited = true;
      if (
        (this.checkRulesIsError(this.set_item_less, "add").isError,
        this.checkRulesIsError(this.set_expire_day_reminder, "add").isError)
      ) {
        return;
      }

      this.$store.state.settings.isBtnLoading = true;
      var res = await this.$store.dispatch("settings/updateSettings", {
        set_expire_day_reminder: this.set_expire_day_reminder,
        set_item_less: this.set_item_less,
        set_id: this.currentSetting.set_id,
        app_name: this.app_name,
        set_phone_number: this.set_phone_number,
        set_header_invoice: this.set_header_invoice,
        set_header_purchase: this.set_header_purchase,
        set_header_return_purchase: this.set_header_return_purchase,
        set_logo: this.set_logo,
      });
      if (res.status == 200) {
        this.isSubmited = false;
        this.getSettings();
      }
    },
    checkRulesIsError(value, type) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of this.rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
  },
};
</script>
