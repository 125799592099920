<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 h-screen dark:bg-bgFullDark rounded-2xl no-print text-center items-center ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
  <br/><br/><br/><br/>
    <img src="../../assets/logo.png" alt="" class="mx-auto my-auto" />
  </div>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";

export default {
  name: "HelloWorld",
  components: {
    HeaderApp,
  },
  data() {
    return {};
  },
};
</script>
