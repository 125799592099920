<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("change_password") }}
      </p>
    </div>

    <div class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
      <div class="grid md:grid-cols-2">
        <form @submit="updatePassword">
          <BaseInput
            type="password"
            v-model="old_password"
            :label="$t('old_password')"
            :placeholder="$t('old_password')"
            :rules="rules"
            :isError="checkRulesIsError(old_password, 'add').isError"
            :errorMsg="checkRulesIsError(old_password, 'add').errorMsg"
          />
          <BaseInput
            type="password"
            v-model="new_password"
            :label="$t('new_password')"
            :placeholder="$t('new_password')"
            :rules="rules"
            :isError="checkRulesIsError(new_password, 'add').isError"
            :errorMsg="checkRulesIsError(new_password, 'add').errorMsg"
          />
          <BaseInput
            type="password"
            v-model="new_password_retype"
            :label="$t('new_password_retype')"
            :placeholder="$t('new_password_retype')"
            :rules="rules"
            :isError="checkRulesIsError(new_password_retype, 'add').isError"
            :errorMsg="checkRulesIsError(new_password_retype, 'add').errorMsg"
          />
          <label
            for="language"
            class="block mb-2 text-base font-medium text-textBlack1 dark:text-textWhite1"
            >{{ $t("language") }}</label
          >
          <select
            id="boxType"
            class="bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="lang"
            @change="changeLanguage"
          >
            <option value="ku">KURDISH</option>
            <option value="en">ENGLISH</option>
            <option value="ar">ARABIC</option>
          </select>
          <BR />

          <ButtonInput
            type="button"
            @click="updatePassword"
            :label="$t('edit')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>

  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";

export default {
  name: "CHANGE_PASSWRD",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ConfirmDialogue,
    ResultDialog,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      old_password: "",
      new_password: "",
      new_password_retype: "",
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      lang: localStorage.getItem("lang_wise_manager"),
      langs: [
        {
          id: "ku",
          value: "Kurdish",
        },
        {
          id: "en",
          value: "English",
        },

        {
          id: "ar",
          value: "Arabic",
        },
      ],
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
    };
  },
  created() {
    //check if lang_wise_manager is null or empty
    if (
      localStorage.getItem("lang_wise_manager") == null ||
      localStorage.getItem("lang_wise_manager") == ""
    ) {
      localStorage.setItem("lang_wise_manager", "ku");
      this.lang = "ku";
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.change_password.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.change_password.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("change_password/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.change_password.isShowResultDialog;
    },

    isDark() {
      return this.$store.state.theme == "dark";
    },
    isRtl() {
      return (
        localStorage.getItem("lang_wise_manager") == "ar" ||
        localStorage.getItem("lang_wise_manager") == "ku" ||
        //if null
        localStorage.getItem("lang_wise_manager") == null ||
        //if empty
        localStorage.getItem("lang_wise_manager") == ""
      );
    },
    isBtnLoading() {
      return this.$store.state.change_password.isBtnLoading;
    },
  },

  methods: {
    async updatePassword(e) {
      e.preventDefault();
      //check form validation
      if (this.$store.state.change_password.isBtnLoading) return;
     
      this.isSubmited = true;
      if (
        (this.checkRulesIsError(this.new_password, "add").isError,
        this.checkRulesIsError(this.new_password_retype, "add").isError)
      ) {
        return;
      }
      if (this.new_password != this.new_password_retype) {
        this.$refs.ResultDialog.show({
          message: this.$t("new_password_and_new_password_retype_not_match"),
          okButton: this.$t("done"),
          isSuccess: false,
        });
        return;
      }
      this.$store.state.change_password.isBtnLoading = true;
      var res = await this.$store.dispatch("change_password/updatePassword", {
        old_password: this.old_password,
        new_password: this.new_password,
      });
      if (res.status == 200) {
        this.new_password = "";
        this.new_password_retype = "";
        this.old_password = "";
        this.isSubmited = false;
      }
    },
    checkRulesIsError(value, type) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of this.rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    changeLanguage() {
      localStorage.setItem("lang_wise_manager", this.lang);
      //refresh page
      location.reload();
    },
  },
};
</script>
