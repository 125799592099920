<template>
  <HeaderApp />
  <LoadingProgress v-show="!isShowDebtDetail" />
  <div
    :class="
      ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
    v-if="isShowDebtDetail"
    id="printableArea"
  >
    <div
      class="w-full p-4 bg-white dark:bg-bgLightDark rounded-2xl center-table"
    >
      <div
        class="flex justify-between mb-6 items-center border-b-2 border-gray-700 dark:border-gray-400 pb-4"
      >
        <div class="flex-col">
          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("debt_id") }} :
            </p>
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ debtDetail.deb_id }}#
            </p>
          </div>
          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("name_person_debt") }}:
            </p>
            <p class="text-textBlack dark:text-textWhite text-base">
              {{
                debtDetail.customer != null
                  ? debtDetail.customer.cu_name
                  : debtDetail.suplier.su_name
              }}
            </p>
          </div>

          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("admin") }} :
            </p>
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ debtDetail.users.name }}
            </p>
          </div>
        </div>
        <div class="flex flex-col items-center">
          <img
            src="../../assets/logo.png"
            alt=""
            width="180"
            height="180"
            v-if="settings.set_logo == '' || settings.set_logo == null"
          /><img
            :src="file_url + settings.set_logo"
            alt=""
            width="180"
            height="180"
          />
          <p
            class="text-textBlack3 dark:text-textWhite3 text-2xl font-semibold mt-2"
          >
            {{ settings.app_name }}
          </p>
        </div>

        <div class="flex-col">
          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("invoice_type") }} :
            </p>
            <p
              class="text-textBlack dark:text-textWhite text-base font-semibold"
            >
              {{ $t("debt") }}
            </p>
          </div>
          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("date") }} :
            </p>
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ formatDate(debtDetail.created_at).toString().slice(0, 10) }}
            </p>
          </div>

          <div class="flex">
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ $t("time") }} :
            </p>
            <p class="text-textBlack dark:text-textWhite text-base">
              {{ formatDate( debtDetail.created_at).toString().slice(10, 16) }}
            </p>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table
          class="table-auto text-center w-full border-collapse border border-gray-400 dark:border-gray-800"
        >
          <tbody>
            <tr>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("money_amount") }}
              </td>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2 font-bold text-xl"
              >
                {{ formatNumber(debtDetail.deb_amount) }}
                {{ $t(debtDetail.currency.cur_name) }}
              </td>
            </tr>
            <tr>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("debt_type") }}
              </td>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ getKurdishName(debtDetail.deb_type) }}
              </td>
            </tr>
            <tr v-if="debtDetail.deb_wasl != '' && debtDetail.deb_wasl != null">
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("debt_invoice_number") }}
              </td>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ debtDetail.deb_wasl }}
              </td>
            </tr>
            <tr v-if="debtDetail.pu_id != '' && debtDetail.pu_id != null">
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("purchase_id") }}
              </td>
              <td
                class="text-primaryColor underline dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
                @click="goToLink('/edit-purchase/' + debtDetail.pu_id)"
              >
                {{ debtDetail.pu_id }}
              </td>
            </tr>
            <tr v-if="debtDetail.in_id != '' && debtDetail.in_id != null">
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("invoice_id") }}
              </td>
              <td
                class="text-primaryColor underline dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
                @click="goToLink('/sale-pos/' + debtDetail.in_id)"
              >
                {{ debtDetail.in_id }}
              </td>
            </tr>
            <tr v-if="debtDetail.deb_note != '' && debtDetail.deb_note != null">
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("note") }}
              </td>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ debtDetail.deb_note }}
              </td>
            </tr>
            <tr
              v-if="
                debtDetail.deb_return_date != '' &&
                debtDetail.deb_return_date != null
              "
            >
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{ $t("debt_return_date") }}
              </td>
              <td
                class="text-textBlack dark:text-textWhite border border-gray-200 dark:border-gray-900 px-4 py-2"
              >
                {{
                  formatDate(debtDetail.deb_return_date).toString().slice(0, 10)
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button
      class="noPrint text-white text-lg md:text-xl bg-primaryColor px-6 md:px-16 py-2 my-6 rounded-2xl items-center hover:shadow-md"
      @click="printDiv()"
    >
      {{ $t("print") }}
    </button>
  </div>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import LoadingProgress from "../../components/loading-progress.vue";
import { formatDate } from "../../utils/functions";
import { CURRENCYS } from "../../constants/config";
import { formatNumber } from "../../utils/functions";
import { FILE_URL } from "../../constants/config";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    LoadingProgress,
  },
  data() {
    return {
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      no_rule: [],
      isSubmited: false,
      currencys: CURRENCYS,
      rules_dropdown: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_choose_an_option");
        },
      ],
      cur_id: "",
      exc_id: "",
      bo_id: "",
      cu_id: "",
      deb_note: "",
      file_url: FILE_URL,
      deb_wasl: "",
      deb_amount: "",
      created_at: "",
      deb_image: "",
      settings: {},
    };
  },
  created() {
    this.settings = JSON.parse(
      localStorage.getItem("currentSettingWiseManager")
    );
    this.getDebtDetail();
  },

  computed: {
    isDark() {
      return this.$store.state.theme == "dark";
    },
    //isShowDebtDetail
    isShowDebtDetail() {
      return this.$store.state.debt_list.isShowDebtDetail;
    },
    debtDetail() {
      return this.$store.state.debt_list.debtDetail;
    },
  },

  methods: {
    getKurdishName(name) {
      if (name == 3) return this.$t("sale_due");
      else if (name == 2) return this.$t("purchase_due");
      else if (name == 0) return this.$t("borrow");
      else if (name == 1) return this.$t("lend");
      else if (name == 4) return this.$t("return_to_suplier_by_due");
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    formatDate(date) {
      return formatDate(date);
    },
    async getDebtDetail() {
      var res = await this.$store.dispatch(
        "debt_list/showDebt",
        this.$route.params.id
      ); //check status
      if (res.status != 200) {
        window.location.href = "/404";
      }
    },
    goToLink(link) {
      //bland window
      window.open(link, "_blank");
    },
    printDiv() {
      var printContents = document.getElementById("printableArea").innerHTML;
      var originalContents = document.body.innerHTML;
      //rtl
      document.body.dir = "rtl";
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
  },
};
</script>
<style scoped>
@media print {
  .noPrint {
    display: none;
  }
}
</style>
