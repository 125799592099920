<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl no-print ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("returned_invoice") }}
      </p>
    </div>

    <div
      class="m-2 p-3 bg-white dark:bg-bgMeduimDark rounded-2xl"
      v-if="
        $store.getters['checkUserAccess']({
          id: '20',
          action: 'add',
        })
      "
    >
      <div class="grid md:grid-cols-2">
        <form @submit="AddItem">
          <BaseInput
            v-model="in_id"
            :label="$t('invoice_id')"
            :placeholder="$t('invoice_id')"
            :rules="rules"
            :isError="checkRulesIsError(in_id, 'add', this.rules).isError"
            :errorMsg="checkRulesIsError(in_id, 'add', this.rules).errorMsg"
          />
          <TextAreaInput
            v-model="re_note"
            :label="$t('returned_invoice_note')"
            :placeholder="$t('returned_invoice_note')"
            :rules="rules"
            :isError="checkRulesIsError(re_note, 'add', this.rules).isError"
            :errorMsg="checkRulesIsError(re_note, 'add', this.rules).errorMsg"
          /><BaseInput
            v-model="created_at"
            type="date"
            :label="$t('returned_invoice_date')"
            :placeholder="$t('returned_invoice_date')"
            :rules="[]"
            :isError="checkRulesIsError(created_at, 'add', []).isError"
            :errorMsg="checkRulesIsError(created_at, 'add', []).errorMsg"
          />
          <ButtonInput
            type="button"
            @click="AddItem"
            :label="$t('add')"
            :isLoading="isBtnLoading"
          />
        </form>
      </div>
    </div>
  </div>

  <div
    class="w-full"
    id="printDev"
    v-if="
      $store.getters['checkUserAccess']({
        id: '20',
        action: 'view',
      })
    "
  >
    <InvoiceHeader invoice_type="wasted_items_list" v-if="isHide == true" />
    <div class="flex mb-4" v-if="isHide == true">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("returned_invoice") }}
      </p>
    </div>

    <div
      :class="
        ' mx-2 mt-14 mb-5 p-3 h-full bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
        (isHide == true
          ? '  '
          : $store.getters['isRtl']
          ? ' md:mr-60 '
          : ' md:ml-60  ')
      "
    >
      <div class="my-2 py-3 bg-white dark:bg-bgMeduimDark rounded-2xl">
        <div class="relative overflow-x-auto mt-4 mb-6">
          <div class="flex items-center justify-between pb-4 mx-5 no-print">
            <label for="table-search" class="sr-only">{{ $t("search") }}</label>
            <div class="relative">
              <!--in ltr change (right-0 to left-0) (pr-3 to pl-3)-->
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
              >
                <svg
                  class="w-5 h-5 text-gray-400 dark:text-gray-500"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <!--in ltr change (pr-10 to pl-10)-->
              <input
                type="text"
                id="table-search"
                class="block w-40 md:w-full p-2 pr-10 text-sm bg-slate-100 border border-gray-300 text-textBlack1 placeholder-gray-400 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-bgFullDark dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                :placeholder="$t('search')"
                @input="
                  $store.commit(
                    'returns_invoice/SET_SEARCH',
                    $event.target.value
                  ),
                    loadFromServer(),
                    (serverOptions.page = 1)
                "
              />
            </div>
            <button
              class="mx-2 my_font bg-primaryColor hover:bg-blue-700 text-white font-bold py-1 px-4 rounded no-print"
              @click="printReportFunc"
              id="button_print"
            >
              {{ $t("print") }}
            </button>
          </div>
          <!--in ltr change (text-right to text-left)-->
          <EasyDataTable
            v-model:server-options="serverOptions"
            :headers="headers"
            :items="items"
            :server-items-length="serverItemsLength"
            :loading="isLoadingData"
            alternating
            :class="isDark ? 'customize-table ' : 'light-mode-table'"
            :buttons-pagination="true"
            :body-text-direction="$store.getters['isRtl'] ? 'right' : 'left'"
            :hide-footer="isHide"
            :rows-items="[25, 50, 100, serverItemsLength]"
            rows-per-page="10"
          >
            <template #default="{ column, sortDirection }">
              <span class="custom-sort-icon" v-if="column.sortable">
                <i
                  v-if="sortDirection === 'asc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i
                  v-else-if="sortDirection === 'desc'"
                  class="fa-solid fa-arrow-up-wide-short"
                ></i>
                <i v-else class="fa-solid fa-arrow-up-wide-short"></i>
              </span>
              {{ column.label }}
            </template>
            <template #item-actions="item">
              <button
                class="mx-1 my-1"
                @click="showModal(item)"
                v-if="
                  $store.getters['checkUserAccess']({
                    id: '20',
                    action: 'edit',
                  })
                "
              >
                <i
                  class="fa-solid fa-pen text-center p-1 w-7 h-7 text-sm bg-blue-200 dark:bg-blue-300 rounded-md text-blue-500 mx-auto"
                >
                  {{ item.text }}
                </i>
              </button>
              <button
                class="mx-1 my-1"
                @click="deleteItem(item.re_id)"
                v-if="
                  $store.getters['checkUserAccess']({
                    id: '20',
                    action: 'delete',
                  })
                "
              >
                <i
                  class="fa-solid fa-trash text-center p-1 w-7 h-7 text-sm bg-red-200 dark:bg-red-300 rounded-md text-red-500 mx-auto"
                ></i>
              </button>
            </template>
            <template
              #item-in_id="item"
              v-if="
                $store.getters['checkUserAccess']({
                  id: '19',
                  action: 'detail',
                })
              "
            >
              <a
                class="text-primaryColor text-underline"
                :href="'sale-pos/' + item.in_id"
              >
                {{ item.in_id }}
              </a>
            </template>
            <template #loading>
              <img
                src="../../assets/loading.webp"
                style="width: 70px; height: 70px"
              />
            </template>
            <template #item-created_at="item">
              {{ formatDate(item.created_at) }} </template
            ><template #empty-message>
              <span class="text-textBlack1 dark:text-white">
                {{ $t("no_data_found") }}
              </span>
            </template>
            <template
              #pagination="{
                prevPage,
                nextPage,
                isFirstPage,
                isLastPage,
                currentPaginationNumber,
                maxPaginationNumber,
              }"
            >
              <button :disabled="isFirstPage" @click="goToPage(1)">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-angle-double-right'
                      : ' fa-angle-double-left')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button :disabled="isFirstPage" @click="prevPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-angle-right'
                      : ' fa-angle-left ')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button
                v-for="page in getVisiblePages(
                  currentPaginationNumber,
                  maxPaginationNumber
                )"
                :key="page"
                @click="goToPage(page)"
                :class="getPageButtonClasses(page, currentPaginationNumber)"
              >
                {{ page }}
              </button>

              <div class="w-5"></div>
              <button :disabled="isLastPage" @click="nextPage">
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-angle-left'
                      : ' fa-angle-right')
                  "
                ></i>
              </button>
              <div class="w-5"></div>
              <button
                :disabled="isLastPage"
                @click="goToPage(maxPaginationNumber)"
              >
                <i
                  :class="
                    'fa-sharp fa-solid ' +
                    ($store.getters['isRtl']
                      ? ' fa-angle-double-left'
                      : ' fa-angle-double-right')
                  "
                ></i>
              </button>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </div>
  </div>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
  <edit-modal ref="editModal">
    <div class="items-center">
      <form @submit="editItem">
        <TextAreaInput
          v-model="current.re_note"
          :label="$t('returned_invoice_note')"
          :placeholder="$t('returned_invoice_note')"
          :rules="rules"
          :isError="
            checkRulesIsError(current.re_note, 'edit', this.rules).isError
          "
          :errorMsg="
            checkRulesIsError(current.re_note, 'edit', this.rules).errorMsg
          "
        /><BaseInput
          v-model="current.created_at"
          type="date"
          :label="$t('returned_invoice_date')"
          :placeholder="$t('returned_invoice_date')"
          :rules="[]"
          :isError="checkRulesIsError(current.created_at, 'edit', []).isError"
          :errorMsg="checkRulesIsError(current.created_at, 'edit', []).errorMsg"
        />
        <center>
          <ButtonInput
            type="button"
            @click="editItem"
            :label="$t('edit')"
            :isLoading="isBtnEditLoading"
          />
        </center>
      </form>
    </div>
  </edit-modal>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import TextAreaInput from "../../components/Inputs/TextAreaInput.vue";
import ButtonInput from "../../components/Inputs/ButtonInput.vue";
import { formatDate } from "../../utils/functions";
import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";
import EditModal from "../../components/Modals/EditModal.vue";
// eslint-disable-next-line no-unused-vars
import { Header, ServerOptions, Item } from "vue3-easy-data-table";
import InvoiceHeader from "../../components/Navbar/InvoiceHeader.vue";
export default {
  name: "ADD_SALE_TYPE",
  components: {
    HeaderApp,
    BaseInput,
    ButtonInput,
    ConfirmDialogue,
    ResultDialog,
    EditModal,
    TextAreaInput,
    InvoiceHeader,
  },
  data() {
    return {
      firstName: "",
      rules: [
        (value) => {
          if (value) return true;

          return this.$t("you_must_fill_this_field");
        },
      ],
      in_id: "",
      re_note: "",
      inputType: "text",
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      created_at: "",
      items: [],
      serverItemsLength: 0,
      serverOptions: {
        page: 1,
        rowsPerPage: 5,
      },
      loading: false,
      isHide: false,
    };
  },
  created() {
    if (
      this.$store.getters["checkUserAccess"]({
        id: "20",
        action: "view",
      })
    ) {
      this.getData();
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.returns_invoice.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.returns_invoice.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("returns_invoice/SET_DELETE_SUCCESS", false);
      }
    },
    serverOptions: {
      handler() {
        this.loadFromServer();
      },
      deep: true,
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.returns_invoice.isShowResultDialog;
    },

    isDark() {
      return this.$store.state.theme == "dark";
    },
    isLoadingData() {
      return this.$store.state.returns_invoice.isLoadingData;
    },
    isBtnLoading() {
      return this.$store.state.returns_invoice.isBtnLoading;
    },
    isBtnEditLoading() {
      return this.$store.state.returns_invoice.isBtnEditLoading;
    },
    headers() {
      var listHeader = [
        { text: "#", value: "re_id", sortable: true },
        { text: this.$t("invoice_id"), value: "in_id", sortable: true },
        { text: this.$t("invoice_note"), value: "re_note", sortable: true },
        { text: this.$t("admin"), value: "users.name", sortable: true },
        { text: this.$t("created_at"), value: "created_at", sortable: true },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ];
      //remove actions column if isHide is true
      if (this.isHide == true) {
        listHeader.pop();
      }
      return listHeader;
    },
  },
  mounted() {
    // First load when the component is mounted
    this.loadFromServer();
  },
  methods: {
    printReportFunc() {
      this.isHide = true;
      //wait 1 second
      setTimeout(() => {
        var printContents = document.getElementById("printDev").innerHTML;

        // Replace the body's innerHTML with the print contents
        document.body.innerHTML = printContents;
        var isRtl = this.$store.getters["isRtl"];
        // Set direction based on the parameter
        if (isRtl) {
          document.body.style.direction = "rtl";
        } else {
          document.body.style.direction = "ltr";
        }

        // Print the content
        window.print();
        //reload page
      }, 100);
      //print element id invoice_print
    },
    getVisiblePages(currentPage, totalPages) {
      const numVisibleButtons = 3; // Adjust this number based on your design preference
      const pages = [];

      // Display pages 1 to numVisibleButtons (e.g., 1, 2, 3)
      if (currentPage <= Math.floor(numVisibleButtons / 2) + 1) {
        for (let i = 1; i <= Math.min(totalPages, numVisibleButtons); i++) {
          pages.push(i);
        }
      }
      // Display pages around the current page (e.g., 1, ..., 10, ..., 20)
      else {
        const startPage = Math.max(
          1,
          currentPage - Math.floor(numVisibleButtons / 2)
        );
        const endPage = Math.min(totalPages, startPage + numVisibleButtons - 1);

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
      }

      return pages;
    },

    // Function to get Tailwind CSS classes for each page button
    getPageButtonClasses(page, currentPage) {
      return {
        "bg-blue-500 text-white": page === currentPage,
        "bg-gray-300 text-gray-700 hover:bg-gray-400": page !== currentPage,
        "p-2 rounded-md mx-1": true, // Adjust padding and rounding based on your design
      };
    },

    goToPage(page) {
      // Implement your logic to update the current page
      //update page
      this.serverOptions.page = page;
      this.loadFromServer();
    },
    async loadFromServer() {
      this.loading = true;

      var tempObj = { ...this.serverOptions };
      //add new field to object search
      tempObj.search = this.$store.state.returns_invoice.search;
      const res = await this.$store.dispatch(
        "returns_invoice/getItems",
        tempObj
      );

      this.items = res.data.data;
      this.serverItemsLength = res.data.total;
      this.loading = false;
    },
    routeTo(href) {
      //go href and refresh page
      this.$store.commit("edit_sale/SET_EDIT_SALE_CLEAR", {});
      this.$router.push({ path: href }).catch(() => {});
      //clear edit_sale state module
    },
    formatDate(date) {
      return formatDate(date);
    },
    getData() {
      //this.$store.dispatch("returns_invoice/getItems");
    },
    async AddItem() {
      //check form validation
      if (this.$store.state.returns_invoice.isBtnLoading) return;

      this.isSubmited = true;
      if (
        this.checkRulesIsError(this.in_id, "add", this.rules).isError ||
        this.checkRulesIsError(this.re_note, "add", this.rules).isError
      ) {
        return;
      }
      this.$store.state.returns_invoice.isBtnLoading = true;
      var res = await this.$store.dispatch("returns_invoice/addNewItem", {
        in_id: this.in_id,
        re_note: this.re_note,
        created_at: this.created_at,
      });
      if (res.status == 200) {
        this.in_id = "";
        this.re_note = "";
        this.isSubmited = false;
        var tempObj = { ...this.serverOptions };
        const res = await this.$store.dispatch(
          "returns_invoice/getItems",
          tempObj
        );

        this.items = res.data.data;
        this.serverItemsLength = res.data.total;
        this.loading = false;
        this.$store.state.returns_invoice.isBtnLoading = false;
      }
    },
    checkRulesIsError(value, type, rules) {
      var isError = false;
      var errorMsg = "";
      if (type == "add") {
        if (this.isSubmited == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }
      if (type == "edit") {
        if (this.isSubmitedEdit == false)
          return {
            isError: false,
            errorMsg: "",
          };
      }

      for (const rule of rules) {
        if (rule(value) !== true) {
          isError = true;
          errorMsg = rule(value);
          // Stop the loop
          break;
        } else {
          isError = false;
          errorMsg = "";
        }
      }

      return {
        isError: isError,
        errorMsg: errorMsg,
      };
    },
    async deleteItem(id) {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$t("are_you_sure_remove_this_item") + id,
        okButton: this.$t("delete"),
      });
      if (ok) {
        //remove item from items array when re_id is equal to id
        this.items = this.items.filter((item) => item.re_id !== id);
        await this.$store.dispatch("returns_invoice/deleteItem", id);
        this.loadFromServer();
      }
    },
    //edit
    async showModal(item) {
      this.current = item;
      this.current.created_at = this.current.created_at
        .toString()
        .split("T")[0];
      await this.$refs.editModal.show();
    },
    async editItem(e) {
      e.preventDefault();
      this.isSubmitedEdit = true;
      if (
        this.checkRulesIsError(this.current.re_id, "edit", this.rules)
          .isError ||
        this.checkRulesIsError(this.current.re_note, "edit", this.rules).isError
      ) {
        return;
      }
      this.$store.state.returns_invoice.isBtnEditLoading = true;
      var res = await this.$store.dispatch("returns_invoice/editItem", {
        re_id: this.current.re_id,
        re_note: this.current.re_note,
        created_at: this.current.created_at,
      });

      this.isSubmitedEdit = false;
      this.$store.state.returns_invoice.isBtnEditLoading = false;
      if (res.status == 200) {
        this.$refs.editModal.hide();
        this.loadFromServer();
      }
    },
  },
};
</script>
